import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'deltoid/core/Checkbox';

const PermissionCheckbox = ({
    input: { value, onChange },
    meta: { touched, error },
    label
  }) => <div>
    <Checkbox
      checked={value}
      onChange={(_e, data) => onChange(data)}
      label={label}
    />
    {touched && error && <span>{error}</span>}
  </div>;
PermissionCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  rest: PropTypes.object
}
export default PermissionCheckbox;
