import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


export default function GenericModal(props) {
  const {
    isOpen,
    modalTitle,
    onClose,
    modalContentClassName,
    isCloseDisabled,
    modalClassName,
    bodyClassName,
    footer,
    headerClassName,
    footerClassName,
    ...rest
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      backdrop={false}
      fade={false}
      modalClassName={modalClassName}
      contentClassName={modalContentClassName}
      {...rest}
    >
      {!isCloseDisabled && <div className="close-section">
        <span tabIndex="0" className="close" role="button" onClick={onClose}>
          ×
        </span>
      </div>}
      {isCloseDisabled && <div className="p-2" />}
      {modalTitle && <ModalHeader className={headerClassName} tag="h4">{modalTitle}</ModalHeader>}
      <ModalBody className={`${bodyClassName} p-0 `}>{props.children}</ModalBody>
      {footer && <ModalFooter className={footerClassName}>{footer}</ModalFooter>}
    </Modal>
  );
}

GenericModal.defaultProps = {
  bodyClassName: '',
  footerClassName: '',
  headerClassName:'',
};
