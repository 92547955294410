import { config } from 'hub-redux-auth';

export const PLATFORM = 'ONBOARDING';
export const PRODUCT_NAME = () => ('PayU' + config.get('product', 'PAYU').toLowerCase().substring(4));
export const MERCHANT_ACCOUNT_PAGE_TITLE = () => (PRODUCT_NAME() + ' Merchant Account');
export const TEAMS_ACCOUNT_TITLE = 'PayU teams'

export const BUSINESS_ENTITIES = {
  PARTNERSHIP: 'Partnership',
  PRIVATE_LIMITED: 'Private Limited',
  PUBLIC_LIMITED: 'Public Limited',
  LLP: 'LLP',
  SOCIETY: 'Society',
  TRUST: 'Trust',
  GOVT: 'Government',
  INDIVIDUAL: 'Individual',
  NGO: 'NGO',
  ONE_PERSON_COMPANY: 'One Person Company',
  PROPRIETORSHIP: 'Sole Proprietorship'
};

export const DOCUMENT_STATUS = {
  COUNTER_SIGNED_RECIEVED: 'Counter Signed Received',
  DECLINED: 'Declined',
};

export const BUSINESS_ASSOCIATION_TYPE = {
  LLP: 'Partner',
  PRIVATE_LIMITED: 'Director',
  PUBLIC_LIMITED: 'Director',
  PARTNERSHIP: 'Partner',
  SOCIETY: 'Member',
  TRUST: 'Member'
}

export const TEAM_ACCOUNTS_LITERALS = {
  MANAGE_EMPLOYEE_HELP_TEXT: 'Manage employees and assign roles to provide restricted access to PayU dashboard.',
  MANAGE_ROLE_HELP_TEXT: 'Create, update and delete different roles and limit permissions for each role to access PayU dashboard.',
  MANAGE_EMPLOYEES_SCREEN_SUBHEADER: 'Add and Manage your employees to provide restricted access to PayU dashboard.',
  MANAGE_ROLES_SCREEN_SUBHEADER: 'Create, update and delete different roles and limit permissions for each role to access PayU dashboard.'
}

export const TEAM_ACCOUNTS_CONSTANTS = {
  MODAL_AUTO_CLOSE_TIMEOUT: 1000
}

export const PERMISSIONS_LIST = {
  BASIC_PERMISSIONS: 'basic_permissions',
  READ_TRANSCATIONS: 'read_transactions',
  READ_SETTLEMENTS: 'read_settlements',
  MANAGE_REFUNDS: 'manage_refunds',
  READ_DASHBOARD_PAYUMONEY: 'read_dashboard_payumoney',
  USER_PROFILE: 'user_profile',
  UPDATE_USER_PROFILE: 'update_user_profile',
  MANAGE_NOTIFICATIONS: 'manage_notifications',
  MANAGE_CHECKOUTS: 'manage_checkouts',
  MANAGE_WEBHOOKS: 'manage_webhooks',
  MANAGE_CREDENTIALS: 'manage_credentials',
  MANAGE_PAYMENT_PRODUCTS: 'manage_payment_products',
  MANAGE_ACCESS: 'manage_thirdparty_access',
  MANAGE_TEAM_ACCOUNTS: 'manage_team_accounts',
  RESTRICT_PERMISSION: 'update_profile_restricted'
}
