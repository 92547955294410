/*
 *
 * Profile actions
 *
 */

import * as C from '../constants/merchantTypes';
import API_ENDPOINTS from '../services/apiConfig';
import RestClient from '../services/RestClient';
import { toCamelCase } from '../services/utils';
import { HubRestClient } from 'hub-redux-auth';
import { HubAuthManager } from 'hub-redux-auth';
import { formatErrors }  from '../services/utils';

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
export async function fetchBusinessEntities(dispatch) {
  dispatch({ type: C.FETCH_BUSINESS_ENTITIES_INITIATED });
  try {
    const response = await RestClient.get(
      `${API_ENDPOINTS.ONBOARDING.BUSINESS_ENTITIES}`,
    );
    dispatch({
      type: C.FETCH_BUSINESS_ENTITIES_SUCCESS,
      payload: response.business_entities,
    });
  } catch (e) {
    dispatch({ type: C.FETCH_BUSINESS_ENTITIES_FAILURE, payload: [] });
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_BUSINESS_CATEGORIES
 */
export async function fetchBusinessCategories(dispatch) {
  dispatch({ type: C.FETCH_BUSINESS_CATEGORIES_INITIATED });
  try {
    const response = await RestClient.get(
      `${API_ENDPOINTS.ONBOARDING.BUSINESS_CATEGORIES}`,
    );
    dispatch({
      type: C.FETCH_BUSINESS_CATEGORIES_SUCCESS,
      payload: response.business_categories,
    });
  } catch (e) {
    dispatch({ type: C.FETCH_BUSINESS_CATEGORIES_FAILURE, payload: [] });
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_PRICING_PLANS
 */
export async function fetchPricingPlans(dispatch) {
  dispatch({ type: C.FETCH_PRICING_PLANS_INITIATED });
  try {
    const response = await RestClient.get(`${API_ENDPOINTS.ONBOARDING.PRICING_PLANS}`);
    dispatch({ type: C.FETCH_PRICING_PLANS_SUCCESS, payload: toCamelCase(response) });
  } catch (e) {
    dispatch({ type: C.FETCH_PRICING_PLANS_FAILURE, payload: [] });
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
export async function fetchCityStateFromPincode(dispatch, pincode) {
  try {
    const response = await RestClient.get(
      `${API_ENDPOINTS.ONBOARDING.PINCODE}/${pincode}`,
    );
    return response;
  } catch (e) {
    const body = await e.response;
    throw { message: body };
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {Promise} server response
 */
export async function fetchBankDetailsFromIfsc(dispatch, ifsc) {
  try {
    const response = await RestClient.get(
      `${API_ENDPOINTS.IFSC_DETAILS.IFSC}/${ifsc}`,
    );
    return response;
  } catch (e) {
    const body = await e.response;
    throw new Error('No data found for this IFSC');
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {Promise} server response
 */
export async function fetchIfscDetails(dispatch, { fetchFor, params }) {
  const { IFSC_DETAILS } = API_ENDPOINTS;
  let actionType;
  let endPoint;
  switch (fetchFor) {
    case 'BANK_NAMES':
      endPoint = IFSC_DETAILS.BANK_NAMES;
      actionType = C.FETCH_BANK_NAMES_FOR_IFSC_SUCCESS;
      break;
    case 'BANK_CITIES':
      endPoint = IFSC_DETAILS.CITIES;
      actionType = C.FETCH_BANK_CITIES_FOR_IFSC_SUCCESS;
      break;
    case 'BANK_STATES':
      endPoint = IFSC_DETAILS.STATES;
      actionType = C.FETCH_BANK_STATES_FOR_IFSC_SUCCESS;
      break;
    case 'BANK_BRANCHES':
      endPoint = IFSC_DETAILS.BRANCHES;
      actionType = C.FETCH_BANK_BRANCHES_FOR_IFSC_SUCCESS;
      break;
    default:
      break;
  }
  try {
    const response = await RestClient.get(endPoint, params);
    dispatch({
      type: actionType,
      payload: response,
    });
    return response;
  } catch (e) {
    const body = await e.response;
    throw { message: body };
  }
}

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {Promise} server response
 */
export async function verifyPenny(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.post(
      `${API_ENDPOINTS.ONBOARDING.VERIFY_PENNY({ uuid: merchantUuid })}`,
      params,
    );
    return response;
  } catch (e) {
    const body = await e.response;
    throw { message: body };
  }
}

export async function updateMerchantProduct(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.put(
      `${API_ENDPOINTS.ONBOARDING.UPDATE_MERCHANT_PRODUCT({ uuid: merchantUuid })}`,
      params,
    );
    dispatch({ type: C.UPDATE_MERCHANT_PRODUCT_SUCCESS, payload: response.product });
    return response;
  } catch (e) {
    const body = await e.response;
    throw { message: body };
  }
}

export async function sendBankUpdateOtp(merchantEmail) {
  return sendProfileUpdateOtp('update_bank_details', merchantEmail)
}

export async function sendWebsiteUpdateOtp(merchantEmail) {
  return sendProfileUpdateOtp('update_website_details', merchantEmail)
}

async function sendProfileUpdateOtp(scope, merchantEmail) {
  try {
    const params = {
    type: 'SignIn',
    channels: ['email','sms'],
    scope: scope,
    identity: merchantEmail,
    token: HubAuthManager.getHubApiToken(),
  }
    const response =  await HubRestClient.sendOtp(params);
    return response;
  } catch(e) {
    const body = await e.response;
    const erroMsgs = formatErrors(body);
    return new Error(erroMsgs);
  }
}

export async function verifyBankUpdateOtp(merchantEmail, otp) {
  return verifyProfileUpdateOtp(merchantEmail, otp)
}

export async function verifyWebsiteUpdateOtp(merchantEmail, otp) {
  return verifyProfileUpdateOtp(merchantEmail, otp)
}

async function verifyProfileUpdateOtp(merchantEmail, otp) {
  try {
    const params = {
    type: 'SignIn',
    identity: merchantEmail,
    token: HubAuthManager.getHubApiToken(),
    code: otp,
  }
    const response = await HubRestClient.verifyOtp(params);
    return response
  } catch(e) {
    const body = await e.response;
    if(body.errors && body.errors.code == 'otp_already_used') return new Error('Otp has expired, please use resend OTP button.')
    const erroMsgs = formatErrors(body);
    return new Error(erroMsgs);
  } 
}

/**
 * Makes HTTP call to the server with bank_detail payload and sets token in authorization header
 * @param {dispatch} redux dispatch
 * @param {parms} bank_detail payload, merchantUuid, token
 * @return {Promise} Returns response in json
*/
export async function updateBankDetails(dispatch, params){
  try {
    const response =  await RestClient.post(`${API_ENDPOINTS.ONBOARDING.BANK_UPDATE({ uuid: params.merchantUuid})}`,
      { bank_detail: params.bank_detail },
      { token: params.access_token}
      );
    dispatch({ type: C.UPDATE_MERCHANT_DETAILS_SUCCESS, payload: response.merchant });
    return true;
  } catch(e) {
    const body = await e.response;
    let erroMsgs = formatErrors(body);
    if (body && body.bank_details) return new Error(formatErrors(body,'bank_details'));
    if (body && body.bank_account) return new Error(formatErrors(body,'bank_account'));
    return new Error(erroMsgs);
  }
}

export async function acceptBankAddendum(dispatch, merchantUuid) {
  const response = await callAcceptAddendum(dispatch, merchantUuid, 'bank');
  return response;
}

export async function acceptWebsiteDetailAddendum(dispatch, merchantUuid) {
  const response = await callAcceptAddendum(dispatch, merchantUuid, 'website_detail');
  return response;
}

async function callAcceptAddendum(dispatch, merchantUuid, source) {
  try {
    const params = {source: source}
    const response = await RestClient.post(API_ENDPOINTS.ONBOARDING.ACCEPT_ADDENDUM({ uuid: merchantUuid }), params);
    dispatch({ type: C.UPDATE_MERCHANT_DETAILS_SUCCESS, payload: response.merchant });
    return response;
  } catch (err) {
    const body = await err.response;
    return new Error('Some error occured, please refresh the page');
  }
}

/**
 * Makes HTTP call to the server to accept address addendum and update address
 * @param {dispatch} redux dispatch
 * @param {parms} merchantUuid, newAddress
 * @return {Promise} Returns response in json
*/
export async function acceptAddendumDetails(dispatch, merchantUuid, newAddress) {
  try {
    const params = { new_address: {
      'Operating': {
        address_line: newAddress.new_operating_address_line,
        city: newAddress.new_operating_city,
        pincode: newAddress.new_operating_address_pincode,
        state: newAddress.new_operating_state
      },
      'Registered': {
        address_line: newAddress.new_registration_address_line,
        city: newAddress.new_registration_city,
        pincode: newAddress.new_registration_address_pincode,
        state: newAddress.new_registration_state
      } },
      source: 'address'
    },
    response = await RestClient.post(API_ENDPOINTS.ONBOARDING.ACCEPT_ADDENDUM({ uuid: merchantUuid }), params);
    dispatch({
      payload: response.merchant,
      type: C.UPDATE_MERCHANT_DETAILS_SUCCESS
    });
    return response;
  } catch (err) {
    const body = await err.response
    let errorMsgs = formatErrors(body);
    return new Error(errorMsgs);
  }
}

/**
 * Makes HTTP call to the server to fetch address addendum data
 * @param {dispatch} redux dispatch
 * @param {parms}  merchantUuid, addressData
 * @return {Promise} Returns response in json
*/
export async function fetchAddressAddendumData(dispatch, merchantUuid, addressData) {
  dispatch({ type: C.FETCH_ADDRESS_ADDENDUM_DETAILS_INITIATED });
  try {
    const params = { new_data: {
      'Operating': {
        address_line: addressData.new_operating_address_line,
        city: addressData.new_operating_city,
        pincode: addressData.new_operating_address_pincode,
        state: addressData.new_operating_state
      },
      'Registered': {
        address_line: addressData.new_registration_address_line,
        city: addressData.new_registration_city,
        pincode: addressData.new_registration_address_pincode,
        state: addressData.new_registration_state
      } },
      source: 'address'
   },
    response = await RestClient.put(API_ENDPOINTS.ONBOARDING.GENERATE_ADDENDUM_DATA({ uuid: merchantUuid }), params);
    dispatch({
      payload: response,
      type: C.FETCH_ADDRESS_ADDENDUM_DETAILS_SUCCESS
    });
    return response;
  } catch (err) {
    const body = await err.response;
    const erroMsgs = formatErrors(body);
    dispatch({
      payload: erroMsgs,
      type: C.FETCH_ADDRESS_ADDENDUM_DETAILS_FAILURE
    });
    return new Error(erroMsgs);
  }
}

export async function fetchWebsiteAddendumData(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_WEBSITE_ADDENDUM_DETAILS_INITIATED });
  try {
    const params = {
      source: 'website_detail'
   },
    response = await RestClient.put(API_ENDPOINTS.ONBOARDING.GENERATE_ADDENDUM_DATA({ uuid: merchantUuid }), params);
    dispatch({
      payload: response,
      type: C.FETCH_WEBSITE_ADDENDUM_DETAILS_SUCCESS
    })
  } catch (err) {
    const response = await err.response;
    dispatch({
      payload: response,
      type: C.FETCH_WEBSITE_ADDENDUM_DETAILS_FAILURE
    });
  }
}
