import React from 'react';
import { Typography as TypographyComponent, IconTypography as IconTypographyComponent } from 'payu-ui-v2';

const Typography = ({
  children,
  variant,
  weight,
  className,
}) => {
  return (
    <TypographyComponent
      variant={variant}
      weight={weight}
      className={className}
    >
      {children}
    </TypographyComponent>
  )
}

const IconTypography = ({
  iconType,
  variant = 'P1',
  spacing,
  color = '',
  children,
  className,
}) => {
  return <IconTypographyComponent
    iconType={iconType}
    typographyVariant={variant}
    spacing={spacing}
    color={color}
    children={children}
    className={className}
  />
}

export {
  Typography,
  IconTypography,
};