import React from 'react';
import { Tooltip as TooltipComponent } from 'payu-ui-v2';

const Tooltip = ({ children, placement, showArrow, containerElement, className, title, content, link, onClick, }) => {

  return (
    <TooltipComponent
      arrow={showArrow}
      containerElement={containerElement}
      placement={placement}
      tooltipContent={{
        title,
        content,
        link,
        onClick,
        className
      }}
    >
      {children}
    </TooltipComponent>
  )
}

export default Tooltip;