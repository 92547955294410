import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorBox(props) {
  return (
    <div className="error-box">
      <span className="error-sign">!</span>
      <h3 className="message-content">
        {props.title}{' '}
        <span>{props.children}</span>
      </h3>
      {props.onClose && <span className="close-btn" onClick={props.onClose}>
        &times;
      </span>}
    </div>
  );
}


ErrorBox.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string
};

ErrorBox.defaultProps = {
  children: '',
  title: ''
};
