import React from 'react';
import PaymentLogo from '@assets/images/payments.png';
import LoadingPage from '../../components/BaseComponents/LoadingPage';
import { skipToChooseWebsiteName } from '../../services/utils';

export default class NeftFallbackScreen extends React.Component {
  constructor(props) {
    super(props);

    this.getNeftFallbackScreen = this.getNeftFallbackScreen.bind(this);
  }

  getNeftFallbackScreen() {
    let loadingScreenContent = (
			<div className="loading-content">
				<div className="numbered-subheadings">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
							<g fill="none" fill-rule="evenodd">
								<circle cx="40" cy="40" r="38.5" stroke="#F36363" strokeWidth="3" />
								<rect
									width="3"
									height="30"
									x="40.568"
									y="10.082"
									fill="#F36363"
									rx="1.5"
									transform="rotate(6 42.068 25.082)"
								/>
								<path
									fill="#F36363"
									d="M39.926 36l2.1 3 4 1c-2.667 1.333-4.667 1.667-6 1-1.334-.667-1.367-2.333-.1-5z"
								/>
								<rect
									width="3"
									height="19.598"
									x="48.726"
									y="29.007"
									fill="#F36363"
									rx="1.5"
									transform="rotate(83 50.226 38.806)"
								/>
							</g>
						</svg>
					</i>
					<ul>
						<li>
							<span className="decimal-blt">1</span>Your bank is taking too long to respond. PayU will make a test
							transaction in your bank account in few hours
						</li>
						<li>
							<span className="decimal-blt">2</span>We will notify you about the test transaction via SMS and Email
						</li>
						<li>
							<span className="decimal-blt">3</span>Follow the instructions in the SMS or Email to setup your payment
							gateway
						</li>
					</ul>
				</div>
				<p>Meanwhile, you can create your free website.</p>
				<div>
					<button onClick={() => skipToChooseWebsiteName({user: this.props.user})} className="cmn-btn bg">
						CHOOSE WEBSITE NAME
					</button>
				</div>
			</div>
		);
		return <LoadingPage loadingText="Few more steps to activate your payment gateway" loadingContent={loadingScreenContent} />;
  }

  render() {
    return(
      <div className="bank-details-flow">
        <div className="wave-content" />
        <div className="page-container first-section">
          <div className="page-container-inner">
            <header className="main-topheader colorize">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 clearfix">
                    <a title="PayUmoney" className="main-logo">
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <div className="container">
              <h1 className="text-center"></h1>
              <div className="otp-sub-header"></div>
              <div className="now-profile landing-form-box">
                {this.getNeftFallbackScreen()}
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center m-0 mt-4 pl-2 pr-2 pb-3">
          <div className="col-md-5 col-sm-5">
            <img src={PaymentLogo} alt="payment-logo" className="img-fluid" />
          </div>
        </div>
      </div>
    )
  }
};