/**
 * profile constants
 */
/* eslint-disable */
export const ACCOUNT_CONTACT_TYPE = {
  SIGNING_AUTHORITY: 'Signing Authority'
};

export const INDIVIDUAL = 'Individual';
export const PROPRIETORSHIP = 'Sole Proprietorship';
export const PATNERSHIP = 'Partnership';
export const TRUST = 'Trust';
export const SOCIETIES = 'Society';
export const PRIVATE_LIMITED = 'Private Limited';
export const PUBLIC_LIMITED = 'Public Limited';
export const ONE_PERSON_COMPANY = 'One Person Company';
export const NGO = 'NGO';
export const LLP = 'LLP';
export const GOVT = 'Government';
export const LOCAL_AUTHORITY = 'Local Authority';
export const HUF = 'HUF';

export const EXPECTED_SALES_OPTIONS = {
  '10000': '0 - 10000',
  '30000': '10001 - 30000',
  '60000': '30001 - 60000',
  '80000': '60001 - 80000',
  '120000': '80001 - 120000',
  '150000': '120001 - 150000',
  '300000': '150001 - 300000',
  '500000': '300001 - 500000',
  '500001': '500001 and more',
};

export const BUSINESS_ADDRESS_HINTS = {
  Individual: 'Address proof upload will be required in next step',
  'Sole Proprietorship': 'Enter address as mentioned in govt. issued certified proof',
  Partnership: 'Enter address as mentioned in govt. issued certified proof',
  Trust: 'Enter address as mentioned in govt. issued certified proof',
  Society: 'Enter address as mentioned in govt. issued certified proof',
  'Private Limited':
    'Enter address as mentioned in certificate of Incorporation',
  'Public Limited':
    'Enter address as  mentioned in certificate of Incorporation',
  LLP: 'Enter address as mentioned in certificate of Incorporation',
};
export const BUSINESS_NAME_HINTS = {
  Individual: ' ',
  'Sole Proprietorship':
    'Govt. issued certified proof upload will be required in next step. Name entered should match with trade name on uploaded proof',
  Partnership:
    'Govt. issued certified proof upload will be required in next step',
  Trust: 'Govt. issued certified proof upload will be required in next step',
  Society:
    'Govt. issued certified proof upload will be required in next step',
  'Private Limited':
    'Certificate of Incorporation upload will be required in next step',
  'Public Limited':
    'Certificate of Incorporation upload will be required in next step',
  LLP: 'Certificate of Incorporation upload will be required in next step',
};
export const BUSINESS_NAME_LABEL_HINTS = {
  Individual: ' ',
  'Sole Proprietorship': ' (View accepted proofs)',
  Partnership: ' (View accepted proofs)',
  Trust: ' (View accepted proofs)',
  Society: ' (View accepted proofs)',
  'Private Limited': ' (Certificate of Incorporation)',
  'Public Limited': ' (Certificate of Incorporation)',
  LLP: ' (Certificate of Incorporation)',
};
export const BUSINESS_ADDRESS_LABEL_HINTS = {
  Individual: ' (View accepted proofs)',
  'Sole Proprietorship': ' (View accepted proofs)',
  Partnership: ' (View accepted proofs)',
  Trust: ' (View accepted proofs)',
  Society: ' (View accepted proofs)',
  'Private Limited': ' (Certificate of Incorporation)',
  'Public Limited': ' (Certificate of Incorporation)',
  LLP: ' (Certificate of Incorporation)',
};

export const GST_LABEL_HINT = 'View PayU GST details';
export const GST_TOOL_TIP = `PayU GST Number - 06AAJCS9091D1Z4 (Haryana Location)
PAN - AAJCS9091D
Address - PayU Payments Private Limited 9th Floor,
Bestech Business Tower, Sector 48, Sohna Road,
Gurgaon, Haryana, Pin - 122001`;

export const BUSINESS_ADDRESS_TOOL_TIP = {
  Individual: 'Passport / Aadhar / Voter\'s Id / Driving License / UtilityBill (electricity, water, landline, gas connection)',
  'Sole Proprietorship': `NOC by Gram Panchayat / Udyog Aadhar Card Certificate /
                TIN/TAN Certificate / Service Tax / Vat / Dvat-Import/
            Export License / Labour License / Central Excise Certificate /
            Municipal Corporation Certificate / Certificate of Business
            Registration /  License issued by Registering Authorities
            like ICAI, ICWAI, ICSI, IRDA / GST Registration Certificate`,
  Partnership: `NOC by Gram Panchayat / Udyog Aadhar Card Certificate /
                        TIN/TAN Certificate / Service Tax / Vat / Dvat-Import/
                        Export License / Labour License / Central Excise Certificate /
                        Municipal Corporation Certificate / Certificate of Business
                        Registration /  License issued by Registering Authorities
                        like ICAI, ICWAI, ICSI, IRDA / Partnership Deed verified by the registrar / GST Registration Certificate`,
  Trust: 'Certificate of Registration / 80G and 12A Certificate / Trust Deed verified by the registrar',
  Society: 'Certificate of Registration / 80G and 12A Certificate / Society Deed verified by the registrar',
};


export const BANK_STATUS = {
  VERIFIED: 'Success',
  PENDING: 'Pending',
};

export const MERCHANT_TYPE = {
  THIRD_PARTY: 'ThirdParty',
  TOOLS: 'Tools',
};

export const PENNY_STATUS = {
  SUCCESS: 'Success',
  PENDING: 'Pending',
  SENT_TO_BANK: 'SENT_TO_BANK',
  FAILED: 'Failed',
  NOT_INITIATED: 'Not Initiated',
};

/**
 * commented mapping of Govt HUF Trust BusinessEntities 
 * as these BE are not enabled for PUM
 */
export const PAN_BUSINESS_ENTITY_MAPPING = {
  A: [SOCIETIES],
  C: [PRIVATE_LIMITED, PUBLIC_LIMITED, ONE_PERSON_COMPANY],
  F: [PATNERSHIP, LLP],
  // L: [LOCAL_AUTHORITY],
  P: [PROPRIETORSHIP, INDIVIDUAL],
  T: [NGO, TRUST],
  G: [GOVT],
  // H: [HUF],
}


export const NOW_BUSINESS_ENTITIES_LIST = [INDIVIDUAL, PROPRIETORSHIP, PRIVATE_LIMITED, PATNERSHIP, PUBLIC_LIMITED, SOCIETIES, TRUST, LLP];
