import React, { Children } from 'react';
import isEqual from 'lodash/isEqual';

export default class FilterDropdown extends React.PureComponent {
  static defaultProps = {
    onSelect: () => {},
    onClick: () => {},
    disable: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentLabel: Array.isArray(props.dropDownItems) ? props.input.value : props.dropDownItems[props.input.value],
      selectedOption: props.selectedOption || -1,
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    this.setState({
      isOpen: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.dropDownItems, nextProps.dropDownItems)) {
      this.setState({ currentLabel: '' });
    }
    const { dropDownItems, selectedOption } = nextProps;
    if (dropDownItems && selectedOption && !this.state.currentLabel) {
      let currentLabel;
      if (dropDownItems && Array.isArray(dropDownItems)) {
        const option = parseInt(this.state.selectedOption, 10); // radix is 10;
        currentLabel = dropDownItems[option];
      } else {
        currentLabel = dropDownItems[selectedOption];
      }
      this.setState({
        selectedOption,
        currentLabel,
      });
    }
  }

  onClickingItem(item) {
    this.setState({ currentLabel: item.value, selectedOption: item.key });
    // To Sync Redux form component with the custom input form
    this.props.input.onChange(item.key);
    this.props.onSelect(item);
    this.props.onClick();
    this.setState({ isOpen: false });
  }

  onClick = () => {
    this.props.onClick();
    if (!this.props.disable) {
      this.setState(({ isOpen }) => ({
        isOpen: !isOpen,
      }));
    }
  }

  createOptions() {
    const { dropDownItems } = this.props;
    return Array.isArray(dropDownItems) ?
      dropDownItems.map(key => (
        <div
          key={key}
          className={`${this.state.selectedOption &&
          this.state.selectedOption.toString() === key ? 'active' : ''} filter-dropdown-box-item`}
          onClick={() => this.onClickingItem({ key, value: key })}>
          {key}
        </div>)) :
      Children.toArray(Object.keys(this.props.dropDownItems).map(key => (
        <div
          key={key}
          className={`${this.state.selectedOption &&
          this.state.selectedOption.toString() === key ? 'active' : ''} filter-dropdown-box-item`}
          onClick={() => this.onClickingItem({ key, value: this.props.dropDownItems[key] })}
        >
          {this.props.dropDownItems[key]}
        </div>)));
  }

  render() {
    const { cssClass, className } = this.props;
    const customClass = 'filter-dropdown form-group';
    return (
      <div className={className || customClass} >
        {this.props.label && <label className={cssClass || ''}> {this.props.label} </label>}
        <div className={cssClass || ''}>
          <input onClick={this.onClick} value={this.state.currentLabel} className="form-control" readOnly />
        </div>
        <div className={`filter-dropdown-box ${this.state.isOpen ? '' : 'd-none'}`}>
          {this.createOptions()}
        </div>
      </div>
    );
  }
}

