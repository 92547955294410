import React from 'react';
import { Error } from '../../ui-lib';

export const PostAutoLogout = () => {
  return (
    <div className='tw-preflight'>
      <div className="tw-flex tw-items-center tw-justify-center tw-h-screen tw-w-screen">
        <div className='tw-w-1/2'>
          <Error
            actions={[
              {
                onClick: () => {
                  window.location.href = '/app/account/signin'
                },
                size: 'medium',
                text: 'Login',
                variant: 'PRIMARY',
                className: 'tw-mt-16 co-min-w-150px'
              },
            ]}
            description={{
              props: {
                className: 'tw-text-green-gray-300',
                variant: 'P2',
                weight: 'medium'
              },
              text: <>
                For security purposes, your session has been timed out due to inactivity.<br />
                Please login again to continue.
              </>
            }}
            heading={{
              props: {
                className: 'tw-text-gray-900',
                variant: 'H3',
                weight: 'semi-bold'
              },
              text: 'You have been logged out'
            }}
            imageProps={{
              className: 'tw-bg-blue-50 co-image-contain',
              height: 'inherit',
              src: '/assets/images/session-timeout.png',
              width: '100%',
            }}
            message={{
              props: {
                className: 'tw-text-green-gray-300',
                variant: 'SH3',
                weight: 'medium'
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}