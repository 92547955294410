import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../BaseComponents/GenericModal'
import Button from 'deltoid/core/Button';

/**
 *  layover for delete employee confirmation
 *
 *  @param  {object}    props      reacts props
 */
const DeleteEmployee = (props) => {
  const { onConfirm, onCancel, CURRENT_EMPLOYEE } = props;
  return (
    <Modal modalContentClassName="delete-emp-confirmation" isOpen={ true } onClose={ onCancel }>
      <div className="delete-container">
        <div className="delete-text-container">
          <span className="delete-text">Are you sure want to delete</span>
        </div>
        <div className="delete-text-container">
          <span className="delete-text">employee &#34;{ CURRENT_EMPLOYEE.name }&#34; ?</span>
        </div>
        <div>
          <span>
            <Button
              className="delete-btn"
              onClick={ () => {
                onConfirm(CURRENT_EMPLOYEE)
              } }>
              DELETE
            </Button>
          </span>
          <span>
            <Button className="delete-btn cancel" type="OUTLINED" onClick={ onCancel }>
              CANCEL
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
},
/**
 * function to map redux state data to props
 *
 * @param {object} state  redux state
 */
mapStateToProps = (state) => ({
  CURRENT_EMPLOYEE: state.teamAccount.currentEmployee
});
DeleteEmployee.propTypes = {
  CURRENT_EMPLOYEE: PropTypes.shape({

  }),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSave: PropTypes.func
};

export default connect(mapStateToProps)(DeleteEmployee);
