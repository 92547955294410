import AuthManager from '@services/AuthManager';
import { postMessageToNativeApp, isWebView, APP_CALLBACK_ACTION } from '@services/nativeApp';
import { ENUM_APP_SOURCE } from "@constants/queryParams";

// axios.defaults.baseURL = "https://api.example.com";
// axios.defaults.headers.common["Authorization"] = "AUTH_TOKEN";
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

export function setupAxios(axios) {
  axios.interceptors.request.use(
    (config) => {
      const authToken = (config && config.token) || AuthManager.getFullToken();
      // merchantMID = cookies.get(MERCHANT_MID),
      // product = cookies.get(PRODUCT);

      /**
       * set authToken, Mid & Product from cookies in headers
       * if any of the three (authToken, MID & Product) is not present in cookies or either cookies are expired
       * then requested API will send unauthenticated response or any other server error
       * which is handled in axios response interceptors
       */
      const { mid, product } = AuthManager.getUser() || {};
      config.headers.Authorization = config.headers.Authorization || `Bearer ${authToken}`;

      if (config?.url?.[0] === '/') {
        config.headers.Source = isWebView() ? AuthManager.getAppSource() : ENUM_APP_SOURCE.WEB;
      };
      // config.headers.mid = `${mid}`;
      // config.headers.product = `${product}`;
      config.headers.Accept = 'application/json';

      return config;
    },
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response =>
      /**
       * Any status code that lie within the range of 2xx cause this function to trigger
       * Do something with response data if needed
       */
      response
    ,
    (error) => {
      /**
       * Any status codes that falls outside the range of 2xx cause this function to trigger
       * Do something with response error
       */

      // The request was made and the server responded with a status code out of 2xx
      if (error.response) {
        // for unauthorized response, redirect on OnBoarding
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.skipLogout) {
          postMessageToNativeApp({ action: APP_CALLBACK_ACTION.LOGOUT });
          AuthManager.removeLocalStorageData();
          const { origin, search, pathname } = window.location;
          if (pathname === '/account/service_login') {
            window.location.assign(`${origin}/app/account${search}`);
          } else {
            const urlSearchParams = new URLSearchParams(decodeURIComponent(search));
            const redirectTo = urlSearchParams.get('redirect_to') || urlSearchParams.get('redirectTo');
            if (redirectTo
              && !urlSearchParams.get('client_id')) {
              window.location = `/app/account?redirectTo=${redirectTo}`;
              return;
            }
            const redirectURL = `${origin}${pathname}${encodeURIComponent(decodeURIComponent(search))}`;
            window.location = `/app/account?redirectTo=${redirectURL}`;
          }
        }
        // handle Internal Server Error
        if (error.response.status === 500) {
          error.message = 'Some error occurred, please try again later';
        }
      }
      return Promise.reject(error);
    },
  );
}
