import React, { useState, useEffect } from 'react';
import { checkSession } from '../../../packs/service_redirect';

import NotFound from '@components/BaseComponents/NotFound';
import { SpinLoader } from 'deltoid/core/Loaders';

const ServiceLogin = () => {
  const [errType, setErrType] = useState(false);
  useEffect(() => {
    checkSession((v) => {
      console.log(v);
      setErrType(v);
    });
  }, []);

  const CONTENT = {
    'code-break': {
      heading: '400',
      subHeading: 'Something went wrong.',
    },
    'redirect_to-not-found': {
      subHeading: 'Redirection path not provided.',
    },
    'redirect_to-not-allowed': {
      heading: 'Not allowed',
      subHeading: 'Redirection path not allowed.',
    }
  };

  return (
    <>
    {
      !errType ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <SpinLoader />
        </div>
      ) : (
        <NotFound goBack={{ redirectTo: '/app' }} content={{...CONTENT[errType]}}/>
      )
    }
    </>
  )
};

export default ServiceLogin;
