/*
 *
 * Merchant action types
 *
 */

// DOCUMENT UPLOAD ACTION TYPES
export const FETCH_SUBMITTED_DOC_LIST_INITIATED = 'FETCH_SUBMITTED_DOC_LIST_INITIATED';
export const FETCH_SUBMITTED_DOC_LIST_SUCCESS = 'FETCH_SUBMITTED_DOC_LIST_SUCCESS';
export const FETCH_SUBMITTED_DOC_LIST_FAILURE = 'FETCH_SUBMITTED_DOC_LIST_FAILURE';

export const UPLOAD_DOCUMENT_INITIATED = 'UPLOAD_DOCUMENT_INITIATED';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';

export const GENERATE_SERVICE_AGREEMENT_INITIATED = 'GENERATE_SERVICE_AGREEMENT_INITIATED';
export const GENERATE_SERVICE_AGREEMENT_SUCCESS = 'GENERATE_SERVICE_AGREEMENT_SUCCESS';
export const GENERATE_SERVICE_AGREEMENT_FAILED = 'GENERATE_SERVICE_AGREEMENT_FAILED';

export const REMOVE_ATTACHMENT_SUCCESS = 'REMOVE_ATTACHMENT_SUCCESS';

export const REMOVE_DOCUMENT_INITIATED = 'REMOVE_DOCUMENT_INITIATED';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILED = 'REMOVE_DOCUMENT_FAILED';

export const SUBMIT_DOCUMENTS_INITIATED = 'SUBMIT_DOCUMENTS_INITIATED';
export const SUBMIT_DOCUMENTS_SUCCESS = 'SUBMIT_DOCUMENTS_SUCCESS';
export const SUBMIT_DOCUMENTS_FAILED = 'SUBMIT_DOCUMENTS_FAILED';

export const FETCH_DOCUMENT_CATEGORY_AND_TYPES_INITIATED = 'FETCH_DOCUMENT_CATEGORY_AND_TYPES_INITIATED';
export const FETCH_DOCUMENT_CATEGORY_AND_TYPES_SUCCESS = 'FETCH_DOCUMENT_CATEGORY_AND_TYPES_SUCCESS';
export const FETCH_DOCUMENT_CATEGORY_AND_TYPES_FAILURE = 'FETCH_DOCUMENT_CATEGORY_AND_TYPES_FAILURE';

export const FETCH_GENERATED_SERVICE_AGREEMENT_INITIATED = 'FETCH_GENERATED_SERVICE_AGREEMENT_INITIATED';
export const FETCH_GENERATED_SERVICE_AGREEMENT_SUCCESS = 'FETCH_GENERATED_SERVICE_AGREEMENT_SUCCESS';
export const FETCH_GENERATED_SERVICE_AGREEMENT_FAILURE = 'FETCH_GENERATED_SERVICE_AGREEMENT_FAILURE';


export const ACCEPT_SERVICE_AGREEMENT_INITIATED = 'ACCEPT_SERVICE_AGREEMENT_INITIATED';
export const ACCEPT_SERVICE_AGREEMENT_SUCCESS = 'ACCEPT_SERVICE_AGREEMENT_SUCCESS';
export const ACCEPT_SERVICE_AGREEMENT_FAILED = 'ACCEPT_SERVICE_AGREEMENT_FAILED';
// SIGNING AUTHORITY LETTER DOWNLOAD

export const FETCH_SIGNING_AUTHORITY_LETTER_INITIATED = 'FETCH_SSIGNING_AUTHORITY_LETTER_INITIATED';
export const FETCH_SIGNING_AUTHORITY_LETTER_SUCCESS = 'FETCH_SIGNING_AUTHORITY_LETTER_SUCCESS';
export const FETCH_SIGNING_AUTHORITY_LETTER_FAILED = 'FETCH_SIGNING_AUTHORITY_LETTER_FAILED';

export const DOWNLOAD_AUTHORITY_LETTER_INITIATED = 'DOWNLOAD_AUTHORITY_LETTER_INITIATED';
export const DOWNLOAD_AUTHORITY_LETTER_SUCCESS = 'DOWNLOAD_AUTHORITY_LETTER_SUCCESS';
export const DOWNLOAD_AUTHORITY_LETTER_FAILED = 'DOWNLOAD_AUTHORITY_LETTER_FAILED';

export const UPLOAD_AUTHORITY_LETTER_INITIATED = 'UPLOAD_AUTHORITY_LETTER_INITIATED';
export const UPLOAD_AUTHORITY_LETTER_SUCCESS = 'UPLOAD_AUTHORITY_LETTER_SUCCESS';
export const UPLOAD_AUTHORITY_LETTER_FAILED = 'UPLOAD_AUTHORITY_LETTER_FAILED';

export const UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED = 'UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED';
export const UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS = 'UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS';
export const UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED = 'UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED';

export const FETCH_DOCUMENT_INITIATED = 'FETCH_DOCUMENT_INITIATED_INITIATED';
export const FETCH_DOCUMENT_FAILED = 'FETCH_DOCUMENT_FAILED';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';

export const COMPLETE_ESIGN_INITIATED = 'COMPLETE_ESIGN_INITIATED';
export const COMPLETE_ESGIN_SUCCESS = 'COMPLETE_ESGIN_SUCCESS';
export const COMPLETE_ESGIN_FAILURE = 'COMPLETE_ESGIN_FAILURE';

export const SEND_SIGNATORY_OTP_INITIATED = 'SEND_SIGNATORY_OTP_INITIATED';
export const SEND_SIGNATORY_OTP_SUCCESS = 'SEND_SIGNATORY_OTP_SUCCESS';
export const SEND_SIGNATORY_OTP_FAILURE = 'SEND_SIGNATORY_OTP_FAILURE';

export const VERIFY_SIGNATORY_OTP_INITIATED = 'VERIFY_SIGNATORY_OTP_INITIATED';
export const VERIFY_SIGNATORY_OTP_SUCCESS = 'VERIFY_SIGNATORY_OTP_SUCCESS';
export const VERIFY_SIGNATORY_OTP_FAILURE = 'VERIFY_SIGNATORY_OTP_FAILURE';

export const GENERATE_MERGED_DOCUMENT_FOR_ESIGN_INITIATED = 'GENERATE_MERGED_DOCUMENT_FOR_ESIGN_INITIATED';
export const GENERATE_MERGED_DOCUMENT_FOR_ESIGN_SUCCESS = 'GENERATE_MERGED_DOCUMENT_FOR_ESIGN_SUCCESS';
export const GENERATE_MERGED_DOCUMENT_FOR_ESIGN_FAILURE = 'GENERATE_MERGED_DOCUMENT_FOR_ESIGN_FAILURE';

export const FETCH_ADDENDUM_DETAILS_INITIATED = 'FETCH_ADDENDUM_DETAILS_INITIATED';
export const FETCH_ADDENDUM_DETAILS_SUCCESS = 'FETCH_ADDENDUM_DETAILS_SUCCESS';
export const FETCH_ADDENDUM_DETAILS_FAILURE = 'FETCH_ADDENDUM_DETAILS_FAILURE';
