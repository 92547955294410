export const sendEvergageEvent = ({ actionType, payload }) => {
  try {
    const Evergage = window.Evergage || {
      sendEvent: () => { }
    }
    Evergage.sendEvent({
      action: actionType,
      ...payload
    })
  } catch (e) { console.error('sendEvergageEvent:', e) }
};