import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';

import FieldUpdateModal from '../BaseComponents/FieldUpdateModal';
import { required } from '../../services/validations';
import { fetchIfscDetails } from '../../actions/profile';

function mapStateToProps(state) {
  return {
    ifscDetails: state.masterData.ifscDetails,
  };
}

@connect(mapStateToProps)
export default class IfscSearchModal extends React.PureComponent {

  static propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    ifscDetails: PropTypes.shape({
      bankNames: PropTypes.array,
      bankStates: PropTypes.array,
      bankCities: PropTypes.array,
      bankBranchesIfscDetails: PropTypes.array,
    }).isRequired,
    onBackClick: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => {},
    isOpen: false,
    onBackClick: () => {},
  };

  filters = {};

  componentWillMount() {
    fetchIfscDetails(this.props.dispatch, {
      fetchFor: 'BANK_NAMES',
      params: this.filters,
    });
  }

  onBankNameSelect = (e) => {
    const bankName = e.target.value;
    this.filters = {
      bank_name: bankName,
    };
    fetchIfscDetails(this.props.dispatch, {
      fetchFor: 'BANK_STATES',
      params: this.filters,
    });
  };

  onBankStateSelect = (e) => {
    const bankState = e.target.value;
    this.filters = {
      ...pick(this.filters, ['bank_name']),
      state: bankState,
    };
    fetchIfscDetails(this.props.dispatch, {
      fetchFor: 'BANK_CITIES',
      params: this.filters,
    });
  };

  onBankCitySelect = (e) => {
    const bankCity = e.target.value;
    this.filters = {
      ...this.filters,
      city: bankCity,
    };
    fetchIfscDetails(this.props.dispatch, {
      fetchFor: 'BANK_BRANCHES',
      params: this.filters,
    });
  };

  onBankBranchSelect = (e) => {
    const selectbranchIndex = e.target.value;
    this.details = this.props.ifscDetails.bankBranchesIfscDetails[selectbranchIndex];
  }

  handleSubmit = () => {
    this.props.onSubmit(this.details);
  };

  render() {
    const {
      bankNames,
      bankStates,
      bankCities,
      bankBranchesIfscDetails,
    } = this.props.ifscDetails;

    const bankBranches = bankBranchesIfscDetails.map(bank => bank.branch_name);

    return (
      <FieldUpdateModal
        modalContentClassName={this.props.cssClass}
        buttonText="SUBMIT"
        className="field-update-modal"
        formName="IfscSearch"
        showLoader={false}
        showModalFlag={this.props.isOpen}
        onClose={this.props.onClose}
        modalHeading="Search IFSC Code"
        onSubmit={this.handleSubmit}
        enableBackBtn={this.props.enableBackBtn}
        onBackClick={this.props.onBackClick}
        fields={[
          {
            type: 'select',
            name: 'bank_name',
            label: 'Bank Name',
            validate: [required],
            props: {
              onChange: this.onBankNameSelect,
            },
            options: [
              <option value="">Select</option>,
              ...bankNames.map(name => <option value={name}>{name}</option>),
            ],
          },
          {
            type: 'select',
            name: 'branch_state',
            label: 'Branch State',
            validate: [required],
            props: {
              onChange: this.onBankStateSelect,
            },
            options: [
              <option value="">Select</option>,
              ...bankStates.map(name => <option value={name}>{name}</option>),
            ],
          },
          {
            type: 'select',
            name: 'branch_city',
            label: 'Branch City',
            validate: [required],
            props: {
              onChange: this.onBankCitySelect,
            },
            options: [
              <option value="">Select</option>,
              ...bankCities.map(name => <option value={name}>{name}</option>),
            ],
          },
          {
            type: 'select',
            name: 'branch',
            label: 'Branch',
            validate: [required],
            props: {
              onChange: this.onBankBranchSelect,
            },
            options: [
              <option value="">Select</option>,
              ...bankBranches.map((name, index) => <option value={index}>{name}</option>),
            ],
          },
        ]}
      />
    );
  }
}
