import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'deltoid/core/Blocks/BootstrapContainers';
import { H, Body } from 'deltoid/core/Blocks/Typography';
import Form from 'deltoid/core/Form';

import Confused from 'deltoid/core/Illustration/svg/confused.svg';
import Topbar from '@components/BaseComponents/Topbar';

const NotFound = ({ goBack, content }) => {
  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    document.body.style.backgroundImage = 'unset';
  }, []);

  const { heading, subHeading } = content || {};
  return (
    <>
    <Topbar
      logo='../../../../../assets/images/PayU-Icon.svg'
    />
    <Container style={{ marginTop: '85px', marginBottom: '72px' }}>
      <Row>
        <Col md={10} className='m-auto text-center'>
          <H variant='bold' className='text-center mb-4'>{heading ? heading : '404'}</H>
          <Confused width='inherit'/>
          <H level={4} className='mt-3 text-center'>Oops,</H>
          <Body className='text-center mb-4'>
            {
              subHeading ? subHeading : 'It seems like we couldn’t find the page you were looking for.'
            }
          </Body>
          {
            goBack && (
              <Link to={{ pathname: goBack?.redirectTo }}>
                <Form.Button type='button' onClick={()=>{}}>
                  { goBack.label || 'Go Back' }
                </Form.Button>
              </Link>
            )
          }
        </Col>
      </Row>
    </Container>
    </>
  )
};

NotFound.defaultProps = {
  goBack: false,
  content: {},
}

NotFound.propTypes = {
  goBack: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      redirectTo: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  content: PropTypes.shape({
    heading: PropTypes.string,
    subHeading: PropTypes.string,
  })
}

export default NotFound;
