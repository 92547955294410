import * as C from '../constants/marketingBoxConstants';

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false
};

/**
 * marketingBox reducer responsible for mapping marketingBox data in redux state
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export default function marketingBoxReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.FETCH_MARKETING_BOX_CONTENT_INITIATED:
      return {
        ...state,
        fetched: false,
        fetching: true
      };
    case C.FETCH_MARKETING_BOX_CONTENT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetched: true,
        fetching: false
      };
    }
    case C.FETCH_MARKETING_BOX_CONTENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    default:
      return state;
  }
}
