import { takeLatest, call, put, select, delay } from "redux-saga/effects";

import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import axios from 'axios';
import { encodeToUrlParams } from "@utils/common";
import { dialogActions, toastActions, productAccountActions, uiActions, productAccountSelectors } from '.';
import { postMessageToNativeApp, APP_CALLBACK_ACTION } from '@services/nativeApp';
import ClevertapHandler from "../../../src/services/cleverTap";
import { CLEVERTAP_EVENTS } from "../../../src/constants/cleverTapEvents";
//  Action types
export const actionTypes = {
  fetch: 'serviceAgreement/INITIATE',
  fetchSuccess: 'serviceAgreement/SUCCESS',
  fetchFailed: 'serviceAgreement/FAILED',
  sendOtp: 'serviceAgreementOTP/INITIATE',
  verifyOtp: 'serviveAgreementVerifyOTP/INITIATE',
  verifyOtpSuccess: 'serviveAgreementVerifyOTP/SUCCESS',
  verifyOtpFailed: 'serviveAgreementVerifyOTP/FAILED',
  getEsignedAgreement: 'esignedAgreement/INITIATE',
  getEsignedAgreementSuccess: 'esignedAgreement/SUCCESS',
  getEsignedAgreementFailed: 'esignedAgreement/FAILED'
};

const initialState = {
  generatedMerged: {
    response: null,
    processing: false,
  },
  verifyOtp: {
    processing: false,
    error: null,
  },
  esignedAgreement: {
    response: null,
    processing: false,
  }
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch:
      return { ...state, generatedMerged: { processing: true, response: action.payload } };
    case actionTypes.fetchFailed:
      return { ...state, generatedMerged: { response: action.payload, processing: false } };
    case actionTypes.fetchSuccess:
      return { ...state, generatedMerged: { response: action.payload, processing: false } };
    case actionTypes.verifyOtp:
      return { ...state, verifyOtp: { processing: true, error: null } };
    case actionTypes.verifyOtpSuccess:
      return { ...state, verifyOtp: { processing: false, error: null } };
    case actionTypes.verifyOtpFailed:
      return { ...state, verifyOtp: { processing: false, error: action.payload } };
    case actionTypes.getEsignedAgreement:
      return { ...state, esignedAgreement: { processing: true, response: null } };
    case actionTypes.getEsignedAgreementSuccess:
    case actionTypes.getEsignedAgreementFailed:
      return { ...state, esignedAgreement: { processing: false, response: action.payload } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getDetails: (state) => state.onboardingApp.serviceAgreement.generatedMerged.response,
  msaProcessing: (state) => state.onboardingApp.serviceAgreement.generatedMerged.processing,
  verifyProcessing: (state) => state.onboardingApp.serviceAgreement.verifyOtp.processing,
  verifyOtpErr: (state) => state.onboardingApp.serviceAgreement.verifyOtp.error,
  getEsignedAgreementProcessing: (state) => state.onboardingApp.serviceAgreement.esignedAgreement.processing,
};

//  Actions
export const actions = {
  fetch: () => ({ type: actionTypes.fetch }),
  sendOtp: (kycDocumentUuid) => ({
    type: actionTypes.sendOtp,
    payload: { kycDocumentUuid }
  }),
  verifyOtp: (otp, kycDocumentUuid) => ({
    type: actionTypes.verifyOtp,
    payload: { otp, kycDocumentUuid },
  }),
  getEsignedAgreement: () => ({ type: actionTypes.getEsignedAgreement }),
  fetchFailed: () => ({ type: actionTypes.fetchFailed })
};

// Workers
function* fetchWorker() {

  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.SERVICE_AGREEMENT.GENERATE_MERGED_DOCUMENT_ESIGN(AuthManager.getMerchantUUID()),
    ) || {};
    yield put({ type: actionTypes.fetchSuccess, payload: response.data });
    yield put(dialogActions.open('msa-review-esign'));
    if (response?.data?.kyc_document?.uuid) {
      yield put(actions.sendOtp(response?.data?.kyc_document?.uuid));
      yield put(productAccountActions.fetch());
    }
    ClevertapHandler.sendEvent({["CTA Name"] : CLEVERTAP_EVENTS.AGREEMENT_SIGNED})
  } catch (err) {
    const errResponse = yield err.response;
    yield put({ type: actionTypes.fetchFailed, payload: errResponse });
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Info',
      content: errResponse?.data?.kyc_document || errResponse?.data?.error || 'Something went wrong',
    }))
  }
}

function* sendOtpWorker(action) {
  const { kycDocumentUuid } = action.payload;
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.SERVICE_AGREEMENT.SEND_SINGNATORY_OTP({
        kycDocumentUuid,
        productAccountUUID: AuthManager.getMerchantUUID(),
      }),
    ) || {};
  } catch{}
}

function* verifyOtpWorker(action) {
  const { otp, kycDocumentUuid } = action.payload;
  const { pancard_name } = yield select(productAccountSelectors.getProductAccountDetails) || {};
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.SERVICE_AGREEMENT.ESIGN_MERGED_DOCUMENT({
        kycDocumentUuid,
        productAccountUUID: AuthManager.getMerchantUUID(),
      }),
      { otp },
    ) || {};
    yield put({ type: actionTypes.verifyOtpSuccess });
    yield put(dialogActions.close());
    yield delay(200);
     yield put(window.location.assign('/app/onboarding/service-agreement-success')); // Test env
    
    // yield put(productAccountSelectors.updateMerchantDetails({ is_service_agreement_esigned: true }))
    yield put(uiActions.loadUI({ is_service_agreement_esigned: true }));
    postMessageToNativeApp({ action: APP_CALLBACK_ACTION.ONBOARDING_COMPELTED });
  } catch (err) {
    const errResponse = yield err.response;
    yield put({
      type: actionTypes.verifyOtpFailed,
      payload: (
        errResponse && errResponse.data && errResponse.data.error
      ) ? errResponse.data.error : 'Something went wrong.'
    });
  }
}

function* getEsignedAgreementWorker() {
  try {
    const params = { 'kyc_document_type[]': 'Agreement', 'status[]': 'Approved' };
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.SERVICE_AGREEMENT.ESIGNED_DOCUMENT(AuthManager.getMerchantUUID(), params),
    ) || {};
    yield put({ type: actionTypes.getEsignedAgreementSuccess, payload: response.data });
    if (response?.data?.kyc_documents?.length) {
      const documentPath = response.data.kyc_documents[0]?.processed_document?.path;
      if (documentPath) {
        window.open(documentPath, '_blank')
      }
    } else {
      yield put(toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Esigned Agreement not found',
      }))
    }
  } catch (err) {
    const errResponse = yield err.response;
    yield put({ type: actionTypes.getEsignedAgreementFailed, payload: errResponse });
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: errResponse?.data?.kyc_document || 'Something went wrong',
    }))
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchWorker);
  yield takeLatest(actionTypes.sendOtp, sendOtpWorker);
  yield takeLatest(actionTypes.verifyOtp, verifyOtpWorker);
  yield takeLatest(actionTypes.getEsignedAgreement, getEsignedAgreementWorker);
};
