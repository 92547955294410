import Cookies from 'universal-cookie';
import RestClient from './RestClient';
import API_ENDPOINTS from './apiConfig';
import { getQueryParams } from '@constants/utils';


export default class AuthManager {
  /**
   * gets current logged in user from backend and stores in local storage
   * @returns
   */
  /* eslint-disable consistent-return */
  static async initialize() {
    if (this.isLoggedIn()) {
      try {
        const response = await RestClient.get(API_ENDPOINTS.AUTH.PRODUCTACCOUNTS);
        return response;
      } catch (e) {
        /* eslint-disable no-console */
        console.warn(e);
      }
    }
  }

  static isLoggedIn() {
    return !!this.getFullToken();
  }

  static getUser() {
    return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  }

  static async logout(redirectTo = '/app', useParent = false) {
    const tokenInfo = JSON.parse(localStorage.getItem('tokenData'));
    await RestClient.request(API_ENDPOINTS.AUTH.LOGOUT, { method: 'DELETE' });
    const cookies = new Cookies();
    const options = {
      domain: 'payu.in',
      expires: new Date(tokenInfo.tokenLifeTime),
      path: '/',
      secure: true,
    };
    cookies.remove('mid', options);
    cookies.remove('product', options);
    cookies.remove('merchantAccessToken', options);
    cookies.remove('dashboardPreference', options);
    cookies.remove('product_account_uuid', options);
    cookies.remove('product_account_type', options);
    cookies.remove('merchant_account_uuid', options);
    cookies.remove('testAccessToken', options);
    cookies.remove('test_product_account_type', options);
    cookies.remove('test_product_account_uuid', options);
    cookies.remove('test_merchant_account_uuid', options);
    cookies.remove('test_mid', options);
    cookies.remove('selected_outlet', options);
    cookies.remove('selected_outlet_id', options);
    this.removeLocalStorageData();
    this.removeQueryParams();
    if (redirectTo) {
      if (useParent && window.parent !== window && window.parent.location) {
          window.parent.location = redirectTo;
      } else {
          window.location = redirectTo;
      }
  }
  }

  static removeLocalStorageData() {
    this.removeEmailLinkSent();
    this.removeFullToken();
    this.removeUserUuid();
    this.removeTempToken();
    this.removeUserFromLocalStorage();
    this.removeResllerId();
    this.removeRedirectUri();
  }

  static persistUserToLocalStorage(user, type) {
    if (type == 'child') localStorage.setItem('childMerchant', JSON.stringify(user));
    else localStorage.setItem('user', JSON.stringify(user));
  }

  static getResellerId() {
    try {
      return JSON.parse(window.localStorage.getItem('reseller_uuid'));
    } catch (e) {
      return null;
    }
  }

  static removeUserFromLocalStorage() {
    localStorage.removeItem('user');
  }
  static removeUserUuid() {
    localStorage.removeItem('user_uuid');
  }


  static getHubToken(hubUser) {
    if (hubUser && hubUser.attributes) {
      const { 'limited-token': token } = hubUser.attributes;
      return token['access-token'];
    }
    return null;
  }

  static persistFullToken(tokenData) {
    let data;
    try {
      /* eslint-disable no-param-reassign */
      const tokenLifeTime = new Date();
      tokenLifeTime.setSeconds(tokenData.expires_in);
      tokenData.tokenLifeTime = tokenLifeTime;
      data = JSON.stringify(tokenData);
    } catch (e) {
      console.warn(e);
    }
    if (!this.getFullToken()) {
      window.localStorage.setItem('tokenData', data);
    }
  }

  static persistAppSource(source) {
    try {
      window.localStorage.setItem('appSource', source);
    } catch { }
  }

  static getAppSource() {
    try {
      return window.localStorage.getItem('appSource');
    } catch (e) {
      return null;
    }
  }

  static getFullToken() {
    try {
      return JSON.parse(window.localStorage.getItem('tokenData')).access_token;
    } catch (e) {
      return null;
    }
  }

  static getUserId() {
    try {
      return JSON.parse(window.localStorage.getItem('tokenData')).user_uuid;
    } catch (e) {
      return null;
    }
  }

  static getProductAccountName() {
    try {
      return JSON.parse(window.localStorage.getItem('user')).name;
    } catch (e) {
      return null;
    }
  }

  static getMID() {
    try {
      return JSON.parse(window.localStorage.getItem('user')).mid;
    } catch (e) {
      return null;
    }
  }

  static removeFullToken() {
    return window.localStorage.removeItem('tokenData');
  }

  static isSessionActive() {
    try {
      const tokenData = JSON.parse(window.localStorage.getItem('tokenData'));
      return Date.parse(tokenData.tokenLifeTime) > Date.now();
    } catch (e) {
      return null;
    }
  }

  static removeTempToken() {
    return window.localStorage.removeItem('tempToken');
  }

  static removeQueryParams() {
    return window.sessionStorage.removeItem('queryString');
  }

  static removeEmailLinkSent() {
    const emailData = localStorage.getItem(`${this.getUserId()}_verification_link_sent`);
    if (emailData) {
      return localStorage.removeItem(`${this.getUserId()}_verification_link_sent`);
    }
    return null;
  }

  static removeResllerId() {
    return window.localStorage.removeItem('reseller_id');
  }

  static removeRedirectUri() {
    return window.localStorage.removeItem('redirect_uri');
  }

  static getUUID() {
    const tokenData = JSON.parse(window.localStorage.getItem('tokenData'));
    if (tokenData) {
      return tokenData.user_uuid;
    }
    return null;
  }

  static getMerchantUUID() {
    const productAccount = JSON.parse(window.localStorage.getItem('user'));
    const childUUID = getQueryParams('childUUID', window.location.search);
    const path = window.location.pathname.split('/').pop();
    if (path === 'aggregator' && productAccount?.merchant_type === 'aggregator' && childUUID) {
      return childUUID;
    }
    return productAccount?.product_account_uuid;
  }

  static getParentAccountUUID() {
    try {
      const productAccount = JSON.parse(window.localStorage.getItem('user'));
      return productAccount?.product_account_uuid;
    } catch {
      return null
    }
  }
}
