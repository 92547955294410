import {
  FETCH_MERCHANT_ACCOUNT_FAILURE,
  FETCH_MERCHANT_ACCOUNT_INITIATED,
  FETCH_MERCHANT_ACCOUNT_SUCCESS,
} from '../constants/merchantTypes';

import {
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED,
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS,
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED,
} from '../constants/documentTypes';

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false,
};

export default function accountReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case FETCH_MERCHANT_ACCOUNT_INITIATED:
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED:
      return {
        ...state,
        success: false,
        fetching: true,
        error: null,
      };
    case FETCH_MERCHANT_ACCOUNT_SUCCESS:
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        success: true,
        fetching: false,
        error: null,
      };
    case FETCH_MERCHANT_ACCOUNT_FAILURE:
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED:
      return {
        ...state,
        success: false,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
