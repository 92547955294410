import React from 'react';
import { Button } from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import { Typography } from '../atoms/typography';

const FormFooter = ({
  btnList,
  whyWeNeedThis,
  variant = 'default'
}) => {
  const CLASSNAME = {
    default: 'tw-bg-white',
    dialog: 'tw-bg-gray-20'
  }
  return <div className={`
  tw-flex tw-justify-end tw-items-center tw-flex-wrap tw-gap-24 tw-p-24 tw-sticky tw-bottom-0
  ${CLASSNAME[variant] || CLASSNAME.default}
  `}>
    {whyWeNeedThis ? <Tooltip
      showArrow
      title={whyWeNeedThis?.heading}
      content={whyWeNeedThis?.body}
      placement='top'
    >
      <div>
        <Typography
          variant='P2'
          weight='semi-bold'
          className='tw-text-green-500 tw-cursor-pointer'
        >
          Why we need this?
        </Typography>
      </div>
    </Tooltip> : null}
    {btnList?.map(({ label, ...v }) => <Button {...v} >{label}</Button>)}
  </div>
}

export default FormFooter;