import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Card from 'deltoid/core/Card';
import Button from 'deltoid/core/Button';
import Delete from '@material-ui/icons/DeleteOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import ReduxFormField from 'deltoid/core/ReduxFormField';
import { required } from '../../../services/validations';
import ErrorImage from '@assets/images/error.svg';


/**
 *  card component for listing employee details
 *  @param  {object}    props      reacts props
 */
 class ListEmployees extends React.Component {
   constructor(props) {
     super(props);
   }

  render() {
    const { employee, onDelete, onEditClick, onEditSave,
      onEditCancel, handleRoleChange, roleData,
      editActiveFor, invalid, formError, formErrorText } = this.props,
      { employee: { user } } = this.props,
      roleOptions = roleData.data &&
      roleData.data.acl_roles &&
      roleData.data.acl_roles.map((ele) => ({
        label: ele.name,
        value: ele.id
      }));

    return (
      <Card className="employee-list-widget">
        <div>
          {
            formError && employee.id === editActiveFor && <div className="error-strip">
              <span className="error_img"><ErrorImage></ErrorImage></span>
              <span className="error_text"> { formErrorText } </span>
            </div>
          }
          <div className="emp-name-container">
            <span className="emp-name">{`${employee.name}`}</span>
            { employee.id === editActiveFor
              ? ''
              : <span>
              <span className="cust-icon-buttons">
                <IconButton onClick={() => {
                  onDelete(employee)
                }}>
                  <Delete
                    style={{ fontSize: 25 }}
                    className="cust-icon-buttons material-icons md-18"
                  />
                </IconButton>
              </span>
              <span className="cust-icon-buttons">
                <IconButton onClick={() => {
                  onEditClick(employee)
                }}>
                  <Edit
                    style={{ fontSize: 25 }}
                    className="cust-icon-buttons" />
                </IconButton>
              </span>
            </span>
            }
          </div>
          { employee.id === editActiveFor
            ? <div className="assign-role-container">
              <Field type="select"
                className="text-field assign-role"
                name="role"
                component={ ReduxFormField }
                label="Assign Role"
                options={ roleOptions }
                onChange={ handleRoleChange }
                validate={ required }
                value={ employee.acl_role.name }
              >
              </Field>
            </div>
            : <div>
              <p className="text-label">Assigned role</p>
              <p className="text-val">{ employee.acl_role.name }</p>
            </div>
          }
          <div>{
            user && user.registered_mobile &&
            <React.Fragment>
              <p className="text-label">Contact number</p>
              <p className="text-val">{ user.registered_mobile }</p>
            </React.Fragment>
          }
          </div>
          <div>
            <p className="text-label">Email ID</p>
            <p className="text-val">{ employee.email }</p>
          </div>
          { employee.id === editActiveFor
            ? <div>
                <span>
                  <Button
                  className={`save-emp-details-btn dflt-btn ${invalid
                    ? 'inactive'
                    : ''}`}
                  onClick={ () => {
                    onEditSave(employee)
                  } }>
                    Save
                  </Button>
                </span>
                <span>
                  <Button
                  className="cancel-save-emp-details"
                  type="OUTLINED"
                  onClick={ onEditCancel }>
                    Cancel
                  </Button>
                </span>
              </div>
            : ''
          }
        </div>
      </Card>
    );
  }
}
const ListEmployeesForm = reduxForm({
  form: 'ListEmployeesForm'
})(ListEmployees),
/**
 * function to map redux state data to props
 *
 * @param {object} state  redux state
 */
mapStateToProps = (state) => {
  const stateValues = {
    roleData: state.app.ownedAclRoles
  }
  return stateValues
};
ListEmployees.propTypes = {
  editActiveFor: PropTypes.number,
  employee: PropTypes.shape({

  }).isRequired,
  formError: PropTypes.bool,
  formErrorText: PropTypes.string,
  handleRoleChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  roleData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

ListEmployees.defaultProps = {
  assignedRole: null,
  contactNumber: '',
  email: '',
  employeeName: ''
};

export default connect(mapStateToProps)(ListEmployeesForm)

