import { takeLatest, put, delay, call } from 'redux-saga/effects';
import axios from 'axios';
import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import { dialogActions, toastActions, productAccountActions, uiActions } from '.';
import { msToTime } from '@utils/common';

//  Action types
export const actionTypes = {
  fetchCKYC: 'fetchCKYC/INITITATE',
  fetchCKYCSuccess: 'fetchCKYC/SUCCESS',
  fetchCKYCFailed: 'fetchCKYC/FAILED',
  aadharConsent: 'aadharConsent/INITIATE',
  aadharConsentSuccess: 'aadharConsent/SUCCESS',
  aadharConsentFailed: 'aadharConsent/FAILED',
  aadharGetOtp: 'aadharGetOtp/INITIATE',
  aadharGetOtpSuccess: 'aadharGetOtp/SUCCESS',
  aadharGetOtpFailed: 'aadharGetOtp/FAILED',
  aadharVerifyOtp: 'aadharVerifyOtp/INITIATE',
  aadharVerifyOtpSuccess: 'aadharVerifyOtp/SUCCESS',
  aadharVerifyOtpFailed: 'aadharVerifyOtp/FAILED',
  resetAadharState: 'aadharForm/REST',
  submitAddress: 'submitAddress/INITIATE',
  submitAddressSuccess: 'submitAddress/SUCCESS',
  submitAddressFailed: 'submitAddress/FAILED',
  fetchPincode: 'fetchPincode/INITIATED',
  fetchPincodeSuccess: 'fetchPincode/SUCCESS',
  uploadAadharXML: 'aadharUploadXML/INITIATE',
  uploadAadharXMLSuccess: 'aadharUploadXML/SUCCESS',
  uploadAadharXMLFailed: 'aadharUploadXML/FAILED',
};

const initialState = {
  aadharXML: {
    consent: { processing: false },
    getOtp: { processing: false, errMsg: null },
    verifyOtp: { processing: false, errMsg: null },
    uploadAadharXML: { processing: false },
  },
  central: { processing: false },
  address: { processing: false },
  pincode: {},
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchCKYC:
      return { ...state, central: { processing: true } };
    case actionTypes.fetchCKYCSuccess:
    case actionTypes.fetchCKYCFailed:
      return { ...state, central: { processing: false } };
    case actionTypes.aadharConsent:
      return { ...state, aadharXML: { ...initialState.aadharXML, consent: { processing: true } } };
    case actionTypes.aadharConsentSuccess:
    case actionTypes.aadharConsentFailed:
      return { ...state, aadharXML: { ...initialState.aadharXML, consent: { processing: false } } };
    case actionTypes.aadharGetOtp:
      return { ...state, aadharXML: { ...state.aadharXML, getOtp: { processing: true, errMsg: null } } };
    case actionTypes.aadharGetOtpSuccess:
      return { ...state, aadharXML: { ...state.aadharXML, getOtp: { processing: false, errMsg: null } } };
    case actionTypes.aadharGetOtpFailed:
      return { ...state, aadharXML: { ...state.aadharXML, getOtp: { processing: false, errMsg: action.payload } } };
    case actionTypes.aadharVerifyOtp:
      return { ...state, aadharXML: { ...state.aadharXML, verifyOtp: { processing: true, errMsg: null } } };
    case actionTypes.aadharVerifyOtpSuccess:
      return { ...state, aadharXML: { ...state.aadharXML, verifyOtp: { processing: false, errMsg: null } } };
    case actionTypes.aadharVerifyOtpFailed:
      return { ...state, aadharXML: { ...state.aadharXML, verifyOtp: { processing: false, errMsg: action.payload } } };
    case actionTypes.resetAadharState:
      return { ...state, aadharXML: { ...initialState.aadharXML } };
    case actionTypes.submitAddress:
      return { ...state, address: { processing: true } };
    case actionTypes.submitAddressSuccess:
    case actionTypes.submitAddressFailed:
      return { ...state, address: initialState.address };
    case actionTypes.fetchPincodeSuccess:
      return { ...state, pincode: { ...state.pincode, ...action.payload } };
    case actionTypes.uploadAadharXML:
      return { ...state, aadharXML: { ...initialState.aadharXML, uploadAadharXML: { processing: true } } };
    case actionTypes.uploadAadharXMLSuccess:
    case actionTypes.uploadAadharXMLFailed:
      return { ...state, aadharXML: { ...initialState.aadharXML, uploadAadharXML: { processing: false } } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  ckycVerifying: state => state.onboardingApp.kyc.central.processing,
  aadharConsentProcessing: state => state.onboardingApp.kyc.aadharXML.consent.processing,
  aadharGetOtpProcessing: state => state.onboardingApp.kyc.aadharXML.getOtp.processing,
  errMsgAadharGetOtp: state => state.onboardingApp.kyc.aadharXML.getOtp.errMsg,
  aadharVerifyOtpProcessing: state => state.onboardingApp.kyc.aadharXML.verifyOtp.processing,
  uploadAadharXMLProcessing: state => state.onboardingApp.kyc.aadharXML.uploadAadharXML.processing,
  submitAddressProcessing: state => state.onboardingApp.kyc.address.processing,
  errMsgAadharVerifyOtp: state => state.onboardingApp.kyc.aadharXML.verifyOtp.errMsg,
  getPincodeDetails: state => state.onboardingApp.kyc.pincode,
};

//  Actions
export const actions = {
  fetchCKYC: (formData) => ({ type: actionTypes.fetchCKYC, payload: formData }),
  aadharConsent: () => ({ type: actionTypes.aadharConsent }),
  aadharGetOtp: (aadharNumber) => ({ type: actionTypes.aadharGetOtp, payload: aadharNumber }),
  aadharVerifyOtp: (otp, aadharNumber) => ({ type: actionTypes.aadharVerifyOtp, payload: { otp, aadharNumber } }),
  submitAddress: ({formData, isManual}) => ({ type: actionTypes.submitAddress, payload: { formData, isManual } }),
  fetchPincode: (addressType, pincode) => ({
    type: actionTypes.fetchPincode,
    payload: { addressType, pincode },
  }),
  uploadAadharXML: (formData) => ({ type: actionTypes.uploadAadharXML, payload: formData }),
};

// Workers
function* fetchCKYCWorker(action) {
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.KYC.CENTRAL(AuthManager.getMerchantUUID()),
      { dob: action.payload.split('-').reverse().join('-') },
    ) || {};
    yield put(({ type: actionTypes.fetchCKYCSuccess }));
    yield put(dialogActions.close());
    yield put(productAccountActions.fetch());
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    yield put(({ type: actionTypes.fetchCKYCFailed }));
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: data?.message || data?.error_message || 'Something went wrong please try after some time'
    }));
  }
}

function* aadharConsentWorker() {
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.KYC.AADHAR.CONSENT(AuthManager.getMerchantUUID()),
    ) || {};
    yield put(({ type: actionTypes.aadharConsentSuccess }));
    yield put(dialogActions.open('aadhar-get-otp'));
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    yield put(({ type: actionTypes.aadharConsentFailed }));
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error', 
      content: data?.message
        ? data.message
        : 'Something went wrong please try after some time'
    }));
  }
}

function* aadharGetOtpWorker(action) {
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.KYC.AADHAR.SEND_OTP(AuthManager.getMerchantUUID()),
      { aadhaar_number: action.payload },
    ) || {};
    yield put(({ type: actionTypes.aadharGetOtpSuccess }));
    yield put(dialogActions.open('aadhar-verify-otp', {
      aadhar_number: action.payload,
      attempts_remaining:  response && response.data && response.data.attempts_remaining,
      message: response && response.data && response.data.message,
    }));
  } catch (err) {
    const errResponse = yield err.response;
    const { data, status } = errResponse || {};
    let errMsg = 'Something went wrong try after some time';
    if (status == 422) {
      errMsg = (data && data.error_message) || errMsg;
      errMsg += (data && data.time_remaining) ? `, retry after ${msToTime(data.time_remaining*1000)} minutes` : '';
    }
    yield put(({ type: actionTypes.aadharGetOtpFailed, payload: errMsg }));
  }
}

function* aadharVerifyOtpWorker(action) {
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.KYC.AADHAR.VERIFY_OTP(AuthManager.getMerchantUUID()),
      { otp: action.payload?.otp, aadhaar_number: action.payload?.aadharNumber },
    ) || {};
    yield put(({ type: actionTypes.aadharVerifyOtpSuccess }));
    yield put(dialogActions.close());
    yield put(productAccountActions.fetch());
  } catch (err) {
    const errResponse = yield err.response;
    const { data, status } = errResponse || {};
    let errMsg = 'Something went wrong try after some time';
    if (status == 422) {
      errMsg = (data && data.error_message)
        ? `${data.error_message}${data.attempts_remaining ? `, attempts left  ${data.attempts_remaining}` : (data.time_remaining ? `, retry after ${msToTime(data.time_remaining*1000)} minutes` : '' ) }`
        : errMsg;
    }
    yield put(({ type: actionTypes.aadharVerifyOtpFailed, payload: errMsg }));
  }
}

function* submitAddressWorker(action) {
  const { payload: { isManual, formData } } = action;
  try {
    const { data: response } = yield call(
      [axios, 'put'],
      API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
      {
        product_account: {
          registration_address: formData.registration_address,
          operating_address: formData.operating_address
            ? formData.operating_address
            : formData.registration_address,
        }
      },
    );
    yield put({ type: actionTypes.submitAddressSuccess })

    yield put(uiActions.loadUI(response ? { ...response.product_account, manualAddress: isManual } : {}));
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    yield put({ type: actionTypes.submitAddressFailed })
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: data?.message
        ? data.message
        : 'Something went wrong please try after some time'
    }));
  }
}

function* fetchPincodeWorker(action) {
  const { payload: { addressType, pincode } } = action;
  try {
    if (pincode) {
      const { data } = yield call(
        [axios, 'get'],
        API_ENDPOINTS.PINCODE_DETAILS(pincode),
      ) || {};
      yield put({
        type: actionTypes.fetchPincodeSuccess,
        payload: { [addressType]: data.pincode_detail }
      })
    }
  } catch {}
}

function* uploadAadharXMLWorker(action) {
  const { payload: formData } = action;
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.KYC.AADHAR.XML_SHARE_CODE(AuthManager.getMerchantUUID()),
      formData,
    ) || {};
    yield put({ type: actionTypes.uploadAadharXMLSuccess });
    yield put(productAccountActions.fetch());
  } catch (err) {
    const errResponse = yield err.response;
    yield put({ type: actionTypes.uploadAadharXMLFailed });
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: errResponse?.data?.error_message
        ? errResponse?.data?.error_message
        : 'Something went wrong please try after some time'
    }));
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchCKYC, fetchCKYCWorker);
  yield takeLatest(actionTypes.aadharConsent, aadharConsentWorker);
  yield takeLatest(actionTypes.aadharGetOtp, aadharGetOtpWorker);
  yield takeLatest(actionTypes.aadharVerifyOtp, aadharVerifyOtpWorker);
  yield takeLatest(actionTypes.submitAddress, submitAddressWorker);
  yield takeLatest(actionTypes.fetchPincode, fetchPincodeWorker);
  yield takeLatest(actionTypes.uploadAadharXML, uploadAadharXMLWorker);
}
