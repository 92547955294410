
import React from 'react';
import PropTypes from 'prop-types';
import GenericModal from '../BaseComponents/GenericModal';
import DropUploader from '../BaseComponents/DropUploader';
import { classNames } from '../../services/utils';

export default class UploadFrontAndBackDoc extends React.PureComponent {
  static defaultProps = {
    backDocName: '',
    description: '',
    frontDocName: '',
    modalTitle: '',
    onMount: () => [],
  };

  static propTypes = {
    backDocName: PropTypes.string,
    /**
     * decription for the dialog
     */
    description: PropTypes.string,
    frontDocName: PropTypes.string,
     /**
     * dialog title
     */
    modalTitle: PropTypes.string,
    /**
     * Callback fired on component mount
     *
     * @param None
     */
    onMount: PropTypes.func,
    /**
     * Callback fired on component mount
     *
     * @param None
     */
    onUpload: PropTypes.func.isRequired
  };

  static getFilePath = filePath => filePath;

  static getDocumentData = (type, doc) => ({
    type,
    id: doc.id,
    file: {
      preview: UploadFrontAndBackDoc.getFilePath(doc.path),
      name: doc.filename,
      size: doc.byte_size,
    },
  });

  INITIAL_STATE = {
    documents: [
      {
        type: this.props.frontDocName || 'FRONT',
        id: null,
        file: null,
      },
      {
        type: this.props.backDocName || 'BACK',
        id: null,
        file: null,
      },
    ],
    isContentLoading: false,
    isRemoveDocsPromptOpen: false,
    showLoader: false,
    error: '',
    responseDelay: false,
  };

  state = { ...this.INITIAL_STATE };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({ isContentLoading: true });
      const {
        uploaded_documents: uploadedDocuments,
        uuid: kycDocumentUuId,
      } = await this.props.onMount();
      this.kycDocumentUuId = kycDocumentUuId;
      const frontDoc = uploadedDocuments.find((doc) => doc.filename.includes(this.props.frontDocName || 'FRONT')),
        backDoc = uploadedDocuments.find((doc) => doc.filename.includes(this.props.backDocName || 'BACK'));
      const { documents } = this.state;
      if (frontDoc) {
        documents[0] = UploadFrontAndBackDoc.getDocumentData(this.props.frontDocName || 'FRONT', frontDoc);
      }
      if (backDoc) {
        documents[1] = UploadFrontAndBackDoc.getDocumentData(this.props.backDocName || 'BACK', backDoc);
      }
      this.setState({
        documents: [...documents],
      });
      this.setState({ isContentLoading: false });
    }
  }

  onDrop = (index, label) => async (file) => {
    const response = await this.props.onUpload(file, label);
    this.kycDocumentUuId = response.attachment.kyc_document_uuid;
    this.setState(({ documents }) => {
      documents[index].id = response.attachment.id;
      return {
        documents: [...documents],
      };
    });
    return response;
  };

  onRemove = index => async () => {
    const { documents } = this.state;
    this.props.onRemove({
      file_ids: [documents[index].id],
    });
    documents[index].id = null;
    this.setState({
      documents: [...documents],
    });
  };

  handleClose = () => this.setState({ isRemoveDocsPromptOpen: true });

  onRemoveDocs = () => {
    const docIds = this.state.documents
      .filter(doc => doc.id)
      .map(doc => doc.id);
    if (docIds.length !== 0) {
      this.props.onRemove({
        file_ids: docIds,
      });
    }
    this.setState({ ...this.INITIAL_STATE });
    this.props.onClose();
  };

  onCancelRemove = () => this.setState({ isRemoveDocsPromptOpen: false });

  onSubmit = async () => {
    const docIds = this.state.documents.map(doc => doc.id);
    this.setState({ showLoader: true });
    const timeout = window.setTimeout(
      () =>
        this.setState({
          responseDelay: true,
        }),
      3000,
    );
    try {
      await this.props.mergeDocuments(this.kycDocumentUuId, {
        document_order: docIds,
      });
      this.setState({ ...this.INITIAL_STATE });
      this.props.onClose();
      this.props.onMergeDocumentSubmitSuccess();
    } catch (err) {
      this.setState({
        error: [err.message],
      });
      this.props.onMergeDocumentSubmitFail(err.message);
    } finally {
      window.clearTimeout(timeout);
      this.setState({ showLoader: false, responseDelay: false });
    }
  };

  renderDocsList() {
    return this.state.documents.map(({ type, file }, index) => (
      <div className="upload-multiple-docs__list__item">
        <div className="upload-multiple-docs__list__label">{type}</div>
        <DropUploader
          fileToUpload={file}
          onDrop={this.onDrop(index, type)}
          onValidate={this.props.onValidate}
          onRemove={this.onRemove(index)}
        />
      </div>
    ));
  }

  render() {
    const {
      documents,
      isRemoveDocsPromptOpen,
      showLoader,
      isContentLoading,
      error,
      responseDelay,
    } = this.state;
    const { bacDocOptional } = this.props,
      disableSubmit = bacDocOptional === true
                          ? !documents.some((doc) => doc.id)
                          : documents.some((doc) => !doc.id);
    const btnClassName = classNames({
      'dflt-btn width-250': true,
      'is-loading': showLoader,
      'inactive fade': disableSubmit,
    });

    return (
      <React.Fragment>
        <GenericModal
          wrapClassName={isRemoveDocsPromptOpen ? 'd-none' : ''}
          isOpen={this.props.isOpen}
          modalTitle={this.props.modalTitle}
          contentClassName="upload-multiple-docs text-center"
          onClose={this.handleClose}
        >
          <p className="upload-multiple-docs__title">
            {this.props.description}
          </p>
          {isContentLoading && <div className="loader mt-15" />}
          {showLoader && (
            <div className="pt-30">
              <div className="loader" />
              <div className="trbl pt-30 pb-30 upload-multiple-docs__title">
                <br />Uploading ...
              </div>
              {responseDelay && (
                <div className="upload-multiple-docs__title">
                  <br/>It’s taking longer than expected…<br />Please don’t close the
                  window or refresh
                </div>
              )}
            </div>
          )}
          {!isContentLoading &&
            !showLoader && (
              <div className="upload-multiple-docs__list mb-30">
                {React.Children.toArray(this.renderDocsList())}
              </div>
            )}
          <div className="upload-multiple-docs__error">{error}</div>
          {!showLoader &&<button
            className={btnClassName}
            onClick={disableSubmit ? null : this.onSubmit}
          >
            DONE
          </button>}
        </GenericModal>

        <GenericModal
          isOpen={this.state.isRemoveDocsPromptOpen}
          contentClassName="upload-address-proof-modal text-center"
          isCloseDisabled
        >
          <div className="cancel-prompt mt-15">
            <div className="pl-r-30 cancel-prompt__heading">
              <p>If you cancel, this document will not be saved.</p>
              <p className="pt-30 cancel-prompt__subheading">
                Are you sure you want to cancel?
              </p>
            </div>
            <div className="cstmz-optn pt-30">
              <div className="form-group">
                <button
                  className="dflt-btn width-150"
                  onClick={this.onRemoveDocs}
                >
                  YES
                </button>
              </div>
              <button className="cmn-btn" onClick={this.onCancelRemove}>
                NO
              </button>
            </div>
          </div>
        </GenericModal>
      </React.Fragment>
    );
  }
}
