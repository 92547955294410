import * as C from '../constants/teamAccountTypes';

const GENERIC_STATE = {
  data: [],
  error: null,
  fetching: false,
  success: false
},

initialState = {
  createdEmployee: { ...GENERIC_STATE },
  currentEmployee: {},
  deletedEmployee: { ...GENERIC_STATE },
  employees: { ...GENERIC_STATE },
  roles: { ...GENERIC_STATE },
  updatedEmployee: { ...GENERIC_STATE }
}
/**
 * reducers for team accounts
 *
 * @param {object} state
 * @param {object} action
 */
export default function employeeList(state = initialState, action) {
  switch (action.type) {
    case C.FETCH_EMPLOYEE_LIST_INITIATED:
      return { ...state,
employees: { fetched: false,
fetching: true } }
    case C.FETCH_EMPLOYEE_LIST_SUCCESS:
      return { ...state,
employees: { data: action.payload.staff_invites,
fetched: true,
fetching: false } };
    case C.FETCH_EMPLOYEE_LIST_FAILURE:
      return { ...state,
employees: { error: action.payload,
fetching: false } };


    case C.CREATE_EMPLOYEE_INITIATED:
      return { ...state,
createdEmployee: { fetched: false,
fetching: true } }
    case C.CREATE_EMPLOYEE_SUCCESS:
      return { ...state,
createdEmployee: { data: action.payload,
fetched: true,
fetching: false } }
    case C.CREATE_EMPLOYEE_FAILURE:
      return { ...state,
createdEmployee: { error: action.payload,
fetched: true,
fetching: false } }


    case C.UPDATE_EMPLOYEE_INITIATED:
      return { ...state,
updatedEmployee: { fetched: false,
fetching: true } }
    case C.UPDATE_EMPLOYEE_SUCCESS:
      return { ...state,
updatedEmployee: { data: action.payload,
fetched: true,
fetching: false } }
    case C.UPDATE_EMPLOYEE_FAILURE:
      return { ...state,
updatedEmployee: { error: action.payload,
fetched: true,
fetching: false } }


    case C.DELETE_EMPLOYEE_INITIATED:
      return { ...state,
deletedEmployee: { fetched: false,
fetching: true } }
    case C.DELETE_EMPLOYEE_SUCCESS:
      return { ...state,
deletedEmployee: { data: action.payload,
fetched: true,
fetching: false } }
    case C.DELETE_EMPLOYEE_FAILURE:
      return { ...state,
deletedEmployee: { error: action.payload,
fetched: true,
fetching: false } }


    case C.FETCH_ROLE_LIST_INITIATED:
      return { ...state,
roles: { fetched: false,
fetching: true } }
    case C.FETCH_ROLE_LIST_SUCCESS:
      return { ...state,
roles: { data: action.payload,
fetched: true,
fetching: false } }
    case C.FETCH_ROLE_LIST_FAILURE:
      return { ...state,
roles: { error: action.payload,
fetched: true,
fetching: false } }


    case C.SET_CURRENT_EMPLOYEE:
      return { ...state,
currentEmployee: action.payload }
    case C.UNSET_CURRENT_EMPLOYEE:
      return { ...state,
currentEmployee: {} }

    default:
      return state;
  }
}


