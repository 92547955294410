import { takeLatest, call, put } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';

//  Action types
export const actionTypes = {
  getBusinessEntities: 'BusinessEntities/INITIATE',
  getBusinessEntitiesSuccess: 'BusinessEntities/SUCCESS',
  getBusinessEntitiesFailed: 'BusinessEntities/FAILED',
  getDocCategoryTypeMapping: 'DocumentsCategoryTypeMapping/INITIATE',
  getDocCategoryTypeMappingSuccess: 'DocumentsCategoryTypeMapping/SUCCESS',
  getDocCategoryTypeMappingFailed: 'DocumentsCategoryTypeMapping/FAILED',
  getAreaCodes: 'AreaCodes/INIITATE',
  getAreaCodesSuccess: 'AreaCodes/SUCCESS',
  getAreaCodesFailed: 'AreaCodes/FAILED',
};

const initialState = {
  Entities: {
    pan_business_entity_mapping: {},
    list: [],
  },
  KYC: {
    document_categories: {},
    document_types: {},
  },
  SHOP_CODE: [],
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getBusinessEntitiesSuccess: {
      return {
        ...state,
        Entities: action.payload
      }
    }
    case actionTypes.getDocCategoryTypeMappingSuccess: {
      return {
        ...state,
        KYC: action.payload
      }
    }
    case actionTypes.getAreaCodesSuccess:
      return { ...state, SHOP_CODE: action.payload }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  businessEntities: (state) => state.onboardingApp.masterData.Entities,
  categoryTypes: (state) => state.onboardingApp.masterData.KYC.document_categories,
  documentTypes: (state) => state.onboardingApp.masterData.KYC.document_types,
  areaCodes: (state) => state.onboardingApp.masterData.SHOP_CODE,
};


//  Actions
export const actions = {
  getBusinessEntities: () => ({ type: actionTypes.getBusinessEntities }),
  getDocCategoryTypeMapping: () => ({ type: actionTypes.getDocCategoryTypeMapping }),
  getAreaCodes: () => ({ type: actionTypes.getAreaCodes }),
};

function* getBusinessEntitiesWorker() {
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.MASTER_DATA.BUSINESS_ENTITIES
    ) || { data: { meta: { pan_business_entity_mapping: {} } , business_entities : []} };
    const {
      business_entities: list,
      meta: { pan_business_entity_mapping }
    } = response.data;
    yield put({
      type: actionTypes.getBusinessEntitiesSuccess,
      payload: { list, pan_business_entity_mapping }
    });

  } catch (err) {
    const errResponse = yield err.response;
    console.log(errResponse, err);
  }
}

function* getDocCategoryTypeMappingWorker() {
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.MASTER_DATA.DOC_CATEGORY_TYPE
    ) || { data: { meta: {} } };
    const { document_categories, document_types } = response.data;
    yield put({
      type: actionTypes.getDocCategoryTypeMappingSuccess,
      payload: { document_categories, document_types }
    });

  } catch (err) {
    const errResponse = yield err.response;
    console.log(errResponse, err);
  }
}

function* getAreaCodesWorker() {
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.MASTER_DATA.AREA_CODES
    ) || { data: {} };
    // console.log(response);
    const areaCodes = response?.data?.area_codes || [];
    yield put({
      type: actionTypes.getAreaCodesSuccess,
      payload: areaCodes.map((v) => ({ label: v.area, value: v.code })),
    })
  } catch (err) {
    const errResponse = yield err.response;
    // console.log(errResponse, err);
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.getBusinessEntities, getBusinessEntitiesWorker);
  yield takeLatest(actionTypes.getDocCategoryTypeMapping, getDocCategoryTypeMappingWorker);
  yield takeLatest(actionTypes.getAreaCodes, getAreaCodesWorker);
}