/*
 *
 * auth actions
 *
 */

import * as C from '../constants/authTypes';
import API_ENDPOINTS from '../services/apiConfig';
import RestClient from '../services/RestClient';
import AuthManager from '../services/AuthManager';
import cleverTap from '../services/cleverTap';
import { CLEVERTAP_EVENTS_MAP } from '../constants/cleverTapEvents';
import { formatErrors } from '../services/utils';

/**
 * called on dashboard to fetch session user
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of ACTION_CHECK_SESSION
 */
export async function sessionCheck(dispatch) {
  try {
    const { product_accounts: merchants } = await AuthManager.initialize();
    const user = AuthManager.getUser();
    sessionStorage.setItem('merchants', JSON.stringify(merchants));
    dispatch({
      type: C.ACTION_CHECK_SESSION,
      payload: merchants.find((merchant) => merchant.uuid === user.merchant_uuid) || merchants[0]
    });
  } catch (e) {
    dispatch({ type: C.ACTION_CHECK_FAILURE,
payload: {} });
  }
}


/**
 * called on Signup and Login containers
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_USER
 */
export async function fetchUser(dispatch, params) {
  dispatch({ type: C.FETCH_USER_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.AUTH.FIND_USER, params);
    dispatch({ type: C.FETCH_USER_SUCCESS,
payload: response });
    return response;
  } catch (e) {
    dispatch({ type: C.FETCH_USER_FAILURE,
payload: {} });
  }
}

/**
 * called on Signup and Login containers
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of CREATE_USER
 */
export async function createUser(dispatch, params) {
  dispatch({ type: C.CREATE_USER_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.CREATE_USER, params);
    dispatch({ type: C.CREATE_USER_SUCCESS,
payload: response.data });
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body);
    dispatch({ type: C.CREATE_USER_FAILURE,
payload: erroMsgs });
  }
}


/**
 * action to send Otp
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} response for SEND_OTP http request
 */
export async function sendOtp(dispatch, params) {
  dispatch({ type: C.SEND_OTP_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.SEND_OTP, params);
    dispatch({ type: C.SEND_OTP_SUCCESS,
payload: response.data });
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body);
    dispatch({ type: C.SEND_OTP_FAILURE,
payload: erroMsgs });
  }
}


/**
 * action to verify Otp
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} response for VERIFY_OTP http request
 */
export async function verifyOtp(dispatch, params) {
  dispatch({ type: C.VERIFY_OTP_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.VERIFY_OTP, params);
    dispatch({ type: C.VERIFY_OTP_SUCCESS,
payload: response });
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body);
    dispatch({ type: C.VERIFY_OTP_FAILURE,
payload: erroMsgs });
  }
}


export function resetVerifyOtp() {
  return { type: C.VERIFY_OTP_RESET };
}


/**
 * action to set user password
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} response for SET_PASSWORD http request
 */
export async function setPassword(dispatch, params) {
  dispatch({ type: C.SET_PASSWORD_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.SET_PASSWORD, params);
    dispatch({ type: C.SET_PASSWORD_SUCCESS,
payload: response.data });
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body, 'cause');
    dispatch({ type: C.SET_PASSWORD_FAILURE,
payload: erroMsgs });
    throw new Error(erroMsgs);
  }
}

/**
 * action to update user password
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} response for UPDATE_PASSWORD http request
 */
export async function updatePassword(dispatch, params) {
  dispatch({ type: C.UPDATE_PASSWORD_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.UPDATE_PASSWORD, params);
    dispatch({ type: C.UPDATE_PASSWORD_SUCCESS,
payload: response.data });
    cleverTap.event(CLEVERTAP_EVENTS_MAP.PASSWORD_UPDATED, {
      MID: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).mid,
      Platform: "PayU Web"
    });
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body, 'cause');
    dispatch({ type: C.UPDATE_PASSWORD_FAILURE,
payload: erroMsgs });
  }
}

/**
 * action to login with password
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} response for LOGIN_WITH_PASSWORD http request
 */
export async function loginWithPassword(dispatch, params) {
  dispatch({ type: C.LOGIN_WITH_PASSWORD_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.AUTH.LOGIN, params);
    cleverTap.onUserLogin({
      Site: {
        Email: params.email,
        Identity: response.reseller_uuid
      }
    });
    cleverTap.event(CLEVERTAP_EVENTS_MAP.LOGIN_SUCCESSFULL, {
      'Platform': "PayU Web",
      "Login type": 'Password',
      Email: params.email,
      RID: response.reseller_uuid
    });
    dispatch({ type: C.LOGIN_WITH_PASSWORD_SUCCESS,
payload: response.data });
    AuthManager.persistFullToken(response);
    return response;
  } catch (e) {
    const body = await e.response,
     erroMsgs = formatErrors(body);
    dispatch({ type: C.LOGIN_WITH_PASSWORD_FAILURE,
payload: erroMsgs });
    throw new Error(erroMsgs);
  }
}

/**
 * called to fetch user details from HUB
 * @return {object} An action object with a type of FETCH_USER
 */
export async function fetchUserFromHub(userUuid) {
  try {
    const response = await RestClient.get(API_ENDPOINTS.AUTH.FETCH_USER_FROM_HUB({ uuid: userUuid }));
    return response;
  } catch (e) {
    const body = await e.response;
    return body;
  }
}

export async function fetchUserMerchants() {
  const { product_accounts } = await AuthManager.initialize();
  return product_accounts;
}

export async function fetchTokenInfoFromHub() {
  const merchantId = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).mid;
  try {
    const response = await RestClient.get(API_ENDPOINTS.ONBOARDING.TOKEN_INFO({ mid: merchantId })),
      user = {};
    user.aclPermissions = response;
    sessionStorage.setItem('user', JSON.stringify(user));
    return response;
  } catch (e) {
    const body = await e.response;
    return body;
  }
}

export async function fetchProductDetailsFromMid(mid, token) {
  try {
    const response = await RestClient.get(API_ENDPOINTS.AUTH.PRODUCT_ACCOUNTS_FROM_MID(mid), {}, { token });
    return response;
  } catch (e) {
    const body = await e.response;
    return body;
  }
}