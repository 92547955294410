import React from "react";
import { BaseDialog as BaseDialogComponent } from "payu-ui-v2";

export const BaseDialog = ({
  fullWidth,
  showCloseIcon, className, enableOutsideClickHandler, open, children, width, onClose }) => {
  return (
    <BaseDialogComponent
      fullWidth={fullWidth}
      showCloseIcon={showCloseIcon}
      overlayClassName={'tw-z-1000'}
      className={className}
      enableOutsideClickHandler={enableOutsideClickHandler}
      open={open}
      width={width}
      onClose={onClose}
    >
      {children}
    </BaseDialogComponent>
  );
}