import { takeLatest, call, put, select } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';
import AuthManager from '@services/AuthManager';


//  Action types
export const actionTypes = {
  fetchTokenInfo: 'tokenInfo/INITIATE',
  fetchTokenInfoSuccess: 'tokenInfo/SUCCESS',
};

const initialState = {
  processing: false,
  response: null,
  scopeList: [],
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchTokenInfo:
      return { processing: true, response: null };
    case actionTypes.fetchTokenInfoSuccess:
      return { processing: false, response: action.payload }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getPermissions: (state) => state.onboardingApp.acl.response,
  getScopeList: (state) => {
    const acl_permissions = state.onboardingApp.acl.response?.product_accounts?.filter?.(ac =>
      ac?.identifier == AuthManager.getMID() || ac?.uuid == AuthManager.getMerchantUUID()
    )?.[0]?.acl_role?.acl_permissions || []
    [AuthManager.getMID()]?.acl_role?.acl_permissions || [];
    let scopeList = []
    acl_permissions.forEach(v => {
      if (v.scopes) {
        scopeList = [...scopeList, ...v.scopes];
      }
    });
    return scopeList.filter(function (item, pos) {
      return scopeList.indexOf(item) == pos;
    })
  },
};


//  Actions
export const actions = {
  fetchTokenInfo: () => ({ type: actionTypes.fetchTokenInfo }),
};

// Workers
function* fetchTokenInfoWorker() {
  const mid = AuthManager.getMID();
  const isKam = window.sessionStorage.getItem('isKam');
  try {
    const endpoint = isKam ? API_ENDPOINTS.AUTH.KAM_TOKEN_INFO({ mid: mid }) : API_ENDPOINTS.AUTH.TOKEN_INFO;
    const response = yield call([axios, 'post'], endpoint);
    yield put({ type: actionTypes.fetchTokenInfoSuccess, payload: response.data })
  } catch (err) {
    const errResponse = yield err.response;
  }
}


// Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchTokenInfo, fetchTokenInfoWorker);
}