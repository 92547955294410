import { useState } from "react";

export const useFetch = ({
  fetchFn,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const [state, setState] = useState({
    isFetching: false,
    data: undefined,
    error: undefined,
    isSuccess: false,
    isError: false,
    params: {},
  })


  const query = async (params) => {
    setState(s => ({ ...s, isFetching: true, data: undefined, params: params || {} }))
    try {
      const response = await fetchFn(params)
      setState(s => ({
        ...s,
        isFetching: false,
        data: response,
        error: undefined,
        isSuccess: true,
        isError: false,
      }))
      onSuccess({ data: response, params })
      return { data: response }
    } catch (e) {
      setState(s => ({
        isFetching: false,
        data: undefined,
        error: e,
        isSuccess: false,
        isError: true,
        params: {},
      }))
      onError({ error: e })
      return { error: e }
    }
  }

  return [query, state]
}
