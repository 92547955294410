import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import * as C from '../../constants/teamAccountTypes';
import OnboardingHeader from '../../components/OnboardingHeader';
import ManageRolesAndEmployeesCard from '../../components/ManageEmployeesAndRoles/ManageRolesAndEmployeesCard';
import ListEmployees from '../../components/ManageEmployeesAndRoles/ListEmployees';
import AddEmployeeDetails from '../../components/ManageEmployeesAndRoles/AddNewEmployeeDetails';
import AddNewEmpRoleWidget from '../../components/ManageEmployeesAndRoles/AddNewEmpRoleWidget';
import RoleAddedSuccessfully from '../../components/ManageEmployeesAndRoles/RoleSuccessfullyAdded';
import DeleteEmployee from '../../components/ManageEmployeesAndRoles/DeleteEmployee';
import { TEAM_ACCOUNTS_LITERALS, TEAMS_ACCOUNT_TITLE } from '../../constants/appConstants';
import { checkPermissionOnElement } from '../../services/utils';
import { PERMISSIONS_LIST } from '../../constants/appConstants';
import NoAccessModal from '../../components/ManageEmployeesAndRoles/NoAccessModal';
import { fetchTokenInfoFromHub } from '../../actions/auth.js';
import cleverTap from '../../services/cleverTap';


/**
 *  container for team accounts
 *
 */
import { config } from 'hub-redux-auth';

import PropTypes from 'prop-types';
import { userOwnedAclRoles,
  fetchAclPermissions,
  fetchEmployeeList,
  createEmployee,
  deleteEmployee,
  updateEmployee } from '../../actions/team_account';
import { sessionCheck } from '../../actions/auth';
import { fetchBusinessEntities } from '../../actions/profile';
import ErrorMessage from '../../components/ErrorMessage';
import { CLEAR_ERROR_MESSAGES } from '../../constants/merchantTypes';
import RoleCard from '../../containers/RoleCard';

const BreadCrumbs = (props) => {
  const links = [
'Dashboard',
'Settings',
'Manage Staff',
...props.links
];
  return (
    <div className="bread-crumbs">
      {
        links.map((link) => <div key={`breadcrumbs_${link}`}>
          <a onClick={() => props.onClick(link)}>
            {link}
          </a>
          <span>
            &gt;
          </span>
        </div>)
      }
    </div>
  );
},

ManageRoles = (props) => {
  const { permissionsMasterData, dispatch, merchantUuid, isFetching, ownedAclRolesError, action } = props;
  return <React.Fragment>
    {
      props.ownedAclRoles.map((aclRole) => <RoleCard
                key={`role_${aclRole.uuid}`}
                dispatch={dispatch}
                merchantUuid={merchantUuid}
                aclRole={aclRole}
                isFetching={isFetching}
                permissionsMasterData={permissionsMasterData}
                error={ownedAclRolesError}
                action={action}
              />)

    }
    <RoleCard
      type="new"
      permissionsMasterData={permissionsMasterData}
      dispatch={dispatch}
      isFetching={isFetching}
      merchantUuid={merchantUuid}
      error={ownedAclRolesError}
      action={action}
    />
  </React.Fragment>;
},
 /**
  * @param  {param} state redux state object
  * @return {object} An object with required redux state properties
  */
  mapStatesToProps = (state) => ({
    EMPLOYEE_LIST: state.teamAccount.employees.data || [],
    merchant: state.app.user || {},
    ownedAclRoles: state.app.ownedAclRoles,
    permissionsMasterData: state.masterData.aclPermissions,
    aclRolePermissions: state.masterData.aclPermissions
  });
class ManageStaff extends React.Component {

  state = {
    confirmation: false,
    editActiveFor: null,
    newEmployee: {
      email: '',
      formError: false,
      formErrorMessages: {
        errorCode: null,
        messageText: ''
      },
      hideFloatingButton: false
    },
    roleData: [
      { label: 'Developer',
        value: 'developer' },
      { label: 'Accountant',
        value: 'accountant' },
      { label: 'lead',
        value: 'lead' },
      { label: 'manager',
        value: 'manager' }
    ],
    viewType: 'Manage Staff'
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, merchant: { uuid: merchantUuid } } = this.props,
      { merchant: { uuid: nextMerchantUuid } } = nextProps;
    if (nextMerchantUuid && !merchantUuid) {
      checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS) && userOwnedAclRoles(dispatch, nextMerchantUuid);
      checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS) && fetchEmployeeList(dispatch, nextMerchantUuid);
    }
  }

  componentWillMount = async () => {
    const response = await fetchTokenInfoFromHub();
    checkPermissionOnElement(PERMISSIONS_LIST.USER_PROFILE) && fetchBusinessEntities(this.props.dispatch);
    checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS) && fetchAclPermissions(this.props.dispatch);
    sessionCheck(this.props.dispatch);
  }

  getStaffCount = () => {
    const staffs = this.props.EMPLOYEE_LIST;
    if (staffs.length <= 0) {
      return 0
    }
      return staffs.length

  }

  resetBreadCrumb = () => {
    this.setState({
      viewType: 'Manage Staff'
    })
  }


  resetFormErrors = () => {
    const empDetails = this.state.newEmployee;
    empDetails.formError = false;
    empDetails.formErrorMessages = '';
    this.setState(empDetails);
  }

  openNoAccessModal = (e) => {
    this.setState({ isNoAccessModalOpen: true })
  }

  closeNoAccessModal = (e) => {
    this.setState({ isNoAccessModalOpen: false })
  }

  initiateAddEmployee = () => {
    this.props.history.push(`${this.props.match.url}/staff/add/form`);
    this.resetFormErrors();
  }

  handleNameChange = (e) => {
    if (e.target.value) {
      const newEmployeeDetails = this.state.newEmployee;
      newEmployeeDetails.name = e.target.value;
      this.setState({ newEmployee: newEmployeeDetails });
    }
  }

  handleEmailChange = (e) => {
    const newEmployeeDetails = this.state.newEmployee;
    newEmployeeDetails.email = e.target.value;
    this.setState({ newEmployee: newEmployeeDetails });
  }

  handleRoleChange = (e) => {
    const newEmployeeDetails = this.state.newEmployee;
    newEmployeeDetails.role = e.target.value;
    this.setState({ newEmployee: newEmployeeDetails });
  }

  onAddEmployeeSave = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'New employee added',
        'CTA Page': 'Manage employees',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const employeeDetails = this.state.newEmployee,
    { merchant: { uuid: merchantUuid } } = this.props,
    newEmployeeDTO = {};
    newEmployeeDTO.email = this.state.newEmployee.email;
    newEmployeeDTO.acl_role_id = this.state.newEmployee.role;
    newEmployeeDTO.name = this.state.newEmployee.name;
    createEmployee({ callback: () => {
        this.props.history.push(`${this.props.match.url}/staff/add/success`);
        this.resetFormErrors();
      },
      dispatch: this.props.dispatch,
      errorCallback: (errors) => {
        if (errors) {
          employeeDetails.formError = true;
          employeeDetails.formErrorMessages = {};
          employeeDetails.formErrorMessages.messageText = '';
          for (const error in errors) {
            if (error === 'merchant_id'){
              employeeDetails.formErrorMessages.messageText = `${employeeDetails.formErrorMessages.messageText}
            ${errors[error][0]}#`;
            } else {
              employeeDetails.formErrorMessages.messageText = `${employeeDetails.formErrorMessages.messageText + error}
            ${errors[error][0]}#`;
            }
          }
          this.setState({ newEmployee: employeeDetails });
        }
      },
      merchantUuid,
      params: newEmployeeDTO });
  }

  onAddEmployeeCancel = () => {
    const newEmployeeDetails = this.state.newEmployee;
    newEmployeeDetails.email = '';
    newEmployeeDetails.role = '';
    newEmployeeDetails.formError = false;
    newEmployeeDetails.formErrorMessages = {};
    newEmployeeDetails.formErrorMessages.messageText = '';
    this.setState({ newEmployee: newEmployeeDetails });
    this.props.history.push(`${this.props.match.url}/staff/add`);
  }

  handleCancelClick = () => {
    this.setState({
      confirmation: true
    })
  }

  handleConfirmationCancel = () => {
    this.setState({
      confirmation: false
    })
  }
  handleConfirmationClose = () => {
    this.setState({
      confirmation: false
    })
    this.onAddEmployeeCancel()
  }

  toggleEditMode = (employee) => {
    this.setState({ editActiveFor: employee.id });
    this.props.history.push(`${this.props.match.url}/staff/add`);
    const newEmployeeDetails = this.state.newEmployee;
    newEmployeeDetails.formError = false;
    newEmployeeDetails.formErrorMessages = {};
    newEmployeeDetails.formErrorMessages.messageText = '';
    this.setState({ newEmployee: newEmployeeDetails });
  }

  resetEditState = () => {
    this.setState({ editActiveFor: null });
  }

  onEditSave = (employee) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Employee edited',
        'CTA Page': 'Manage employees',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const { merchant: { uuid: merchantUuid } } = this.props,
      DTO = { acl_role_id: this.state.newEmployee.role,
name: employee.name };
    updateEmployee({ callback: () => {
        const employeeDetails = this.state.newEmployee;
        employeeDetails.role = '';
        this.props.history.push(`${this.props.match.url}/staff/update/success`);
        this.setState({ newEmployee: employeeDetails });
        this.setState({ editActiveFor: null })
      },
      dispatch: this.props.dispatch,
      errorCallback: (errors) => {
        if (errors) {
          const employeeDetails = this.state.newEmployee;
          employeeDetails.formError = true;
          employeeDetails.formErrorMessages = {};
          employeeDetails.formErrorMessages.messageText = '';
          for (const error in errors) {
            employeeDetails.formErrorMessages.messageText = `${employeeDetails.formErrorMessages.messageText + error}
            ${errors[error][0]}; `;
          }
          this.setState({ newEmployee: employeeDetails });
        }
      },
      merchantUuid,
      params: DTO,
      staffInviteId: employee.id });
  }

  onEditCancel = () => {
    this.setState({ editActiveFor: null });
    const newEmployeeDetails = this.state.newEmployee;
    newEmployeeDetails.email = '';
    newEmployeeDetails.role = '';
    newEmployeeDetails.formError = false;
    newEmployeeDetails.formErrorMessages = {};
    newEmployeeDetails.formErrorMessages.messageText = '';
    this.setState({ newEmployee: newEmployeeDetails });
  }

  onDeleteEmployee = (employeeDetails) => {
    this.props.dispatch({ payload: employeeDetails,
      type: C.SET_CURRENT_EMPLOYEE });
    this.props.history.push(`${this.props.match.url}/staff/delete`);
  }

  onDeleteEmployeeConfirm = (employeeDetails) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Employee deleted',
        'CTA Page': 'Manage employees',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const cb = () => {
      this.props.history.push(`${this.props.match.url}/staff/add`);
    },
    { merchant: { uuid: merchantUuid } } = this.props;
    deleteEmployee({
      callback: cb,
      dispatch: this.props.dispatch,
      merchantUuid,
      staffInviteId: employeeDetails.id
    });
  }

  onDeleteEmployeeCancel = () => {
    this.props.dispatch({ type: C.UNSET_CURRENT_EMPLOYEE })
    this.props.history.push(`${this.props.match.url}/staff/add`)
  }

  redirectToRole = () => {
    this.props.history.push(`${this.props.match.url}/roles`)
  }

  manageEmployees = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Manage Employees',
        'CTA Page': 'Manage Staff',
        'CTA Type': 'View',
        'Email ID': user.email,
        MID: user.mid,
    });
    if (checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS)) {
      this.setState({ viewType: 'Manage Employees' });
      this.props.history.push(`${this.props.match.url}/staff/add`);
    } else {
      this.openNoAccessModal(e)
    }
  }

  manageRoles = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Manage Roles',
        'CTA Page': 'Manage Staff',
        'CTA Type': 'View',
        'Email ID': user.email,
        MID: user.mid,
    });
    if (checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS)) {
      this.setState({ viewType: 'Manage Roles' });
      this.props.history.push(`${this.props.match.url}/roles`);
    } else {
      this.openNoAccessModal(e)
    }
  }

  closeSuccessModal = () => {
    this.props.history.push(`${this.props.match.url}/staff/add`);
  }

  renderSwitchOne = () => <Switch>
      <Route
        exact
        path={ this.props.match.url }
        render={ (props) => <React.Fragment>
          <div className="manage_card_container">
            {<ManageRolesAndEmployeesCard
              { ...props }
              forEmployees={ true }
              count= { this.getStaffCount() }
              onclick={ this.manageEmployees }
              resetBreadCrumb = { this.resetBreadCrumb }
              resetEdit = { this.resetEditState }
            />}
            {<ManageRolesAndEmployeesCard
              { ...props }
              forEmployees={ false }
              count={ this.roleCount }
              onclick={ this.manageRoles }
              resetBreadCrumb = { this.resetBreadCrumb }
              resetEdit = { this.resetEditState }
            />}
            {<NoAccessModal isOpen={this.state.isNoAccessModalOpen} onClose={this.closeNoAccessModal}></NoAccessModal>}
          </div>
            </React.Fragment>
            }
      />
      <Route
        exact
        path={ `${this.props.match.url}/roles` }
        render={this.renderRolesContent}
      />
      <Route
        exact
        path={ `${this.props.match.url}/staff/add` }
        render={ (props) => <AddNewEmpRoleWidget
          { ...props }
          onAction={ this.initiateAddEmployee }
          textContent="Add New Employee"
          staffCount={ this.getStaffCount() }
        /> }
      />
      <Route
        exact
        path={`${this.props.match.url}/staff/add/form`}
        render={(props) => <AddEmployeeDetails
          {...props}
          onSave={this.onAddEmployeeSave}
          onCancel={this.handleCancelClick}
          handleEmailChange={this.handleEmailChange}
          handleRoleChange={this.handleRoleChange}
          handleNameChange={this.handleNameChange}
          handlePhoneChange={this.handlePhoneChange}
          staffCount={this.getStaffCount()}
          confirmation={ this.state.confirmation}
          handleConfirmationCancel={this.handleConfirmationCancel}
          handleConfirmationClose={this.handleConfirmationClose}
          formError={this.state.newEmployee.formError}
          formErrorText={this.state.newEmployee.formErrorMessages.messageText}
          resetEdit = { this.resetEditState }
        /> }
      />
    </Switch>

  navigateTo = (link) => {
    switch (link) {
      case 'Dashboard':
        window.location.href = '#';
        break;
      case 'Settings':
        window.location.href = `${config.get('productHost')}/merchant-dashboard/#/notification-settings`;
        break;
      case 'Manage Staff':
        this.setState({ viewType: 'Manage Staff' });
        this.props.history.push(`${this.props.match.url}`);
        break;
      case 'Manage Roles':
        this.setState({ viewType: 'Manage Roles' });
        this.props.history.push(`${this.props.match.url}/roles`);
        break;
      case 'Manage Employees':
        this.setState({ viewType: 'Manage Employees' });
        this.props.history.push(`${this.props.match.url}/staff`);
        break;
      default:
        break;
    }
  }

  clearErrorMessages = () => {
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERROR_MESSAGES });
  }

  renderAlert = () => {
    const { ownedAclRoles: { error, message, warningMessage, helpText } } = this.props;
    if (error) {
      return <ErrorMessage
                type="error"
                message={error}
                helpText={ helpText }
                warningMessage={warningMessage}
                openModal={true}
                onClose={this.clearErrorMessages}
              />;
    } else if (message) {
      return <ErrorMessage
                type="message"
                message={message}
                helpText={ helpText }
                warningMessage={warningMessage}
                openModal={true}
                onClose={this.clearErrorMessages}
              />;
    }
      return <div />;
  }
  getBreadCrumbText = () => {
    const { viewType } = this.state;
    if (viewType === 'Manage Staff') {
      return [];
    }
      return [[viewType]];
  }
  get roleCount() {
    const { ownedAclRoles } = this.props,
    emptyCount = 0;
    if (ownedAclRoles &&
        ownedAclRoles.data &&
        ownedAclRoles.data.acl_roles) {
          return ownedAclRoles.data.acl_roles.length;
        }
          return emptyCount;
  }
  renderContent = () => {
    const { ownedAclRoles, dispatch, merchant: { uuid: merchantUuid } } = this.props;
    return [
            ownedAclRoles &&
            ownedAclRoles.data &&
            ownedAclRoles.data.acl_roles &&
            <ManageRoles
              merchantUuid={merchantUuid}
              ownedAclRoles={ownedAclRoles.data.acl_roles}
              ownedAclRolesError={ownedAclRoles.error}
              action={ownedAclRoles.action}
              isFetching={ownedAclRoles.fetching}
              dispatch={dispatch}
              permissionsMasterData={this.props.permissionsMasterData} />
            ];

  }
  renderRolesContent = () => {
    const { ownedAclRoles: { success } } = this.props;
    return (
      <div className="main-body mt-100 staff-management-container">
        {success && this.renderAlert()}
        <div className="page-heading">
          <span>Manage Roles</span>
          <span> ({this.roleCount})</span>
        </div>
        <span className="page-subheading">
          { TEAM_ACCOUNTS_LITERALS.MANAGE_ROLES_SCREEN_SUBHEADER }
        </span>
        {
          this.renderContent()
        }
      </div>
    );
  }

  componentDidMount = () => {
    document.title = TEAMS_ACCOUNT_TITLE
    const { location: { pathname } } = window;
    if (pathname.includes('staff')) {
      this.setState({ viewType: 'Manage Employees' });
    } else if (pathname.includes('roles')) {
      this.setState({ viewType: 'Manage Roles' });
    }
  }

  render() {
    const { match, EMPLOYEE_LIST } = this.props;
    return (
      <div>
        <div className="app-container">
          <OnboardingHeader pageTitle="Settings" pageType="settings" />
        </div>
        <React.Fragment>
          <span className="team-accounts-content">
            {/*<BreadCrumbs onClick={this.navigateTo} links={this.getBreadCrumbText()} />*/}
            <div className="team-accounts-container-2">
              {this.renderSwitchOne()}
            </div>
            <span>
              <Switch>
                <Route
                  path={`${match.url}/staff`}
                  render={
                    (props) => EMPLOYEE_LIST.map((employee) => <ListEmployees
                      {...props}
                      key={employee.id}
                      employee={employee}
                      onDelete={this.onDeleteEmployee}
                      onEditClick={this.toggleEditMode}
                      onEditSave={this.onEditSave}
                      onEditCancel={this.onEditCancel}
                      handleRoleChange={this.handleRoleChange}
                      editActiveFor={this.state.editActiveFor}
                      formError={this.state.newEmployee.formError}
                      formErrorText={this.state.newEmployee.formErrorMessages.messageText}
                    />)
                  }
                />
              </Switch>
            </span>
          </span>
          <Route
            exact
            path={ `${match.url}/staff/add/success` }
            render={ (props) => <RoleAddedSuccessfully
              { ...props } successText="Employee added successfully!"
              helpTextEnabled={ true }
              isOpen={ this.state.isSuccessModalOpen }
              onClose={ this.closeSuccessModal }
              autoClose={3}
              employee= { this.state.newEmployee }
              /> }
          />
          <Route
              exact
              path={ `${match.url}/staff/update/success` }
              render={ (props) => <RoleAddedSuccessfully
                  { ...props } successText="Employee updated successfully!"
                  helpTextEnabled={ false }
                  isOpen={ this.state.isSuccessModalOpen }
                  onClose={ this.closeSuccessModal }
                  autoClose={3}
                  employee= { this.state.newEmployee }
              /> }
          />
        </React.Fragment>
        <Route
          path={`${match.url}/staff/delete`}
          render={(props) => <DeleteEmployee
            {...props}
            onConfirm={this.onDeleteEmployeeConfirm}
            onCancel={this.onDeleteEmployeeCancel}/>
          }
        />
      </div >
    );
  }
}

ManageStaff.propTypes = {
  action: PropTypes.string,
  EMPLOYEE_LIST: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  merchant: PropTypes.object,
  ownedAclRoles: PropTypes.object,
  permissionsMasterData: PropTypes.array
};

ManageRoles.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  merchantUuid: PropTypes.string,
  ownedAclRoles: PropTypes.array,
  permissionsMasterData: PropTypes.array
}
BreadCrumbs.propTypes = {
  links: PropTypes.array,
  onClick: PropTypes.func
}

export default connect(mapStatesToProps)(ManageStaff);
