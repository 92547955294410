import React from 'react';
import PropTypes from 'prop-types';
import Card from 'deltoid/core/Card';
import AddNewStaffIcon from '@assets/images/add.svg'
import { TEAM_ACCOUNTS_LITERALS } from '../../../constants/appConstants'

/**
 *  common card to initiate add new employee/role flow
 *  @param  {object}    props      reacts props
 */
export default function AddNewEmpRoleWidget(props) {
  const { textContent, onAction, staffCount } = props;
  return (
    <div>
      <div className="page-heading">
        <span>Manage Employees</span>&nbsp;
        <span>({staffCount}) </span>
      </div>
      <span className="page-subheading">
      { TEAM_ACCOUNTS_LITERALS.MANAGE_EMPLOYEES_SCREEN_SUBHEADER }
      </span>
      <Card className="add-new-emp-role-widget" waves="light">
          <div className="add-new-emp-role-content" onClick={ onAction }>
            <span className="add-new-employee-img">
              <AddNewStaffIcon></AddNewStaffIcon>
            </span>
            <div className="add-new-emp-role-text-cont">
              <span className="add-new-emp-role-text">{`${textContent}`}</span>
            </div>
          </div>
      </Card>
    </div>
  );
}

AddNewEmpRoleWidget.propTypes = {
  onAction: PropTypes.func.isRequired,
  staffCount: PropTypes.number.isRequired,
  textContent: PropTypes.string
};

AddNewEmpRoleWidget.defaultProps = {
  textContent: 'Add'
};
