import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

import { config } from 'hub-redux-auth';
import HeaderDropdown from '../HeaderDropdown';
import { ACTION_LOGOUT } from '../../constants/authTypes';
import MoneyBrandLogo from '../../../../assets/images/money-brand-logo.svg';
import BizBrandLogo from '../../../../assets/images/biz-brand-logo.svg';
import NowBrandLogo from '@assets/images/payunow-brand-logo.svg';
import PayuBrandLogo from '@assets/images/new-brand-logo.svg';
import * as EVENT from '../../constants/cleverTapEvents';
import cleverTap from '../../services/cleverTap';
import PaperToolTip from '../BaseComponents/PaperTooltip';
import { isItemCompleted, removeStoredItem } from '../../services/utils';
import { setIntegrationDropdownMenuList, setSessionDropDownList } from '../../utils/dropDownList';
import AuthManager from '../../services/AuthManager';
import { onRedirectionCLick } from '../../services/utils';
import {
  selectUser,
  checkIsWebsiteUrlFilled,
  checkIsBankVerified,
  disableBankVerificationConfig
} from '../../reducers/selectors';
import {checkPermissionOnElement} from '../../services/utils'
import { PERMISSIONS_LIST } from '../../constants/appConstants';


@connect(state => ({
  product: state.product,
  user: selectUser(state),
  isWebsiteDetailsFilled: checkIsWebsiteUrlFilled(state),
  isBankVerfied: checkIsBankVerified(state),
  disableBankVerification: disableBankVerificationConfig(state),
  aclRolePermissions: state.masterData.aclPermissions
}), null, null, { pure: false })
export default class OnboardingHeader extends React.Component {
  static propTypes = {
    product: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    showDocumentToolTip: false,
    isSessionDropdownOpen: false,
    isGstTabVisible: false
  }

  showDocumentToolTipModal = () => {
    if (isItemCompleted('showDocumentToolTipModal') && !this.state.showDocumentToolTip) {
      this.setState({ showDocumentToolTip: true });
    }
  }

  productRedirectUrl = () => {
    return this.props.user.merchant_type && this.props.user.settlement_status !== 'Active' &&
    this.props.user.merchant_type === 'aggregator' ? '#' : config.get('productRedirectUrl')
  }

  componentWillMount() {
    document.body.addEventListener('click', this.handleClickOutside);
    this.intervalId = setInterval(this.showDocumentToolTipModal, 1000);
    this.gstTabVisibliltyHandler();
    if (config.get('product', '').toUpperCase() === 'PAYU') {
      setIntegrationDropdownMenuList();
      setSessionDropDownList();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleClick = () => {
    removeStoredItem('showDocumentToolTipModal');
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.setState({ showDocumentToolTip: false });
  }

  onSessionDropdownClick = (e) => {
    e.preventDefault();
    this.setState(({ isSessionDropdownOpen }) => ({
      isSessionDropdownOpen: !isSessionDropdownOpen,
    }));
  }

  onIntegrationDropdownClick = (e) => {
    e.preventDefault();
    this.setState(({ isIntegrationDropdownOpen }) => ({
      isIntegrationDropdownOpen: !isIntegrationDropdownOpen,
    }));
  }

  getHomePageContent = () => {
    const user = AuthManager.getUser();
    if (!user || user.product === 'PayUbiz') {
      return <a href="#" onClick={() => onRedirectionCLick()}>Home</a>;
    }
    return config.get('productRedirectUrl') &&
      (<a href={config.get('productRedirectUrl')}>Home</a> || <Link to="/">Home</Link>)
  };

  getBreadCrumb() {
    return (
      <div>
        <h3 className="page-title first">{this.getHomePageContent()}</h3>
        <h3 className="page-title">{this.props.pageTitle}</h3>
      </div>
    );
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: ACTION_LOGOUT });
    cleverTap.event(EVENT.LOGOUT);
  }

  handleClickOutside = () => {
    this.setState({
      isSessionDropdownOpen: false,
      isIntegrationDropdownOpen: false,
    });
  }

  renderLogo = () => {
    switch (this.props.product) {
      case 'MONEY':
        return <MoneyBrandLogo className="float-left brand-logo" />;
      case 'BIZ':
        return <BizBrandLogo className="float-left brand-logo" width="122" height="28" />;
      case 'NOW':
        return <NowBrandLogo className="float-left brand-logo" />;
      default:
        return <PayuBrandLogo className="float-left brand-logo" width="122" height="28" />;
    }
  }

  renderLogoWithRedirection = () => {
    const user = AuthManager.getUser();
    if (!user || user.product === 'PayUbiz') {
      return <a href="#" onClick={() => onRedirectionCLick()}>{this.renderLogo()}</a>;
    }
    return config.get('productRedirectUrl') &&
      (<a href={config.get('productRedirectUrl')}>{this.renderLogo()}</a> || <Link to="/">{this.renderLogo()}</Link>);
  };

  toolTipContent = () => {
    return (
      <div className="document-tool-tip">
        <span className="document-tool-tip-header">Ready to upload</span>
        <div className="document-tool-tip-body">You can now start uploading the documents.<br />
          Checkout the list of documents you need to upload.
        </div>
        <Button type="submit" className="dflt-btn v3" onClick={this.handleClick}>Okay, Got it!</Button>
      </div >
    );
  }

  gstTabVisibliltyHandler = () => {
    let curMid = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).mid
    if(curMid) {
      let allMerchants = sessionStorage.getItem('merchants') && JSON.parse(sessionStorage.getItem('merchants'));
      if(allMerchants && allMerchants.length > 0) {
        allMerchants.forEach((merchant) => {
          if(merchant.mid === curMid && merchant.pancard_number) {
            this.setState({ isGstTabVisible: true });
          }
        })
      }
    }
  }

  render() {
    const sessionDropdownMenuList = config.get('sessionDropdownMenuList', []).concat([{
      label: 'Logout',
      onItemClick: this.handleLogout,
    }]);
    if (this.props.product == 'NOW' && this.props.user && this.props.user.business_origin === 'PayUnow') {
      sessionDropdownMenuList.splice(1, 0, {
        label: 'Settings',
        linkTo: `${config.get('productHost') || config.get('nowProductHost')}/merchant-dashboard/#/tdr-settings`
      });
    }
    let allMerchants = sessionStorage.getItem('merchants') && JSON.parse(sessionStorage.getItem('merchants'));
    let teamAccountIndex = null;
    sessionDropdownMenuList.forEach((ele, i) => {
      if(ele.label === 'Team Accounts') {
        teamAccountIndex = i;
      }
    })
    if (teamAccountIndex && allMerchants && allMerchants.length < 2) {
      sessionDropdownMenuList.splice(teamAccountIndex, 1);
    }
    if (this.props.user && this.props.user.merchant_type === 'aggregator' && this.props.user.settlement_status !== 'Active') {
      sessionDropdownMenuList.splice(2, 1, {
        label: 'Business Dashboard',
        linkTo: '#',
      });
    }

    const showWebsiteTab = this.props.product !== 'NOW' && this.props.isBankVerfied
    const documentApplicable = this.props.product !== 'NOW' && this.props.user.integration_type === 'ThirdParty' &&
      this.props.isWebsiteDetailsFilled && this.props.isBankVerfied

    const integrationDropdownMenuList = config.get('integrationDropdownMenuList', null);
    if (integrationDropdownMenuList && !checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_CREDENTIALS)){
      integrationDropdownMenuList.forEach((ele, i) => {
        if(ele.label === 'Integration Details'){
          integrationDropdownMenuList.splice(i, 1);
        }
      })
    }
    return (
      <div>
        <header className="fixed-top">
          <div className="onbrdng-waves">
            <div className="layout-header">
              {this.renderLogoWithRedirection()}
              {this.getBreadCrumb()}
              <ul className="hdr-right-icon">
                {integrationDropdownMenuList &&
                  <HeaderDropdown
                    isOpen={this.state.isIntegrationDropdownOpen}
                    linkClassName={`cmnicon-integration${this.props.product === 'NOW' ? '-now' : ''}`}
                    onDropdownClick={this.onIntegrationDropdownClick}
                    menuList={integrationDropdownMenuList}
                  />
                }
                {config.get('productHelpUrl') && <li key="productHelpUrl">
                  <a className={`icon cmnicon-help${this.props.product === 'NOW' ? '-now' : ''}`}
                    href={config.get('productHelpUrl')}>&nbsp;</a>
                </li>}
                <HeaderDropdown
                  isOpen={this.state.isSessionDropdownOpen}
                  linkClassName={`cmnicon-account${this.props.product === 'NOW' ? '-now' : ''}`}
                  onDropdownClick={this.onSessionDropdownClick}
                  menuList={sessionDropdownMenuList}
                />
              </ul>
              <div className="layout-header">
                <ul className="header-tabs left-side-tabs">
                  {
                    this.props.pageType === 'settings'
                      ? [
                        checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_NOTIFICATIONS) && <li><a href={`${config.get('productHost')}/merchant-dashboard/#/notification-settings`} >Notification Settings</a></li>,
                        checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_WEBHOOKS) && <li><a href={`${config.get('productHost')}/merchant-dashboard/#/webhook-settings`} >Webhook Settings</a></li>,
                        checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_CHECKOUTS) && <li><a href={`${config.get('productHost')}/merchant-dashboard/#/checkout-settings`} >Checkout</a></li>,
                        checkPermissionOnElement(PERMISSIONS_LIST.USER_PROFILE) && this.state.isGstTabVisible && <li><a href={`${config.get('productHost')}/merchant-dashboard/#/gst-settings`} activeClassName="active">Gst Settings</a></li>,
                        checkPermissionOnElement(PERMISSIONS_LIST.MANAGE_TEAM_ACCOUNTS) && <li><NavLink exact to="/settings/team_accounts" activeClassName="active">Manage Staff</NavLink></li>
                      ] : [
                        <li key="profile"><NavLink exact to="/onboarding" activeClassName="active">Profile</NavLink></li>,
                        (showWebsiteTab || this.props.disableBankVerification) && <li key="website"><NavLink exact to="/onboarding/website" activeClassName="active">Website</NavLink></li>,
                        (documentApplicable || this.props.disableBankVerification) &&
                        <li key="documents">
                          <PaperToolTip
                            open={this.state.showDocumentToolTip}
                            noHover={true}
                            content={this.toolTipContent()}
                            contentClassName={'document-tool-tip-content'}>
                            <NavLink to="/onboarding/documents" activeClassName="active">Documents</NavLink>
                          </PaperToolTip>
                        </li>,
                        this.props.product === 'NOW' && <li key="billing-history"><a href={`${config.get('productHost')}/merchant-dashboard/#/tdr-billing-history`} >TDR Billing History</a></li>
                      ]
                  }
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div className="mt-5 mb-5" />
        <div className="pt-5" />
      </div>
    );
  }
}
