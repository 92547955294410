import React from 'react';

import AgreementForm from './AgreementForm';
import AgreementLoader from './AgreementLoader';
import AgreementRetry from './AgreementRetry';
import AgreementFailure from './AgreementFailure';
import AgreementDownload from './AgreementDownload';

import { config } from 'hub-redux-auth';
import cleverTap from '../../services/cleverTap';
import { getContactUsLink } from '../../services/utils'

export default class GenerateServiceAgreement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderOpen: false,
      isRetryOpen: false,
      isFailureOpen: false,
      isDownloaderOpen: false,
      isMainPopUpOpen: true,
      submitCount: 0,
    };
    this.fileLocation = '';
    this.params = {};
  }

  componentWillReceiveProps(nextProps) {
    const serviceAgreement = nextProps.generatedServiceAgreement;
    if (serviceAgreement.fetching) {
      this.setState({
        isLoaderOpen: true,
        isRetryOpen: false,
        isFailureOpen: false,
        isDownloaderOpen: false,
      });
    }
    else if (serviceAgreement.data.processed_document) {
      this.setState({
        isLoaderOpen: false,
        isRetryOpen: false,
        isFailureOpen: false,
        isDownloaderOpen: true,
      });
    }
  }

  onDownloadClick = () => {
    cleverTap.event('Download Agreement clicked', { mid: this.props.merchant.mid });
    this.props.onPopUpClose();
    this.setState({
      isDownloaderOpen: false,
      isMainPopUpOpen: true,
    });

  };

  onContactUsClose = () => {
    this.props.onPopUpClose();
    this.setState({
      isFailureOpen: false,
      isMainPopUpOpen: true,
    });
  };

  handleSubmit = () => {
    this.setState({
      isLoaderOpen: true,
      isMainPopUpOpen: false,
    });
    this.generateAgreement();
  };

  generateAgreement = async () => {
    try {
      const response = await this.props.generateServiceAgreement(this.params);
      this.setState({
        isLoaderOpen: false,
        isRetryOpen: false,
        isDownloaderOpen: true,
      });
    } catch (err) {
      this.setState(({ submitCount }) => ({
        submitCount: submitCount + 1,
      }));
      this.setState({
        isRetryOpen: false,
        isLoaderOpen: true,
      });
      this.handleRetryClick();
    }
  };

  toggleMainPopUp = () => {
    const { isPopUpOpen, onPopUpClose, merchant } = this.props;
    const initialValues = {
      business_category_id: merchant.business_category_id,
      business_sub_category_id: merchant.business_sub_category_id,
      monthly_expected_volume: merchant.monthly_expected_volume,
    }
    if (isPopUpOpen && this.state.isMainPopUpOpen) {
      return (
        <AgreementForm
          isOpen={isPopUpOpen}
          onClose={onPopUpClose}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          merchantUuid={this.props.merchant.uuid}
          mid={this.props.merchant.mid}
        />
      )
    }
    return null;
  };

  toggleLoader = () => {
    if (this.state.isLoaderOpen) {
      return (
        <AgreementLoader
          isOpen={this.state.isLoaderOpen}
          isCloseDisabled
        />
      );
    }
    return null;
  };

  toggleRetry = () => {
    if (this.state.isRetryOpen) {
      return (
        <AgreementRetry
          isOpen={this.state.isRetryOpen}
          action={this.generateAgreement}
          isCloseDisabled
        />
      );
    }
    return null;
  };

  handleRetryClick = () => {
    if (this.state.submitCount === 3) {
      this.setState({
        isLoaderOpen: false,
        isFailureOpen: true,
      });
    } else {
      this.setState({
        isLoaderOpen: false,
        isRetryOpen: true,
      });
    }
  };

  toggleContactUs = () => {
    if (this.state.isFailureOpen) {
      return (
        <AgreementFailure
          isOpen={this.state.isFailureOpen}
          action={this.handleContactUs}
          onClose={this.onContactUsClose}
        />
      );
    }
    return null;
  };

  handleContactUs = () => {
    const { onPopUpClose } = this.props;
    onPopUpClose();
    this.setState({
      isFailureOpen: false,
      isMainPopUpOpen: true,
    });
    window.open(getContactUsLink('issue=Account Related'), '_blank');
  };

  toggleDownloader = () => {
    return this.state.isDownloaderOpen ? (
      <AgreementDownload
        isOpen={this.state.isDownloaderOpen}
        onClose={this.onDownloaderClose}
        link={this.props.generatedServiceAgreement.data.processed_document && this.props.generatedServiceAgreement.data.processed_document.path }
        onDownload={this.onDownloadClick}
        isCloseDisabled
        businessFilingStatus={this.props.businessFilingStatus}
      />
    ) : null;
  };


  render() {
    return (
      <React.Fragment>
        {this.toggleMainPopUp()}
        {this.toggleLoader()}
        {this.toggleRetry()}
        {this.toggleContactUs()}
        {this.toggleDownloader()}
      </React.Fragment>
    );
  }
}
