import { select, put, takeLatest, call } from "redux-saga/effects";
import AuthManager from "@services/AuthManager";
import axios from 'axios';
import { productAccountSelectors, toastActions, websiteDetailsActions, websiteDetailsSelectors, masterDataSelectors, productAccountActions } from '.';
import { COMMENTS_API_ENDPOINTS } from "../../../one-onboarding/services/apiConfig";

//  Action types
export const actionTypes = {
  submitWebisteComment: 'submitWebisteComment/INITIATE',
  submitWebisteCommentSuccess: 'submitWebisteComment/SUCCESS',
  submitWebisteCommentFailed: 'submitWebisteComment/FAILED',
  updateCommentFormData: 'commentFormData/UPDATE',
  restCommentFormData: 'commentFormData/REST',
  createWebsiteDocument: 'createWebsiteDocument/INITIATE',
};

const initialState = {
  website: {
    processing: false,
  },
  formData: {},
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.submitWebisteComment:
      return { ...state, website: { ...state.website, processing: true } };
    case actionTypes.submitWebisteCommentSuccess:
    case actionTypes.submitWebisteCommentFailed:
      return { ...state, website: { ...state.website, processing: false } };
    case actionTypes.updateCommentFormData:
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case actionTypes.restCommentFormData:
      return { ...state, formData: {} };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  submitWebsiteCommentProcessing: (state) => state.onboardingApp.comments.website.processing,
  getWebsiteComments: (state) => {
    const { websiteCommentUploadedFile, websiteComment, } = state.onboardingApp.comments?.formData || {};
    return { websiteCommentUploadedFile, websiteComment };
  }
};


//  Actions
export const actions = {
  submitWebisteComment: ({comment, file, urlNotUpdated, callback}) => ({ type: actionTypes.submitWebisteComment, payload: {comment, file, urlNotUpdated, callback}}),
  updateCommentFormData: (formData) => ({ type: actionTypes.updateCommentFormData, payload: formData }),
  createWebsiteDocument: (formData) => ({ type: actionTypes.createWebsiteDocument, payload: formData }),
};

// Workers
function* submitWebisteCommentWorker(action) {
  const { comment, file, urlNotUpdated, callback } = action.payload;
  const productAccountDetails = yield select(productAccountSelectors.getProductAccountDetails);
  const latestSFCommentId = productAccountDetails?.sf_agent_comments?.latest_sf_comment?.id;
  const documentTypes = yield select(masterDataSelectors.documentTypes);
  const docTypeId = documentTypes?.['WD']?.id || 0;
  
  if (file) {
    const docCategoryId = productAccountDetails?.sf_agent_comments?.req_doc_category?.id;
    let formData = new FormData();
    formData.append('processed_document', file);
    if (docCategoryId) {
      formData.append('document_type_id', docTypeId);
      formData.append('document_category_id', docCategoryId);
    }
    yield put(actions.createWebsiteDocument(formData));
  };

  try {
    if (comment) {
      yield call(
        [axios, 'post'],
        COMMENTS_API_ENDPOINTS.ADD_COMMENTS(AuthManager.getMerchantUUID()),
        { comment: { comment, parent_comment_id: latestSFCommentId } },
      );
      yield put(({ type: actionTypes.submitWebisteCommentSuccess }));
    }
    if ((comment || file) && urlNotUpdated) {
      yield put(websiteDetailsActions.fetchCurrentWebsiteDetails());
      yield put(toastActions.show({
        variant: 'success',
        title: 'Success',
        content: 'Thanks for the reply. We will get back to you.'
      }));
    }
    yield put({ type: actionTypes.restCommentFormData });
    yield put(
      productAccountActions.updateProductAccountDetails({
        sf_agent_comments: {}
      })
    );
    if (callback) callback?.('success')
  } catch {
    yield put(toastActions.show({
      variant: 'error',
      title: 'Failed',
      content: 'Something went wrong.'
    }));
    if (callback) callback?.('error')
    yield put(({ type: actionTypes.submitWebisteCommentFailed }));
  }
}

function* createWebsiteDocumentWorker(action) {
  try {
    yield call(
      [axios, 'post'],
      COMMENTS_API_ENDPOINTS.ADD_DOCUMENT(AuthManager.getMerchantUUID()),
      action.payload,
    );
    yield put(toastActions.show({
      variant: 'success',
      title: 'Success',
      content: 'Thanks for providing document. We will get back to you.'
    }));
    yield put(
      productAccountActions.updateProductAccountDetails({
        sf_agent_comments: {}
      })
    );
  } catch {
    yield put(toastActions.show({
      variant: 'error',
      title: 'Failed',
      content: 'Something went wrong.'
    }));
  }
}

// Sagas
export function* saga() {
  yield takeLatest(actionTypes.submitWebisteComment, submitWebisteCommentWorker);
  yield takeLatest(actionTypes.createWebsiteDocument, createWebsiteDocumentWorker);
}