import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from "redux-saga";
import { setupAxios } from "@services/setupAxios";
import axios from "axios";
import CleverTapHandler from 'deltoid/core/utils/clevertap';
import SentryErrorBoundary from 'deltoid/core/SentryErrorBoundary';
import createReduxStore from './store';
import Routes from './routes';

import '@assets/stylesheets/payu_application.scss';

import AuthManager from '../src/services/AuthManager';

import { ActionCableProvider } from './third-party/react-action-cable-provider'
import ActionCable from 'action-cable-react-jwt'

import { rootSaga } from '@store/rootDuck';
// import mockAxios from '@mock/mockAxios';
import { AUTH_QUERY_PARAMS } from "@constants/queryParams";
import { getQueryParams } from "@constants/utils";
import { AutoLogout } from './features/auto-logout';
const Application = () => {
  const
    authToken = `Bearer_${getQueryParams(AUTH_QUERY_PARAMS.authToken, location) || AuthManager.getFullToken()}`,
    cableUrl = window.location.origin.replace(/^http/, 'ws'),
    consumer = ActionCable.createConsumer(`${cableUrl}/cable`, authToken);
  return <>
    <AutoLogout />
    <ActionCableProvider cable={consumer}> <Routes/> </ActionCableProvider>
  </>;
};


import { config } from 'hub-redux-auth';
const allScopes = process.env.PERMISSION_SCOPE_LIST,
  bizProductUrl = process.env.BIZ_LOGIN_URL,
  oneDashboardUrl = process.env.ONE_DASHBOARD_HOST,
  bizProductHost = process.env.BIZ_HOST,
  moneyProductHost = process.env.MONEY_HOST,
  moneyProductUrl = process.env.MONEY_LOGIN_URL,
  nowProductUrl = process.env.NOW_LOGIN_URL,
  nowProductHost = process.env.NOW_HOST,
  payuWebsiteHost = process.env.PAYU_WEBSITE_HOST,
  prismicCmsHost = process.env.PRISMIC_CMS_HOST,
  cmsAccessToken = process.env.CMS_ACCESS_TOKEN;

let product;
if (window.location.host.includes('payumoney.com')) {
  product = 'PAYUMONEY';
} else if (window.location.host.includes('payunow.com')) {
  product = 'PAYUNOW';
} else {
  product = 'PAYU';
}

config.set('bizProductHost', bizProductHost);
config.set('bizRedirectionUrl', bizProductUrl);
config.set('oneDashboardUrl', oneDashboardUrl);
config.set('moneyRedirectionUrl', moneyProductUrl);
config.set('moneyProductHost', moneyProductHost);
config.set('nowProductHost', nowProductHost);
config.set('nowRedirectionUrl', nowProductUrl);
config.set('product', product);
config.set('payuWebsiteHost', payuWebsiteHost);
config.set('tncURL', `${payuWebsiteHost}/tnc`);
config.set(
  'loginScope',
  'send_verification_otp verify_verification_otp read_dashboard_payumoney ' +
  `hub_session send_verification_link manage_addendum ${allScopes}`
);
config.set('prismicCmsHost', prismicCmsHost);
config.set('cmsAccessToken', cmsAccessToken);

config.set('PRISMIC_DEFAULT_SIGNIN_DOC_ID', process.env.PRISMIC_DEFAULT_SIGNIN_DOC_ID);
config.set('PRISMIC_DEFAULT_SIGNUP_DOC_ID', process.env.PRISMIC_DEFAULT_SIGNUP_DOC_ID);
config.set('PRISMIC_DEFAULT_OAUTH_DOC_ID', process.env.PRISMIC_DEFAULT_OAUTH_DOC_ID);

const sagaMiddleware = createSagaMiddleware();
const store = createReduxStore({ product, }, sagaMiddleware);

sagaMiddleware.run(rootSaga);

/* mock api calls */
// mockAxios(axios);

setupAxios(axios, store);
SentryErrorBoundary.initialize(process.env.SENTRY_DSN);
CleverTapHandler.initialize(process.env.CLEVERTAP_ID);


ReactDOM.render(
  <Provider store={store}>
    <SentryErrorBoundary>
      <Application/>
    </SentryErrorBoundary>
  </Provider>,
  document.getElementById('app'),
);
