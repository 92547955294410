/**
 *
 * selectors.js
 *
 * contains public selectors to fetch data from redux store
 */
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import difference from 'lodash/difference';
import mergeWith from 'lodash/mergeWith';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import _ from 'lodash';

import { ACCOUNT_CONTACT_TYPE, BANK_STATUS, MERCHANT_TYPE } from '../constants/profileConstants';
import {
  DOCUMENT_SUBCATEGORIES,
  DOCUMENT_CHECKLIST,
  DOCUMENT_TYPES,
  RECORD_TYPES
} from '../constants/documentConstants';
import { BUSINESS_ENTITIES } from '../constants/appConstants';
import {
  DOC_UPLOAD_STATUS,
  INDIVIDUAL_DOC_UPLOAD_STATUS,
  LOB_STATUS,
  WEBSITE_STATUS,
  AGREEMENT_STATUS,
  BANK_ADDENDUM_STATUS,
  WEBSITE_ADDENDUM_STATUS,
  SETTLEMENT_STATUS
} from '../constants/statusCodeConstants';
import { formatSubmittedDocResponseUtil } from '../services/utils';

export function selectUser(state) {
  return state.app.user || {};
}
/** select account from redux state. */
export function selectAccountData(state) {
  return state.account.data || {};
}

export function selectUploadedDocuments(state) {
  return state.documents.kycDocuments;
}

/**
 * select BankDetail addendum responsible from state
 * @param  {object} state  initial state
 * @return {object}        addendum details
 */
export function selectBankDetailAddendumData(state) {
  return state.addendumBankDetails;
}

export function selectWebsiteDetailAddendumData(state) {
  return state.addendumWebsiteDetails;
}

/**
 * select Address addendum data from state
 * @param  {object} state  initial state
 * @return {object}        address addendum details
 */
export function selectAddressAddendumData(state) {
  return state.addendumAddressDetails;
}

export const selectUploadedWebsiteDocuments = (state) => state.website.websiteDocuments

export function selectGeneratedServiceAgreements(state) {
  return state.documents.generatedServiceAgreements;
}

export function selectGeneratedServiceAgreement(state) {
  return state.documents.generatedServiceAgreement;
}

export function selectBusinessFilingStatus(state) {
  const { businessEntities } = state.masterData;
  const { business_entity_id: businessEntityId } = selectUser(state);
  if (businessEntities.length && businessEntityId) {
    const index = businessEntities.findIndex(entity => entity.id === businessEntityId,);
    return businessEntities[index] ? businessEntities[index].name : null;
  }
}

export function selectBusinessName(state) {
  const { business_name: businessName } = selectUser(state);
  return businessName || '';
}

export function selectBankVerification(state) {
  const { bank_detail: bankDetail } = selectUser(state);
  return bankDetail ? bankDetail.bank_verification_status : null;
}

/**
 * select settlement_status from state
 * @param  {state} redux state
 * @return {boolean}
 */
export function checkSettlementEnabled(state) {
  const merchant = selectUser(state);
  return merchant && merchant.settlement_status === SETTLEMENT_STATUS.ACTIVE;
}

export function selectPennyAttemptCount(state) {
  const { bank_detail: bankDetail } = selectUser(state);
  return bankDetail ? bankDetail.penny_attempt_left : null;
}

export function selectPennyStatus(state) {
  const { bank_detail: bankDetail } = selectUser(state);
  return bankDetail ? bankDetail.penny_deposit_status : null;
}

export function selectPennyDepositMethod(state) {
  const { bank_detail: bankDetail } = selectUser(state);
  return bankDetail ? bankDetail.penny_deposit_method : null;
}

export function checkIsBusinessDetailsFilled(state) {
  const {
    business_entity_id: businessEntity,
    business_name: businessName,
    registration_address: registrationAddress,
    operating_address: operatingAddress
  } = selectUser(state);
  return !!(businessEntity && businessName && registrationAddress && operatingAddress);
}

export function checkIsWebsiteDetailsFilled(state) {
  const { website_detail: websiteDetail } = selectUser(state);
  return !!(websiteDetail && websiteDetail.id);
}

export function checkIsNowBankDetailsFilled(state) {
  const { pancard_number: panNo, pancard_name: panName, business_entity_id: businessEntity, bank_detail: bankDetail } = selectUser(state);
  return !!panNo && !!(bankDetail ? bankDetail.id : false)
}

export function checkIsPanDetailsFilled(state) {
  const { pancard_number: panNo, pancard_name: panName, pan_verification_status: panStatus } = selectUser(state);
  return !!(panNo && panName && panStatus != 'Failed');
}

export function checkIsBankDetailsFilled(state) {
  return isBankDetailsFilled(selectUser(state))
}

export function isBankDetailsFilled(user) {
  const { bank_detail: bankDetail } = user;
  return !!(bankDetail ? bankDetail.id : false);
}

export function checkIsAllRequiredDocsUploaded(state) {
  const uploadedDocList = selectUploadedDocuments(state).data;
  const businessFilingStatus = selectBusinessFilingStatus(state);
  const { documentCategories } = state.masterData.documentCategoryAndTypes;
  if (businessFilingStatus && !isEmpty(uploadedDocList)) {
    const { DOCUMENT_REJECTED } = INDIVIDUAL_DOC_UPLOAD_STATUS;
    const submittedDocs = Object.keys(uploadedDocList).
      filter((categoryId) => uploadedDocList[categoryId].status !== DOCUMENT_REJECTED).
      map(Number);
    const requiredDocsKeys = Object.keys(omit(DOCUMENT_CHECKLIST[businessFilingStatus], ['SIGNED_AUTHORISATION_LETTER']),);
    const requiredDocs = requiredDocsKeys.
      map((key) => documentCategories[key]);
    return difference(requiredDocs, submittedDocs).length === 0;
  }
  return false;
}

export function isProfileDetailsFilled(user) {
  const { profile_completed: profileCompleted, bank_detail: bankDetail } = user;
  const bank_verification_status = bankDetail ? bankDetail.bank_verification_status : null;
  return profileCompleted && (bank_verification_status === BANK_STATUS.VERIFIED ||
                              checkBankVerificationConfig(user))
}

export function checkIsBankVerified(state) {
  return selectBankVerification(state) === BANK_STATUS.VERIFIED;
}

export function disableBankVerificationConfig(state) {
  return checkBankVerificationConfig(selectUser(state));
}

export function checkBankVerificationConfig(user) {
  const { attached_configs: merchantConfig = [] } = user;
  const onboardingConfig = merchantConfig.find(config => config.name === 'onboarding_config')
  if(!!onboardingConfig && onboardingConfig.config && onboardingConfig.config.bank_verification){
    const { active, config: { bank_verification: { penny_verification_required } } } = onboardingConfig
    return (isBankDetailsFilled(user) && active && !penny_verification_required);
  }
  return false;
}

export function showWebsiteOptionForThirdParty(state) {
  const { attached_configs: merchantConfig = [] } = selectUser(state);
  const onboardingConfig = merchantConfig.find(config => config.name === 'onboarding_config')
  if (!!onboardingConfig && onboardingConfig.config && onboardingConfig.config.flow_preference){
    const { active, config: { flow_preference: { integration_type } } } = onboardingConfig;
    return active && integration_type === MERCHANT_TYPE.THIRD_PARTY;
  }
  return false;
}

export function checkIsAccountCreated(state) {
  const { account_id: accountId } = selectUser(state)
  return !!accountId;
}
export function selectStatus(state, status) {
  const {
    account_statuses: accountStatus = [],
    merchant_statuses: merchantStatus = []
  } = selectUser(state),
  statuses =
    merchantStatus.find((i) => i.status_type === status) ||
    accountStatus.find((i) => i.status_type === status) ||
    {},
  { status_value: statusValue } = statuses;
  return statusValue
}

export function checkIsLobVerified(state) {
  const lobStatus = selectStatus(state, 'LOB');
  if (lobStatus) {
    return lobStatus === LOB_STATUS.APPROVED;
  }
  return false;
}

export function checkIsDocsAlreadySubmitted(state) {
  const documentStatus = selectStatus(state, 'KYC_DOCUMENTS'),
   agreementStatus = selectStatus(state, 'Agreement'),
   { DOCUMENT_SUBMITTED, DOCUMENT_REUPLOADED, DOCUMENT_VERIFIED,
     DOCUMENT_PARTIALLY_RECEIVED, DOCUMENT_PARTIALLY_REUPLOADED, DOCUMENT_VERIFIED_EXC } = DOC_UPLOAD_STATUS,
  businessFilingStatus = selectBusinessFilingStatus(state);
  if (businessFilingStatus && businessFilingStatus === BUSINESS_ENTITIES.GOVT){
    return [DOCUMENT_SUBMITTED,
       DOCUMENT_REUPLOADED,
       DOCUMENT_VERIFIED,
       DOCUMENT_VERIFIED_EXC].includes(documentStatus)
  }
  if (documentStatus && agreementStatus) {
    return [DOCUMENT_PARTIALLY_REUPLOADED,
       DOCUMENT_PARTIALLY_RECEIVED,
       DOCUMENT_SUBMITTED,
       DOCUMENT_REUPLOADED,
       DOCUMENT_VERIFIED,
       DOCUMENT_VERIFIED_EXC].includes(documentStatus) &&
       agreementStatus !== AGREEMENT_STATUS.REJECTED;
  }
  return false;
}
/**
 * returns true if documents and agreeemnt are not submitted at account level,
 * it doesnot include the case of reupload.
 * considers only first time submission.
 */
export function checkAreDocumentsNotSubmitted(state) {
  const documentStatus = selectStatus(state, 'KYC_DOCUMENTS');
  const agreementStatus = selectStatus(state, 'Agreement');
  const { PENDING, DOCUMENT_PARTIALLY_RECEIVED } = DOC_UPLOAD_STATUS;
  const { ACCEPTED } = AGREEMENT_STATUS;
  const isDocumentPending = [PENDING,
DOCUMENT_PARTIALLY_RECEIVED].includes(documentStatus);
  const isAgreementPending = [
    AGREEMENT_STATUS.PENDING,
    ACCEPTED
  ].includes(agreementStatus) || !agreementStatus; // returns true for undefined agreement status
  return checkIsAccountCreated(state) ? isDocumentPending && isAgreementPending : true;
}

export function checkIsAgreementApproved(state) {
  const agreementStatus = selectStatus(state, 'Agreement');
  return agreementStatus === AGREEMENT_STATUS.APPROVED;
}
export function checkIfAgreementIsAccepted(state) {
  const documentStatus = selectStatus(state, 'AGREEMENT');
}

export function checkIsDocsRejected(state) {
  const documentStatus = selectStatus(state, 'KYC_DOCUMENTS');
  const agreementStatus = selectStatus(state, 'Agreement');
  return documentStatus === DOC_UPLOAD_STATUS.DOCUMENT_REJECTED || agreementStatus === AGREEMENT_STATUS.REJECTED
}

export function selectDocumentSubcategories(state) {
  return mergeWith(
    { ...state.masterData.documentCategoryAndTypes.documentTypes },
    DOCUMENT_SUBCATEGORIES,
    (objValue, srcValue) => {
      if (objValue) {
        srcValue.value = String(objValue);
      }
      return srcValue;
    },
  );
}

export function checkAreDocsVerified(state) {
  const documentStatus = selectStatus(state, 'KYC_DOCUMENTS')
  return documentStatus === DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED || documentStatus === DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED_EXC;
}

export function checkIsWebsiteVerified(state) {
  const websiteStatus = selectStatus(state, 'WEBSITE');
  return !!websiteStatus && websiteStatus === WEBSITE_STATUS.APPROVED;
}

 /* selectors for digital signin modal */
function selectDigitalSignature(state) {
  const documents = state.documents
  return documents ? documents.digitalSignature : {};
}

function selectMasterData(state) {
  return state.masterData || {};
}

function selectMergedKycDocs(state) {
  const digitalSignature = selectDigitalSignature(state);
  return digitalSignature ? digitalSignature.mergedDocument.data.kyc_document : null;

}

/** selectors for signatory authority email. */
export function selectSignatoryEmail(state) {
  const accountData = selectAccountData(state),
    { signatory_contact_details: signingDetails = [] } = accountData || {},
    signAuth = signingDetails.filter((detail) => detail.contact_detail_type === ACCOUNT_CONTACT_TYPE.SIGNING_AUTHORITY),
    [signingAuthorityDetail] = signAuth;
  return signingAuthorityDetail
    ? signingAuthorityDetail.email
    : null;
}

function getReverseDocsCategoriesMap(state) {
  const newDocsCategories = {};
  const masterData = selectMasterData(state);
  const docsCategories = masterData ? masterData.documentCategoryAndTypes.documentCategories : null;
  if (docsCategories) {
    Object.entries(docsCategories).map((items) => newDocsCategories[items[1]] = items[0]);
    return newDocsCategories
  }
  return null;
}

export function checkIsAddendumPending(state) {
  const { bank_detail: bankDetail } = selectUser(state);
  return !!bankDetail &&  bankDetail.addendum_status === BANK_ADDENDUM_STATUS.PENDING;
}

export function checkWebsiteAddendumPending(state) {
  const { website_detail: websiteDetail } = selectUser(state);
  return !!websiteDetail && websiteDetail.addendum_status === WEBSITE_ADDENDUM_STATUS.PENDING;
}

export function selectErrorsOccured(state) {
  const digitalSignature = selectDigitalSignature(state);
  return digitalSignature ?  digitalSignature.signatoryOtp.error || digitalSignature.mergedDocument.error: null;
}

export function selectMergedDocsUrl(state) {
  const data = selectMergedKycDocs(state)
  return data ? data.processed_document.path : '';
}

export function selectMergedKycDocsStatus(state) {
  const data = selectMergedKycDocs(state)
  return data ? data.status : '';
}

export function selectSubmittedDocsNames(state) {
  const documentCategoriesMap = getReverseDocsCategoriesMap(state),
  kycDocuments = state.documents
    ? state.documents.kycDocuments.data
    : null;
  if (kycDocuments) {
    const catIdList = Object.entries(kycDocuments).
    map((item) => {
      const [, record] = item,
        { kyc_document_type: docType, record_type: recordType, document_category_id: catId } = record;
      return docType === DOCUMENT_TYPES.KYC && recordType === RECORD_TYPES.ACCOUNT
      ? catId
      : ''
    }).
    filter((item) => item);
    return catIdList.map((key) => documentCategoriesMap[key]);
  }
  return null;
}
/**
 * when now merchant comes to money then product is changed to PayUmoney
 * at the server side. However, businessOrigin will be same
 * @param  {Object}  state
 * @return {Boolean}
 */

export function hasNowMerchantMigratedToMoney(state) {
  const { business_origin: businessOrigin, product } = selectUser(state);
  return businessOrigin === 'PayUnow' && product === 'PayUmoney';
}

export function isWebsiteDetailsFilled(user){
  const { website_details_completed: websiteDetailsCompleted } = user;
  return websiteDetailsCompleted
}

export function checkIsWebsiteAddendumPending(state) {
  const { website_detail_filled: websiteDetailFilled } = checkIsWebsiteUrlFilled(state);
  const websiteDetail = state.website.data;
  return websiteDetailFilled &&  websiteDetail.addendum_status === WEBSITE_ADDENDUM_STATUS.PENDING;
}

/**
 * selects website details from redux store
 * @param { redux state} state
 * @return {boolean} website details
 */
export const checkIsWebsiteUrlFilled = (state) => {
      let {
        android_url: androidUrl,
        ios_url: iosUrl,
        website_url: websiteUrl
      } = selectUser(state);
      const websiteDetail = state.website.data;
      if (websiteDetail) {
        websiteUrl = websiteUrl || websiteDetail.website_url;
        androidUrl = androidUrl || websiteDetail.android_url;
        iosUrl = iosUrl || websiteDetail.ios_url;
      }
      return !(isEmpty(websiteUrl) && isEmpty(androidUrl) && isEmpty(iosUrl));
    },
    selectWebsiteDetails = (state) => state.website.data || {},
    selectWebsiteDocumentCategories = (state) => {
      const { website_document_categories: docCategories = {} } = selectWebsiteDetails(state);
      return docCategories;
    },
    selectWebsiteDocuments = (state) => {
      const merchantDocuments = selectUploadedDocuments(state).data,
          websiteDocCategories = selectWebsiteDocumentCategories(state);
      if (isEmpty(merchantDocuments) || isEmpty(websiteDocCategories)) {
        return [];
      }
      let websiteDocs = websiteDocCategories.map((record) => {
        const { id: docCatId } = record;
        return merchantDocuments[docCatId];
      });
      websiteDocs = formatSubmittedDocResponseUtil(_.compact(websiteDocs));
      return websiteDocs;
    },
    selectWebsiteDocumentStatus = (state) => {
      const  kycDocuments  = selectWebsiteDocuments(state);
      let docStatus;
      if (kycDocuments) {
        const statuses = _.compact(Object.values(kycDocuments).map((kycDocument) => kycDocument.status));
        if (statuses.includes(INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_REJECTED)) {
          docStatus = INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_REJECTED;
        } else if (statuses.includes(INDIVIDUAL_DOC_UPLOAD_STATUS.PENDING)) {
          docStatus = INDIVIDUAL_DOC_UPLOAD_STATUS.PENDING;
        } else if (statuses.includes(INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_SUBMITTED)) {
          docStatus = INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_SUBMITTED;
        } else if (statuses.includes(INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED)) {
          docStatus = INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED;
        }
      }
      return docStatus;
    },
    selectWebsiteStatus = (state) => {
      const { website_approval_status: websiteStatus } = selectUser(state);
      return websiteStatus;
    },
    isUrlsLive = (state) => {
      const { android_url_invalid: androidUrlInvalid = null, website_url_invalid: websiteUrlInvalid = null,
        ios_url_invalid: iosUrlInvalid = null } = selectWebsiteDetails(state);
      return !(androidUrlInvalid || iosUrlInvalid || websiteUrlInvalid);
    };
