import { combineReducers } from 'redux';
import * as C from '../constants/documentTypes';
import * as C1 from '../constants/merchantTypes';

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false
};

/**
 * addendum details reducer responsible for fething addendum data
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export function addendumBankDetailsReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.FETCH_ADDENDUM_DETAILS_INITIATED:
      return {
        ...state,
        fetching: true
      };
    case C.FETCH_ADDENDUM_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        fetching: false,
        success: true
      };
    case C.FETCH_ADDENDUM_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        success: false
      };
    default:
      return state;
  }
}

/**
 * addendum address details reducer used for fething address addendum data
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export function addendumAddressDetailsReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C1.FETCH_ADDRESS_ADDENDUM_DETAILS_INITIATED:
      return {
        ...state,
        fetching: true
      };
    case C1.FETCH_ADDRESS_ADDENDUM_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        fetching: false,
        success: true
      };
    case C1.FETCH_ADDRESS_ADDENDUM_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        success: false
      };
      default:
        return state;
  }
}

export function addendumWebsiteDetailsReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C1.FETCH_WEBSITE_ADDENDUM_DETAILS_INITIATED:
      return {
        ...state,
        fetching: true
      };
    case C1.FETCH_WEBSITE_ADDENDUM_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        fetching: false,
        success: true
      };
    case C1.FETCH_WEBSITE_ADDENDUM_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        success: false
      };
      default:
        return state;
  }
}

export default combineReducers({
  addendumAddressDetails: addendumAddressDetailsReducer,
  addendumBankDetails: addendumBankDetailsReducer,
  addendumWebsiteDetails: addendumWebsiteDetailsReducer
});
