export const pushGTMEvent = ({ eventType, payload }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...payload,
      event: eventType,
    });
  } catch {}
};

export const stepCompletionGTMEvent = ({ stepNo, stepName }) => {
  pushGTMEvent({
    eventType: "form_complete",
    payload: {
      form_step: stepNo,
      step_name: stepName,
    },
  });
};
