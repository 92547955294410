export const actionTypes = {
  SET: 'CACHE/SET',
  REMOVE: 'CACHE/REMOVE',
  REST: 'CACHE/REST'
};

const initialState = {
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value
      }
    }
    case actionTypes.REMOVE: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: null
      }
    }
    case actionTypes.REST:
      return {}
    default: {
      return state;
    }
  }
};

export const actions = {
  addKey: (key, value) => ({ type: actionTypes.SET, payload: { key, value } })
}

//  Selectors
export const selectors = {
  getCache: (state) => state.onboardingApp.cache,
};