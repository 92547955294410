import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useAutoLogout } from './useAutoLogout';
import { BaseDialog } from '../../uiv2-wrapper/atoms/base-dialog';
import FormFooter from '../../uiv2-wrapper/form/form-footer';
import { Icon } from '../../uiv2-wrapper/atoms/icons';
import { Typography } from '../../uiv2-wrapper/atoms/typography';
import { useMerchantParams } from '../../services/api/hooks/useMerchantParams';
import AuthManager from '../../services/AuthManager';
import { useStorageListner } from './useStorageListener';

const THREE_HOURS_IN_SECONDS = 60 * 60 * 3;
const options = {
  domain: 'payu.in',
  path: '/',
  secure: true
};
const getCookieKeyOfTime = (mid) => `${mid}_allowed_idle_time_in_seconds`;
const getSavedAllowedIdleTimeInSeconds = () => {
  try {
    const mid = AuthManager.getMID();
    const cookies = new Cookies();
    return cookies.get(getCookieKeyOfTime(mid), options) || THREE_HOURS_IN_SECONDS;
  } catch {
    return THREE_HOURS_IN_SECONDS;
  }
};

const setSavedAllowedIdleTimeInSeconds = (time) => {
  try {
    const mid = AuthManager.getMID();
    const cookies = new Cookies();
    return cookies.set(getCookieKeyOfTime(mid), time, options) || THREE_HOURS_IN_SECONDS;
  } catch {
  }
}

export const AutoLogout = () => {
  const [allowedIdleTimeInSeconds, setAllowedIdleTimeInSeconds] = useState(getSavedAllowedIdleTimeInSeconds())
  const [disableCallbackOnActivity, setDisableCallbackOnActivity] = useState(true)
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!(AuthManager.isLoggedIn() && AuthManager.getMID()))
  useStorageListner({
    onChange: () => {
      setIsLoggedIn(!!(AuthManager.isLoggedIn() && AuthManager.getMID()))
    }
  })
  const isLoggedInRef = useRef();
  isLoggedInRef.current = isLoggedIn;
  const aboutToExpireCallback = () => {
    if (isLoggedInRef.current) {
      setIsOpen(true)
      setDisableCallbackOnActivity(true)
    }
  }

  const { timeLeftToTimeoutInSeconds, onLogout, setAutoLogoutTimeout, clearCookie } = useAutoLogout({
    disableCallbackOnActivity,
    aboutToExpireCallback,
    allowedIdleTimeInSeconds,
    isLoggedIn: isLoggedInRef.current,
  });


  const [getMerchantParams] = useMerchantParams({
    onSuccess: (data) => {
      const autoLogoutConfig = (data?.data?.data?.result || []).filter(v => v.key == 'auto_logout_time')
      const time = autoLogoutConfig[0]?.value
        ? autoLogoutConfig[0]?.value * 60
        : THREE_HOURS_IN_SECONDS;
      setAllowedIdleTimeInSeconds(time);
      setSavedAllowedIdleTimeInSeconds(time)
      setDisableCallbackOnActivity(false)
    },
    onError: () => {
      setDisableCallbackOnActivity(false)
    }
  });


  useEffect(() => {
    if (isLoggedIn) {
      getMerchantParams()
    } else {
      clearCookie();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isOpen && !isLoggedIn) {
      onLogout();
    }
  }, [isLoggedIn, isOpen])

  useEffect(() => {
    if (isOpen && Math.round(timeLeftToTimeoutInSeconds) <= 0) {
      onLogout();
      setIsOpen(false)
    } else if (isOpen && Math.round(timeLeftToTimeoutInSeconds) > 60) {
      setIsOpen(false)
    }
  }, [timeLeftToTimeoutInSeconds])


  return <BaseDialog
    open={isOpen}
    showCloseIcon={false}
    width={'sm'}
  >
    <div className='tw-flex tw-gap-8 tw-items-center tw-p-16'>
      <Icon type='hour-glass' color={'#DBB429'} size={'24px'} />
      <Typography variant={'H3'} weight='bold'>
        Are you still there?
      </Typography>
    </div>
    <Typography className={'tw-text-gray-80 tw-px-16'} variant={'P1'}>
      You've been inactive for a while. For your security, you will be logged out in 1 minute. Please click on "I'm still here" to stay logged in.
    </Typography>
    <Typography className={'tw-text-center tw-py-16'} variant={'H1'} weight={'bold'}>
      {Math.round(timeLeftToTimeoutInSeconds)}s
    </Typography>
    <FormFooter
      btnList={[
        {
          label: 'Logout',
          variant: 'SECONDARY',
          onClick: onLogout
        },
        {
          label: "I'm still here",
          onClick: () => {
            setAutoLogoutTimeout();
            setIsOpen(false);
            setDisableCallbackOnActivity(false)
          }
        },
      ]}
      variant='dialog'
    />
  </BaseDialog>
}