import { reject as _reject } from 'lodash';
import * as C from '../constants/authTypes';
import {
  UPDATE_MERCHANT_DETAILS_INITIATED,
  UPDATE_MERCHANT_DETAILS_SUCCESS,
  UPDATE_MERCHANT_PRODUCT_SUCCESS,
  UPDATE_MERCHANT_DETAILS_FAILURE,
  FETCH_OWNED_ACL_ROLES,
  FETCH_OWNED_ACL_ROLES_SUCCESS,
  FETCH_OWNED_ACL_ROLES_FAILURE,
  ADD_ACL_ROLE_INITIATED,
  ADD_ACL_ROLE_SUCCESS,
  ADD_ACL_ROLE_FAILURE,
  MOVE_INVITES_TO_NEW_ROLE_FAILURE,
  MOVE_INVITES_TO_NEW_ROLE_INITIATED,
  MOVE_INVITES_TO_NEW_ROLE_SUCCESS,
  UPDATE_ACL_ROLE_FAILURE,
  UPDATE_ACL_ROLE_INITIATED,
  UPDATE_ACL_ROLE_SUCCESS,
  DELETE_ROLE_INITIATED,
  ROLE_FORM_CLOSED,
  DELETE_ROLE_SUCCESS,
  CLEAR_ERROR_MESSAGES,
  FETCH_ACL_PERMISSIONS_INITIATED,
  FETCH_ACL_PERMISSIONS_SUCCESS,
  FETCH_ACL_PERMISSIONS_FAILURE
}
from '../constants/merchantTypes';
import {
  SUBMIT_DOCUMENTS_SUCCESS,
  FETCH_SIGNING_AUTHORITY_LETTER_FAILED,
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED,
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS,
  UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED,
} from '../constants/documentTypes';

import AuthManager from '../services/AuthManager';
import API_ENDPOINTS from "../services/apiConfig";

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false,
};

const initialState = {
  user: null,
  hubUser: { ...GENERIC_STATE },
  otpData: { ...GENERIC_STATE },
  passwordUpdate: { ...GENERIC_STATE },
  merchantUpdate: { ...GENERIC_STATE },
  ownedAclRoles: { ...GENERIC_STATE },
  rolePermissions: { ...GENERIC_STATE }
};

export default function reducer(state = initialState, action) {
  let staffInviteCount = 1;
  switch (action.type) {
    case C.ACTION_CHECK_SESSION:
    case SUBMIT_DOCUMENTS_SUCCESS:
      return { ...state, user: action.payload };
    case C.ACTION_LOGOUT:
      window.location = API_ENDPOINTS.PRODUCT.LOGOUT;
      return state;
    case C.ACTION_CHECK_FAILURE:
      return { ...state, user: null };
    case C.UPDATE_PASSWORD_INITIATED:
      return {
        ...state,
        passwordUpdate: {
          success: false,
          fetching: true,
          error: null,
        },
      };
    case C.UPDATE_PASSWORD_SUCCESS:
      window.setTimeout(function() {
        window.location = API_ENDPOINTS.PRODUCT.LOGOUT;
      }, 4000);
      return {
        ...state,
        passwordUpdate: {
          success: true,
          fetching: false,
          error: null,
        },
      };
    case C.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordUpdate: {
          success: false,
          fetching: false,
          error: action.payload,
        },
      };
    case UPDATE_MERCHANT_DETAILS_INITIATED:
      return {
        ...state,
        success: false,
        fetching: true,
        error: null,
      };
    case UPDATE_MERCHANT_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        success: true,
        fetching: false,
        error: null,
      };
    case UPDATE_MERCHANT_DETAILS_FAILURE:
      return {
        ...state,
        merchantUpdate: {
          success: false,
          fetching: false,
          error: action.payload,
        },
      };
    case UPDATE_MERCHANT_PRODUCT_SUCCESS: {
      const user = AuthManager.getUser();
      user.product = action.payload;
      AuthManager.persistUserToLocalStorage(user);
      return state;
    }
    case FETCH_SIGNING_AUTHORITY_LETTER_FAILED:
      return {
        ...state,
        success: false,
        fetching: false,
        error: action.payload,
      };
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED:
      return {
        ...state,
        success: false,
        fetching: true,
        error: action.payload,
      };
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          signatory_details: action.payload.signatory_details,
        },
        fetching: false,
        success: true,
        error: null,
      };
    case UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED:
      return {
        ...state,
        success: false,
        fetching: false,
        error: action.payload,
      };
    case FETCH_OWNED_ACL_ROLES:
      return {
        ...state,
        ownedAclRoles: {
          error: null,
          fetching: false,
          message: null,
          success: false
        }
      }
    case FETCH_OWNED_ACL_ROLES_SUCCESS:
      return {
        ...state,
        ownedAclRoles: {
          data: action.payload,
          error: null,
          fetching: false,
          message: null,
          success: true
        }
      }
    case MOVE_INVITES_TO_NEW_ROLE_FAILURE:
      return {
        ...state,
        ownedAclRoles: {
          data: { ...state.ownedAclRoles.data },
          error: null,
          fetching: false,
          message: null,
          success: false
        }
      }
    case MOVE_INVITES_TO_NEW_ROLE_INITIATED:
      return {
        ...state,
        ownedAclRoles: {
          data: { ...state.ownedAclRoles.data },
          error: null,
          fetching: true,
          message: null,
          success: false
        }
      }
    case ADD_ACL_ROLE_SUCCESS:
      return {
        ...state,
        ownedAclRoles: {
          data: { acl_roles: [...state.ownedAclRoles.data.acl_roles, action.payload.acl_role] },
          error: null,
          fetching: false,
          helpText: 'All the assigned employees will get the updated role settings.',
          message: 'Role Added successfully!',
          warningMessage: 'This screen will automatically close in 3, 2,1 seconds…',
          success: true
        }
      }
    case CLEAR_ERROR_MESSAGES:
     return {
       ...state,
       ownedAclRoles: {
        data: { ...state.ownedAclRoles.data },
        error: null,
        fetching: false,
        message: null,
        success: true
      }
     }
    case ADD_ACL_ROLE_INITIATED:
     return {
       ...state,
       ownedAclRoles: {
         ...state.ownedAclRoles,
         error: null,
         fetching: true,
         message: null,
         success: true
       }
     }
    case ADD_ACL_ROLE_FAILURE:
      return {
        ...state,
        ownedAclRoles: {
          ...state.ownedAclRoles,
          action: ADD_ACL_ROLE_FAILURE,
          error: action.payload,
          fetching: false,
          message: null,
          success: false
        }
      }
    case UPDATE_ACL_ROLE_INITIATED:
      return {
        ...state,
        ownedAclRoles: {
          ...state.ownedAclRoles,
          error: action.payload,
          fetching: true,
          message: null,
          success: false
        }
      }
    case ROLE_FORM_CLOSED:
    return {
      ...state,
      ownedAclRoles: {
        ...state.ownedAclRoles,
        error: null,
        fetching: false,
        message: false,
        success: false,
        action: null
      }
    }
    case UPDATE_ACL_ROLE_SUCCESS:
      const aclRoles = state.ownedAclRoles.data.acl_roles,
        updatedAclRole = action.payload.acl_role,
        updatedAclRoleList = aclRoles.map((role) => role.id === updatedAclRole.id
          ? updatedAclRole
          : role);
      return {
        ...state,
        ownedAclRoles: {
          data: { acl_roles: updatedAclRoleList },
          error: null,
          fetching: false,
          helpText: 'All the assigned employees will get the updated role settings',
          message: 'Role Updated Successfully',
          warningMessage: 'This screen will automatically close in 3, 2,1 seconds…',
          success: true
        }
      }
    case UPDATE_ACL_ROLE_FAILURE:
      return {
        ...state,
        ownedAclRoles: {
          ...state.ownedAclRoles,
          action: UPDATE_ACL_ROLE_FAILURE,
          error: action.payload,
          fetching: false,
          message: null,
          success: false
        }
      }
    case DELETE_ROLE_INITIATED:
      return {
        ...state,
        ownedAclRoles: {
          ...state.ownedAclRoles,
          error: null,
          fetching: true,
          message: null
        }
      }
    case DELETE_ROLE_SUCCESS:
      const deletedRoleId = action.payload.aclRoleId,
        oldAclRoles = state.ownedAclRoles.data.acl_roles,
        updatedRoleList = _reject(oldAclRoles, (role) => role.uuid === deletedRoleId);
      return {
        ...state,
        ownedAclRoles: {
          data: { acl_roles: updatedRoleList },
          error: null,
          fetching: false,
          message: 'Role Deleted Successfully',
          success: true,
          warningMessage: 'This screen will automatically close in 3, 2,1 seconds…'
        }
      }
    case MOVE_INVITES_TO_NEW_ROLE_SUCCESS:
      const { oldRoleId, newRoleId } = action.payload,
        OldRoleList = state.ownedAclRoles.data.acl_roles;
        for (const role of OldRoleList) {
          if (role.uuid === oldRoleId) {
            staffInviteCount = role.staff_invite_count;
            break;
          }
        }
      const newRoleList = OldRoleList.map((role) => role.uuid === newRoleId
          ? { ...role,
            staff_invite_count: role.staff_invite_count + staffInviteCount }
          : role).map((role) => role.uuid === oldRoleId
            ? { ...role,
              staff_invite_count: role.staff_invite_count - staffInviteCount }
            : role);
      return {
        ...state,
        ownedAclRoles: {
          data: { acl_roles: newRoleList },
          error: null,
          fetching: false,
          message: null,
          success: true
        }
      }
    case FETCH_OWNED_ACL_ROLES_FAILURE:
      return {
        ...state,
        ownedAclRoles: {
          data: {},
          error: action.payload,
          fetching: false,
          message: null,
          success: true
        }
      }
    case FETCH_ACL_PERMISSIONS_INITIATED:
      return {
        ...state,
        rolePermissions: {
          data: {},
          error: null,
          fetching: true,
          message: null,
          success: false
        }
      }
    case FETCH_ACL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissions: {
          data: action.payload,
          error: null,
          fetching: false,
          message: null,
          success: true
        }
      }
    case FETCH_ACL_PERMISSIONS_FAILURE:
      return {
        ...state,
        rolePermissions: {
          data: {},
          error: action.payload,
          fetching: false,
          message: null,
          success: false
        }
      }
    default:
      return state;
  }
}
