import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form } from 'redux-form';

class RoleForm extends React.Component {
  componentWillMount() {
    const { initialize, formInitValues } = this.props;
    if (formInitValues) {
      initialize(formInitValues);
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return <Form onSubmit={handleSubmit}>
      {this.props.children}
    </Form>;
  }
}

RoleForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  formInitValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func
}

/**
 *
 * @function
 * @param {formName} string
 * @return {reduxForm} object
 */
const RoleFormCreator = (formName, destroyOnUnmount = false) => reduxForm({
    destroyOnUnmount,
    form: formName
  })(RoleForm)
export default RoleFormCreator;
