import { useEffect } from "react";

const NOOP = () => { };
export const useDocumentActivityListener = ({
  activityCallback = NOOP,
}) => {
  useEffect(() => {
    window.addEventListener('load', () => activityCallback('load'), true);
    window.addEventListener('mousemove', () => activityCallback('mousemove'), true);
    window.addEventListener('mousedown', () => activityCallback('mousedown'), true);
    window.addEventListener('touchstart', () => activityCallback('touchstart'), true);
    window.addEventListener('touchmove', () => activityCallback('touchmove'), true);
    window.addEventListener('click', () => activityCallback('click'), true);
    window.addEventListener('keydown', () => activityCallback('keydown'), true);
    window.addEventListener('scroll', () => activityCallback('scroll'), true);
    window.addEventListener('wheel', () => activityCallback('wheel'), true);

    return () => {
      window.removeEventListener('load', () => activityCallback('load'), true);
      window.removeEventListener('mousemove', () => activityCallback('mousemove'), true);
      window.removeEventListener('mousedown', () => activityCallback('mousedown'), true);
      window.removeEventListener('touchstart', () => activityCallback('touchstart'), true);
      window.removeEventListener('touchmove', () => activityCallback('touchmove'), true);
      window.removeEventListener('click', () => activityCallback('click'), true);
      window.removeEventListener('keydown', () => activityCallback('keydown'), true);
      window.removeEventListener('scroll', () => activityCallback('scroll'), true);
      window.removeEventListener('wheel', () => activityCallback('wheel'), true);
    }
  }, [])
}