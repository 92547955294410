import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from 'hub-redux-auth';

import ReactLottie from '../BaseComponents/ReactLottie';

function AccountCreated({ product }) {
  let animationData;
  switch (product) {
    case 'BIZ':
      animationData = require('@assets/lottie_animation/bizCreateAccountTick.json');
      break;
    case 'MONEY':
      animationData = require('@assets/lottie_animation/moneyCreateAccountTick.json');
      break;
    case 'NOW':
      animationData = require('@assets/lottie_animation/nowCreateAccountTick.json');
      break;
    default:
      break;
  }
  return (
    <div className="d-flex justify-content-center">
      <ReactLottie options={{ loop: false, animationData }} />
    </div>
  );
}

AccountCreated.propTypes = {
  product: PropTypes.string.isRequired,
};

export default connect(state => ({ product: state.product }))(AccountCreated);
