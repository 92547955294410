import IDs from "@constants/componentIds";
import { BANK_STATUS } from "@constants/bankConstants";
import { WEBSITE_APPROVAL_STATUS, INTEGRATION_TYPE } from "@constants/websiteDetailsConstants";
import { MERCHANT_DOC_STATUS } from "@constants/manualUploadConstants";
import { getSkippedSteps } from "merchant-status-timeline";

// merchant-statuses
export const getProductAccountStatuses = (productAccountDetails) => {
  const isReKYC = productAccountDetails?.re_kyc_required;
  const productAccountStatuses = productAccountDetails?.product_account_statuses || [];
  const AgreementDetails = productAccountStatuses.find(v => v.status_type === 'Agreement') || {};
  const attachedConfigs = productAccountDetails?.attached_configs || [];
  const skippedStepsList = getSkippedSteps({ attachedConfigs }) || [];
  const isChildAggregator = productAccountDetails?.merchant_type == 'child_aggregator'
  const isPartnerAccount = productAccountDetails?.partner_uuid
  const pepOnboardingStatusFlag = productAccountDetails?.product_account_detail?.pep_onboarding_status
  const vKYCFlag = productAccountDetails?.vkyc_status
  const vKYCExemption = productAccountDetails?.vkyc_exemption
  const stopOnboardingFlag = productAccountDetails?.stop_onboarding
  const allowWebsiteFlag = productAccountDetails?.unlock_steps_till_website
  const gstNumber = productAccountDetails?.gst_number
  const isMerchantOffline = productAccountDetails?.offline_merchant
  const copyMidParent = productAccountDetails?.copy_mid_parent
  const isOnboardingCompleted = productAccountDetails?.onboarding_completed
  return ({
    pan: {
      filled: productAccountDetails?.pancard_number,
      verified: productAccountDetails?.pan_verification_status === BANK_STATUS.VERIFIED,
    },
    businessDetails: {
      completed: (
        productAccountDetails?.business_category_id &&
        productAccountDetails?.business_sub_category_id &&
        productAccountDetails?.monthly_expected_volume
      ),
    },
    bankDetails: {
      verified: productAccountDetails?.bank_detail?.bank_verification_status == BANK_STATUS.VERIFIED,
      filled: productAccountDetails?.bank_detail,
      uploadedProof: productAccountDetails?.bank_detail?.bank_verification_proof,
    },
    website: {
      completed: (
        productAccountDetails?.integration_type === INTEGRATION_TYPE.TOOLS ||
        productAccountDetails?.website_detail?.website_approval_status === WEBSITE_APPROVAL_STATUS.OK
      ),
    },
    account: {
      created: productAccountDetails?.account_uuid,
    },
    signingAuthority: {
      completed: productAccountDetails?.signatory_contact_details?.length || skippedStepsList.indexOf(IDs.signingAuthority.default) >= 0,
    },
    kyc: {
      addressConfirmed: productAccountDetails?.registration_address,
      notManual: productAccountDetails?.kyc_status?.kyc_status === 'COMPLETED',
    },
    documents: {
      verified: productAccountDetails?.document_status === 'Docs Approved',
      reKycVerified: productAccountDetails?.re_kyc_document_status === 'Docs Approved',

      videoKyc: productAccountDetails?.document_status === 'Docs Approved' || productAccountDetails?.document_status === 'Exceptionally Approved' || productAccountDetails?.document_status === 'DOCUMENT_REUPLOADED' || productAccountDetails?.document_status === 'DOCUMENT_REUPLOADED' || productAccountDetails?.document_status === 'Docs Received' || productAccountDetails?.document_status === 'DOCUMENT_SUBMITTED' ,

      rekycVideoKyc : productAccountDetails?.re_kyc_document_status === 'Docs Approved' || productAccountDetails?.re_kyc_document_status === 'Exceptionally Approved' || productAccountDetails?.re_kyc_document_status === 'DOCUMENT_REUPLOADED' || productAccountDetails?.re_kyc_document_status === 'DOCUMENT_REUPLOADED' || productAccountDetails?.re_kyc_document_status === 'Docs Received'  || productAccountDetails?.re_kyc_document_status === 'DOCUMENT_SUBMITTED' 
    },
    serviceAgreement: {
      generated: isReKYC ? false : AgreementDetails.status_value !== 'Not Generated',
      esigned: isReKYC ? false : AgreementDetails.status_value == 'Approved' || productAccountDetails?.is_service_agreement_esigned,
    },
    isReKYC,
    skippedStepsList,
    isChildAggregator,
    isPartnerAccount,
    pepOnboardingStatusFlag,
    vKYCFlag,
    vKYCExemption,
    stopOnboardingFlag,
    gstNumber,
    isMerchantOffline,
    copyMidParent,
    isOnboardingCompleted,
    allowWebsiteFlag
  });
};

// pan-details
const panStepProps = (productAccountDetails) => {
  const { serviceAgreement: { esigned , generated}, isReKYC, pepOnboardingStatusFlag, stopOnboardingFlag, allowWebsiteFlag } = getProductAccountStatuses(productAccountDetails);
  const livelinessLock = stopOnboardingFlag ? allowWebsiteFlag ? false : stopOnboardingFlag : stopOnboardingFlag ;
  return {
    completed: productAccountDetails && productAccountDetails.pan_verification_status === BANK_STATUS.VERIFIED,
    incomplete: !(productAccountDetails && productAccountDetails.pancard_number && productAccountDetails.business_entity_id),
    filled: productAccountDetails && !!productAccountDetails.pancard_name && !!productAccountDetails.pancard_number,
    editable: !esigned && !generated && !productAccountDetails?.settlement_status=="Active" && !productAccountDetails?.onboarding_completed ,
    locked: pepOnboardingStatusFlag === "block" || livelinessLock
  };
};

// business-detila
const businessDetailsStepProps = (productAccountDetails) => {
  const { serviceAgreement: { generated }, isReKYC, pepOnboardingStatusFlag, stopOnboardingFlag, gstNumber, isMerchantOffline, copyMidParent, isOnboardingCompleted, isChildAggregator, allowWebsiteFlag } = getProductAccountStatuses(productAccountDetails);
  
  const consents = productAccountDetails.consents || [];
  const gst_consent = consents?.find(v => v.name === 'gst_consent') || {};
  const gstConsentFlow = !isMerchantOffline && !isChildAggregator && !copyMidParent && !isOnboardingCompleted
  const completed =
  gstConsentFlow ? (!!productAccountDetails &&
    !!productAccountDetails.business_category_id &&
    !!productAccountDetails.business_sub_category_id &&
    !!productAccountDetails.monthly_expected_volume &&
    (gstNumber || gst_consent?.active)) : (!!productAccountDetails &&
      !!productAccountDetails.business_category_id &&
      !!productAccountDetails.business_sub_category_id &&
      !!productAccountDetails.monthly_expected_volume);

  const reKYCCompleted = completed && productAccountDetails?.product_account_detail?.business_details_rekyced;
  const livelinessLock = stopOnboardingFlag ? allowWebsiteFlag ? false : stopOnboardingFlag : stopOnboardingFlag ;

  return {
    completed: isReKYC ? reKYCCompleted : completed,
    incomplete: isReKYC ? !reKYCCompleted : !completed,
    editable: !generated,
    locked: !panStepProps(productAccountDetails).completed || pepOnboardingStatusFlag === "block" || livelinessLock,
  };
};

// bank-details
const bankDetailsStepProps = (productAccountDetails) => {
  const bankDetailsFilled = !!productAccountDetails && !!productAccountDetails.bank_detail;
  const docUploaded = bankDetailsFilled && productAccountDetails.bank_detail.bank_verification_proof;
  const bankNotVerified =
    bankDetailsFilled && productAccountDetails.bank_detail.bank_verification_status !== BANK_STATUS.VERIFIED;
  const { serviceAgreement: { generated }, isReKYC, pepOnboardingStatusFlag, stopOnboardingFlag, allowWebsiteFlag } = getProductAccountStatuses(productAccountDetails);
  const livelinessLock = stopOnboardingFlag ? allowWebsiteFlag ? false : stopOnboardingFlag : stopOnboardingFlag ;
  return {
    completed: bankDetailsFilled && productAccountDetails.bank_detail.bank_verification_status === BANK_STATUS.VERIFIED,
    filled: !!productAccountDetails && !!productAccountDetails.bank_detail,
    incomplete: !bankDetailsFilled || (bankDetailsFilled && !docUploaded && bankNotVerified),
    editable: !generated,
    error: bankDetailsFilled && productAccountDetails.bank_detail.bank_verification_status !== BANK_STATUS.VERIFIED,
    locked: !panStepProps(productAccountDetails).completed || pepOnboardingStatusFlag === "block" || livelinessLock,
  };
};

// signing-authority
export const signingAuthorityStepProps = (productAccountDetails) => {
  const cinRequiredEntityTypes = ['Public Limited', 'Private Limited', 'LLP', 'One Person Company'];
  const signingAuthorityDetails = productAccountDetails?.signatory_contact_details?.[0];
  let completed = (
    signingAuthorityDetails?.name &&
    signingAuthorityDetails?.email &&
    signingAuthorityDetails?.pancard_number
  );

  const { serviceAgreement: { generated }, documents: { verified: documentsApproved, reKycVerified: rekycDocumentsApproved }, isReKYC, vKYCFlag, vKYCExemption , pepOnboardingStatusFlag, stopOnboardingFlag, isChildAggregator } = getProductAccountStatuses(productAccountDetails);
  const entityType = productAccountDetails?.business_entity?.name;
  if (cinRequiredEntityTypes.includes(entityType)) { completed = completed && productAccountDetails?.cin_number; }

  if (isChildAggregator) {
    completed = signingAuthorityDetails?.name &&
    signingAuthorityDetails?.email &&
    signingAuthorityDetails?.designation &&
    signingAuthorityDetails?.mobile
  }
  const reKYCCompleted = completed && signingAuthorityDetails?.rekyc_record;
  const attachedConfigs = productAccountDetails?.attached_configs || [];
  const skippedStepsList = getSkippedSteps({ attachedConfigs }) || [];
  const documentVerified = isReKYC ? rekycDocumentsApproved : documentsApproved;
  const ckycOrAadharCompleted = productAccountDetails ?. [isReKYC ? "re_kyc_kyc_status" : "kyc_status"]?.kyc_status === 'COMPLETED';
  let editable
  if (vKYCExemption === "" || vKYCExemption === null) {
    if (!ckycOrAadharCompleted && !documentVerified && !generated && vKYCFlag != "approved") {
      editable = true
    } else {
      editable = false
    }
  } else {
    if (!ckycOrAadharCompleted && !documentVerified && !generated) {
      editable = true
    } else {
      editable = false
    }
  }

  return {
    completed: isReKYC ? reKYCCompleted : completed || skippedStepsList.indexOf(IDs.signingAuthority.default) >= 0,
    incomplete: isReKYC ? !reKYCCompleted : !completed,
    editable: !ckycOrAadharCompleted && !documentVerified && !generated,
    locked: !panStepProps(productAccountDetails).completed || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
  };
};

// website-details
const websiteDetailsStepProps = (productAccountDetails) => {
  let website_approval_status = null;
  let completed = false;
  let incomplete = true;
  if (productAccountDetails) {
    if (productAccountDetails.integration_type === INTEGRATION_TYPE.NOT_SELECTED) {
      completed = false;
      incomplete = true;
    } else if (productAccountDetails.integration_type === INTEGRATION_TYPE.THIRD_PARTY) {
      if (productAccountDetails.website_detail) {
        ({ website_approval_status } = productAccountDetails.website_detail);
        if (website_approval_status == WEBSITE_APPROVAL_STATUS.PENDING) {
          completed = false;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.IN_PROGRESS) {
          completed = false;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.OK) {
          completed = true;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.ERROR) {
          completed = false;
          incomplete = true;
        } else {
          completed = false;
          incomplete = true;
        }
      } else {
        completed = false;
        incomplete = true;
      }
    } else if (productAccountDetails.integration_type === INTEGRATION_TYPE.TOOLS) {
      completed = productAccountDetails?.product_account_detail?.lob_status_prerisk == 'LOB Approved'
      incomplete = (
        productAccountDetails?.product_account_detail?.lob_status_prerisk
        && productAccountDetails?.product_account_detail?.lob_status_prerisk == 'LOB Error'
      );
    } else {
      completed = false;
      incomplete = true;
    }
  } else {
    completed = false;
    incomplete = true;
  }

  const { serviceAgreement: { generated }, isReKYC , pepOnboardingStatusFlag, stopOnboardingFlag, allowWebsiteFlag} = getProductAccountStatuses(productAccountDetails);
  const livelinessLock = stopOnboardingFlag ? allowWebsiteFlag ? false : stopOnboardingFlag : stopOnboardingFlag ;
  return {
    completed,
    incomplete,
    editable: !generated,
    locked: (isReKYC || !panStepProps(productAccountDetails).completed) || pepOnboardingStatusFlag === "block" || livelinessLock,
  };
};

// kyc
const kycStepProps = (productAccountDetails) => {
  const addressConfirmed = productAccountDetails?.registration_address;
  const { serviceAgreement: { generated }, isReKYC, pepOnboardingStatusFlag , stopOnboardingFlag} = getProductAccountStatuses(productAccountDetails);
  const document_status = isReKYC ? productAccountDetails?.re_kyc_document_status : productAccountDetails?.document_status;
  const documentsApproved = document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED;
  const ckycOrAadharCompleted = productAccountDetails?.[isReKYC ? "re_kyc_kyc_status" : "kyc_status"]?.kyc_status === 'COMPLETED';
  return {
    completed: documentsApproved || (ckycOrAadharCompleted && addressConfirmed),
    incomplete: !(ckycOrAadharCompleted && addressConfirmed) && !documentsApproved && !productAccountDetails?.manualAddress,
    editable: !additionalDocumentsStepProps(productAccountDetails).completed && !generated,
    filled: documentsApproved || ckycOrAadharCompleted,
    locked: !signingAuthorityStepProps(productAccountDetails).completed || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
  };
};

// additional documents
const additionalDocumentsStepProps = (productAccountDetails) => {
  const document_status = productAccountDetails?.re_kyc_required ? productAccountDetails?.re_kyc_document_status : productAccountDetails?.document_status;
  const { completed: esignCompleted } = serviceAgreementStepProps(productAccountDetails);
  const addressConfirmed = productAccountDetails?.registration_address;
  let incomplete = true;
  if (document_status == MERCHANT_DOC_STATUS.DOCUMENT_RECEIVED || document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED) {
    incomplete = false;
  }
  const { skippedStepsList, bankDetails, isChildAggregator, isPartnerAccount, pepOnboardingStatusFlag, stopOnboardingFlag } = getProductAccountStatuses(productAccountDetails);
  const addressNotRequired = skippedStepsList.indexOf(IDs.kyc.default) >= 0;
  return {
    completed: document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED,
    incomplete: incomplete,
    editable: !esignCompleted,
    locked: !(panStepProps(productAccountDetails).completed && signingAuthorityStepProps(productAccountDetails).completed && (isChildAggregator || isPartnerAccount ? bankDetails?.filled : true)) || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
    hidden: addressNotRequired ? false : !addressConfirmed,
  };
};

// service agreement
const serviceAgreementStepProps = (productAccountDetails) => {
  const { serviceAgreement: { esigned }, isReKYC, pepOnboardingStatusFlag , stopOnboardingFlag} = getProductAccountStatuses(productAccountDetails);
  // const {
  //   businessDetails: { completed: businessDetailsCompleted },
  //   website: { completed: websiteDetailsCompleted },
  //   documents: { verified: docsVerified },
  // } = getProductAccountStatuses(productAccountDetails);
  return {
    completed: esigned,
    incomplete: !esigned,
    // locked: !(businessDetailsCompleted && websiteDetailsCompleted && docsVerified),
    locked: isReKYC || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
    hidden: isReKYC
  };
};

const businessMemberStepProps = (productAccountDetails) => {
  const { serviceAgreement: { generated }, documents: { verified: documentsApproved, reKycVerified: rekycDocumentsApproved }, isReKYC, isChildAggregator, isPartnerAccount, pepOnboardingStatusFlag, stopOnboardingFlag } = getProductAccountStatuses(productAccountDetails);
  const documentVerified = isReKYC ? rekycDocumentsApproved : documentsApproved;

  let completed
  if (productAccountDetails?.business_members?.length) {
    let designationList = []
    designationList = productAccountDetails?.business_members?.reduce((acc, item) => {
      acc.push(item?.designation?.label ? item?.designation?.label : item?.designation)
      return acc;
    }, [])
   if ((['Partnership', "LLP", "Private Limited", "Public Limited"].indexOf(productAccountDetails?.business_entity?.name) >= 0) &&
      !((designationList.includes("Senior Management") || designationList.includes("CEO") || designationList.includes("CFO") || designationList.includes("Company Secretary")))) {
      completed = false;
    } else {
      completed = true;
    }
  }
  else {
    completed = false;
  }

  const hidden = !(['Partnership', "LLP", "Private Limited", "Public Limited"].indexOf(productAccountDetails?.business_entity?.name) >= 0) || isChildAggregator
  
  return {
    completed: completed,
    incomplete: !completed,
    editable: !documentVerified && !generated,
    locked: !signingAuthorityStepProps(productAccountDetails).completed || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
    hidden: hidden
  };
};

// Video KYC
const videoKycStepProps = (productAccountDetails) => {
  const { serviceAgreement: { esigned }, documents, isReKYC, vKYCFlag, vKYCExemption, isChildAggregator, pepOnboardingStatusFlag, stopOnboardingFlag } = getProductAccountStatuses(productAccountDetails);
  const completed = vKYCFlag == "approved" ? true : false
  const hidden = !(vKYCExemption === "" || vKYCExemption === null ) || isChildAggregator
  const documentVerified = isReKYC ? documents?.rekycVideoKyc : documents?.videoKyc;
  const ckycOrAadharCompleted = productAccountDetails ?. [isReKYC ? "re_kyc_kyc_status" : "kyc_status"]?.kyc_status === 'COMPLETED';
  return {
    completed: completed,
    incomplete: !completed,
    locked:  !(documentVerified || ckycOrAadharCompleted) || pepOnboardingStatusFlag === "block" || stopOnboardingFlag,
    hidden: hidden
  };
};

//  Selectors
export const selectors = {
  getStepProps: (productAccountDetails, componentID) => {
    switch (componentID) {
      case IDs.panDetails.default:
        return panStepProps(productAccountDetails);
      case IDs.businessDetails.default:
        return businessDetailsStepProps(productAccountDetails);
      case IDs.bankDetails.default:
        return bankDetailsStepProps(productAccountDetails);
      case IDs.websiteDetails.default:
        return websiteDetailsStepProps(productAccountDetails);
      case IDs.signingAuthority.default:
        return signingAuthorityStepProps(productAccountDetails);
      case IDs.kyc.default:
        return kycStepProps(productAccountDetails);
      case IDs.additionalDocuments.default:
        return additionalDocumentsStepProps(productAccountDetails);
      case IDs.serviceAgreement.default:
        return serviceAgreementStepProps(productAccountDetails);
      case IDs.ManagementDetails.default:
        return businessMemberStepProps(productAccountDetails);
      case IDs.videoKyc.default:
        return videoKycStepProps(productAccountDetails);  
      default:
        return { completed: false, editable: true };
    }
  },
};
