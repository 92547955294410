import React from 'react';
import { connect } from 'react-redux';
import { config } from 'hub-redux-auth';
import { onRedirectionCLick } from '../../services/utils';
import { Link } from 'react-router-dom';

import {
  partnerActions,
  partnerSelectors,
  hubActions,
  hubSelectors,
} from "@store/ducks";

import PayuBlackLogo from '@assets/images/PayU-Icon.svg';
import WelcomePageSideLogo from '@assets/images/welcome-page-side-logo.png';
import PremiumStar from '@assets/images/premium-star.svg';
import RightArrow from '@assets/images/right-arrow.svg';
import CsBackground from '@assets/images/cs-bg-pink.svg';
import AuthManager from '../../services/AuthManager';
import TopBar from '@components/BaseComponents/Topbar';

import cleverTap from '../../services/cleverTap';

class WelcomePage extends React.Component {

  componentWillMount() {
    document.body.style.background = 'none';
    this.props.fetchHubToken();
  }

  componentDidUpdate() {
    const { mid } = AuthManager.getUser() || {};
    const reseller_id = window.localStorage.getItem('reseller_id')
    if (reseller_id && mid ) {
        const data = {
          'credentials': false,
          'merchant_id': mid,
          'reseller_uuid': reseller_id,
          }
        this.props.linkPartner(data);
    } 
  }

  redirectToProductIntegration = () => {
    const user = AuthManager.getUser();
    if (user && user.product.toUpperCase() === 'PAYUBIZ'){
      onRedirectionCLick();
    } else if (user && user.product.toUpperCase() === 'PAYUMONEY'){
      const productHost = config.get('moneyProductHost') || config.get('productHost') || null;
      window.location.href = `${productHost}/merchant-dashboard/#/transactions`;
    } else if (user && user.product.toUpperCase() === 'PAYUNOW'){
      const productHost = config.get('nowProductHost') || config.get('productHost') || null;
      window.location.href = `${productHost}/merchant-dashboard/#/transactions`;
    }
  };


  renderLinksAndImages = () => {
    const name = AuthManager.getMerchantName();
    return (
        <div className="container">
          <div className="row">
            <div className="col-md-7 box-pos">
              <div className="start-tour">
                <h3>
                  <p className="diff-fnt"><span>Welcome, </span><strong>{name || ''}</strong></p>
                  <p className="diff-fnt-2">What would you like to do? </p>
                </h3>
                <div className="button-wrap">
                  <Link to="/onboarding" id="completeProfile" className="ntf-btn col-sm-12 btn-gap">
                    ACTIVATE ACCOUNT
                  </Link>
                  <a
                    href="#"
                    onClick={() => this.redirectToProductIntegration()}
                    name="Integration"
                    className="need-assessment-btn-confirm"
                    id="startIntegration"
                  >EXPLORE DASHBOARD</a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="dotted-bg">
                <div className="welcome-page-side-logo">
                  <img src={WelcomePageSideLogo} className="collect-payments" />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };
  renderContent(){
    return (
      <div className="frst-ntf-waves">
        <div className="main-content">
          <TopBar
            logo={process.env.PAYU_LOGO_IMG_URL}
            menuList={[]}
          />
          <div className="notification first custom-welcome grey-background fonts-wrap">
            {this.renderLinksAndImages()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}
const mapDispatchToProps = (dispatch) => ({
  fetchHubToken: () => dispatch(hubActions.fetchHubToken()),
  linkPartner: (data) => dispatch(partnerActions.linkPartner(data)),
});

const mapStateToProps = (state) => {
  return {
    hubToken: hubSelectors.getHubToken(state),
    isPartnerFetching: partnerSelectors.isPartnerFetching(state),
    partnerName: partnerSelectors.getName(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
