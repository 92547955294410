import 'url-search-params-polyfill';

export default class Browser {
  /**
     * Determine the web device.
     * This function returns one of 'Tablet', 'Mobile', or 'Desktop'
     * @returns {String}
     */
  static setStartTime() {
    Browser.startTime = Date.now();
  }

  static setFinishTime() {
    Browser.finishTime = Date.now();
  }

  static getLoadTime() {
    return Browser.finishTime - Browser.startTime;
  }

  static getWebDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isWindowPhone = /windows phone/i.test(userAgent);
    const isAndroidPhone = /android/i.test(userAgent);
    const isApplePhone = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    if (isWindowPhone || isAndroidPhone || isApplePhone) {
      return (window.innerWidth > 1023 || window.innerHeight > 1023) ? 'Tablet' : 'Mobile';
    }
    return 'Desktop';
  }

  static getCurrentLocation() {
    return window.location.href;
  }
  static parseQueryString(paramsString) {
    const params = new URLSearchParams(paramsString);
    return {
      'UTM Campaign': params.get('utm_campaign'),
      'UTM Source': params.get('utm_source'),
      'UTM Medium': params.get('utm_medium'),
      'UTM Term': params.get('utm_term'),
      'UTM Content': params.get('utm_content'),

    };
  }
}

Browser.platform = 'Onboarding';
