import React from 'react';

import GenericModal from '../BaseComponents/GenericModal';

export default function AgreementFailure(props) {
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      contentClassName="service-agreement-failure-popup"
      modalTitle="There seems to be a problem while generating your service agreement."
    >
      <div className="text-center contact-bttn">
        <button className="dflt-btn" onClick={props.action}>
          CONTACT US
        </button>
      </div>
    </GenericModal>
  );
}
