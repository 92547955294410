import { takeEvery, put } from "redux-saga/effects";
import { postMessageToWindow } from '@services/nativeApp';

//  Action types
export const actionTypes = {
  open: 'dialog/OPEN',
  close: 'dialog/CLOSE',
};

const initialState = {
  open: false,
  type: null,
  props: {},
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.open: {
      postMessageToWindow('dialog-open');
      return {
        open: true,
        type: action.payload.type,
        props: action.payload.props ? action.payload.props : {},
      }
    }
    case actionTypes.close: {
      postMessageToWindow('dialog-close');
      return {
        ...state,
        open: false,
        props: {},
      }
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  open: state => state.onboardingApp.dialog.open,
  type: state => state.onboardingApp.dialog.type,
  getProps: state => state.onboardingApp.dialog.props,
};


//  Actions
export const actions = {
  open: (type, props) => ({ type: actionTypes.open, payload: { type, props } }),
  close: (actionType) => ({ type: actionTypes.close, payload: actionType ? actionType : null }),
};

// Workers
function* closeWorker(action) {
  if (action.payload) yield put({ type: action.payload });
}

// Sagas
export function* saga() {
  yield takeEvery(actionTypes.close, closeWorker);
}