import React from 'react';
import PropTypes from 'prop-types';

import Success from '@assets/images/status/success.svg';
import { bytesToKB, classNames } from '../../../services/utils';
import GenericModal from '../GenericModal';
import FileUploaderButton from '../FileUploaderButton';
import NoAccessModal from '../../ManageEmployeesAndRoles/NoAccessModal';

class FileUploader extends React.Component {
  static contextTypes = {
    initiateOldFlow: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isNoAccessModalOpen: false,
      isRemoveModalOpen: false,
      uploadBtnText: props.uploadBtnText
    };
    this.downloadFile = this.downloadFile.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onRemoveModalClose = this.onRemoveModalClose.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.showRemoveModal = this.showRemoveModal.bind(this);
  }

  componentDidMount() {
    const { initiateOldFlow } = this.props;
    if (this.props.fileObject) {
      this.initState(this.props);
    }
    this.setState({ initiateOldFlow });
  }

  componentWillReceiveProps(nextProps) {
    const currentProps = this.props;
    if (nextProps.fileObject) {
      this.initState(nextProps);
    } else if (this.props.fileObject && !nextProps.fileObject) {
      this.resetState();
    }
    if (this.props.uploadBtnText !== nextProps.uploadBtnText) {
      this.setState({ uploadBtnText: nextProps.uploadBtnText });
    }
  }

  initState(props) {
    const { fileObject } = props,
     processedDocument = fileObject.processed_document || {};
    this.setState({
      fileObject,
      isAlreadyUploaded: true,
      showLoader: false,
      filePath: this.getFileNameFromPath(processedDocument.path || ''),
      fileName: processedDocument.filename,
      size: bytesToKB(processedDocument.byte_size)
    });
  }

  resetState() {
    this.setState({
      fileObject: '',
      isAlreadyUploaded: false,
      fileName: '',
      filePath: '',
      size: ''
    });
  }

  getFileNameFromPath(filePath) {
    return filePath.replace(/^.*[\\\/]/, '');
  }

  getFileExtention(file) {
    return file.name.split('.').pop();
  }

  isAgreementAccepted() {
    const { fileObject } = this.props;
    if (fileObject && fileObject.active && (fileObject.status === 'accepted' ||
      fileObject.status === 'Counter Signed Received' ||
      fileObject.status === 'Approved'
    )) {
      return true;
    }
    return false;
  }

  validateFile(file) {
    const ext = this.getFileExtention(file),
     size = bytesToKB(file.size);
    let errors = [];
    if (this.props.onValidate) {
      errors = this.props.onValidate(size, ext);
    }
    return errors;
  }

  downloadFile() {
    if (this.props.fileObject) {
      const { processed_document: { path } } = this.props.fileObject;
      window.open(path, '_blank');
    }
  }

  onButtonClick(e, { triggerManual = false } = {}) {
    if (this.props.canUpdate){
      if (this.props.openFirstTimeHelpModal() || this.props.disabled) {
        e.preventDefault();
      } else if (this.props.onButtonClick) {
        e.preventDefault();
        this.props.onButtonClick();
      } else if (triggerManual) {
        this.triggerInputFileManually();
      }
      this.props.onUploadInit();
    } else {
      this.openNoAccessModal(e);
    }
  }

  uploadFile(file, documentType, docSubcategoryLabel) {
    const errors = this.validateFile(file);
    documentType = documentType || this.props.documentType;
    const isValid = errors.length === 0;
    if (isValid) {
      const formData = new FormData();
      formData.append('document_category_id', this.props.documentCategory);
      formData.append('processed_document', file);
      if (documentType) {
        formData.append('document_type_id', documentType);
      }
      this.props.onUpload(formData).
        then(() => this.props.onUploadSuccess({
          size: this.state.size,
          Type: docSubcategoryLabel
        })).
        catch((err) => {
          this.props.onUploadError({ size: this.state.size,
errorCode: err.message });
          this.setState({
            showLoader: false,
            errors: [err.message]
          });
        }, );

      this.setState({
        showLoader: true,
        uploadBtnText: this.props.uploadBtnText,
        fileName: file.name,
        size: bytesToKB(file.size),
        errors
      });
    } else {
      this.setState({
        uploadBtnText: this.props.uploadBtnErrorText,
        fileName: '',
        size: '',
        errors: [{ errors }]
      });
      this.props.onUploadError({ size: bytesToKB(file.size),
errorCode: errors.toString() });
    }
  }

  handleFileChange(e, documentType, docSubcategoryLabel) {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      this.uploadFile(file, documentType, docSubcategoryLabel);
    }
  }

  removeFile() {
    this.props.onRemove({
      fileObject: this.state.fileObject
    }).then(() => {
        this.props.onRemoveSuccess(this.state.fileObject);
        this.setState({
          uploadBtnText: this.props.uploadBtnText,
          fileName: '',
          filePath: '',
          size: '',
          isRemoveModalOpen: false,
          isAlreadyUploaded: false
        });
      }).
      catch((err) => {
        this.setState({
          isRemoveModalOpen: false,
          showLoader: false,
          errors: [err.message]
        });
        setTimeout(() => {
          this.setState({ errors: [] });
        }, 3000);
      });
  }

  showRemoveModal(e) {
    e.preventDefault();
    if (this.props.canUpdate){
      this.props.onRemoveInit(this.state.fileObject);
      this.setState({
        isRemoveModalOpen: true
      });
    } else {
      this.openNoAccessModal(e);
    }
  }

  onRemoveModalClose() {
    this.setState({
      isRemoveModalOpen: false
    });
    this.props.onRemoveCancel && this.props.onRemoveCancel(this.state.fileObject);
  }

  triggerInputFileManually() {
    this.btnElement && this.btnElement.inputFile && this.btnElement.inputFile.click();
  }

  openNoAccessModal = (e) => {
    e.preventDefault();
    this.setState({ isNoAccessModalOpen: true })
  }

  closeNoAccessModal = () => {
    this.setState({ isNoAccessModalOpen: false })
  }


  render() {
    const {
      fileName, size, uploadBtnText, errors, showLoader, isAlreadyUploaded
    } = this.state,
     { isFetching, fileObject } = this.props,
     { intiateOldFlow } = this.props,
     removeBtnClass = classNames({
      inactive: isFetching,
      'dflt-btn': true,
      'width-150': true
    });
    let caption = <small className={`${this.props.disabled ? 'light-grey-text' : ''}`}>{this.props.description}</small>;
    if (errors.length && errors[0]) {
        caption = errors.map((errMsg, index) => <div key={index}>
              <small className="errorTxt">
                  {Object.values(errMsg)}
              </small>
            </div>);
    } else if (this.props.isDocRejected()) {
      caption = <small className={`${this.props.disabled ? 'light-grey-text' : ''}`}><strong>{this.props.fileObject && this.props.fileObject.remarks}</strong><br />{this.props.description}</small>;
    } else if (fileName && fileObject && fileObject.kyc_document_type !== 'Agreement' && size !== undefined) {
      caption = <div>
        <small>
          <span className="file-name" onClick={this.downloadFile}>{fileName} </span>
          <span className="file-size">{size} kb </span>
          {!this.props.isDocVerified() && !showLoader && !this.props.hideRemoveButton &&
          <a href="#" onClick={this.showRemoveModal}>Remove</a>}
        </small>
      </div>;
    }
    if (fileObject && intiateOldFlow && fileObject.active && fileObject.kyc_document_type === 'Agreement' && !this.isAgreementAccepted()) {
      caption = <div>
        <small>
          <span className="file-name" onClick={this.downloadFile}>{fileName} </span>
          <span className="file-size">{size} kb </span>
          {!showLoader && !this.props.isDocVerified() && <a href="#" onClick={this.showRemoveModal}>Remove</a>}
        </small>
      </div>;
    }

    if (fileObject && intiateOldFlow && fileObject.active && fileObject.kyc_document_type === 'Agreement' && this.props.intiateOldFlow) {
      caption = <div>
        <small>
          <span className="file-name" onClick={this.downloadFile}>{fileName} </span>
          <span className="file-size">{size} kb </span>
          {!showLoader && !this.props.isDocVerified() && <a href="#" onClick={this.showRemoveModal}>Remove</a>}
        </small>
      </div>;
    }

    const uploadDone = <Success width={40} height={40} />;
    let fileUploadBtn;
    if (this.props.isDocRejected()) {
      fileUploadBtn = <FileUploaderButton
        ref={(el) => this.btnElement = el}
        onInputChange={this.handleFileChange}
        disabled={this.props.disabled}
        onButtonClick={this.onButtonClick}
        buttonLabel={uploadBtnText}
      />;
    } else if (fileObject && fileObject.kyc_document_type === 'Agreement' && this.props.intiateOldFlow) {
      fileUploadBtn = <FileUploaderButton
        ref={(el) => this.btnElement = el}
        onInputChange={this.handleFileChange}
        disabled={this.props.disabled}
        onButtonClick={this.onButtonClick}
        buttonLabel={uploadBtnText}
      />;
      if (this.isAgreementAccepted()) {
        fileUploadBtn = uploadDone;
      }
    } else if (isAlreadyUploaded && fileObject.kyc_document_type !== 'Agreement') {
      fileUploadBtn = uploadDone;
    } else if (fileObject && fileObject.kyc_document_type === 'Agreement' && this.isAgreementAccepted()) {
      fileUploadBtn = uploadDone;
    } else {
      fileUploadBtn = <FileUploaderButton
        ref={(el) => this.btnElement = el}
        onInputChange={this.handleFileChange}
        disabled={this.props.disabled}
        onButtonClick={this.onButtonClick}
        buttonLabel={uploadBtnText}
      />;
    }

    return (
      <div>
        {showLoader ? <div className="loader" /> : fileUploadBtn}
        {caption}
        <GenericModal
          isOpen={this.state.isRemoveModalOpen}
          contentClassName="upload-address-proof-modal text-center p-0"
          onClose={this.onRemoveModalClose}
        >
          <div className="main-content-file-uploader">
            <p className="confirmation-txt">Are you sure you want to remove the file - "{fileName}"</p>
            <div className="cstmz-optn">
              <div className="form-group">
                <button className={removeBtnClass} onClick={this.removeFile}>REMOVE</button>
              </div>
              <button className="cmn-btn" onClick={this.onRemoveModalClose}>CANCEL</button>
            </div>
          </div>
        </GenericModal>
        <NoAccessModal isOpen={this.state.isNoAccessModalOpen} onClose={this.closeNoAccessModal}></NoAccessModal>
      </div>
    );
  }
}

FileUploader.propTypes = {
  canUpdate: PropTypes.bool,
  description: PropTypes.string,
  hideRemoveButton: PropTypes.bool,
  id: PropTypes.string,
  initiateOldFlow: PropTypes.bool.isRequired,
  isDocVerified: PropTypes.bool,
  onRemoveInit: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadInit: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  openFirstTimeHelpModal: PropTypes.func,
  uploadBtnErrorText: PropTypes.string,
  uploadBtnText: PropTypes.string
};

FileUploader.defaultProps = {
  hideRemoveButton: false,
  isDocVerified: () => { },
  onUploadInit: () => { },
  onUploadSuccess: () => { },
  onUploadError: () => { },
  onRemoveInit: () => { },
  onRemoveSuccess: () => { },
  openFirstTimeHelpModal: () => {},
  uploadBtnText: 'UPLOAD',
  uploadBtnErrorText: 'Try Again',
  description: 'File size should be less than 15MB'
};

FileUploader.contextTypes = {
  initiateOldFlow: PropTypes.bool
};

export default FileUploader;
