/*eslint-disable*/
/**
 * doc Upload constants
 * Contants used for dynamic components creation
 * and values for document category id for http requests
 */
export const DOCUMENT_CATEGORIES = {
  SERVICE_AGREEMENT: 1,
  PANCARD_SIGNED_AUTHORITY: 2,
  ADDRESS_PROOF_SIGNED_AUTHORITY: 3,
  BANK_PROOF: 4,
  GOVT_ISSUED_CERTIFICATE: 5,
  COMPANY_PAN_COPY: 6,
  PATNERSHIP_PAN_CARD: 6,
  LLP_PAN_CARD: 6,
  SIGNED_AUTHORISATION_LETTER: 7,
  BOARD_RESOLUTION_LETTER: 8,
  TRUST_PAN: 9,
  SOCIETY_PAN: 10
};

export const DOCUMENT_CATEGORY_GENERIC_DATA = {
  PANCARD_SIGNED_AUTHORITY: {
    label: 'PAN Card of Signing Authority',
    subCategory: null,
    maxFileSizeLimit: 5, // in MB
    clevertapEventName: 'Pan card'
  },
  ADDRESS_PROOF_SIGNED_AUTHORITY: {
    label: 'Address Proof of Signing Authority',
    subCategory: {
      modalTitle: 'Upload Address Proof',
      value: [
              'PASSPORT',
              'AADHAR',
              'VOTER',
              'DL',
              'BMTB',
              'AVFB'
            ],
      uploadFrontAndBack: [
                            'PASSPORT',
                            'AADHAR'
                          ]
    },
    maxFileSizeLimit: 5,
    clevertapEventName: 'Address proof',
    tooltipText: `Passport / Aadhar / Voter's Id / Driving License / UtilityBill
                  (electricity, water, landline, gas connection)`
  },
  BANK_PROOF: {
    label: 'Bank Account Proof of ${holderName}',
    subCategory: {
      modalTitle: 'Upload Bank Proof',
      value: [
              'CC',
              'BC'
             ],
      coachMark: {
        'CC': 'Documents would get rejected if Account holder name and number is not printed on the cheque'
      }       
    },
    maxFileSizeLimit: 5,
    clevertapEventName: 'Bank proof',
    tooltipText: 'Cancelled Cheque / Bank Verification letter'
  },
  SERVICE_AGREEMENT: {
    label: 'Service Agreement',
    subCategory: null,
    maxFileSizeLimit: 5,
    clevertapEventName: 'Service agreement',
    uploadMultiple: true,
    multipleUploadModalTitle: 'Upload Service Agreement',
    multipleUploadModalDescription: 'Upload all pages of your document'
  },
  PATNERSHIP_PAN_CARD: {
    label: 'Patnership PAN Card',
    subCategory: null,
    maxFileSizeLimit: 5,
    clevertapEventName: 'Pan card'
  },
  COMPANY_PAN_COPY: {
    label: 'Company PAN Card',
    subCategory: null,
    maxFileSizeLimit: 5,
    clevertapEventName: 'Company pan card'
  },
  CERTIFICATE_OF_INCORPORATION: {
    label: 'Certificate of Incorporation',
    subCategory: {
      modalTitle: 'Upload Certificate of Incorporation',
      value: ['CERTIFICATE_OF_INCORPORATION'],
      uploadFrontAndBack: ['CERTIFICATE_OF_INCORPORATION'],
      frontDocName: 'Certificate of Incorporation',
      backDocName: '(Optional) INC 22',
      backDocOptional: true
    },
    maxFileSizeLimit: 15,
    clevertapEventName: 'Govt certificate'
  },
  GOVT_ISSUED_CERTIFICATE: {
    label: 'Government issued certificate copy',
    subCategory: {
      modalTitle: 'Upload Govt issued certificate copy'
    },
    maxFileSizeLimit: 15,
    clevertapEventName: 'Govt certificate',
  },
  SOCIETY_PAN: {
    label: 'Society PAN Card',
    subCategory: null,
    maxFileSizeLimit: 5,
    clevertapEventName: 'Company Pan card'
  },
};


export const DOCUMENT_CHECKLIST = {
  Individual: {
    PANCARD_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
      label: 'PAN Card of ${nameOnPanCard}'
    },
    ADDRESS_PROOF_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
      label: 'Address Proof of ${nameOnPanCard}'
    },
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  'Sole Proprietorship': {
    PANCARD_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
      label: 'PAN Card of ${nameOnPanCard}'
    },
    ADDRESS_PROOF_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
      label: 'Address Proof of ${nameOnPanCard}'
    },
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      label: 'Government issued certificate copy',
      subCategory: {
        modalTitle: 'Upload Govt issued certificate copy',
        value: [
                'GST_CERTIFICATE',
                'NOC',
                'UACC',
                'TTC',
                'SERVICE_TAX',
                'OTHERS'
                ],
        uploadMultiple: [
                          'GST_CERTIFICATE',
                          'NOC',
                          'UACC',
                          'TTC',
                          'SERVICE_TAX',
                          'OTHERS'
                          ]
      },
      maxFileSizeLimit: 15,
      clevertapEventName: 'Govt certificate',
      tooltipText: `NOC by Gram Panchayat / Udyog Aadhar Card Certificate /
                    TIN/TAN Certificate / Service Tax / Vat / Dvat-Import/
                    Export License / Labour License / Central Excise Certificate /
                    Municipal Corporation Certificate / Certificate of Business
                    Registration /  License issued by Registering Authorities
                    like ICAI, ICWAI, ICSI, IRDA / GST Registration Certificate`
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  Partnership: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      label: 'Government issued certificate copy',
      subCategory: {
        modalTitle: 'Upload Govt issued certificate copy',
        value: [
                'GST_CERTIFICATE',
                'NOC',
                'UACC',
                'TTC',
                'SERVICE_TAX',
                'OTHERS'
                ],
        uploadMultiple: [
                          'GST_CERTIFICATE',
                          'NOC',
                          'UACC',
                          'TTC',
                          'SERVICE_TAX',
                          'OTHERS'
                          ]
      },
      maxFileSizeLimit: 15,
      clevertapEventName: 'Govt certificate',
      tooltipText: `NOC by Gram Panchayat / Udyog Aadhar Card Certificate /
                    TIN/TAN Certificate / Service Tax / Vat / Dvat-Import/
                    Export License / Labour License / Central Excise Certificate /
                    Municipal Corporation Certificate / Certificate of Business
                    Registration /  License issued by Registering Authorities
                    like ICAI, ICWAI, ICSI, IRDA / GST Registration Certificate / 
        Partnership Deed verified by the registrar`
    },
    PATNERSHIP_PAN_CARD: DOCUMENT_CATEGORY_GENERIC_DATA.PATNERSHIP_PAN_CARD,
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  LLP: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    LLP_PAN_CARD: {
      label: 'LLP PAN Card',
      subCategory: null,
      maxFileSizeLimit: 5,
      clevertapEventName: 'Company pan card'
    },
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  'Private Limited': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  'Public Limited': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  Trust: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      label: 'Government issued certificate copy',
      subCategory: {
        modalTitle: 'Upload Govt issued certificate copy',
        value: [
                'CR',
                'C80G',
                'TDVR'
                ],
        uploadMultiple: [
                        'CR',
                        'C80G',
                        'TDVR'
                        ]
      },
      maxFileSizeLimit: 15,
      clevertapEventName: 'Govt certificate',
      tooltipText: `Certificate of Registration / 80G and 12A Certificate
                    / Trust Deed verified by the registrar`
    },
    TRUST_PAN: {
      label: 'Trust PAN Card',
      subCategory: null,
      clevertapEventName: 'Company pan card',
      maxFileSizeLimit: 5
    },
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  Society: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      label: 'Government issued certificate copy',
      subCategory: {
        modalTitle: 'Upload Govt issued certificate copy',
        value: [
                'CR',
                'C80G',
                'SDVR'
                ],
        uploadMultiple: [
                          'CR',
                          'C80G',
                          'SDVR'
                        ]
      },
      maxFileSizeLimit: 15,
      clevertapEventName: 'Govt certificate',
      tooltipText: `Certificate of Registration / 80G and 12A Certificate
                    / Society Deed verified by the registrar`
    },
    SOCIETY_PAN: {
      label: 'Society PAN Card',
      subCategory: null,
      maxFileSizeLimit: 5,
      clevertapEventName: 'Company Pan card'
    },
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  },
  'Government': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
  },
  NGO: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    SOCIETY_PAN: DOCUMENT_CATEGORY_GENERIC_DATA.SOCIETY_PAN,
    GOVT_ISSUED_CERTIFICATE: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.GOVT_ISSUED_CERTIFICATE,
      subCategory: {
        ...DOCUMENT_CATEGORY_GENERIC_DATA.GOVT_ISSUED_CERTIFICATE['subCategory'],
        value: [ 'C80G' ],
        uploadMultiple: [ 'C80G']
      },
      tooltipText: `80G and 12A Certificate`
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SIGNED_AUTHORISATION_LETTER: {
      label: 'Signed Authorisation Letter'
    }
  },
  'One Person Company': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT
  }
};


// label for the UI and value for backend
export const DOCUMENT_SUBCATEGORIES = {
  PASSPORT: {
    label: 'Passport',
    value: '4',
    modalTitle: 'Upload Address Proof',
    modalDescription: 'Upload front and back side of your Passport below'
  },
  AADHAR: {
    label: 'Aadhar Card',
    value: '5',
    modalTitle: 'Upload Address Proof',
    modalDescription: 'Upload front and back side of your Aadhar below'
  },
  VOTER: {
    label: 'Voter Card',
    value: '6'
  },
  DL: {
    label: 'Driving License',
    value: '7'
  },
  BMTB: {
    label: 'Landline telephone Bill',
    value: '8'
  },
  AVFB: {
    label: 'Address Verification Letter from Bank',
    value: '6'
  },
  CC: {
    label: 'Cancelled Cheque',
    value: '1'
  },
  BC: {
    label: 'Bank Verification letter',
    value: '2'
  },
  TTC: {
    label: 'TIN/TAN Certificate',
    value: '9',
    modalTitle: 'Upload TIN/TAN Certificate Proof',
    modalDescription: 'Upload all pages of your document'
  },
  LOR: {
    label: 'Letter of Registration',
    value: '10'
  },
  IEP: { // import/Export License
    label: 'Import/Export License',
    value: '11'
  },
  LL: { // labour License
    label: 'Labour License',
    value: '12'
  },
  CEC: { // central Excise Certificate
    label: 'Central Excise Certificate',
    value: '13'
  },
  MCC: { // municipal Corporation Certificate
    label: 'Municipal Corporation Certificate',
    value: '14'
  },
  UACC: { // udyog Aadhar Card Certificate
    label: 'Udyog Aadhar Card Certificate',
    value: '15',
    modalTitle: 'Upload Udyog Aadhar Card Certificate Proof',
    modalDescription: 'Upload'
  },
  NOC: { // nOC by Gram Panchayat
    label: 'NOC by Gram Panchayat',
    value: '16',
    modalTitle: 'Upload NOC Proof',
    modalDescription: 'Upload all pages of your document'
  },
  COR: { // valid Business License or Certificate of Registration issued by State/Central Govt. Authority
    label: 'Valid Business License or Certificate of Registration',
    value: '17'
  },
  LIRA: { // license (eg. Certificate of Practice) issued by Registering Authorities like
    label: 'License (eg. Certificate of Practice) issued by Registering Authorities', // iCAI, ICWAI, ICSI, IRDA etc.
    value: '18'
  },
  PDVR: { // partnership Deed verified by the registrar
    label: 'Partnership Deed verified by the registrar',
    value: '19'
  },
  CORIR: { // certificate of Registration/incorporation issued by registrar of firm
    label: 'Certificate of Registration/incorporation issued by registrar of firm',
    value: '20'
  },
  CR: { // certificate of Registration
    label: 'Certificate of Registration',
    value: '22',
    modalTitle: 'Upload Certificate Of Registration',
    modalDescription: 'Upload all pages of your document'
  },
  C80G: { // 80G and 12A Certificate (mandatory if trust is taking donation)
    label: '80G and 12A Certificate (mandatory if trust/society/NGO is taking donation)',
    value: '23',
    modalTitle: 'Upload 80G and 12A Certificate Proof',
    modalDescription: 'Upload all pages of your document'
  },
  TDVR: { // trust Deed verified by the registrar
    label: 'Trust Deed verified by the registrar',
    value: '24',
    modalTitle: 'Upload Trust Deed Proof',
    modalDescription: 'Upload all pages of your document'
  },

  SDVR: { // society Deed verified by the registrar
    label: 'Society Deed verified by the registrar',
    value: '26',
    modalTitle: 'Upload Society Deed Proof',
    modalDescription: 'Upload all pages of your document'
  },
  OTHERS: {
    label: 'Others',
    value: '27',
    subLabel: `(Vat / Dvat-Import/ Export License / Labour License / Central Excise Certificate
                / Municipal Corporation Certificate / Certificate of Business Registration /
                License issued by Registering Authorities like ICAI, ICWAI, ICSI, IRDA) /
                Partnership Deed verified by the registrar`,
    modalTitle: 'Upload Proof',
    modalDescription: 'Upload all pages of your document'
  },
  GST_CERTIFICATE: {
    label: 'GST Registration Certificate',
    value: '28',
    modalTitle: 'Upload GST Registration Copy',
    modalDescription: 'Upload all pages of your document'
  },
  SERVICE_TAX: {
    label: 'Service Tax',
    value: '29',
    modalTitle: 'Upload Service Tax Proof',
    modalDescription: 'Upload all pages of your document'
  },
  CERTIFICATE_OF_INCORPORATION: {
    label: 'Certificate of Incorporation',
    modalTitle: 'Upload Certificate of incorporation',
    modalDescription: 'Upload INC 22 with payment challan if your address on certificate of incorporation is outdated'
  }
};

export const DOCUMENT_TYPES = {
  KYC: 'KYC',
  AGREEMENT: 'Agreement',
  ADDENDUM: 'Addendum'
},
  RECORD_TYPES = {
    ACCOUNT: 'Account',
    MERCHANT: 'Merchant',
    WEBSITE: 'WebsiteDetail'
  },
WEBSITE_DOCUMENT_CHECKLIST = {
  CERTIFICATE_80G_12A: { label: '80G or 12A Certificate' },
  NO_TAX_EXEMPTION: { label: 'Undertaking on Letterhead for no tax exemption claim' },
  AFFILIATION_CERTIFICATE: { label: 'Affiliation certificate' },
  BIS_CERTIFICATE: { label: 'BIS Certificate' },
  CHEMICALS_FERTILIZERS_CERTIFICATE: { label: 'Certificate from Ministry of Chemicals and Fertilizers' },
  DAS_LICENSE: { label: 'DAS license' },
  DGCA_LICENSE: { label: 'DGCA license' },
  FSSAI_CERTIFICATE: { label: 'FSSAI certificate' },
  GEMOLOGICAL_CERTIFICATE: { label: 'Gemological institute certificate' },
  FRANCHISEE_AGREEMENT: { label: 'Franchisee Agreement with Bank' },
  TOURISM_MINISTRY_APPROVAL: { label: 'Approval from Indian Ministry of Tourism' },
  IATA_CERTIFICATE: { label: 'IATA Certificate' },
  WEB_HOSTING_INVOICE: { label: 'Web Hosting Invoice' },
  PROCUREMENT_INVOICE: { label: 'Procurement Invoice' },
  IRDA_CERTIFICATE: { label: 'IRDA certificate' },
  AUDITED_FINANCIALS_2_YEARS: { label: 'Last 2 years Audited Financials' },
  BANK_STATEMENT_6_MONTHS: { label: 'Last 6 Months Bank Statement' },
  SHIPPING_LICENSE: { label: 'License from Directorate General of shipping' },
  LOGISTIC_TRANSPORT_LICENSE: { label: 'Logistic Transport License' },
  NBFC_CERTIFICATE: { label: 'NBFC Certificate' },
  NISM_MFD: { label: 'NISM MFD' },
  VENURE_BOOKING_RECEIPT: { label: 'Venue Booking Receipt' },
  PCI_DSS_CERTIFICATE: { label: 'PCI DSS certificate' },
  PEST_CONTROL_LICENSE: { label: 'Pest control business license' },
  POLICE_VERIFICATION_CERTIFICATE: { label: 'Police Verification Certificate' },
  RADIO_LICENSE: { label: 'Radio License' },
  MONEY_CHANGER_LICENSE: { label: 'Money Changer License' },
  RBI_LICENSE: { label: 'RBI License' },
  RBI_PPI_LICENSE: { label: 'RBI PPI license' },
  RERA_CERTIFICATE: { label: 'RERA Certificate' },
  SAMPLE_PROOF_DELIVERY: { label: 'Sample Proof of Delivery' },
  SEBI_CERTIFICATE: { label: 'SEBI Certificate' },
  SEBI_INVESTMENT_ADVISOR_CERTIFICATE: { label: 'SEBI certificate as Investment Advisor' },
  TIE_UP_AGREEMENT: { label: 'Tie-up Agreement with your Partners' },
  ISP_LICENSE: { label: 'ISP License' },
  NSDL_CERTIFICATE: { label: 'NSDL Certificate' },
  ACCREDITION_CERTIFICATE: { label: 'Accredition Certificate' },
  PROPERTY_OWNERSHIP_DOCUMENT: { label: 'Document validating Ownership of Property' },
  CRUISE_OWNERS_AGREEMENT: { label: 'Tie-up Agreement with Cruise Owners' },
  DIAGNOSTIC_CENTRES_AGREEMENT: { label: 'Tie-up Agreement with Car Owners' },
  CAR_OWNERS_AGREEMENT: { label: 'Tie-up Agreement with Diagnostic Centers' },
  LOGISTIC_PARTNERS_AGREEMENT: { label: 'Tie-up Agreement with Logistic Partners' },
  NBFC_AGREEMENT: { label: 'Tie-up Agreement with NBFC' },
  UNIFORM_ORGANISATIONS_AGREEMENT: { label: 'Tie-up Agreement with Uniform Organisations' },
  RESTAURANTS_AGREEMENT: { label: 'Tie-up Agreement with Restaurants' },
  SCHOOLS_COLLEGES_AGREEMENT: { label: 'Tie-up Agreement with Schools/Colleges' },
  SERVICE_PROVIDERS_AGREEMENT: { label: 'Tie-up Agreement with Service Providers' },
  SHOPS_DEALERS_AGREEMENT: { label: 'Tie-up Agreement with Shops/Dealers' },
  SOCIETIES_AGREEMENT: { label: 'Tie-up Agreement with Societies' },
  TUTORS_AGREEMENT: { label: 'Tie-up Agreement with Tutors' },
  VENDORS_SERVICE_PROVIDERS_AGREEMENT: { label: 'Tie-up Agreement with vendors/service providers' },
  AUTHORISATION_DOCUMENT: { label: 'Authorisation Document for carrying out this business' },
  JUDGES_CONFIRMATION_LETTER: { label: 'Confirmation email/letter from the judges' },
  IE_LICENSE: { label: 'IE license' },
  FILMS_EXHIBITION_CERTIFICATE: { label: 'Approved Films Exhibition Certificate' },
  MANUFACTURING_LICENSE: { label: 'Manufacturing License' },
  CSP_DOCUMENT: { label: 'Grahak Seva Kendra or CSP Document' },
  COURSE_COMPLETION_CERTIFICATE: { label: 'Course Completion Sample Certificate' },
  DIRECT_SELLING_LICENSE: { label: 'Direct Selling License' },
  AYUSH_CERTIFICATE: { label: 'AYUSH Certificate' },
  GMP_CERTIFICATE: { label: 'GMP Certificate' },
  HOMEOPATHIC_SELLING_LICENSE: { label: 'Homeopathic Selling Drugs License' }
};

