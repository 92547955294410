import { combineReducers } from 'redux';
import map from 'lodash/map';
import * as C from '../constants/documentTypes';
import { formatSubmittedDocResponseUtil } from '../services/utils';

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false,
};

function getErrorMsg(error) {
  const values = Array.isArray(error)? Object.values(error) : error
  return map(values);
};

export function documentReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.FETCH_SUBMITTED_DOC_LIST_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.FETCH_SUBMITTED_DOC_LIST_SUCCESS: {
      const formattedResponse = formatSubmittedDocResponseUtil(action.payload.kyc_documents);
      return {
        ...state,
        data: formattedResponse,
        fetching: false,
        fetched: true,
      };
    }
    case C.UPLOAD_DOCUMENT_SUCCESS: {
      const { document_category_id: documentCategory } = action.payload.kyc_document;
      return {
        ...state,
        data: { ...state.data, [documentCategory]: action.payload.kyc_document },
      };
    }
    case C.REMOVE_ATTACHMENT_SUCCESS: {
      const { document_category_id: documentCategory } = action.payload.fileObject,
      documentState = { ...state.data },
      oldDocument = documentState[documentCategory];
      return {
        ...state,
        data: {
          ...documentState,
          [documentCategory]: { ...oldDocument, processed_document: null }
        }
      };
    }
    case C.REMOVE_DOCUMENT_SUCCESS: {
      const updateData = { ...state.data };
      delete updateData[action.payload.document_category_id];
      return { ...state, data: { ...updateData } };
    }
    case C.FETCH_SUBMITTED_DOC_LIST_FAILURE:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}

/*
 * TODO: Remove this reducer and it's action, there should be only one single API call.
 */
export function generatedServiceAgreementReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.FETCH_GENERATED_SERVICE_AGREEMENT_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.FETCH_GENERATED_SERVICE_AGREEMENT_SUCCESS: {
      const formattedResponse = formatSubmittedDocResponseUtil(action.payload.kyc_documents);
      return {
        ...state,
        data: formattedResponse,
        fetching: false,
        fetched: true,
      };
    }
    case C.FETCH_GENERATED_SERVICE_AGREEMENT_FAILURE:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}

export function generateServiceAgreementReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.GENERATE_SERVICE_AGREEMENT_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.GENERATE_SERVICE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.kyc_document,
        fetching: false,
        fetched: true,
      };
    }
    case C.GENERATE_SERVICE_AGREEMENT_FAILED:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}


export function acceptServiceAgreementReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.ACCEPT_SERVICE_AGREEMENT_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.ACCEPT_SERVICE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        fetched: true,
      };
    }
    case C.ACCEPT_SERVICE_AGREEMENT_FAILED:
      return { ...state, fetching: false, error: action.payload };
      default:
      return state;
  }
}

export function digitalSignatureReducer(state = { signatoryOtp: GENERIC_STATE, mergedDocument: GENERIC_STATE }, action) {
  switch (action.type) {
    case C.SEND_SIGNATORY_OTP_INITIATED:
      return { ...state, signatoryOtp: { fetching: true, fetched: false }};
    case C.SEND_SIGNATORY_OTP_SUCCESS:
      return { ...state, signatoryOtp: { fetching: false }};
    case C.SEND_SIGNATORY_OTP_FAILURE:
      return { ...state, signatoryOtp: { fetching: false, blocked: Boolean(action.payload.blocked), error: getErrorMsg(action.payload.error || action.payload) } };
    case C.VERIFY_SIGNATORY_OTP_INITIATED:
      return { ...state, signatoryOtp: { fetching: true, fetched: false } };
    case C.VERIFY_SIGNATORY_OTP_FAILURE:
      return { ...state, signatoryOtp: { fetching: false, blocked: Boolean(action.payload.blocked), error: getErrorMsg(action.payload.error || action.payload)} };
    case C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_INITIATED:
      return { ...state,
        signatoryOtp: { fetching: true },
        mergedDocument: { ...state.mergedDocument, fetching: true, fetched: false }};
    case C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_SUCCESS: {
      return {
        ...state,
        signatoryOtp: { fetching: false },
        mergedDocument: {
          data: action.payload,
          fetching: false,
          fetched: true,
          success: true,
        },
      };
    }
    case C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_FAILURE:
      return { ...state, mergedDocument: { ...state.mergedDocument, fetching: false, error: getErrorMsg(action.payload)} };
    default:
    return state;
  }
}

export function signingAuthorityDetailsReducer(state = { document: null }, action) {
  switch (action.type) {
    case C.UPLOAD_AUTHORITY_LETTER_SUCCESS:
      return {
        ...state,
        document: action.payload,
      };
    case C.FETCH_SIGNING_AUTHORITY_LETTER_SUCCESS:
      return {
        ...state,
        document: action.payload,
      };
    case C.REMOVE_DOCUMENT_INITIATED:
      return {
        ...state,
        fetching: true,
        success: false,
      };
    case C.REMOVE_DOCUMENT_SUCCESS:
      const updatedData = { ...state };
      const document = updatedData.document;
      const payload = action.payload;
      if (document && document.document_category_id == payload.document_category_id && document.document_type_id == payload.document_type_id) {
        return {
          ...state,
          document: null,
          fetching: false,
          success: true,
        }
      }
      return {
        ...state,
        fetching: false,
        success: true,
      };
    case C.REMOVE_DOCUMENT_FAILED:
      return {
        ...state,
        fetching: false,
        success: false,
      };
    default:
      return state;
  }
}

export function downloadSigningLetterApiReducer(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.DOWNLOAD_AUTHORITY_LETTER_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.DOWNLOAD_AUTHORITY_LETTER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        fetched: true,
        error: false,
      };
    }
    case C.DOWNLOAD_AUTHORITY_LETTER_FAILED:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}


export function documentEsign(state = GENERIC_STATE, action) {
  switch (action.type) {
    case C.COMPLETE_ESIGN_INITIATED:
      return {
        ...state,
        fetching: true,
      };
    case C.COMPLETE_ESGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        data: action.payload,
        error: null,
      };
    case C.COMPLETE_ESGIN_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  generatedServiceAgreements: generatedServiceAgreementReducer,
  generatedServiceAgreement: generateServiceAgreementReducer,
  kycDocuments: documentReducer,
  acceptedServiceAgreement: acceptServiceAgreementReducer,
  signingAuthorityDetails: signingAuthorityDetailsReducer,
  downloadSigningLetter: downloadSigningLetterApiReducer,
  documentEsign,
  digitalSignature: digitalSignatureReducer,
});
