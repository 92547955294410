import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import FileUploader from '../BaseComponents/FileUploader';
import GenericRadioListModal from '../BaseComponents/GenericRadioListModal';
import PaperTooltip from '../BaseComponents/PaperTooltip';
import DownloadCircleIcon from '@assets/images/icons/download-icon.svg';
import cleverTap from '../../services/cleverTap';
import GenerateServiceAgreement from '../GenerateServiceAgreement';
import UploadFrontAndBackDoc from '../UploadFrontAndBackDoc';
import UploadMultipleDocsModal from '../UploadMultipleDocsModal';
import { INDIVIDUAL_DOC_UPLOAD_STATUS } from '../../constants/statusCodeConstants';
import {
  checkIsWebsiteUrlFilled,
  selectDocumentSubcategories,
  selectGeneratedServiceAgreements,
  selectUser,
  isUrlsLive
} from '../../reducers/selectors';
import {fetchGeneratedServiceAgreements} from "../../actions/documents";
import { setItemToCompleted, checkPermissionOnElement } from '../../services/utils';
import ServiceAgreementModal from "../ServiceAgreementModal";
import { sessionCheck } from '../../actions/auth';
import { PERMISSIONS_LIST } from '../../constants/appConstants';
import { CLEVERTAP_EVENTS_MAP } from '../../constants/cleverTapEvents';


import GenericModal from '../BaseComponents/GenericModal';
import NoAccessAgreementModal from "../NoAccessAgreementModal";

function mapStateToProps(state) {
  return {
    aclRolePermissions: state.masterData.aclPermissions,
    DOCUMENT_SUBCATEGORIES: selectDocumentSubcategories(state),
    generatedServiceAgreements: selectGeneratedServiceAgreements(state).data || {},
    accceptedServiceAgreement: state.documents.accceptedServiceAgreement || {},
    merchant: selectUser(state),
    DOCUMENT_CATEGORIES: state.masterData.documentCategoryAndTypes.documentCategories,
    isUrlsFilled: checkIsWebsiteUrlFilled(state),
    isUrlsLive: isUrlsLive(state)
  };
}

@connect(mapStateToProps, null, null, { withRef: true })
export default class DocumentUploadListItem extends React.Component {
  static propTypes = { hideRemoveButton: PropTypes.bool };
  static defaultProps = { hideRemoveButton: false };
  static allowedFileExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

  static maxFileSize = 15; // 15MB in kb

  static contextTypes = {
    merchant: PropTypes.object,
    intiateOldFlow: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const subCategories = props.docTypes.subCategory
      ? props.docTypes.subCategory.value
      : [];
    const coachMarkData = props.docTypes.subCategory && props.docTypes.subCategory.coachMark
                          ? props.docTypes.subCategory.coachMark
                          : {};
    this.radioList = Object.values(this.getDocumentTypeMap(subCategories, coachMarkData));
    this.state = {
      isOpen: false,
      isDocTypesModalOpen: false,
      isMultipleUploadOpen: false,
      isDisclaimerModalOpen: false,
      isDisclaimerAckowledged: false,
      modalTitle: '',
      modalDescription: '',
      docSubCategory: this.radioList.length ? this.radioList[0].label : '',
      isAgreementPopUpOpen: this.props.openNDXScore ? true : false,
      multipleUploaddocSubcategory: null,
    };
    this.onDisclaimerAcknowledged = this.onDisclaimerAcknowledged.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const subCategories = nextProps.docTypes.subCategory ? nextProps.docTypes.subCategory.value : [];
    const coachMarkData = nextProps.docTypes.subCategory && nextProps.docTypes.subCategory.coachMark
                          ? nextProps.docTypes.subCategory.coachMark
                          : {};
    this.radioList = Object.values(this.getDocumentTypeMap(subCategories, coachMarkData));
  }

  validateFile = (size, ext) => {
    const hasValidExtenstion = DocumentUploadListItem.allowedFileExtensions.includes(
      ext.toLowerCase(),
    );
    const maxFileSizeLimit =
      this.props.docTypes.maxFileSizeLimit ||
      DocumentUploadListItem.maxFileSize;
    const hasValidSize = size < maxFileSizeLimit * 1024;
    const errors = [];
    if (!hasValidExtenstion) {
      errors.push('Error: only PDF, PNG and JPG files are allowed');
    }
    if (!hasValidSize) {
      errors.push(
        `Error: File size exceeded, should be less than ${maxFileSizeLimit}MB`,
      );
    }
    return errors;
  }

  openDocTypesModal = () => {
    this.setState({
      isDocTypesModalOpen: true,
    });
    cleverTap.event(`${this.props.docTypes.clevertapEventName} overlay loaded`, { mid: this.props.mid });
    cleverTap.event(`${this.props.docTypes.clevertapEventName} type selected`, {
      Type: this.state.docSubCategory, mid: this.props.mid,
    });
  }

  openAgreementPopUp = () => {
    this.setState({
      isOpen: true,
    });
    // fetchGeneratedServiceAgreements(this.props.dispatch, this.props.merchant.uuid);
  }

  onAgreementPopUpClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  onDocTypesModalClose = () => {
    this.setState({
      isDocTypesModalOpen: false,
    });
    cleverTap.event(
      `${this.props.docTypes.clevertapEventName} overlay cancelled`, { mid: this.props.mid },
    );
  }

  getDocumentTypeMap(docTypes, coachMarkData = {}) {
    const { DOCUMENT_SUBCATEGORIES } = this.props;
    return docTypes.reduce((map, docType) => {
      map[docType] = DOCUMENT_SUBCATEGORIES[docType];
      map[docType]['coachMark'] = coachMarkData[docType];
      return map;
    }, {});
  }

  handleFileChange = (e, selectedOptionValue, selectedOptionLabel) => {
    this.setState({
      isDocTypesModalOpen: false,
    });
    this.child.handleFileChange(e, selectedOptionValue, selectedOptionLabel);
  }

  onDisclaimerAcknowledged(){
    this.setState({isDisclaimerModalOpen: false, isDisclaimerAckowledged: true });
  }

  onBrowseClick = (e, selectedOptionValue, selectedOptionLabel) => {
    const { DOCUMENT_SUBCATEGORIES } = this.props;
    const {
      uploadFrontAndBack,
      uploadMultiple,
    } = this.props.docTypes.subCategory;
    const categoriesForMultipleUpload = uploadFrontAndBack || uploadMultiple;
    if (categoriesForMultipleUpload) {
      for (let validCategoryForMultipleUpload of categoriesForMultipleUpload) {
        const categoryValue =
          DOCUMENT_SUBCATEGORIES[validCategoryForMultipleUpload];
        // added label condition to avoid random value given to default selected docType in radioList
        if (categoryValue.value === selectedOptionValue || categoryValue.label === selectedOptionLabel) {
          e.preventDefault();
          this.setState({
            modalTitle: categoryValue.modalTitle,
            modalDescription: categoryValue.modalDescription,
            isDocTypesModalOpen: false,
            isMultipleUploadOpen: true,
            multipleUploaddocSubcategory: selectedOptionValue,
          });
          break;
        }
      }
    }
  };

  onMutipleUpload = (file, label) => {
    const params = new FormData();
    if (this.props.DOCUMENT_CATEGORIES['SERVICE_AGREEMENT'] === this.props.documentCategory) {
      params.append('kyc_document_type', 'Agreement');
      params.append('status', 'Counter Signed Received');
      params.append('active', true);
    }
    params.append('document_category_id', this.props.documentCategory);
    params.append('uploaded_documents', file);
    params.append('mid', this.props.mid);
    if (this.state.multipleUploaddocSubcategory) {
      params.append(
        'document_type_id',
        this.state.multipleUploaddocSubcategory,
      );
    }
    if (label) {
      // appending label in filename for filtering front & back of attachment
      params.append('uploaded_documents', file, `${label}_${file.name}`);
    }
    return this.props.onMultipleUpload(params);
  };

  onMergeDocumentSubmitSuccess = (...args) => {
    cleverTap.event(`${this.props.docTypes.clevertapEventName} upload success`, { mid: this.props.mid,
      type: this.state.docSubCategory });
    this.onFrontBackModalClose();
    if (this.isAgreement()) {
      sessionCheck(this.props.dispatch);
    }
  }

  onMergeDocumentSubmitFail = (error) => {
    cleverTap.event(`${this.props.docTypes.clevertapEventName} upload failed`,
      { mid: this.props.mid, error: Object.values(error).join(' '), type: this.state.docSubCategory });
  }

  onMergeDocuments = (...args) => this.props.mergeDocuments(...args);

  fetchMultipleDocPages = () => {
    return this.props.fetchMultipleDocPages({
      document_category_id: this.props.documentCategory,
      document_type_id: this.state.multipleUploaddocSubcategory,
    });
  };

  onOptionSelect = (selectedValue) => {
    this.setState({
      docSubCategory: selectedValue,
    });
    if(selectedValue == 'Aadhar Card' && !this.state.isDisclaimerAckowledged){
      this.setState({isDisclaimerModalOpen: true, isDisclaimerAckowledged: true});
    }
    cleverTap.event(`${this.props.docTypes.clevertapEventName} type selected`, {
      Type: selectedValue, mid: this.props.mid,
    });
  }

  onRemoveDoc(instance, { isInit = false, cancel = false }) {
    const eventState = isInit
      ? 'Initiated'
      : cancel ? 'cancel clicked' : 'successful';
    const subCategory = this.radioList.find(
      subCategory => subCategory.value == instance.documentType,
    );
    cleverTap.event(`Remove document ${eventState}`, {
      DocumentName: this.props.docTypes.clevertapEventName,
      DocumentType: subCategory ? subCategory.label : null,
      mid: this.props.mid,
    });
  }

  isDocRejected = () => {
    return (
      this.props.documentObj &&
      this.props.documentObj.status ===
      INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_REJECTED
    );
  }

  isDocVerified = () => {
    return (
      this.props.documentObj &&
      this.props.documentObj.status ===
      INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED
    );
  }

  getErrorDescription = () => {
    return this.isDocRejected() ? (
      <div className="doc-error-description">
        <strong>Error</strong>: {this.props.documentObj.error}
      </div>
    ) : (
        ''
      );
  }

  onDownloadAttachment = () => {
    if(this.props.docTypes.clevertapEventName === 'Service agreement')
      setItemToCompleted('agreement_downloaded'); // mark in localstorage
    cleverTap.event(
      `${this.props.docTypes.clevertapEventName} download clicked`,
      { mid: this.props.mid }
    );
  }

  getLabel(label) {
    const nameOnPanTemplate = '${nameOnPanCard}';
    const holderNameTemplate = '${holderName}';
    const { holder_name: holderName } = this.context.merchant.bank_detail || {};
    const { pancard_name: nameOnPanCard } = this.context.merchant;
    label = label.replace(nameOnPanTemplate, capitalize(nameOnPanCard));
    label = label.replace(holderNameTemplate, capitalize(holderName));
    return label;
  }

  isAgreement() {
    const { documentCategory: documentCategoryId, DOCUMENT_CATEGORIES } =  this.props;
    return documentCategoryId && documentCategoryId === DOCUMENT_CATEGORIES.SERVICE_AGREEMENT;
  }

  getUploadButtonText = () => {
    if (this.props.uploadBttnText) {
      return this.props.uploadBttnText;
    } else if (this.isAgreement()) {
      return 'ACCEPT';
    }
    return this.isDocRejected() ? 'REUPLOAD' : 'UPLOAD';
  }

  getDescriptionText = () => {
    const { docTypes, descriptionText, uploadBttnText } = this.props;
    if ((!descriptionText && uploadBttnText) || this.isAgreement()) {
      return '';
    }
    return `File size should be less than ${docTypes.maxFileSizeLimit}MB`;
  }

  onFrontBackModalClose = () => this.setState({ isMultipleUploadOpen: false });

  render() {
    const { intiateOldFlow } = this.context;
    const { docTypes, uploadBttnText, monthlyExpectedVolume, documentCategory, generatedServiceAgreements } = this.props;
    const documentObj = this.state.isDocTypesModalOpen ? null : (this.props.documentObj || generatedServiceAgreements[documentCategory]);
    const conditionalProps = {};
    let modal;
    let tooltip;
    let attachmentLink;
    let serviceAgreementModal;
    let disclaimerModal;

    if (docTypes.subCategory) {
      conditionalProps.onButtonClick = this.openDocTypesModal;
      disclaimerModal = (
        <GenericModal
          isOpen={this.state.isDisclaimerModalOpen}
          contentClassName="upload-address-proof-modal text-center"
          wrapClassName="confirmation-modal"
          backdrop={false}
          fade={false}
          isCloseDisabled={true}

        >
        <div className="cancel-prompt mt-15">
           <div className="pl-r-30 cancel-prompt__heading">
             <p>Please hide some characters of your Aadhar Card before uploading</p>
           </div>
            <button className="dflt-btn" onClick={this.onDisclaimerAcknowledged}>OK</button>
        </div>
        </GenericModal>
    );
      modal = (
        <GenericRadioListModal
          modalTitle={docTypes.subCategory.modalTitle}
          isOpen={this.state.isDocTypesModalOpen}
          onClose={this.onDocTypesModalClose}
          radioList={this.radioList}
          onInputChange={this.handleFileChange}
          onOptionSelect={this.onOptionSelect}
          onClick={this.onBrowseClick}
        />
      );
      if (docTypes.subCategory.uploadFrontAndBack) {
        modal = (
          <div>
            {modal}
            <UploadFrontAndBackDoc
              onMount={this.fetchMultipleDocPages}
              onValidate={this.validateFile}
              modalTitle={this.state.modalTitle}
              onUpload={this.onMutipleUpload}
              frontDocName={docTypes.subCategory.frontDocName}
              backDocName={docTypes.subCategory.backDocName}
              bacDocOptional={docTypes.subCategory.backDocOptional}
              onRemove={this.props.onMultipleRemove}
              mergeDocuments={this.onMergeDocuments}
              description={this.state.modalDescription}
              isOpen={this.state.isMultipleUploadOpen}
              onClose={this.onFrontBackModalClose}
              onMergeDocumentSubmitSuccess={this.onMergeDocumentSubmitSuccess}
              onMergeDocumentSubmitFail={this.onMergeDocumentSubmitFail}
            />
          </div>
        );
      } else if (docTypes.subCategory.uploadMultiple) {
        modal = (
          <div>
            {modal}
            <UploadMultipleDocsModal
              onMount={this.fetchMultipleDocPages}
              onValidate={this.validateFile}
              onUpload={this.onMutipleUpload}
              onRemove={this.props.onMultipleRemove}
              mergeDocuments={this.onMergeDocuments}
              modalTitle={this.state.modalTitle}
              description={this.state.modalDescription}
              isOpen={this.state.isMultipleUploadOpen}
              onClose={this.onFrontBackModalClose}
              onMergeDocumentSubmitSuccess={this.onMergeDocumentSubmitSuccess}
              onMergeDocumentSubmitFail={this.onMergeDocumentSubmitFail}
            />
          </div>
        );
      }
    } else if (docTypes.uploadMultiple && monthlyExpectedVolume) {
      conditionalProps.onButtonClick = () =>
        this.setState({ isMultipleUploadOpen: true });
      modal = (
        <div>
          {modal}
          <UploadMultipleDocsModal
            onMount={this.fetchMultipleDocPages}
            mergeDocuments={this.onMergeDocuments}
            onValidate={this.validateFile}
            onUpload={this.onMutipleUpload}
            onRemove={this.props.onMultipleRemove}
            modalTitle={docTypes.multipleUploadModalTitle}
            description={docTypes.multipleUploadModalDescription}
            isOpen={this.state.isMultipleUploadOpen}
            onClose={this.onFrontBackModalClose}
            onMergeDocumentSubmitSuccess={this.onMergeDocumentSubmitSuccess}
            onMergeDocumentSubmitFail={this.onMergeDocumentSubmitFail}
          />
        </div>
      );
    }

    if (uploadBttnText && !intiateOldFlow) {
      conditionalProps.onButtonClick = this.openAgreementPopUp;
      serviceAgreementModal =
         checkPermissionOnElement(PERMISSIONS_LIST.UPDATE_USER_PROFILE) ?
          <ServiceAgreementModal
            isOpen={this.state.isOpen}
            onClose={this.onAgreementPopUpClose}
            agreementLink={this.props.agreementLink}
            businessFilingStatus={this.props.businessFilingStatus}
            generateServiceAgreement={this.props.generateServiceAgreement}
            generatedServiceAgreement={this.props.generatedServiceAgreement}
            {...this.props}
          /> : <NoAccessAgreementModal isOpen={this.state.isOpen} onClose={this.onAgreementPopUpClose} ></NoAccessAgreementModal>
      ;
    }
    if (docTypes.tooltipText) {
      tooltip = (
        <PaperTooltip content={docTypes.tooltipText}>
          <a className="tooltip-anchor pl-4">View accepted proofs</a>
        </PaperTooltip>
      );
    }
    if (docTypes.attachment || this.props.agreementLink) {
      const props = {
        target: '_blank',
        onClick: this.onDownloadAttachment,
      };
      let text;
      if (docTypes.attachment) {
        props.href = docTypes.attachment;
        text = 'Download Format';
      }
      if (this.props.agreementLink) {
        props.href = this.props.agreementLink.processed_document.path;
        text = 'Download Agreement';
      }
      attachmentLink = (
        <div className="d-flex">
          <a {...props}>{text}</a>
          <span className="download-circle-icon">
            <DownloadCircleIcon />
          </span>
        </div>
      );
    }
    if (this.props.DOCUMENT_CATEGORIES['SERVICE_AGREEMENT'] === this.props.documentCategory && !this.props.agreementLink) {
      tooltip = this.props.isUrlsFilled && this.props.isUrlsLive
        ? <PaperTooltip content={
          <div>
            Agreement Generation is in progress.<br/>
            It will be generated shortly.
          </div>
        }>
          <a className="tooltip-anchor">Agreement will be generated shortly.</a>
        </PaperTooltip>
        : <PaperTooltip content={
          <div>
            Website URLs are required for agreement generation.<br/>
            Please submit your website URLs.
          </div>
          }>
          <a className="tooltip-anchor">Please submit website details</a>
        </PaperTooltip>;
    }
    return (
      <div className="upload-list-item">
        <div className="left-sec">
          {this.props.counter}. {this.getLabel(docTypes.label)}
          {this.getErrorDescription()}
          {tooltip}
          <div className="download-attachment"> {attachmentLink} </div>
        </div>
        <div className="right-sec">
          <FileUploader
            canUpdate={ checkPermissionOnElement(PERMISSIONS_LIST.UPDATE_USER_PROFILE)  || checkPermissionOnElement(PERMISSIONS_LIST.RESTRICT_PERMISSION)}
            description={this.getDescriptionText()}
            ref={(instance) => {
              this.child = instance;
            }}
            intiateOldFlow={intiateOldFlow}
            onValidate={this.validateFile}
            onUpload={this.props.onUpload}
            onRemove={this.props.onRemove}
            documentCategory={this.props.documentCategory}
            disabled={this.props.disabled}
            fileObject={documentObj}
            openFirstTimeHelpModal={() =>
              this.props.openFirstTimeHelpModal(this.props.counter)
            }
            onUploadInit={() =>
              cleverTap.event(`${docTypes.clevertapEventName} upload initiated`, { mid: this.props.mid })
            }
            onUploadSuccess={params =>
              cleverTap.event(
                CLEVERTAP_EVENTS_MAP.DOCUMENT_UPLOADED,
                {
                  MID: this.props.mid,
                  'Document name': docTypes.clevertapEventName,
                  Platform: 'PayU Web',
                  'Doc category': ""
                },
                params,
              )
            }
            onUploadError={params =>
              cleverTap.event(
                `${docTypes.clevertapEventName} upload CTA error`,
                params,
              )
            }
            onRemoveInit={params => this.onRemoveDoc(params, { isInit: true })}
            onRemoveSuccess={params => this.onRemoveDoc(params, this.radioList)}
            onRemoveCancel={params =>
              this.onRemoveDoc(params, { cancel: true })
            }
            isDocRejected={this.isDocRejected}
            isDocVerified={this.isDocVerified}
            uploadBtnText={this.getUploadButtonText()}
            hideRemoveButton={this.props.hideRemoveButton}
            {...conditionalProps}
          />
          {modal}
          {serviceAgreementModal}
        </div>
      </div>
    );
  }
}
