import React from 'react';

const Step2 = ({
  previous,
  currentStep,
  questions,
  answers,
  handleSelect,
  onConfirm
}) => {

  const validate = answers.length>1 && answers[1].selected_options.length;

  const renderOptions = () => {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <ul className="need-assessment-options-container">      
            {
              questions[1].assessment_options
              .filter((option) => option.category === answers[0].category)
              .map((option, i) => (
                <li
                  className={`${validate && answers[1].selected_options.includes(option.id) ? 'highlight' : ''}`}
                  key={`step-2-${i}`}
                  onClick={(e) => handleSelect(e, option.id)}
                >
                  <label className="custom-checkbox">
                    <div className="option">
                      <span className="title">{option.statement}</span>
                      <small className="sub-title">{option.label}</small>
                    </div>
                    <input type="checkbox" checked={validate && answers[1].selected_options.includes(option.id)}/>
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }


  if (currentStep !== 2) {
    return null;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 m-auto text-center assessment-step-1">
          <h3 className="sub-title step-2">What are you looking for from PayU?</h3>
          <h4 className="title step-2">Select all that applies</h4>
        </div>
      </div>
      {renderOptions()}
      <div className="d-flex justify-content-center w-100">
        <button onClick={previous} className="need-assessment-btn-confirm back">Go Back</button>
        <button
          onClick={validate ? onConfirm : () => {}}
          className={`need-assessment-btn-confirm${validate ? '' : ' disable'}`}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default Step2;