/*
 *
 * Merchant action types
 *
 */

export const FETCH_MERCHANT_LIST_INITIATED = 'FETCH_MERCHANT_LIST_INITIATED';
export const FETCH_MERCHANT_LIST_SUCCESS = 'FETCH_MERCHANT_LIST_SUCCESS';
export const FETCH_MERCHANT_LIST_FAILURE = 'FETCH_MERCHANT_LIST_FAILURE';

export const UPDATE_MERCHANT_DETAILS_INITIATED = 'UPDATE_MERCHANT_DETAILS_INITIATED';
export const UPDATE_MERCHANT_DETAILS_SUCCESS = 'UPDATE_MERCHANT_DETAILS_SUCCESS';
export const UPDATE_MERCHANT_DETAILS_FAILURE = 'UPDATE_MERCHANT_DETAILS_FAILURE';


export const FETCH_RESELLER_DETAILS_INITIATED = 'FETCH_RESELLER_DETAILS_INITIATED';
export const FETCH_RESELLER_DETAILS_SUCCESS = 'FETCH_RESELLER_DETAILS_SUCCESS';
export const FETCH_RESELLER_DETAILS_FAILURE = 'FETCH_RESELLER_DETAILS_FAILURE';

export const FETCH_BUSINESS_ENTITIES_INITIATED = 'FETCH_BUSINESS_ENTITIES_INITIATED';
export const FETCH_BUSINESS_ENTITIES_SUCCESS = 'FETCH_BUSINESS_ENTITIES_SUCCESS';
export const FETCH_BUSINESS_ENTITIES_FAILURE = 'FETCH_BUSINESS_ENTITIES_FAILURE';

export const FETCH_BUSINESS_CATEGORIES_INITIATED = 'FETCH_BUSINESS_CATEGORIES_INITIATED';
export const FETCH_BUSINESS_CATEGORIES_SUCCESS = 'FETCH_BUSINESS_CATEGORIES_SUCCESS';
export const FETCH_BUSINESS_CATEGORIES_FAILURE = 'FETCH_BUSINESS_CATEGORIES_FAILURE';

export const SEND_SMS_LINK_REQUEST_INITIATED = 'SEND_SMS_LINK_REQUEST_INITIATED';
export const SEND_SMS_LINK_REQUEST_SUCCESS = 'SEND_SMS_LINK_REQUEST_SUCCESS';
export const SEND_SMS_LINK_REQUEST_FAILURE = 'SEND_SMS_LINK_REQUEST_FAILURE';

export const SET_MERCHANTLIST_FILTER = 'SET_MERCHANTLIST_FILTER';
export const SET_APPLIED_FILTER = 'SET_APPLIED_FILTER';

export const FETCH_TIMELINE_STATUS_INITIATED = 'FETCH_TIMELINE_STATUS_INITIATED';
export const FETCH_TIMELINE_STATUS_SUCCESS = 'FETCH_TIMELINE_STATUS_SUCCESS';
export const FETCH_TIMELINE_STATUS_FAILED = 'FETCH_TIMELINE_STATUS_FAILED';
export const PROFILE_COMPLETED_FOR_TIMELINE = 'PROFILE_COMPLETED_FOR_TIMELINE';

export const FETCH_BANK_NAMES_FOR_IFSC_SUCCESS = 'FETCH_BANK_NAMES_FOR_IFSC_SUCCESS';
export const FETCH_BANK_CITIES_FOR_IFSC_SUCCESS = 'FETCH_BANK_CITIES_FOR_IFSC_SUCCESS';
export const FETCH_BANK_STATES_FOR_IFSC_SUCCESS = 'FETCH_BANK_STATES_FOR_IFSC_SUCCESS';
export const FETCH_BANK_BRANCHES_FOR_IFSC_SUCCESS = 'FETCH_BANK_BRANCHES_FOR_IFSC_SUCCESS';

export const FETCH_MERCHANT_PRICING_PLAN_INITIATED = 'FETCH_MERCHANT_PRICING_PLAN_INITIATED';
export const FETCH_MERCHANT_PRICING_PLAN_SUCCESS = 'FETCH_MERCHANT_PRICING_PLAN_SUCCESS';
export const FETCH_MERCHANT_PRICING_PLAN_FAILED = 'FETCH_MERCHANT_PRICING_PLAN_FAILED';

export const UPDATE_MERCHANT_PRICING_PLAN_INITIATED = 'UPDATE_MERCHANT_PRICING_PLAN_INITIATED';
export const UPDATE_MERCHANT_PRICING_PLAN_SUCCESS = 'UPDATE_MERCHANT_PRICING_PLAN_SUCCESS';
export const UPDATE_MERCHANT_PRICING_PLAN_FAILED = 'UPDATE_MERCHANT_PRICING_PLAN_FAILED';

export const FETCH_PRICING_PLANS_INITIATED = 'FETCH_PRICING_PLANS_INITIATED';
export const FETCH_PRICING_PLANS_SUCCESS = 'FETCH_PRICING_PLANS_SUCCESS';
export const FETCH_PRICING_PLANS_FAILURE = 'FETCH_PRICING_PLANS_FAILURE';

export const FETCH_MERCHANT_ACCOUNT_INITIATED = 'FETCH_MERCHANT_ACCOUNT_INITIATED';
export const FETCH_MERCHANT_ACCOUNT_SUCCESS = 'FETCH_MERCHANT_ACCOUNT_SUCCESS';
export const FETCH_MERCHANT_ACCOUNT_FAILURE = 'FETCH_MERCHANT_ACCOUNT_FAILURE';

export const UPDATE_MERCHANT_PRODUCT_SUCCESS = 'UPDATE_MERCHANT_PRODUCT_SUCCESS';

export const FETCH_ADDRESS_ADDENDUM_DETAILS_INITIATED = 'FETCH_ADDRESS_ADDENDUM_DETAILS_INITIATED';
export const FETCH_ADDRESS_ADDENDUM_DETAILS_SUCCESS = 'FETCH_ADDRESS_ADDENDUM_DETAILS_SUCCESS';
export const FETCH_ADDRESS_ADDENDUM_DETAILS_FAILURE = 'FETCH_ADDRESS_ADDENDUM_DETAILS_FAILURE';

export const FETCH_WEBSITE_ADDENDUM_DETAILS_INITIATED = 'FETCH_WEBSITE_ADDENDUM_DETAILS_INITIATED';
export const FETCH_WEBSITE_ADDENDUM_DETAILS_SUCCESS = 'FETCH_WEBSITE_ADDENDUM_DETAILS_SUCCESS';
export const FETCH_WEBSITE_ADDENDUM_DETAILS_FAILURE = 'FETCH_WEBSITE_ADDENDUM_DETAILS_FAILURE';

export const UPDATE_ADDRESS_DETAILS_INITIATED = 'UPDATE_ADDRESS_DETAILS_INITIATED';
export const UPDATE_ADDRESS_DETAILS_SUCCESS = 'UPDATE_ADDRESS_DETAILS_SUCCESS';
export const UPDATE_ADDRESS_DETAILS_FAILURE = 'UPDATE_ADDRESS_DETAILS_FAILURE';
export const ADD_ACL_ROLE_FAILURE = 'ADD_ACL_ROLE_FAILURE',
  ADD_ACL_ROLE_INITIATED = 'ADD_ACL_ROLE_INITIATED',
  ADD_ACL_ROLE_SUCCESS = 'ADD_ACL_ROLE_SUCCESS',
  CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES',
  DELELTE_ROLE_FAILURE = 'DELELTE_ROLE_FAILURE',
  DELETE_ROLE_INITIATED = 'DELETE_ROLE_INITIATED',
  DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS',
  FETCH_ACL_PERMISSIONS_INITIATED = 'FETCH_ACL_PERMISSIONS',
  FETCH_ACL_PERMISSIONS_FAILURE = 'FETCH_ACL_PERMISSIONS_FAILURE',
  FETCH_ACL_PERMISSIONS_SUCCESS = 'FETCH_ACL_PERMISSIONS_SUCCESS',
  FETCH_OWNED_ACL_ROLES = 'FETCH_OWNED_ACL_ROLES',
  FETCH_OWNED_ACL_ROLES_FAILURE = 'FETCH_OWNER_ACL_ROLES_FAILURE',
  FETCH_OWNED_ACL_ROLES_SUCCESS = 'FETCH_OWNED_ACL_ROLES_SUCCESS',
  MOVE_INVITES_TO_NEW_ROLE_FAILURE = 'MOVE_INVITES_TO_NEW_ROLE_FAILURE',
  MOVE_INVITES_TO_NEW_ROLE_INITIATED = 'MOVE_INVITES_TO_NEW_ROLE_INITIATED',
  MOVE_INVITES_TO_NEW_ROLE_SUCCESS = 'MOVE_INVITES_TO_NEW_ROLE_SUCCESS',
  UPDATE_ACL_ROLE_FAILURE = 'UPDATE_ACL_ROLE_FAILURE',
  UPDATE_ACL_ROLE_INITIATED = 'UPDATE_ACL_ROLE_INITIATED',
  ROLE_FORM_CLOSED = 'ROLE_FORM_CLOSED',
  UPDATE_ACL_ROLE_SUCCESS = 'UPDATE_ACL_ROLE_SUCCESS';
