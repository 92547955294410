import React, { useState, useEffect } from 'react';
import { logoutUser } from '../../../packs/logout_redirect_service';

import NotFound from '@components/BaseComponents/NotFound';
import { SpinLoader } from 'deltoid/core/Loaders';

const ServiceLogout = () => {
  const [errType, setErrType] = useState(false);
  useEffect(() => {
    logoutUser((v) => {
      console.log(v);
      setErrType(v);
    });
  }, []);

  const CONTENT = {
    'code-break': {
      heading: '400',
      subHeading: 'Something went wrong.',
    },
  };

  return (
    <>
    {
      !errType ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <SpinLoader />
        </div>
      ) : (
        <NotFound goBack={{ redirectTo: '/app' }} content={{...CONTENT[errType]}}/>
      )
    }
    </>
  )
};

export default ServiceLogout;
