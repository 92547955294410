import React from 'react';
import PropTypes from 'prop-types';


export default class OtpInput extends React.PureComponent {
  static propTypes = {
    length: PropTypes.number,
    wrapperClassName: PropTypes.string,
    onOtpFilled: PropTypes.func,
    onOtpNotFilled: PropTypes.func,
  };

  static defaultProps = {
    wrapperClassName: '',
    length: 4,
    onOtpFilled: () => {},
    onOtpNotFilled: () => {},
  };

  getValue() {
    return this.inputElements.reduce((value, el) => value + el.value, '');
  }

  inputElements = [];

  isOtpFilled() {
    return this.getValue().length === this.props.length;
  }

  autoTab = (e) => {
    if (this.isOtpFilled()) {
      this.props.onOtpFilled(true);
    } else {
      this.props.onOtpNotFilled(false);
    }
    const isBackspaceKey = e.keyCode === 8;
    const { previousSibling, nextSibling } = e.target;
    if (previousSibling && isBackspaceKey) {
      previousSibling.value = ''; // empty previous input value on backspace
      previousSibling.focus();
    } else if (nextSibling && !isBackspaceKey) {
      nextSibling.focus();
    }
  }

  render() {
    const { length, wrapperClassName } = this.props;
    this.inputElements = []
    return (
      <div className={wrapperClassName}>
        {[...Array(length)].map((_, index) => (
            React.Children.toArray(<input
              ref={el => {
                if (el) this.inputElements.push(el);
              }}
              type="text"
              name={`otp${index}`}
              maxLength="1"
              onKeyUp={this.autoTab}
              className="otp-input"
              autoComplete="off"
            />)
          ))}
      </div>
    );
  }
}
