/*
 *
 * Team Account action types
 *
 */

export const FETCH_EMPLOYEE_LIST_INITIATED = 'FETCH_EMPLOYEE_LIST_INITIATED';
export const FETCH_EMPLOYEE_LIST_SUCCESS = 'FETCH_EMPLOYEE_LIST_SUCCESS';
export const FETCH_EMPLOYEE_LIST_FAILURE = 'FETCH_EMPLOYEE_LIST_FAILURE';

export const CREATE_EMPLOYEE_INITIATED = 'CREATE_EMPLOYEE_INITIATED';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const ACTIVATE_EMPLOYEE_UPDATE = 'ACTIVATE_EMPLOYEE_UPDATE';
export const UPDATE_EMPLOYEE_INITIATED = 'UPDATE_EMPLOYEE_INITIATED';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_INITIATED = 'DELETE_EMPLOYEE_INITIATED';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const FETCH_ROLE_LIST_INITIATED = 'FETCH_ROLE_LIST_INITIATED';
export const FETCH_ROLE_LIST_SUCCESS = 'FETCH_ROLE_LIST_SUCCESS';
export const FETCH_ROLE_LIST_FAILURE = 'FETCH_ROLE_LIST_FAILURE';

export const SET_CURRENT_EMPLOYEE = 'SET_CURRENT_EMPLOYEE';
export const UNSET_CURRENT_EMPLOYEE = 'UNSET_CURRENT_EMPLOYEE';
