import React from 'react'
import { config } from 'hub-redux-auth';
import BankAccountVerification from '../../components/BankAccountVerification';
import PaymentLogo from '@assets/images/payments.png';
import BrandLogo from '@assets/images/payunow-brand-logo-red.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sessionCheck } from '../../actions/auth';
import AuthManager from '../../services/AuthManager';

function mapStateToProps(state) {
  return {
    user: state.session.user || {},
    product: state.product,
  }
}

@connect(mapStateToProps)
export default class PennyDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    sessionCheck(dispatch);
  }

  componentWillMount() {
    if(this.props.product != 'NOW') {
      this.props.history.push('/onboarding');
    }
  }

	componentDidMount() {
		window.scrollTo(0, 0);
  }
  
	render() {
    const { user } = this.props;
		return (
      <div className="bank-details-flow">
        <div className="wave-content" />
        <div className="page-container first-section">
          <div className="page-container-inner">
            <header className="main-topheader colorize">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 clearfix">
                    <a title="PayUmoney" className="main-logo" href={`${config.get('productHost')}?authToken=${AuthManager.getFullToken()}&merchantId=${user.mid}`}>
                      <BrandLogo width="95" height="27" />
                    </a>
                    <a className='clickable-txt noselect logout-text' href='/account/service_logout' >LOGOUT</a>
                  </div>
                </div>
              </div>
            </header>
            <div className="container">
              <div className="profile-setting-container now-flow">
                <BankAccountVerification
                  user={this.props.user}
                  bankFlow={true}
                  history={this.props.history}
                  authParams={`authToken=${AuthManager.getFullToken()}&merchantId=${user.mid}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center m-0 mt-4 pl-2 pr-2 pb-3">
          <div className="col-md-5 col-sm-5">
            <img src={PaymentLogo} alt="payment-logo" className="img-fluid" />
          </div>
        </div>
      </div>
		);
	}
}

PennyDetails.proptypes = {
  loadingText: PropTypes.string,
  subText: PropTypes.string,
}

PennyDetails.defaultProps = {
  loadingText: 'Just wait for a moment please...',
  subText: '',
  loadingContent: <div className="circular-loader"/>
}