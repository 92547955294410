/*
 *website Page Constants
 */
export const FETCH_WEBSITE_DETAIL_FAILURE = 'FETCH_WEBSITE_DETAIL_FAILURE',
FETCH_WEBSITE_DETAIL_INITIATED = 'FETCH_WEBSITE_DETAIL_INITIATED',
FETCH_WEBSITE_DETAIL_SUCCESS = 'FETCH_WEBSITE_DETAIL_SUCCESS',
WEBSITE_DETAIL_SUBMITTED_FAILURE = 'WEBSITE_DETAIL_SUBMITTED_FAILURE',
WEBSITE_DETAIL_SUBMITTED_INITIATED = 'WEBSITE_DETAIL_SUBMITTED_INITIATED',
WEBSITE_DETAIL_SUBMITTED_SUCCESS = 'WEBSITE_DETAIL_SUBMITTED_SUCCESS',
WEBSITE_DOCUMENT_SUBMITTED_FAILURE = 'WEBSITE_DOCUMENT_SUBMITTED_FAILURE',
WEBSITE_DOCUMENT_SUBMITTED_INITIATED = 'WEBSITE_DOCUMENT_SUBMITTED_INITIATED',
WEBSITE_DOCUMENT_SUBMITTED_SUCCESS = 'WEBSITE_DOCUMENT_SUBMITTED_SUCCESS';
