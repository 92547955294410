import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

//  Action types
export const actionTypes = {
  fetch: 'timeline/FETCH_INITIATED',
  fetchSuccess: 'timeline/FETCH_SUCCESS',
  fetchFailed: 'timeline/FETCH_FAILED',
  updateRefreshSignal: 'refreshSignal/UPDATE',
  updateTimelineStatus: 'timeline/UPDATE_DATA',
};

const initialState = {
  response: null,
  refreshSignal: false,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchSuccess:
    case actionTypes.updateTimelineStatus:
      return { response: action.payload };
    case actionTypes.fetchFailed:
      return initialState;
    case actionTypes.updateRefreshSignal:
      return { ...state, refreshSignal: !state.refreshSignal };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getTimelineStatus: (state) => (
    state.onboardingApp.timeline.response
    && state.onboardingApp.timeline.response?.timeline_status
  ),
  timelineRefreshSignal: (state) => state.onboardingApp.timeline.refreshSignal,
  getMcpTicketId : (state) => state.onboardingApp.timeline.response?.cross_sell_status?.mcp_status?.ticket_id,
};

//  Actions
export const actions = {
 fetch: () => ({ type: actionTypes.fetch }),
 updateRefreshSignal: () => ({ type: actionTypes.updateRefreshSignal }),
 updateTimelineStatus: (response) => ({ type: actionTypes.updateTimelineStatus, payload: response})
};

// Workers
function* fetchWorker() {
  const { mid } = AuthManager.getUser() || {};
  try {
    const response = yield call(
      [axios, "get"],
      API_ENDPOINTS.TIMELINE(AuthManager.getMerchantUUID()),
      { headers: { mid: mid } },
    ) || { data: {} };
    yield put({ type: actionTypes.fetchSuccess, payload: response?.data });
  } catch (err) {
    yield put({ type: actionTypes.fetchFailed });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchWorker);
}
