/* eslint-disable max-len */
/* eslint-disable camelcase */
import { takeLatest, call, put } from "redux-saga/effects";

import API_ENDPOINTS from "@services/apiConfig";
import AuthManager from "@services/AuthManager";
import axios from "axios";
import { toastActions, productAccountActions } from '.';

//  Action types
export const actionTypes = {
  createTicket: "createMCPTicket/INITIATE",
  createTicketFailed: "createMCPTicketFailed/CREATE_TICKET_FAILED",
  createTicketFulfilled: "createMCPTicketFulfilled/CREATE_TICKET_FULFILLED",
};

const initialState = {
  fetching: false,
  fetched: false,
  response: null,
  errResponse: null,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchPlans: {
      return {
        ...state,
        fetching: true ,
      };
    }
    case actionTypes.fetchPlansFulfilled: {
      return {
        ...state,
          fetching: false,
          fetched: true,
          response: action.payload,
      };
    }
    case actionTypes.fetchPlansFailed: {
      return {
        ...state,
          fetching: false,
          fetched: true,
          errResponse: action.payload,
      };
    }
    case actionTypes.createTicketFulfilled: {
      console.log(action.payload,"payload action ")
      return {
        ...state,
          fetching: false,
          fetched: true,
          response: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  createTicketFetching: (state) => state.onboardingApp.mcpTicket.fetching,
  createTicketFetched: (state) => state.onboardingApp.mcpTicket.fetched,
  response:(state) => state.onboardingApp.mcpTicket.response,
};

//  Actions
export const actions = {
  createMcpTicket: (data) => ({
    type: actionTypes.createTicket,
    payload: data,
  }),
  createTicketFailed: (data) => ({
    type: actionTypes.createTicketFailed,
    payload: data,
  }),
  createTicketFulfilled: (data) => ({
    type: actionTypes.createTicketFulfilled,
    payload: data,
  }),
};

//  Workers
function* createTicketWorker() {
  try {
    const response = yield call(
      [axios, "post"],
      API_ENDPOINTS.CROSS_SELL.INTNL_PYMNT.CREATE_MCP_TICKET(
        AuthManager.getMerchantUUID()
      ),
      {
        platform: "PayUbiz",
        merchant_id: AuthManager.getMID(),
      }
    );
    yield put({
      type: actionTypes.createTicketFulfilled,
      payload: response?.data,
    });
    yield put (productAccountActions.fetch())
  } catch (error) {
    // console.error('Error in createTicketWorker:', error);
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Warning',
      content: 'Error while creating MCP ticket!',
    }))
    console.log('')
    yield put({ type: actionTypes.createTicketFailed, payload: error?.response?.data });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.createTicket, createTicketWorker);
}
