import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { classNames } from '../../../services/utils';


const GenericForm = (props) => {
  const {
    buttonClass,
    formClassName,
    handleSubmit,
    inactive,
    invalid,
    showLoader,
    wrapperClassName
  } = props;

  const skipBtnClass = classNames({
    'is-loading': showLoader && props.skipped,
    'cmn-btn': true
  });

  const btnClass = classNames({
    'dflt-btn': true,
    inactive: (!showLoader || props.skipped) && invalid || inactive,
    'is-loading': showLoader && !props.skipped
  });

  const btnClassName = [
buttonClass,
btnClass
]
  return (
    <div className={wrapperClassName}>
      <Form onSubmit={handleSubmit} className={formClassName}>
        {props.children}
        {props.formStage === 2
          ? <div className="text-center">
            <Button
              onClick={
             props.onSkip}
              className={skipBtnClass}
            >
              Skip
            </Button>
            <Button
              type="submit"
              className={btnClass}
            >
              Submit
            </Button>
          </div>
          : <div className="text-center">
            <Button id={props.form} type="submit" className={btnClassName.join(' ')} >
              {props.btnText}
            </Button>
          </div>}
      </Form>
    </div>
  );
};
GenericForm.propTypes = {
  btnText: PropTypes.string,
  buttonClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  form: PropTypes.string,
  formClassName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  inactive: PropTypes.bool,
  // redux form prop tells about whether form is valid or not
  invalid: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  wrapperClassName: PropTypes.string
};

GenericForm.defaultProps = {
  buttonClass: '',
  formClassName: '',
  showLoader: false,
  btnText: '',
  wrapperClassName: '',
  inactive: false
};

export default reduxForm({})(GenericForm);
