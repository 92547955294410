import React from "react";
import { config } from 'hub-redux-auth';
import PaymentLogo from '@assets/images/payments.png';
import BrandLogo from '@assets/images/payunow-brand-logo-red.svg';
import PropTypes from 'prop-types';

export default class LoadingPage extends React.PureComponent {
	componentDidMount() {
		// scroll to top
		window.scrollTo(0, 0);
	}

	splitText() {
		const { loadingText } = this.props
		const lines = loadingText.split('\n');
		return lines.map((line, index) => (<div key={index} className='inherit-font'>{line}</div>))
	}

	render() {
		return (
      <div className="bank-details-flow">
        <div className="wave-content" />
        <div className="page-container first-section">
          <div className="page-container-inner">
            <header className="main-topheader colorize">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 clearfix">
                    <a title="PayUmoney" className="main-logo" href={config.get('productHost') + this.props.authParams}>
                      <BrandLogo width="95" height="27" />
                    </a>
                    <a className='clickable-txt noselect logout-text' href='/account/service_logout' >LOGOUT</a>
                  </div>
                </div>
              </div>
            </header>
            <div className="container">
              <h1 className="text-center">{this.splitText()}</h1>
              {this.props.subText && <h2 className="text-center">
							{this.props.subText}
              </h2>}
              <div className="padding-bottom-30pc">
							{this.props.loadingContent}
						  </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center m-0 mt-4 pl-2 pr-2 pb-3">
          <div className="col-md-5 col-sm-5">
            <img src={PaymentLogo} alt="payment-logo" className="img-fluid" />
          </div>
        </div>
      </div>
		);
	}
}

LoadingPage.proptypes = {
  loadingText: PropTypes.string,
  subText: PropTypes.string,
}

LoadingPage.defaultProps = {
  loadingText: 'Just wait for a moment please...',
  subText: '',
  loadingContent: <div className="circular-loader"/>
}