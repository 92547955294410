import { takeLatest, call, put, select } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';
import AuthManager from '@services/AuthManager';
import { DOES_NOT_HAVE_OCR } from '@constants/bankConstants';

import {
  dialogActions, productAccountSelectors,
  productAccountActions, stepSelectors,
  otpActions, toastActions,
  uiActions,
} from '.';
import IDs from '@constants/componentIds';
import { stepCompletionGTMEvent } from '@services/gtm';

//  Action types
export const actionTypes = {
  submitBankDetails: 'submitBankDetails/INITIATE',
  submitBankDetailsSuccess: 'submitBankDetails/SUCCESS',
  submitBankDetailsFailed: 'submitBankDetails/Failed',
  saveUpdateValues: 'saveUpdateValue/BANK',
  updateBankDetails: 'updateBankDetails/INITIATE',
  uploadBankProof: 'uploadBankProof/INITIATE',
  uploadBankProofSuccess: 'uploadBankProof/SUCCESS',
  uploadBankProofFailed: 'uploadBankProof/FAILED',
  resetUploadBankProof: 'uploadBankProof/RESET',
};

const initialState = {
  update: {},
  uploadProof: {
    processing: false,
    response: {},
    errMsg: null,
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.saveUpdateValues:
      return { ...state, update: action.payload };
    case actionTypes.uploadBankProof:
      return { ...state, uploadProof: { ...initialState.uploadProof, processing: true } };
  case actionTypes.uploadBankProofSuccess:
  case actionTypes.uploadBankProofFailed:
    return { ...state, uploadProof: { ...initialState.uploadProof, processing: false } };
  case actionTypes.resetUploadBankProof:
    return { ...state, uploadProof: { ...initialState.uploadProof } }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getUpdateValues: (state) => state.onboardingApp.bankDetails.update,
  getUploadProcessing: (state) => state.onboardingApp.bankDetails.uploadProof.processing,
};


//  Actions
export const actions = {
  submitBankDetails: (bank_account_number, ifsc_code, holder_name) => (
    { type: actionTypes.submitBankDetails, payload: { bank_account_number, ifsc_code, holder_name } }
  ),
  uploadBankProof: (formData, docType) =>
    ({ type: actionTypes.uploadBankProof, payload: { formData, docType } }),
  resetUploadBankProof: () => ({ type: actionTypes.resetUploadBankProof }),
  saveUpdateValues: (payload) => ({ type: actionTypes.saveUpdateValues, payload }),
};

function* submitBankDetailsWorker(action) {
  const { payload } = action;
  try {
    const productAccountDetails = yield select(productAccountSelectors.getProductAccountDetails);
    const { filled } = stepSelectors.getStepProps(productAccountDetails, IDs.bankDetails.default);
    if (filled) {
      yield put({ type: actionTypes.saveUpdateValues, payload });
      const { bank_detail: { bank_account_number, ifsc_code, holder_name } } = productAccountDetails;
      if (
        holder_name === payload.holder_name
        && bank_account_number === payload.bank_account_number
        && ifsc_code === payload.ifsc_code
      ) {
        yield put(toastActions.show({
          variant: 'warning',
          title: 'Warning',
          content: 'Bank details cannot be same as previous one.',
        }));
      } else {
        yield put(otpActions.sendOTP(
          ['email', 'sms'],
          productAccountDetails.email ? productAccountDetails.email : '',
          'update_bank_details',
          'SignIn',
          'verify-otp-bank'
        ));
      }
    } else {
      yield put(dialogActions.open('bank-ws-loader'));
      const response = yield call(
        [axios, 'put'],
        API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
        { product_account: { bank_detail: { ...payload } } },
      );
      const { product_account } = response.data || { product_account: {} };
      yield put(productAccountActions.updateProductAccountDetails(product_account));
      stepCompletionGTMEvent({ stepNo: '3', stepName: 'bank_details_submitted' });
    }
  } catch (err) {
    yield put(dialogActions.close());
    const errResponse = yield err.response;
    if (errResponse?.status === 422 ) {
      yield put(toastActions.show({
        variant: 'error',
        title: 'Error',
        content:
          errResponse?.data?.errors?.bank_account?.[0] ||
          errResponse?.data?.errors?.bank_details?.[0] ||
          errResponse?.data?.errors?.['bank_detail.bank_account_number']?.[0] ||
          'Something went wrong please try after sometime',
      }));
    } else {
      yield put(toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Something went wrong please try after sometime',
      }));
    }
  }
}

function* updateBankDetailsWorker(action) {
  const { payload: { access_token } } = action;
  try {
    const formData = yield select(selectors.getUpdateValues);
    yield put(dialogActions.open('bank-ws-loader'));
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.UPDATE_BANK_DETAILS(AuthManager.getMerchantUUID()),
      { bank_detail: { ...formData } },
      { token: access_token },
    );
    const { product_account } = response.data || { product_account: {} };
    yield put(productAccountActions.updateProductAccountDetails(product_account));
  } catch (err) {
    console.log(err)
    yield put(dialogActions.close());
    const errResponse = yield err.response;
    if (errResponse.status === 422 ) {
      yield put(toastActions.show({
        variant: 'error',
        title: 'Error',
        content:
          errResponse?.data?.bank_account?.[0] ||
          errResponse?.data?.bank_details?.[0] ||
          errResponse?.data?.bank_account_number?.[0] ||
          'Something went wrong please try after sometime',
      }));
    } else {
      yield put(toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Something went wrong please try after sometime',
      }));
    }
  }
}

function* uploadBankProofWorker(action) {
  const { payload: { formData, docType } } = action;
  const productAccountDetails = yield select(productAccountSelectors.getProductAccountDetails);
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.UPLOAD_BANK_PROOF(AuthManager.getMerchantUUID()),
      formData,
    );
    if (DOES_NOT_HAVE_OCR.includes(docType)) {
      yield put(dialogActions.close(actionTypes.uploadBankProofSuccess));
      yield put(uiActions.loadUI({
        bank_detail: { ...productAccountDetails.bank_detail, bank_verification_status: 'Pending', bank_verification_proof: docType }
      }));
      yield put(toastActions.show({
        variant: 'warning',
        title: 'Proof Submitted sucessfully!',
        content: 'Bank details proof received. We will verify it within the next 24-48 hours.',
      }))
    }
  } catch (err) {
    const errResponse = yield err.response;
    yield put({ type: actionTypes.uploadBankProofFailed });
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: errResponse?.data?.error || 'Something went wrong please try after sometime',
    }));
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.submitBankDetails, submitBankDetailsWorker);
  yield takeLatest(actionTypes.updateBankDetails, updateBankDetailsWorker);
  yield takeLatest(actionTypes.uploadBankProof, uploadBankProofWorker);
}
