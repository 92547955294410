//  Action types
export const actionTypes = {
  show: 'toast/SHOW',
  hide: 'toast/HIDE',
};

const initialState = {
  show: false,
  props: {
    title: 'Success',
    content: 'Thanks bruh!',
    variant: 'success',
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.show: {
      return {
        show: true,
        props: action.payload,
      }
    }
    case actionTypes.hide: {
      return {
        ...state,
        show: false,
      }
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  show: state => state.onboardingApp.toast.show,
  getProps: state => state.onboardingApp.toast.props,
};

//  Actions
export const actions = {
  show: toastProps => ({ type: actionTypes.show, payload: toastProps }),
  hide: () => ({ type: actionTypes.hide }),
};
