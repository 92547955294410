import React from "react";
import GenericModal from "../BaseComponents/GenericModal";
import PropTypes from "prop-types";

class NoAccessAgreementModal extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    buttonText: 'Ok, got it!',
    isOpen: true,
    modalText: 'Please ask the merchant to digitally Sign the service agreement'
  }

  render () {
    const { isOpen, onClose } = this.props;
    return (
      <GenericModal
        isOpen={ isOpen }
        isCloseDisabled={ true }
        contentClassName="no-access-modal-content"
        modalClassName="no-access-modal-container">
        <div className="no-access-modal-wrapper">
          <div className="no-access-text-wrapper">
            <p>
              {this.state.modalText}
            </p>
          </div>
          <div>
            <button className="cmn-btn" onClick={ onClose }> { this.state.buttonText } </button>
          </div>
        </div>
      </GenericModal>
    );
  }
}
NoAccessAgreementModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}
NoAccessAgreementModal.defaultProps = {
  isOpen: false
}

export default NoAccessAgreementModal;
