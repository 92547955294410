import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

import PaperTooltip from '../PaperTooltip';
import FilterDropdown from '../../Dropdown/FilterDropdown';

export default function GenericFieldField(props) {
  const {
    input,
    label,
    hint,
    labelHint,
    disableLabel,
    labelHintText,
    meta: { touched, error, warning },
    ...domProps
  } = props;
  const tooltipContent = (
    <PaperTooltip content={labelHintText} wrapperClassName="d-inline m-0 ml-1">
      <span className="tooltip-anchor">{labelHint}</span>
    </PaperTooltip>
  );
  return (
    <FormGroup className={`row ${touched && (error || warning) ? 'error' : ''}`}>
      <Label className={`label-name d-flex ${disableLabel ? 'd-none disabled' : ''}`} >
        {label}{' '}
        {labelHintText ? (
          tooltipContent
        ) : (
          <span className="tooltip-anchor">{labelHint}</span>
        )}
      </Label>
      {domProps.type === 'custom-select' ? (
        <FilterDropdown input={input} {...domProps} />
      ) : (
        <Input {...domProps} {...input} />
      )}

      {touched && (error && <Label className="error-message">{error}</Label>) || (warning && <Label className="error-message">{warning}</Label>)}
      {(!touched || !error) &&
        hint && <span className="hint-text yellow-info">{hint} </span>}
    </FormGroup>
  );
}

GenericFieldField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  hint: PropTypes.node,
  labelHint: PropTypes.node,
  labelHintText: PropTypes.node
};

GenericFieldField.defaultProps = {
  label: '',
  input: {},
  hint: '',
  labelHint: '',
  labelHintText: ''
};
