import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Field } from 'redux-form';

import GenericFormFields from '../GenericFormFields';
import GenericForm from '../GenericForm';
import cleverTap from '../../../services/cleverTap';
import * as EVENT from '../../../constants/cleverTapEvents';

import map from 'lodash/map';

const Arrow = props => <i className="icon-arrow icon-arrow--left" {...props} />;

class FieldUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.constructFieldComponent = this.constructFieldComponent.bind(this);
  }

  onFocusOut(e, cb) {
    const { name, value } = e.target;
    cleverTap.event(EVENT.fieldValueChange(name), {
      Value: value,
    });
    if (cb) {
      cb(e);
    }
  }

  getErrorMsg = () => {
    const { error } = this.props;
    const values = typeof error === 'string' ? [error] : _.values(error);
    return map(values);
  };

  constructFieldComponent(fieldDetails) {
    // if (fieldDetails.props) {
    //   return fieldDetails;
    // }

    const { onChange, ...fieldProps } = fieldDetails.props || {};

    if (fieldDetails.type && fieldDetails.type === 'custom-select') {
      return (
        <Field
          key={fieldDetails.name}
          type={fieldDetails.type}
          name={fieldDetails.name}
          component={GenericFormFields}
          label={fieldDetails.label}
          validate={fieldDetails.validate}
          cssClass={fieldDetails.cssClass}
          dropDownItems={fieldDetails.dropDownItems
                         ? fieldDetails.dropDownItems
                         : [] }
          selectedOption={fieldDetails.selectedOption}
        />
      );
    }

    return (
      <Field
        key={fieldDetails.name}
        type={fieldDetails.type}
        name={fieldDetails.name}
        className={fieldDetails.className}
        defaultValue={fieldDetails.defaultValue}
        component={fieldDetails.component ? fieldDetails.component : GenericFormFields}
        maxLength={fieldDetails.maxLength ? fieldDetails.maxLength : 100}
        label={fieldDetails.label}
        validate={fieldDetails.validate}
        onBlur={e => this.onFocusOut(e, onChange)}
        {...fieldProps}
      >
        {fieldDetails.options && React.Children.toArray(fieldDetails.options)}
      </Field>
    );
  }

  render() {
    const {
      onSubmit, fields, modalHeading, onClose, formName,
      initialValues, showLoader, buttonText, showModalFlag,
      formWrapperClassName, modalContentClassName, validate,
      wrapClassName, destroyOnUnmount, headerClassName, extraContent,
      enableBackBtn, enabledivider, inactive,
    } = this.props;

    const errors = this.getErrorMsg();

    return (
      <Modal
        isOpen={showModalFlag}
        backdrop={false}
        fade={false}
        onClosed={onClose}
        contentClassName={modalContentClassName}
        wrapClassName={wrapClassName}
      >
        <div className="d-flex align-items-center ml-1 mr-1 mt-1">
          {enableBackBtn && <Arrow onClick={this.props.onBackClick} />}
          <div className="close-section ml-auto">
            <span tabIndex="0" className="close" role="button" onClick={onClose}>×</span>
          </div>
        </div>
        {enabledivider && <div className="divider" />}
        {modalHeading && <ModalHeader className={headerClassName}>{modalHeading}</ModalHeader>}
        {React.Children.toArray(
          errors.map(err => <div className="error-message text-center error-message-format">{err}</div>),
        )}
        <ModalBody className="text-left">
          {fields.length !== 0 && <GenericForm
            form={formName}
            onSubmit={onSubmit}
            validate={validate}
            showLoader={showLoader}
            btnText={buttonText}
            initialValues={initialValues}
            destroyOnUnmount={destroyOnUnmount}
            inactive={inactive}
            wrapperClassName={formWrapperClassName}
          >
            {fields.map(this.constructFieldComponent)}
            {extraContent}
          </GenericForm>}
          {this.props.children}
        </ModalBody>
      </Modal>
    );
  }
}

FieldUpdateModal.propTypes = {
  showModalFlag: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalDialogClassName: PropTypes.string,
  destroyOnUnmount: PropTypes.bool,
  onBackClick: PropTypes.func,
  enableBackBtn: PropTypes.bool,
  enabledivider: PropTypes.bool,
  inactive: PropTypes.bool,
};

FieldUpdateModal.defaultProps = {
  modalDialogClassName: '',
  destroyOnUnmount: true,
  onBackClick: () => { },
  enableBackBtn: false,
  enabledivider: true,
  inactive: false,
};

export default FieldUpdateModal;