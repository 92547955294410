import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { sessionReducer } from 'hub-redux-auth';

import appReducer from './application';
import masterData from './masterData';
import timeline from './timeline';
import documents from './documents';
import merchantPricingPlan from './merchantPricingPlan';
import accountReducer from './account';
import marketingBoxReducer from './marketingBox';
import addendumBankDetailsReducer from './addendumDetails';
import addendumAddressDetailsReducer from './addendumDetails';
import addendumWebsiteDetailsReducer from './addendumDetails';
import website from './website';

import teamAccountReducer from './teamAccounts'

import { rootReducer as onboardingAppReducer } from '@store/rootDuck';

const rootReducer = combineReducers({
  product: state => state || '',
  onboardingApp: onboardingAppReducer,
  masterData,
  session: sessionReducer,
  timeline,
  documents,
  merchantPricingPlan,
  form: formReducer,
  app: appReducer,
  account: accountReducer,
  addendumAddressDetails: addendumAddressDetailsReducer,
  addendumBankDetails: addendumBankDetailsReducer,
  addendumWebsiteDetails: addendumWebsiteDetailsReducer,
  marketingBox: marketingBoxReducer,
  website,
  teamAccount: teamAccountReducer
});

export default rootReducer;
