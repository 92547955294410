const IDs = {
  panDetails: {
    default: 'pan-details',
  },
  businessDetails: {
    default: 'business-details',
  },
  bankDetails: {
    default: 'bank-details',
  },
  websiteDetails: {
    default: 'website-details',
  },
  signingAuthority: {
    default: 'signing-authority',
  },
  kyc: {
    default: 'kyc',
  },
  additionalDocuments: {
    default: 'additional-documents',
  },
  serviceAgreement: {
    default: 'service-agreement',
  },
  ManagementDetails: {
    default: 'management-details',
  },
  videoKyc: {
    default: 'video-kyc',
  }
};

export default IDs;
