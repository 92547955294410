import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../../services/utils';

export default function PaperTooltip(props) {
  const tooltipClassName = classNames({
    'paper-tooltip': true,
    'with-hover': !props.noHover,
    'full-width': props.fullWidth,
    open: props.open
  });

  return (
    <div className={`paper-tooltip-wrapper ${props.wrapperClassName}`}>
      <div className={tooltipClassName}>
        {React.Children.toArray(props.children)}
        <div className={`paper-tooltip-content ${props.contentClassName}`}>
          {props.content}
        </div>
      </div>
    </div>
  );
}

PaperTooltip.propTypes = {
  wrapperClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
};

PaperTooltip.defaultProps = {
  wrapperClassName: '',
  contentClassName: 'position-left',
};
