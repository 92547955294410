import { takeLatest, call, put, select } from "redux-saga/effects";

import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import axios from 'axios';

import { toastActions, dialogActions, hubSelectors } from ".";

//  Action types
export const actionTypes = {
  send: "otp/SEND",
  sendFailed: "otp/SEND_FAILED",
  verify: "otp/VERIFY",
  verifySuccess: "otp/VERIFY_SUCCESS",
  verifyFailed: "otp/VERIFY_FAILED",
  resetOtpState: "otp/RESET_STATE",
};

const initialState = {
  verify: {
    fetching: false,
    fetched: false,
    response: null,
    errMsg: null,
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.verify:
      return {
        ...state,
        verify: {
          ...initialState.verify,
          fetching: true,
        }
      }
    case actionTypes.verifySuccess:
    case actionTypes.verifyFailed: {
      return {
        ...initialState,
        verify: { ...initialState.verify, errMsg: action?.payload },
      };
    }
    case actionTypes.resetOtpState:
      return initialState;
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getError: (state) => state.onboardingApp.otp.verify.errMsg,
  getVerifying: (state) => state.onboardingApp.otp?.verify?.fetching,
};

//  Actions
export const actions = {
  sendOTP: (channels, identity, scope, type, openDialogType, accessToken) => ({
    type: actionTypes.send,
    payload: { channels, identity, scope, type, openDialogType, accessToken },
  }),
  verifyOTP: (identity, type, code, actionType, accessToken) => ({
    type: actionTypes.verify,
    payload: { identity, type, code, actionType, accessToken },
  }),
};

//  Workers
function* sendWorker(action) {
  const {
    payload: { channels, identity, scope, type, openDialogType, accessToken },
  } = action;
  try {
    const hubToken = yield select(hubSelectors.getHubToken);
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.OTP.SEND,
      { otp: { channels, identity, scope, type, password_token: AuthManager.getFullToken() } },
      { token: accessToken || hubToken }
    ) || {};
    if (openDialogType) yield put(dialogActions.open(openDialogType));
  } catch (err) {
    const { data } = yield err.response || {};
    yield put(toastActions.show({
      title: 'Failed',
      content: data?.messages?.user || 'Something went wrong.',
      variant: 'error',
    }))
    yield put({ type: actionTypes.sendFailed, payload: "send-otp-error" });
  }
}

function* verifyWorker(action) {
  const {
    payload: { identity, type, code, actionType, accessToken },
  } = action;
  try {
    const hubToken = yield select(hubSelectors.getHubToken);
    const { data: response } = yield call(
      [axios, 'post'],
      API_ENDPOINTS.OTP.VERIFY,
      { otp: { identity, code, type } },
      { token: accessToken || hubToken }
    ) || {};
    yield put(dialogActions.close());
    yield put({ type: actionTypes.verifySuccess });
    if (actionType) yield put({ type: actionType, payload: response });
  } catch (err) {
    const errResponse = yield err.response;
    const {
      messages: { code, user },
    } = (errResponse && errResponse.data) || { messages: {} };
    yield put({
      type: actionTypes.verifyFailed,
      payload: code || user || "Something went wrong.",
    });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.send, sendWorker);
  yield takeLatest(actionTypes.verify, verifyWorker);
}
