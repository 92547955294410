import Cookies from "universal-cookie"
import { UTM_QUERY_PARAMS } from "@constants/queryParams"
const cookies = new Cookies();

export const setCookies = (key, value, expiry) => {
    return cookies.set(key, value, { maxAge: expiry })
}

export const getCookies = (key) => {
    return cookies.get(key)
}

export const getUTMCookies = () => getCookies("UTM_QUERY_PARAMS")

export const setUTMCookies = (params) => {
    try {
        const paramkeys = Object.keys(params)
        const reqdParams = paramkeys.filter(item => UTM_QUERY_PARAMS.includes(item));
        const filtered = paramkeys.filter(key => reqdParams?.includes(key))?.reduce((obj, key) => {
            return {
                ...obj,
                [key]: params[key]
            };
        }, {});
        if (reqdParams.length) {
            return setCookies("UTM_QUERY_PARAMS", filtered, 60 * 60 * 24 * 10)
        }
    } catch (error) {
        console.log(error);

    }
} 