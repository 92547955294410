//  Action types
export const actionTypes = {
  initiated: 'WEBSOCKET/INITIATED',
  connected: 'WEBSOCKET/CONNECTED',
  disconnected: 'WEBSOCKET/DISCONNECTED',
  rejected: 'WEBSOCKET/REJECTED',
  receivedMsg: 'WEBSOCKET/RECEIVED_MSG',
  resetWebsocket: 'WEBSOCKET/RESET',
};

const initialState = {
  status: null,
  recentMsg: null,
  messages: [],
  signal: false,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.initiated:
    case actionTypes.connected:
    case actionTypes.disconnected:
    case actionTypes.rejected:
      return { ...state, status: action?.type };
    case actionTypes.receivedMsg: {
      return {
        ...state,
        signal: !state.signal,
        recentMsg: action.payload,
        messages: [ ...state.messages, action.payload ],
      }
    }
    case actionTypes.resetWebsocket:
      return { ...state, recentMsg: null };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getSignal: state => state.onboardingApp.websocket.signal,
  getRecentMsg: state => state.onboardingApp.websocket.recentMsg,
};

//  Actions
export const actions = {
  initiated: () => ({ type: actionTypes.initiated }),
  connected: () => ({ type: actionTypes.connected }),
  disconnected: () => ({ type: actionTypes.disconnected }),
  rejected: () => ({ type: actionTypes.rejected }),
  receivedMsg: (payload) => ({ type: actionTypes.receivedMsg, payload }),
  resetWebsocket: () => ({ type: actionTypes.resetWebsocket }),
};
