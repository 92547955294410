export const MOCK_DATA = {
  "business_entity": "Sole Proprietorship",
  "document_categories": [
    {
      "id": 2,
      "name": "PAN Card of Signing Authority",
      "uuid": "11e8-748f-297824ce-9081-020aca9875be",
      "name_on_frontend": "PANCARD_SIGNED_AUTHORITY",
      "document_types": [
        {
          "name": "PAN Card",
          "id": 3,
          "uuid": "11e8-748f-2946799c-9081-020aca9875be",
          "name_on_frontend": "PANCARD",
          "issue_date_req": false,
          "expiry_date_req": false
        }
      ],
      "kyc_document": null
    },
    {
      "id": 9,
      "name": "Address Proof of Signing Authority",
      "uuid": "11e8-748f-297a15b8-9081-020aca9875be",
      "name_on_frontend": "ADDRESS_PROOF_SIGNED_AUTHORITY",
      "document_types": [
        {
          "name": "Passport",
          "id": 4,
          "uuid": "11e8-748f-2948a29e-9081-020aca9875be",
          "name_on_frontend": "PASSPORT",
          "issue_date_req": true,
          "expiry_date_req": false
        },
        {
          "name": "Aadhar",
          "id": 5,
          "uuid": "11e8-748f-294a800a-9081-020aca9875be",
          "name_on_frontend": "AADHAR",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Voter's ID",
          "id": 6,
          "uuid": "11e8-748f-294c6ef6-9081-020aca9875be",
          "name_on_frontend": "VOTER",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Driving Licence",
          "id": 7,
          "uuid": "11e8-748f-294e7dea-9081-020aca9875be",
          "name_on_frontend": "DL",
          "issue_date_req": true,
          "expiry_date_req": true
        },
        {
          "name": "Utilities Bill (electricity, water, landline, gas connection) ",
          "id": 8,
          "uuid": "11e8-748f-29508112-9081-020aca9875be",
          "name_on_frontend": "BMTB",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Address Verification Letter from Bank",
          "id": 17,
          "uuid": "24d8-a849-14f755a1-d49b-12ca65c5cd7a",
          "name_on_frontend": "AVFB",
          "issue_date_req": false,
          "expiry_date_req": false
        }
      ],
      "kyc_document": null
    },
    {
      "id": 10,
      "name": "Government Issued Certificate",
      "uuid": "11e8-748f-297e7cb6-9081-020aca9875be",
      "name_on_frontend": "GOVT_ISSUED_CERTIFICATE",
      "document_types": [
        {
          "name": "TIN Certificate",
          "id": 10,
          "uuid": "11e8-748f-2956ad12-9081-020aca9875be",
          "name_on_frontend": "TTC",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "GST Registration Certificate",
          "id": 15,
          "uuid": "4b87-a0cd-3f4d0517-8418-5ae7ba077c75",
          "name_on_frontend": "GST_CERTIFICATE",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Service Tax Registration Certificate",
          "id": 16,
          "uuid": "1cf4-2c90-a2766f4b-bede-6a66a28c72f1",
          "name_on_frontend": "SERVICE_TAX",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "NOC by Gram Panchayat",
          "id": 19,
          "uuid": "11e8-748f-296cf8a6-9081-020aca9875be",
          "name_on_frontend": "NOC",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Udyog Aadhar Card Certificate",
          "id": 21,
          "uuid": "11e8-748f-2970c076-9081-020aca9875be",
          "name_on_frontend": "UACC",
          "issue_date_req": false,
          "expiry_date_req": false
        },
        {
          "name": "Others",
          "id": 29,
          "uuid": "5efb-91dc-a8eb8e63-6a33-b780b79bfb5d",
          "name_on_frontend": "OTHERS",
          "issue_date_req": false,
          "expiry_date_req": false
        }
      ],
      "kyc_document": null
    }
  ]
};

export const MERCHANT_DOC_STATUS = {
  DOCUMENT_APPROVED: "Docs Approved",
  DOCUMENT_RECEIVED: "Docs Received",
  DOCUMENT_ERROR: "Docs Error",
  PENDING: "Pending",
}

export const INDIVIDUAL_DOC_UPLOAD_STATUS = {
  DOCUMENT_SUBMITTED: "DOCUMENT_SUBMITTED",
  DOCUMENT_REJECTED: "Declined",
  DOCUMENT_VERIFIED: "Approved",
  DOCUMENT_REUPLOADED: "DOCUMENT_REUPLOADED",
  COUNTER_SIGNED: "Counter Signed Received",
  ACCEPTED: "accepted",
  PENDING: "Pending",
};

export const DOCUMENT_CATEGORY_GENERIC_DATA = {
  PANCARD_SIGNED_AUTHORITY: {
    docCategoryLabel: "PAN Card of {{signatory_authority_name}}",
    maxFileSizeLimit: 5,
    fileCount: "single",
  },
  ADDRESS_PROOF_SIGNED_AUTHORITY: {
    docCategoryLabel: "Address Proof of {{signatory_authority_name}}",
    maxFileSizeLimit: 5,
    fileCount: "uploadFrontAndBack",
    docTypes: [
      {
        name: "PASSPORT",
        frontDocName: "Upload first page of Passport",
        backDocName: "Upload last page of Passport",
        optional: [],
      },
      {
        name: "AADHAR",
        frontDocName: "Upload first page of Aadhar Card",
        backDocName: "Upload last page of Aadhar Card",
        optional: [],
      },
      {
        name: "DL",
        frontDocName: "Upload first page of Driving License",
        backDocName: "Upload last page of Driving License",
        optional: ["back"],
      },
    ],
  },
  BANK_PROOF: {
    docCategoryLabel: "Bank Account Proof of {{holder_name}}",
    maxFileSizeLimit: 5,
    fileCount: "single",
  },
  SERVICE_AGREEMENT: {
    docCategoryLabel: "Service Agreement",
    maxFileSizeLimit: 5,
    fileCount: "multiple",
    docTypes: ["AGR"],
  },
  PATNERSHIP_PAN_CARD: {
    docCategoryLabel: "Partnership PAN Card",
    maxFileSizeLimit: 5,
    fileCount: "single",
  },
  COMPANY_PAN_COPY: {
    docCategoryLabel: "Company PAN Card",
    maxFileSizeLimit: 5,
    fileCount: "single",
  },
  CERTIFICATE_OF_INCORPORATION: {
    docCategoryLabel: "Certificate of Incorporation",
    fileCount: "uploadFrontAndBack",
    docTypes: [
      {
        name: "CERTIFICATE_OF_INCORPORATION",
        frontDocName: "Certificate of Incorporation",
        backDocName: "(Optional) INC 22",
        optional: ["back"],
      },
    ],
    maxFileSizeLimit: 15,
  },
  GOVT_ISSUED_CERTIFICATE: {
    docCategoryLabel: 'Government issued certificate copy',
    maxFileSizeLimit: 15,
  },
  SOCIETY_PAN: {
    docCategoryLabel: 'Society PAN Card',
    fileCount: "single",
    maxFileSizeLimit: 5,
    clevertapEventName: 'Company Pan card'
  },
  SCHEDULE_C: {
    docCategoryLabel: "SCHEDULE C",
    fileCount: "single",
  }
};

export const DOCUMENT_CHECKLIST = {
  Individual: {
    PANCARD_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
      docCategoryLabel: "PAN Card of {{signatory_authority_name}}",
    },
    ADDRESS_PROOF_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
      docCategoryLabel: "Address Proof of {{signatory_authority_name}}",
    },
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  "Hindu Undivided Family": {
    PANCARD_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
      docCategoryLabel: "PAN Card of {{signatory_authority_name}}",
    },
    ADDRESS_PROOF_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
      docCategoryLabel: "Address Proof of {{signatory_authority_name}}",
    },
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  "Sole Proprietorship": {
    PANCARD_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
      docCategoryLabel: "PAN Card of {{signatory_authority_name}}",
    },
    ADDRESS_PROOF_SIGNED_AUTHORITY: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
      docCategoryLabel: "Address Proof of {{signatory_authority_name}}",
    },
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      docCategoryLabel: "Government issued certificate copy",
      fileCount: "multiple",
      docTypes: ["GST_CERTIFICATE", "NOC", "UACC", "TTC", "SERVICE_TAX", "OTHERS"],
      maxFileSizeLimit: 15,
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  Partnership: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      docCategoryLabel: "Government issued certificate copy",
      fileCount: "multiple",
      docTypes: ["GST_CERTIFICATE", "NOC", "UACC", "TTC", "SERVICE_TAX", "OTHERS"],
      maxFileSizeLimit: 15,
    },
    PATNERSHIP_PAN_CARD: DOCUMENT_CATEGORY_GENERIC_DATA.PATNERSHIP_PAN_CARD,
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  LLP: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    LLP_PAN_CARD: {
      docCategoryLabel: "LLP PAN Card",
      maxFileSizeLimit: 5,
      fileCount: "single",
    },
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  "Private Limited": {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  "Public Limited": {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  Trust: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      docCategoryLabel: "Government issued certificate copy",
      fileCount: "multiple",
      docTypes: ["CR", "C80G", "TDVR"],
      maxFileSizeLimit: 15,
    },
    TRUST_PAN: {
      docCategoryLabel: "Trust PAN Card",
      fileCount: "single",
      maxFileSizeLimit: 5,
    },
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  Society: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: {
      docCategoryLabel: "Government issued certificate copy",
      fileCount: "multiple",
      docTypes: ["CR", "C80G", "SDVR"],
      maxFileSizeLimit: 15,
    },
    SOCIETY_PAN: {
      docCategoryLabel: "Society PAN Card",
      maxFileSizeLimit: 5,
      fileCount: "single",
    },
    SIGNED_AUTHORISATION_LETTER: {
      docCategoryLabel: "Signed Authorisation Letter",
      fileCount: "single",
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  'Government': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  'Artificial Juridical Person': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  'Local Authority': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  NGO: {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    SOCIETY_PAN: DOCUMENT_CATEGORY_GENERIC_DATA.SOCIETY_PAN,
    GOVT_ISSUED_CERTIFICATE: {
      ...DOCUMENT_CATEGORY_GENERIC_DATA.GOVT_ISSUED_CERTIFICATE,
      fileCount: "multiple",
      docTypes: ["C80G"],
      maxFileSizeLimit: 15,
    },
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  },
  'One Person Company': {
    PANCARD_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.PANCARD_SIGNED_AUTHORITY,
    ADDRESS_PROOF_SIGNED_AUTHORITY: DOCUMENT_CATEGORY_GENERIC_DATA.ADDRESS_PROOF_SIGNED_AUTHORITY,
    BANK_PROOF: DOCUMENT_CATEGORY_GENERIC_DATA.BANK_PROOF,
    COMPANY_PAN_COPY: DOCUMENT_CATEGORY_GENERIC_DATA.COMPANY_PAN_COPY,
    GOVT_ISSUED_CERTIFICATE: DOCUMENT_CATEGORY_GENERIC_DATA.CERTIFICATE_OF_INCORPORATION,
    SERVICE_AGREEMENT: DOCUMENT_CATEGORY_GENERIC_DATA.SERVICE_AGREEMENT,
    SC: DOCUMENT_CATEGORY_GENERIC_DATA.SCHEDULE_C,
  }
};

export const DOCUMENT_SUBCATEGORIES = {
  PASSPORT: {
    docTypeLabel: "Passport",
    value: "4",
  },
  AADHAR: {
    docTypeLabel: "Aadhar Card",
    value: "5",
  },
  VOTER: {
    docTypeLabel: "Voter Card",
    value: "6",
  },
  DL: {
    docTypeLabel: "Driving License",
    value: "7",
  },
  BMTB: {
    docTypeLabel: "Landline telephone Bill",
    value: "8",
  },
  AVFB: {
    docTypeLabel: "Address Verification Letter from Bank",
    value: "6",
  },
  CC: {
    docTypeLabel: "Cancelled Cheque",
    value: "1",
  },
  BC: {
    docTypeLabel: "Bank Verification letter",
    value: "2",
  },
  TTC: {
    docTypeLabel: "TIN/TAN Certificate",
    value: "9",
  },
  LOR: {
    docTypeLabel: "Letter of Registration",
    value: "10",
  },
  IEP: {
    docTypeLabel: "Import/Export License",
    value: "11",
  },
  LL: {
    docTypeLabel: "Labour License",
    value: "12",
  },
  CEC: {
    docTypeLabel: "Central Excise Certificate",
    value: "13",
  },
  MCC: {
    docTypeLabel: "Municipal Corporation Certificate",
    value: "14",
  },
  UACC: {
    docTypeLabel: "Udyog Aadhar Card Certificate",
    value: "15",
  },
  NOC: {
    docTypeLabel: "NOC by Gram Panchayat",
    value: "16",
  },
  COR: {
    docTypeLabel: "Valid Business License or Certificate of Registration",
    value: "17",
  },
  LIRA: {
    docTypeLabel: "License (eg. Certificate of Practice) issued by Registering Authorities", // iCAI, ICWAI, ICSI, IRDA etc.
    value: "18",
  },
  PDVR: {
    docTypeLabel: "Partnership Deed verified by the registrar",
    value: "19",
  },
  CORIR: {
    docTypeLabel: "Certificate of Registration/incorporation issued by registrar of firm",
    value: "20",
  },
  CR: {
    docTypeLabel: "Certificate of Registration",
    value: "22",
  },
  C80G: {
    docTypeLabel: "80G and 12A Certificate (mandatory if trust/society is taking donation)",
    value: "23",
  },
  TDVR: {
    docTypeLabel: "Trust Deed verified by the registrar",
    value: "24",
  },
  SDVR: {
    docTypeLabel: "Society Deed verified by the registrar",
    value: "26",
  },
  OTHERS: {
    docTypeLabel: "Others",
    value: "27",
    subLabel: `(Vat / Dvat-Import/ Export License / Labour License / Central Excise Certificate
                / Municipal Corporation Certificate / Certificate of Business Registration /
                License issued by Registering Authorities like ICAI, ICWAI, ICSI, IRDA) /
                Partnership Deed verified by the registrar`,
  },
  GST_CERTIFICATE: {
    docTypeLabel: "GST Registration Certificate",
    value: "28",
  },
  SERVICE_TAX: {
    docTypeLabel: "Service Tax",
    value: "29",
  },
  CERTIFICATE_OF_INCORPORATION: {
    docTypeLabel: "Certificate of Incorporation",
    modalDescription: "Upload INC 22 with payment challan if your address on certificate of incorporation is outdated",
  },
};

export const WEBSITE_DOCUMENT_CHECKLIST = {
  CERTIFICATE_80G_12A: { label: '80G or 12A Certificate' },
  NO_TAX_EXEMPTION: { label: 'Undertaking on Letterhead for no tax exemption claim' },
  AFFILIATION_CERTIFICATE: { label: 'Affiliation certificate' },
  BIS_CERTIFICATE: { label: 'BIS Certificate' },
  CHEMICALS_FERTILIZERS_CERTIFICATE: { label: 'Certificate from Ministry of Chemicals and Fertilizers' },
  DAS_LICENSE: { label: 'DAS license' },
  DGCA_LICENSE: { label: 'DGCA license' },
  FSSAI_CERTIFICATE: { label: 'FSSAI certificate' },
  GEMOLOGICAL_CERTIFICATE: { label: 'Gemological institute certificate' },
  FRANCHISEE_AGREEMENT: { label: 'Franchisee Agreement with Bank' },
  TOURISM_MINISTRY_APPROVAL: { label: 'Approval from Indian Ministry of Tourism' },
  IATA_CERTIFICATE: { label: 'IATA Certificate' },
  WEB_HOSTING_INVOICE: { label: 'Web Hosting Invoice' },
  PROCUREMENT_INVOICE: { label: 'Procurement Invoice' },
  IRDA_CERTIFICATE: { label: 'IRDA certificate' },
  AUDITED_FINANCIALS_2_YEARS: { label: 'Last 2 years Audited Financials' },
  BANK_STATEMENT_6_MONTHS: { label: 'Last 6 Months Bank Statement' },
  SHIPPING_LICENSE: { label: 'License from Directorate General of shipping' },
  LOGISTIC_TRANSPORT_LICENSE: { label: 'Logistic Transport License' },
  NBFC_CERTIFICATE: { label: 'NBFC Certificate' },
  NISM_MFD: { label: 'NISM MFD' },
  VENURE_BOOKING_RECEIPT: { label: 'Venue Booking Receipt' },
  PCI_DSS_CERTIFICATE: { label: 'PCI DSS certificate' },
  PEST_CONTROL_LICENSE: { label: 'Pest control business license' },
  POLICE_VERIFICATION_CERTIFICATE: { label: 'Police Verification Certificate' },
  RADIO_LICENSE: { label: 'Radio License' },
  MONEY_CHANGER_LICENSE: { label: 'Money Changer License' },
  RBI_LICENSE: { label: 'RBI License' },
  RBI_PPI_LICENSE: { label: 'RBI PPI license' },
  RERA_CERTIFICATE: { label: 'RERA Certificate' },
  SAMPLE_PROOF_DELIVERY: { label: 'Sample Proof of Delivery' },
  SEBI_CERTIFICATE: { label: 'SEBI Certificate' },
  SEBI_INVESTMENT_ADVISOR_CERTIFICATE: { label: 'SEBI certificate as Investment Advisor' },
  TIE_UP_AGREEMENT: { label: 'Tie-up Agreement with your Partners' },
  ISP_LICENSE: { label: 'ISP License' },
  NSDL_CERTIFICATE: { label: 'NSDL Certificate' },
  ACCREDITION_CERTIFICATE: { label: 'Accredition Certificate' },
  PROPERTY_OWNERSHIP_DOCUMENT: { label: 'Document validating Ownership of Property' },
  CRUISE_OWNERS_AGREEMENT: { label: 'Tie-up Agreement with Cruise Owners' },
  DIAGNOSTIC_CENTRES_AGREEMENT: { label: 'Tie-up Agreement with Car Owners' },
  CAR_OWNERS_AGREEMENT: { label: 'Tie-up Agreement with Diagnostic Centers' },
  LOGISTIC_PARTNERS_AGREEMENT: { label: 'Tie-up Agreement with Logistic Partners' },
  NBFC_AGREEMENT: { label: 'Tie-up Agreement with NBFC' },
  UNIFORM_ORGANISATIONS_AGREEMENT: { label: 'Tie-up Agreement with Uniform Organisations' },
  RESTAURANTS_AGREEMENT: { label: 'Tie-up Agreement with Restaurants' },
  SCHOOLS_COLLEGES_AGREEMENT: { label: 'Tie-up Agreement with Schools/Colleges' },
  SERVICE_PROVIDERS_AGREEMENT: { label: 'Tie-up Agreement with Service Providers' },
  SHOPS_DEALERS_AGREEMENT: { label: 'Tie-up Agreement with Shops/Dealers' },
  SOCIETIES_AGREEMENT: { label: 'Tie-up Agreement with Societies' },
  TUTORS_AGREEMENT: { label: 'Tie-up Agreement with Tutors' },
  VENDORS_SERVICE_PROVIDERS_AGREEMENT: { label: 'Tie-up Agreement with vendors/service providers' },
  AUTHORISATION_DOCUMENT: { label: 'Authorisation Document for carrying out this business' },
  JUDGES_CONFIRMATION_LETTER: { label: 'Confirmation email/letter from the judges' },
  IE_LICENSE: { label: 'IE license' },
  FILMS_EXHIBITION_CERTIFICATE: { label: 'Approved Films Exhibition Certificate' },
  MANUFACTURING_LICENSE: { label: 'Manufacturing License' },
  CSP_DOCUMENT: { label: 'Grahak Seva Kendra or CSP Document' },
  COURSE_COMPLETION_CERTIFICATE: { label: 'Course Completion Sample Certificate' },
  DIRECT_SELLING_LICENSE: { label: 'Direct Selling License' },
  AYUSH_CERTIFICATE: { label: 'AYUSH Certificate' },
  GMP_CERTIFICATE: { label: 'GMP Certificate' },
  HOMEOPATHIC_SELLING_LICENSE: { label: 'Homeopathic Selling Drugs License' }
};

