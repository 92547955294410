/* eslint-disable max-len */
/* eslint-disable camelcase */
import { takeLatest, call, put, toPromise } from "redux-saga/effects";

import API_ENDPOINTS from "@services/apiConfig";
import AuthManager from "@services/AuthManager";
import { toastActions } from '.';
import axios from "axios";

//  Action types
export const actionTypes = {
  fetchPlans: "fetchPriorityStlmntPlans/INITIATE",
  fetchPlansFailed: "fetchPriorityStlmntPlans/FETCH_FAILED",
  fetchPlansFulfilled: "fetchPriorityStlmntPlans/FETCH_FULFILLED",
  activatePlan: "activatePriorityStlmntPlan/INITIATE",
  activatePlanFailed: "activatePriorityStlmntPlan/ACTIVATE_FAILED",
  activatePlanFulfilled: "activatePriorityStlmntPlan/ACTIVATE_FULFILLED",
};

const initialState = {
  plans: {
    fetching: false,
    fetched: false,
    response: null,
    errResponse: null,
  },
  activate: {
    fetching: false,
    fetched: false,
    response: null,
    errResponse: null,
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchPlans: {
      return {
        ...state,
        plans: { ...state.plans, fetching: true },
      };
    }
    case actionTypes.fetchPlansFulfilled: {
      return {
        ...state,
        plans: {
          ...state.plans,
          fetching: false,
          fetched: true,
          response: action.payload,
        },
      };
    }
    case actionTypes.fetchPlansFailed: {
      return {
        ...state,
        plans: {
          ...state.plans,
          fetching: false,
          fetched: true,
          errResponse: action.payload,
        },
      };
    }
    case actionTypes.activatePlan: {
      return {
        ...state,
        activate: { ...state.activate, fetching: true },
      };
    }
    case actionTypes.activatePlanFulfilled: {
      return {
        ...state,
        activate: {
          ...state.activate,
          fetching: false,
          fetched: true,
          response: action.payload,
        },
      };
    }
    case actionTypes.activatePlanFailed: {
      return {
        ...state,
        activate: {
          ...state.activate,
          fetching: false,
          fetched: true,
          errResponse: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  plansFetching: (state) => state.onboardingApp.priorityStlmnt.plans.fetching,
  plansFetched: (state) => state.onboardingApp.priorityStlmnt.plans.fetched,
  fetchingFailed: (state) =>
    state.onboardingApp.priorityStlmnt.plans.errResponse,
  getPlans: (state) =>
    state.onboardingApp?.priorityStlmnt?.plans?.response?.result || [],

  activateFetching: (state) =>
    state.onboardingApp.priorityStlmnt.activate.fetching,
  activateFetched: (state) =>
    state.onboardingApp.priorityStlmnt.activate.fetched,
  activateFailed: (state) =>
    state.onboardingApp.priorityStlmnt.activate.errResponse,
};

//  Actions
export const actions = {
  fetchPlans: (data) => ({
    type: actionTypes.fetchPlans,
    payload: data,
  }),
  fetchPlansFailed: (data) => ({
    type: actionTypes.fetchPlansFailed,
    payload: data,
  }),
  fetchPlansFulfilled: (data) => ({
    type: actionTypes.fetchPlansFulfilled,
    payload: data,
  }),

  activatePlan: (data) => ({
    type: actionTypes.activatePlan,
    payload: data,
  }),
  activatePlanFailed: (data) => ({
    type: actionTypes.activatePlanFailed,
    payload: data,
  }),
  activatePlanFulfilled: (data) => ({
    type: actionTypes.activatePlanFulfilled,
    payload: data,
  }),
};

//  Workers
function* fetchPlansWorker() {
  try {
    const response = yield call(
      [axios, "get"],
      API_ENDPOINTS.CROSS_SELL.PRIORITY_STLMNT.FETCH_PLANS(
        AuthManager.getMerchantUUID()
      )
    );
    yield put({
      type: actionTypes.fetchPlansFulfilled,
      payload: response?.data,
    });
  } catch (error) {
    // console.error('Error in fetchPlansWorker:', error);
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Warning',
      content: 'Error while fetching priority settlement plans!',
    }))
    yield put({ type: actionTypes.fetchPlansFailed, payload: error?.response?.data });
  }
}

function* activatePlanWorker({ payload: planDetails }) {
  try {
    const response = yield call(
      [axios, "post"],
      API_ENDPOINTS.CROSS_SELL.PRIORITY_STLMNT.ACTIVATE_PLAN(
        AuthManager.getMerchantUUID()
      ),
      planDetails
    );
    yield put({
      type: actionTypes.activatePlanFulfilled,
      payload: response?.data,
    });
  } catch (error) {
    // console.error('Error in activatePlanWorker:', error);
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Warning',
      content: 'Error while activating priority settlement plan!',
    }))
    yield put({
      type: actionTypes.activatePlanFailed,
      payload: error?.response?.data,
    });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchPlans, fetchPlansWorker);
  yield takeLatest(actionTypes.activatePlan, activatePlanWorker);
}
