import * as C from '../constants/websiteTypes';

const GENERIC_STATE = {
  data: {},
  error: null,
  fetching: false,
  success: false
},

websiteReducer = (state = GENERIC_STATE, action) => {
  switch (action.type) {
    case C.FETCH_WEBSITE_DETAIL_INITIATED:
      return {
        ...state,
        fetched: false,
        fetching: true
      };
    case C.FETCH_WEBSITE_DETAIL_SUCCESS:
      return {
        data: { ...action.payload },
        fetched: true,
        fetching: false,
        success: true
      };
    case C.FETCH_WEBSITE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetched: true,
        fetching: false
      };
    case C.WEBSITE_DETAIL_SUBMITTED_INITIATED:
      return {
        ...state,
        fetched: false,
        fetching: true
      };
    case C.WEBSITE_DETAIL_SUBMITTED_SUCCESS:
      return {
        data: action.payload,
        fetched: true,
        fetching: false,
        success: true
      };
    case C.WEBSITE_DETAIL_SUBMITTED_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetched: true,
        fetching: false
      };
    default:
      return state;
  }
}
export default websiteReducer;


