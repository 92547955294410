import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import isString from 'lodash/isString';

import { bytesToKB } from '../../../services/utils';
import DocumentIcon from '@assets/images/documents/document.svg';

export default class DropUploader extends React.PureComponent {
  static defaultProps = {
    onDrop: () => {},
    onRemove: () => {},
    onUpload: () => {},
    allowMultiple: false,
    bottomText: 'jpg, jpeg, pdf, png less than 5 MB',
    buttonText: 'CHOOSE FILE',
  };

  static propTypes = {
    /**
     * Callback fired when file dropped or selected by file selector.
     *
     * @param {File} file object
     */
    onDrop: PropTypes.func,
    /**
     * Callback fired on remove file.
     *
     * @param {File} file object
     */
    onRemove: PropTypes.func,
    /**
     * Callback fired on uploading file.
     *
     * @param {File} file object
     */
    onUpload: PropTypes.func,
    /**
     * bottom text shown in the uploader
     */
    bottomText: PropTypes.string,
    /**
     * If `true`, will allow select multiple files at once.
     */
    allowMultiple: PropTypes.bool,
    /**
     * button label for the uploader
     */
    buttonText: PropTypes.string,
    /**
     * used to create custom content for upload
     */
    uploaderContent: PropTypes.node,
  };

  static getFileExtension = file =>
    file.name
      .split('.')
      .pop()
      .toLowerCase();

  state = {
    file: null,
    isLoading: false,
    errors: [],
  };

  componentWillUnmount() {
    if (this.state.file) {
      // delete object URL to avoid memory leaks.
      window.URL.revokeObjectURL(this.state.file.preview);
    }
  }

  async componentDidMount() {
    if (this.props.fileToUpload) {
      const errors = this.validateFile(this.props.fileToUpload);
      const isValid = errors.length === 0;
      if (!isValid) {
        this.setState({
          file: this.props.fileToUpload,
          errors,
        });
        return;
      }
      this.setState({
        file: this.props.fileToUpload,
        isLoading: true,
      });
      try {
        /**
         * checking if param in type of `File` to avoid `onUpload`
         * call for other file types as param `fileToUpload` will be
         * `object` when fetching data from server
         */
        if (this.props.fileToUpload.constructor === File) {
          await this.props.onUpload(this.props.fileToUpload);
        }
      } catch (err) {
        this.setState({ errors: [err.message] });
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }

  onButtonClick = (e) => {
    e.stopPropagation();
    this.dropzoneRef.open();
  };

  validateFile = file =>
    this.props.onValidate(
      bytesToKB(file.size),
      DropUploader.getFileExtension(file),
    );

  onDropAccepted = async (acceptedFiles) => {
    const [file] = acceptedFiles;
    // call `onDrop` for all files if `allowMultiple` is `true`
    if (this.props.allowMultiple) {
      acceptedFiles.forEach(this.props.onDrop);
    } else {
      const errors = this.validateFile(file);
      const isValid = errors.length === 0;
      if (!isValid) {
        this.setState({ errors });
        return;
      }
      this.setState({ file, isLoading: true, errors: [] });
      try {
        await this.props.onDrop(file);
      } catch (err) {
        this.setState({ errors: [err.message] });
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  handleRemoveFile = (e) => {
    e.preventDefault();
    this.props.onRemove(this.state.file);
    this.setState({
      file: null,
      errors: [],
    });
  };

  // stop event bubbling to avoid file drop if file already uploaded
  handlePreviewClick = e => e.stopPropagation();

  render() {
    const { file, isLoading, errors } = this.state;
    const { buttonText, uploaderContent } = this.props;

    let content;
    if (file) {
      // do not show thumbnail for pdf document
      if (
        !['jpeg', 'jpg', 'png'].includes(DropUploader.getFileExtension(file))
      ) {
        content = (
          <div
            className="drag-uploader__content drag-uploader__preview"
            onClick={this.handlePreviewClick}
          >
            {isLoading && (
              <div className="loader drag-uploader__preview__loader" />
            )}
            {!isLoading && (
              <DocumentIcon
                className="drag-uploader__preview__icon"
                width={90}
                height={94}
              />
            )}
          </div>
        );
      } else {
        content = (
          <div
            className="drag-uploader__preview"
            onClick={this.handlePreviewClick}
          >
            {isLoading && (
              <div className="drag-uploader__preview__filter">
                <div className="loader drag-uploader__preview__loader" />
              </div>
            )}
            <img src={file.preview} className="drag-uploader__preview__image" />
          </div>
        );
      }
    } else {
      content = (
        <div className="drag-uploader__content">
          {uploaderContent || (
            <div>
              <DocumentIcon width={20} height={24} />
              <div>
                <p className="drag-uploader__content-text pt-b-10">
                  Drag & drop
                </p>
                <p className="grey-info pt-b-10">OR</p>
              </div>
            </div>
          )}
          <button className="dflt-btn drag-uploader__btn" onClick={this.onButtonClick}>
            {buttonText}
          </button>
          <p className="grey-info pt-b-10">{this.props.bottomText}</p>
        </div>
      );
    }

    return (
      <div>
        <Dropzone
          onDropAccepted={this.onDropAccepted}
          ref={el => (this.dropzoneRef = el)}
          className="drag-uploader"
          rejectClassName="drag-uploader--error"
          multiple={this.props.allowMultiple}
        >
          {content}
        </Dropzone>
        <div className="file-details">
          {errors.map((errMsg, index) => (
            <div key={index} className="file-details__error">
              {errMsg}
            </div>
          ))}
          {file && (
            <div>
              <div className="file-details__info">
                <span className="file-details__name">{file.name} </span>
                <span className="file-details__size">
                  {isString(file.size) ? file.size : bytesToKB(file.size)}kb
                </span>
              </div>
              <p>
                {!isLoading && <a href="#" onClick={this.handleRemoveFile}>Remove</a>}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
