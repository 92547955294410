import { useEffect } from "react"

const NOOP = () => { };
export const useStorageListner = ({ onChange = NOOP }) => {

  useEffect(() => {
    window.addEventListener('storage', () => {
      onChange()
    })
    let lastCookie = document.cookie;
    const timerId = setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          onChange();
        } finally {
          lastCookie = cookie;
        }
      }
    }, 1000);

    return () => {
      window.removeEventListener('storage', () => {
        onChange()
      })
      clearInterval(timerId)
    }
  }, [])
}