/*
 *
 * Config for API
 *
 */

import { config } from 'hub-redux-auth';

const BASE_URL = '/',
  WEBSITE_BASE_URL = `${BASE_URL}api/v1/merchants`

const API_ENDPOINTS = {
  AUTH: {
    PRODUCT_ACCOUNTS_FROM_MID: mid => `${BASE_URL}api/v3/product_accounts/${mid}`,
    MERCHANTS: `${BASE_URL}api/v3/product_accounts/`,
    TEAM_ACCOUNTS: `${BASE_URL}api/v3/product_accounts/team_accounts`,
    FIND_USER: `${BASE_URL}api/v1/user/get`,
    CREATE_USER: `${BASE_URL}api/v1/user/create`,
    SEND_OTP: `${BASE_URL}api/v1/user/send_otp`,
    VERIFY_OTP: `${BASE_URL}api/v1/user/verify_otp`,
    SET_PASSWORD: `${BASE_URL}api/v1/user/password`,
    UPDATE_PASSWORD: `${BASE_URL}api/v1/user/update_password`,
    LOGIN: `${BASE_URL}api/v1/user/login`,
    LOGOUT: `${BASE_URL}api/v1/user/logout`,
    AUTHORIZE_USER: () => `${config.get('hubHost', '')}/api/v1/oauth/authorize`,
    FETCH_USER_FROM_HUB: ({ uuid }) => `${config.get('hubHost', '')}/api/v1/users/${uuid}`,
    CLIENT_AUTHORIZATION: () => `${config.get('hubHost', '')}/api/v1/oauth/authorize`,
  },
  PRODUCT: {
    LOGOUT: '/account/service_logout',
    USER_PRODUCTS: ({ uuid, email }) =>
    `${BASE_URL}api/v3/product_enrollments/check_product_accounts?uuid=${uuid}&email=${email}`,
  },
  ONBOARDING: {
    ACL_PERMISSIONS: `${BASE_URL}api/v1/acl_permissions`,
    MARKETING_BOX: () => `${config.get('marketingBoxHost', '')}/auth/op/merchant/default/marketingbox`,
    STATUS_TIMELINE: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/status_timeline`,
    PINCODE: `${BASE_URL}api/v1/pincode_details`,
    BUSINESS_ENTITIES: `${BASE_URL}api/v1/business_entities`,
    BUSINESS_CATEGORIES: `${BASE_URL}api/v1/business_categories`,
    MERCHANT_PRICING_PLAN: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/merchant_pricing_plan`,
    UPDATE_MERCHANT_PRICING_PLAN: ({ merchantUuid, pricingPlanUuid }) => `${BASE_URL}api/v1/merchants/${merchantUuid}/merchant_pricing_plan/${pricingPlanUuid}`,
    PRICING_PLANS: `${BASE_URL}api/v1/pricing_plans`,
    VERIFY_PENNY: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/verify_penny`,
    UPDATE_MERCHANT_PRODUCT: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/update_merchant_product`,
    BANK_UPDATE: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/bank_details`,
    ACCEPT_ADDENDUM: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/accept_addendum`,
    GENERATE_ADDENDUM_DATA: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/generate_addendum_data`,
    ACCOUNT: ({ uuid }) => `${BASE_URL}api/v1/accounts/${uuid}`,
    AGREEMENT_HTML: ({ kycDocumentUuid }) => `${BASE_URL}api/v1/kyc_document/${kycDocumentUuid}/generated_agreement_html`,
    UPDATE_ADDRESS_DETAILS: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/addresses`,
    TOKEN_INFO: ({ mid }) => `${BASE_URL}api/v1/oauth/token/info?mid=${mid}`
  },
  IFSC_DETAILS: {
    IFSC: `${BASE_URL}api/v1/ifsc_details`,
    BANK_NAMES: `${BASE_URL}api/v1/ifsc_details/bank_names`,
    CITIES: `${BASE_URL}api/v1/ifsc_details/cities`,
    STATES: `${BASE_URL}api/v1/ifsc_details/states`,
    BRANCHES: `${BASE_URL}api/v1/ifsc_details/branches`,
  },
  DOCUMENTS: {
    FETCH_DOCUMENT_CATEGORY_AND_TYPES: `${BASE_URL}api/v1/document_categories_and_types`,
    FETCH_DOCUMENTS: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents`,
    FETCH_GENERATED_AGREEMENTS: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/generated_agreements`,
    UPLOAD_DOCUMENT: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents`,
    UPLOAD_DOC_ATTACHMENT: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/append_attachment`,
    REMOVE_DOCUMENT: ({ uuid, docId }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/${docId}`,
    SUBMIT_DOCUMENTS: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/submit_documents`,
    REMOVE_DOCUMENT_ATTACHMENT: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/attachment`,
    MERGE_DOCUMENTS: ({ uuid, docId }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/${docId}/merge_documents`,
    FINAL_DOC_SUBMIT: ({ uuid }) =>
      `${BASE_URL}api/v1/merchants/${uuid}/final_doc_submit`,
    GENERATE_SERVICE_AGREEMENT: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/generate_agreement`,
    ACCEPT_SERVICE_AGREEMENT: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/accept_service_agreement`,
    SAVE_SIGNING_AUTHORITY_LETTER: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/upload_authority_letter`,
    GET_SIGNING_AUTHORITY_DETAILS: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/get_signatory_details`,
    UPDATE_SIGNING_AUTHORITY_DETAILS: ({ uuid }) => `${BASE_URL}api/v1/accounts/${uuid}`,
    DOWNLOAD_SIGNING_AUTHORITY_LETTER: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/kyc_documents/generate_authority_letter`,
    SEND_SIGNATORY_OTP: ({ merchantUuid, docUuid }) => `${BASE_URL}api/v1/merchants/${merchantUuid}/kyc_documents/${docUuid}/send_signatory_otp`,
    VERIFY_SIGNATORY_OTP: ({ merchantUuid, docUuid }) => `${BASE_URL}api/v1/merchants/${merchantUuid}/kyc_documents/${docUuid}/verifiy_signatory_otp`,
    GENERATE_MERGED_DOCUMENT_FOR_ESIGN: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/generate_merged_document_for_esign`,
    ESIGN_MERGED_DOCUMENT: ({ merchantUuid, docUuid }) => `${BASE_URL}api/v1/merchants/${merchantUuid}/kyc_documents/${docUuid}/esign_merged_document`,
  },
  ACL: {
    ROLES: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/acl_roles`,
    UPDATE_ROLES: ({ uuid, aclRoleId }) => `${BASE_URL}api/v1/merchants/${uuid}/acl_roles/${aclRoleId}`,
    ADD_ROLES: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/acl_roles`,
    MOVE_ASSOC_TO_NEW_ROLE: ({ merchantUuid, roleId, newRoleId }) =>
      `${BASE_URL}api/v1/merchants/${merchantUuid}/acl_roles/${roleId}/move_assoc_to_new_role/${newRoleId}`,
    DELETE_ROLE: ({ merchantUuid, aclRoleId }) => `${BASE_URL}api/v1/merchants/${merchantUuid}/acl_roles/${aclRoleId}`
  },
  TEAM_ACCOUNTS: {
    STAFF: ({ uuid }) => `${BASE_URL}api/v1/merchants/${uuid}/staff_invites`,
    STAFF_ACTION: ({ uuid, staffInviteId }) => `${BASE_URL}api/v1/merchants/${uuid}/staff_invites/${staffInviteId}`,
    FETCH_SCOPES_ACTION: ({  }) => `${BASE_URL}/api/v2/permission`
  },
  ERROR: {
    NOTIFY_BACKEND: '/api/v1/exceptions',
  },
  WEBSITE: {
    WEBSITE_DETAIL:
    ({ uuid }) => `${WEBSITE_BASE_URL}/${uuid}/website_details`,
    WEBSITE_DOCUMENT_SUBMIT:
      ({ uuid, websiteDetailUuid }) => `${WEBSITE_BASE_URL}/${uuid}/website_details/${websiteDetailUuid}/submit_website_documents`,
    WEBSITE_PAGE:
    ({ uuid, websiteDetailUuid }) => `${WEBSITE_BASE_URL}/${uuid}/website_details/${websiteDetailUuid}/website_pages`,
    PAGE_DETAIL:
    ({ uuid, websiteDetailUuid, websitePageUuid }) => `${WEBSITE_BASE_URL}/${uuid}/website_details/${websiteDetailUuid}/website_pages/${websitePageUuid}/page_details`
  },
  CS_PAYMENT: merchantUuid => `/api/v3/merchant_accounts/${merchantUuid}/cs_plans/generate_payment_params`,
  NEED_ASSESSMENT: {
    GET: '/api/v1/assessment_questions',
    POST: (uuid) => `/api/v3/merchant_accounts/${uuid}/assessment_responses`
  }
};

export default API_ENDPOINTS;
