import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { HubAuthManager } from 'hub-redux-auth';
import {
  sendBankUpdateOtp,
  verifyBankUpdateOtp,
  updateBankDetails,
} from '../../actions/profile';
import OtpInput from '../BaseComponents/OtpInput';
import cleverTap from '../../services/cleverTap';
import { classNames, getCurrentTimeIST, getSecondsToHoursMinutes } from '../../services/utils';
import FieldUpdateModal from '../BaseComponents/FieldUpdateModal';
import {
  required,
  bankAccountRegex,
  ifscCodeRegex,
  nameAlphaNumRegex,
  minLength9, maxLength18,
} from '../../services/validations';
import {
  PRIVATE_LIMITED,
  PROPRIETORSHIP,
  PUBLIC_LIMITED,
  TRUST,
  SOCIETIES
} from '../../constants/profileConstants';

@connect(null)
export default class BankUpdateModal extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onIfscChange: PropTypes.func,
    merchantUuid: PropTypes.string,
    mid: PropTypes.number.isRequired,
    merchantEmail: PropTypes.string,
    updateAttemptsLeft: PropTypes.number,
    onSearchIfscClick: PropTypes.func.isRequired,
    businessFilingStatus: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    oldValues: PropTypes.shape({
      holder_name: PropTypes.string.isRequired,
      bank_account_number: PropTypes.string.isRequired,
      ifsc_code: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    bankDetailsFromIfsc: PropTypes.shape({
      bank_name: PropTypes.string,
      branch_name: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      address: PropTypes.string,
    }),
    lastCreatedTime: PropTypes.string,
    nextUpdateTime: PropTypes.string
  };

  static defaultProps = {
    onIfscChange: () => {},
    onClose: () => {},
    isOpen: false,
    bankDetailsFromIfsc: null,
    updateAttemptsLeft: 1,
    merchantUuid: '',
    merchantEmail: '',
  };

  state = {
    disbleResendOtp: false,
    showOtpScreen: false,
    isOtpFilled: false,
    showLoader: false,
    error: null,
    bankDetailsFromIfsc: false,
    message: null,
  };

  cannotUpdateBank = () => {
    const { nextUpdateTime } = this.props,
      getCurrentTime = getCurrentTimeIST();
    return Date.parse(nextUpdateTime) > Date.parse(getCurrentTime);
  };

  onOtpVerify = async (token) => {
    const params = {
      bank_detail: this.values,
      access_token: token,
      merchantUuid: this.props.merchantUuid,
    };
    const response = await updateBankDetails(this.props.dispatch, params);
    this.setState({ showLoader: false });
    if (response.name === 'Error') {
      cleverTap.event('Updated bank details submission error',
       { mid: this.props.mid, error: response.message });
      this.setState({ error: response.message });
      return;
    }
    this.props.onSubmit();
  };

  onOtpSubmit = async () => {
    this.setState({ showLoader: true, error: null });
    const otp = this.otpInputElement.getValue();
    const response = await verifyBankUpdateOtp(this.props.merchantEmail, otp);
    if (response.name === 'Error') {
      this.setState({ error: response.message });
    } else {
      response.access_token ? this.onOtpVerify(response.access_token) : null;
    }
  };

  onBankDetailsSubmit = async (values) => {
    if (this.props.updateAttemptsLeft <= 0 || this.cannotUpdateBank()) return;
    this.values = values;
    const { oldValues } = this.props;
    if (oldValues.holder_name === values.holder_name &&
        oldValues.bank_account_number === values.bank_account_number &&
        oldValues.ifsc_code === values.ifsc_code) {
      this.setState({ error: 'Old and new bank details are same', showLoader: false });
      return;
    }
    this.setState({ showLoader: true, error: null });
    let response = await HubAuthManager.fetchHubApiToken();
    response = await sendBankUpdateOtp(this.props.merchantEmail);
    if (response.name === 'Error') {
      this.setState({ error: response.message, showLoader: false });
    } else {
      this.setState({ showOtpScreen: true, showLoader: false });
    }
  };

  onBackClick = () => {
    this.setState({
      showOtpScreen: false,
      error: null,
      isOtpFilled: false,
    });
  };

  onClose = () => {
    this.setState({
      showOtpScreen: false,
      error: null,
      bankDetailsFromIfsc: false,
      isOtpFilled: false,
      showLoader: false,
    });
    this.props.onClose();
  };

  resendOtp = async (e) => {
    e.preventDefault();
    if (this.state.disbleResendOtp) return;
    this.setState({ disbleResendOtp: true });
    setTimeout(() => this.setState({ disbleResendOtp: false }), 20000); // 20 secs
    await sendBankUpdateOtp(this.props.merchantEmail);
  };

  toggleOtpSubmission = (flag) => {
    this.setState({ isOtpFilled: flag });
  };

  OnSubmitErrors = () => {
    const { error, message } = this.state;
    if (error || message) {
      this.setState({ showLoader: false });
      return error ? error.toString() : message.toString();
    }
    return '';
  };

  getInitialValues = () => {
    const { oldValues } = this.props;
    const { holder_name, bank_account_number, ifsc_code } = oldValues;
    const values = { holder_name };
    const { updateAttemptsLeft } = this.props;
    return updateAttemptsLeft <= 0 || this.cannotUpdateBank()
      ? { ...values, bank_account_number, ifsc_code }
      : values;
  };

  getTimeGap = () => {
    const seconds = (Date.parse(this.props.nextUpdateTime) - Date.parse(this.props.lastCreatedTime)) * 0.001;
    return getSecondsToHoursMinutes(seconds);
  };

  disableUpdate = () => {
    const { updateAttemptsLeft } = this.props;
    if (updateAttemptsLeft <= 0)
      this.setState({ message: 'You have exhausted your limits of updates.', error: null, showOtpScreen: false });
    else if (this.cannotUpdateBank()) {
      this.setState({ error: null,
        message: `Please maintain a period of ${this.getTimeGap()} between successive updates`,
        showOtpScreen: false });
    } else
      this.setState({ message: null });
  };

  attemptPrompt = (msg, fontWeight) =>
    (
      <h6>
        <small className={fontWeight}>
          {msg}
        </small>
      </h6>
    );

  renderAttemptsLeft = () => {
    const { updateAttemptsLeft, nextUpdateTime } = this.props;
    if (updateAttemptsLeft > 0 && this.cannotUpdateBank()) {
      return (
        <div className="text-center mt-3">
          {this.attemptPrompt(
            `Come back after ${new Date(nextUpdateTime).toLocaleString()} and try again`,
            'font-weight-bold'
          )}
        </div>
      );
    }
    if (this.props.updateAttemptsLeft > 1 && this.props.updateAttemptsLeft < 10) {
      return (
        <div className="text-center mt-3">
          {this.attemptPrompt(`You have ${this.props.updateAttemptsLeft} update attempts remaining.`)}
        </div>
      );
    } else if (this.props.updateAttemptsLeft === 1) {
      return (
        <div className="text-center dark-red mt-3">
          { this.attemptPrompt('This is your last attempt to update the bank details.', 'font-weight-bold') }
        </div>
      );
    }
    return null;
  };

  getFormFeilds = () => {
    const { showOtpScreen } = this.state;
    const { updateAttemptsLeft, businessFilingStatus } = this.props;
    const holderNameField = this.renderHolderName(businessFilingStatus);
    if (updateAttemptsLeft <= 0 || this.cannotUpdateBank()) {
      return ([{
        type: 'text',
        name: 'holder_name',
        label: "Account Holder's Name",
        props: {
          className: 'disabled-field',
          disabled: true,
        },
      },
      {
        type: 'text',
        name: 'bank_account_number',
        label: 'Bank Account Number',
        props: {
          className: 'disabled-field',
          disabled: true,
        },
      }, {
        type: 'text',
        name: 'ifsc_code',
        label: 'IFSC Code',
        props: {
          className: 'disabled-field',
          disabled: true,
        },
      }]);
    }
    return (
      showOtpScreen ? [] : [holderNameField, {
        type: 'text',
        name: 'bank_account_number',
        label: 'New Bank Account Number',
        validate: [required, bankAccountRegex, minLength9, maxLength18],
      }, {
        type: 'text',
        name: 'ifsc_code',
        label: 'IFSC Code',
        validate: [required, ifscCodeRegex],
        props: {
          onChange: this.props.onIfscChange,
        },
      }]);
  }

  renderbankDetailsFromIfsc = () => {
    const { bankDetailsFromIfsc } = this.props;
    return bankDetailsFromIfsc ?
      <div className="row mb-4">
        <p className="address-section m-0">
          {bankDetailsFromIfsc.bank_name}
        </p>
        <p className="address-section m-0">
          {bankDetailsFromIfsc.branch_name}
        </p>
        <p className="address-section m-0">
          {bankDetailsFromIfsc.city}
        </p>
        <p className="address-section m-0">
          {bankDetailsFromIfsc.state}
        </p>
        <p className="address-section m-0">
          {bankDetailsFromIfsc.address}
        </p>
      </div> : null;
  };

  renderContent = () => {
    const { isOtpFilled, disbleResendOtp } = this.state;
    const disabled = !isOtpFilled;
    const btnClass = classNames({
      'dflt-btn': true,
      inactive: disabled,
      'is-loading': this.state.showLoader,
    });
    if (this.state.showOtpScreen) {
      return (
        <div className="px-3">
          <div className="form-group d-flex flex-column align-items-center">
            <label htmlFor="email">Enter OTP</label>
            <OtpInput
              ref={(el) => { this.otpInputElement = el; }}
              wrapperClassName="d-flex justify-content-center"
              length={4}
              onOtpFilled={this.toggleOtpSubmission}
              onOtpNotFilled={this.toggleOtpSubmission}
            />
            <p className="text-center dark-red pt-5">
              Your settlements will be on hold for at least 24 hours while we
              update your new bank details.
            </p>
          </div>
          <div className="text-center pt-3">
            <Button type="submit" className={btnClass} onClick={this.onOtpSubmit}>
              UPDATE BANK DETAILS
            </Button>
          </div>
          <div className="text-center pt-1">
            <a
              href="#"
              className={`resend-otp ${disbleResendOtp ? 'resend-otp--disabled' : ''}`}
              onClick={this.resendOtp}
            >
              RESEND OTP
            </a>
          </div>
        </div>
      );
    }
  };

  renderSearchIfscButton = () => (
    <div
      onClick={this.props.onSearchIfscClick}
      className="show-password position-relative search-text"
      role="presentation"
    >

      Search
    </div>);

  renderHolderName = (businessFilingStatus) => {
    /**
     * donot allow merchants of these business_entities to update
     * as documentteam validates bank_holder_name with pancard_name (i.e business_name)
     */
    // if ([PRIVATE_LIMITED, PUBLIC_LIMITED, TRUST, SOCIETIES].includes(businessFilingStatus)) {
    //   return ({
    //     type: 'text',
    //     name: 'holder_name',
    //     label: "Account Holder's Name",
    //     validate: [required, nameAlphaNumRegex],
    //   });
    // }
    
    /**
     * For Sole Prop Business Entity we need to drop down list of business_name & pancard_name in holder_name field
     * in bank_details update modal as well
     */
    const { businessPanNameMatch } = this.props;
    if (businessFilingStatus === PROPRIETORSHIP && !businessPanNameMatch){
      const { businessName, panCardName, oldValues: { holder_name} = {} } = this.props,
        bankNameList = [ businessName, panCardName ];
      return ({
        type: 'custom-select',
        name: 'holder_name',
        label: "Account Holder's Name",
        validate: [required],
        className: 'select_box business-status',
        dropDownItems: bankNameList,
        selectedOption: holder_name
      });
    }
    return ({
      type: 'text',
      name: 'holder_name',
      label: "Account Holder's Name",
      validate: [required],
      props: {
        className: 'disabled-field',
        disabled: true,
      },
    });
  };

  render() {
    const {
      bankDetailsFromIfsc, updateAttemptsLeft,
    } = this.props;
    const { showOtpScreen } = this.state;
    this.setState({ bankDetailsFromIfsc: !!bankDetailsFromIfsc });
    this.disableUpdate();
    const fields = this.getFormFeilds();
    return (
      <FieldUpdateModal
        headerClassName="mb-0 px-3"
        wrapClassName="px-3"
        formWrapperClassName="px-3"
        buttonText="NEXT"
        modalContentClassName={`p-0 ${showOtpScreen ? 'w-100' : ''}`}
        formName="BankUpdateForm"
        showLoader={this.state.showLoader}
        showModalFlag={this.props.isOpen}
        enableBackBtn={showOtpScreen}
        onBackClick={this.onBackClick}
        initialValues={this.getInitialValues()}
        onClose={this.onClose}
        modalHeading={showOtpScreen
            ? 'To update bank details, enter OTP sent to your verified mobile/email'
            : 'Update Bank Details'
        }
        onSubmit={this.onBankDetailsSubmit}
        destroyOnUnmount={false}
        error={this.OnSubmitErrors()}
        inactive={updateAttemptsLeft <= 0 || this.cannotUpdateBank()}
        fields={fields}
        extraContent={
          <React.Fragment>
            { updateAttemptsLeft <= 0 || this.cannotUpdateBank() ? null : this.renderSearchIfscButton()}
            { this.state.bankDetailsFromIfsc ? this.renderbankDetailsFromIfsc() : null}
          </React.Fragment>}
      >
        {this.renderContent()}
        {this.renderAttemptsLeft()}
      </FieldUpdateModal>
    );
  }
}
