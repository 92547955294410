import { takeEvery, takeLatest, call, put, select } from "redux-saga/effects";
import API_ENDPOINTS from "@services/apiConfig";
import AuthManager from "@services/AuthManager";
import axios from "axios";
import { uiActions, toastActions } from ".";
import { MERCHANT_DOC_STATUS } from "@constants/manualUploadConstants";

export const actionTypes = {
  fetch: "manualUpload/INITIATE_FETCH",
  fetchFailed: "manualUpload/FETCH_FAILED",
  fetchFulfilled: "manualUpload/FETCH_FULFILLED",
  uploadFile: "manualUpload/UPLOAD_FILE",
  removeFile: "manualUpload/REMOVE_FILE",
  appendAttachment: "manualUpload/APPEND_ATTACHMENT",
  removeAttachment: "manualUpload/REMOVE_ATTACHMENT",
  mergeAttachments: "manualUpload/MERGE_ATTACHMENTS",
  mergeMultipleFileAttachments: "manualUpload/MERGE_MULTIPLE_FILE_ATTACHMENTS",
  addFilePathAndIDItem: "manualUpload/ADD_FILE_PATH_AND_ID_ITEM",
  removeFilePathAndIDItem: "manualUpload/REMOVE_FILE_PATH_AND_ID_ITEM",
  resetFilePathAndIDItem: "manualUpload/RESET_FILE_PATH_AND_ID_ITEM",
  removeAllAttachments: "manualUpload/REMOVE_ALL_ATTACHMENTS",
  verifyDocumentStatus: "manualUpload/VERIFY_DOCUMENT_STATUS",
};

export const actions = {
  fetch: () => ({ type: actionTypes.fetch }),
  fetchFulfilled: (document_details) => ({
    type: actionTypes.fetchFulfilled,
    payload: document_details,
  }),
  fetchFailed: (errResponse) => ({
    type: actionTypes.fetchFailed,
    payload: errResponse,
  }),
  uploadFile: (fileData, callback) => ({
    type: actionTypes.uploadFile,
    payload: { fileData, callback },
  }),
  removeFile: (docUUID, callback) => ({
    type: actionTypes.removeFile,
    payload: { docUUID, callback },
  }),
  appendAttachment: (fileData, callback) => ({
    type: actionTypes.appendAttachment,
    payload: { fileData, callback },
  }),
  removeAttachment: (fileData, callback) => ({
    type: actionTypes.removeAttachment,
    payload: { fileData, callback },
  }),
  mergeAttachments: (fileData, docId, issueAndExpiryDates, documentCategoryId,
    documentTypeId, callback) => ({
      type: actionTypes.mergeAttachments,
      payload: {
        fileData, docId, issueAndExpiryDates, documentCategoryId,
        documentTypeId, callback
      },
    }),
  mergeMultipleFileAttachments: (fileData, docId, documentCategoryId,
    documentTypeId, callback) => ({
      type: actionTypes.mergeMultipleFileAttachments,
      payload: {
        fileData, docId, documentCategoryId,
        documentTypeId, callback
      },
    }),
  addFilePathAndIDItem: (filePathAndIDItem) => ({
    type: actionTypes.addFilePathAndIDItem,
    payload: filePathAndIDItem,
  }),
  removeFilePathAndIDItem: (path) => ({
    type: actionTypes.removeFilePathAndIDItem,
    payload: path,
  }),
  resetFilePathAndIDItem: () => ({
    type: actionTypes.resetFilePathAndIDItem,
  }),
  removeAllAttachments: (callback) => ({
    type: actionTypes.removeAllAttachments,
    payload: callback,
  }),
  verifyDocumentStatus: () => ({
    type: actionTypes.verifyDocumentStatus,
  }),
};

const initialState = {
  fetching: false,
  fetched: false,
  document_details: {
    business_entity: "",
    document_categories: [],
  },
  errResponse: null,
  filePathAndIDList: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch: {
      return {
        ...state,
        fetching: true,
      };
    }
    case actionTypes.fetchFulfilled: {
      return {
        ...state,
        fetched: true,
        document_details: action.payload,
      };
    }
    case actionTypes.fetchFailed: {
      return {
        ...state,
        fetched: false,
        errResponse: action.payload,
      };
    }
    case actionTypes.uploadFile: {
      return {
        ...state,
      };
    }
    case actionTypes.removeFile: {
      return {
        ...state,
      };
    }
    case actionTypes.appendAttachment: {
      return {
        ...state,
      };
    }
    case actionTypes.removeAttachment: {
      return {
        ...state,
      };
    }
    case actionTypes.mergeAttachments: {
      return {
        ...state,
      };
    }
    case actionTypes.mergeMultipleFileAttachments: {
      return {
        ...state,
      };
    }
    case actionTypes.addFilePathAndIDItem: {
      return {
        ...state,
        filePathAndIDList: [...state.filePathAndIDList, action.payload],
      };
    }
    case actionTypes.removeFilePathAndIDItem: {
      const path = action.payload;
      let copyOfFilePathAndIDList = [...state.filePathAndIDList];
      copyOfFilePathAndIDList = copyOfFilePathAndIDList.filter((item) => {
        if (item.path == path) {
          return;
        } else {
          return item;
        }
      });
      return { ...state, filePathAndIDList: [...copyOfFilePathAndIDList] };
    }
    case actionTypes.resetFilePathAndIDItem: {
      return {
        ...state,
        filePathAndIDList: [],
      };
    }
    case actionTypes.removeAllAttachments: {
      return {
        ...state,
      };
    }
    case actionTypes.verifyDocumentStatus: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export const selectors = {
  getDocumentDetails: (state) => state.onboardingApp.manualUpload.document_details,
  getFilePathAndIDList: (state) => state.onboardingApp.manualUpload.filePathAndIDList,
};

function* fetchDocumentDetails() {
  try {
    const { data: document_details } = yield call(
      [axios, "get"],
      API_ENDPOINTS.DOCUMENTS.DOCUMENT_DETAILS(AuthManager.getMerchantUUID())
    );
    yield put(actions.fetchFulfilled(document_details));
  } catch (err) {
    const errResponse = err.response;
    yield put(actions.fetchFailed(errResponse));
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "Could not fetch data. Refresh the page and try again.",
      })
    );
  }
}

function* uploadFile(action) {
  const {
    payload: { fileData, callback },
  } = action;
  try {
    const {
      data: { kyc_document },
    } = yield call([axios, "post"], API_ENDPOINTS.DOCUMENTS.UPLOAD_DOCUMENT(AuthManager.getMerchantUUID()), fileData);
    if (callback) {
      callback(kyc_document);
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "There was an error in uploading the file. Please try again.",
      })
    );
  }
}

function* removeFile(action) {
  const {
    payload: { docUUID, callback },
  } = action;
  try {
    const response = yield call(
      [axios, "delete"],
      API_ENDPOINTS.DOCUMENTS.REMOVE_DOCUMENT(AuthManager.getMerchantUUID(), docUUID)
    );
    if (callback) {
      if (response.status === 204) callback(response);
      else {
        callback(null);
        yield put(
          toastActions.show({
            variant: "error",
            title: "Error",
            content: "Could not remove file. Please try again.",
          })
        );
      }
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "Could not remove file. Please try again.",
      })
    );
    callback(null);
  }
}

function* appendAttachment(action) {
  const {
    payload: { fileData, callback },
  } = action;
  try {
    const {
      data: { attachment },
    } = yield call(
      [axios, "post"],
      API_ENDPOINTS.DOCUMENTS.UPLOAD_DOC_ATTACHMENT(AuthManager.getMerchantUUID()),
      fileData
    );
    if (callback) {
      callback(attachment);
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "Could not append file. Please try again.",
      })
    );
    callback && callback(null);
  }
}

function* removeAllAttachments(action) {
  const callback = action.payload;
  try {
    let fileData = yield select(selectors.getFilePathAndIDList);
    fileData = fileData.map((item) => item.id);
    const response = yield call(
      [axios, "delete"],
      API_ENDPOINTS.DOCUMENTS.REMOVE_DOCUMENT_ATTACHMENT(AuthManager.getMerchantUUID()),
      { data: { file_ids: fileData } }
    );

    if (callback) {
      if (response.status === 204) {
        callback(response);
      } else {
        callback(null);
        yield put(
          toastActions.show({
            variant: "error",
            title: "Error",
            content: "We encountered an error. Could not remove the files. Please try again.",
          })
        );
        callback(null);
      }
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "We encountered an error. Could not remove the files. Please try again.",
      })
    );
    callback(null);
  }
}

function* removeAttachment(action) {
  const {
    payload: { fileData, callback },
  } = action;
  try {
    const response = yield call(
      [axios, "delete"],
      API_ENDPOINTS.DOCUMENTS.REMOVE_DOCUMENT_ATTACHMENT(AuthManager.getMerchantUUID()),
      { data: { file_ids: fileData } }
    );

    if (callback) {
      if (response.status === 204) {
        callback(response);
      } else {
        yield put(
          toastActions.show({
            variant: "error",
            title: "Error",
            content: "We encountered an error. Could not remove the files. Please try again.",
          })
        );
        callback(null);
      }
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "We encountered an error. Could not remove the files. Please try again.",
      })
    );
    callback(null);
  }
}

function* mergeAttachments(action) {
  const {
    payload: { fileData, docId, issueAndExpiryDates, documentCategoryId,
      documentTypeId, callback },
  } = action;
  try {
    const {
      data: { kyc_document },
    } = yield call([axios, "put"], API_ENDPOINTS.DOCUMENTS.MERGE_DOCUMENTS(AuthManager.getMerchantUUID(), docId), {
      document_order: fileData,
      ...issueAndExpiryDates,
      document_category_id: documentCategoryId,
      document_type_id: documentTypeId,
    });
    if (callback) {
      callback(kyc_document);
    }
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Warning',
      content: data?.error ? data.error : 'Something went wrong',
    }));
    if (callback) {
      callback();
    }
  }
}

function* mergeMultipleFileAttachments(action) {
  const {
    payload: { fileData, docId, documentCategoryId,
      documentTypeId, callback },
  } = action;
  try {
    let fileData = yield select(selectors.getFilePathAndIDList);
    fileData = fileData.map((item) => item.id);
    const {
      data: { kyc_document },
    } = yield call([axios, "put"], API_ENDPOINTS.DOCUMENTS.MERGE_DOCUMENTS(AuthManager.getMerchantUUID(), docId), {
      document_order: fileData,
      document_category_id: documentCategoryId,
      document_type_id: documentTypeId,
    });
    if (callback) {
      callback(kyc_document);
    }
  } catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "We encountered an error. Could not upload the files. Please try again.",
      })
    );
  }
}


function* verifyDocumentStatusWorker(action) {
  try {
    const { data: { doc_status, message } } = yield call([axios, 'get'],
      API_ENDPOINTS.DOCUMENTS.VERIFY_DOCUMENT_STATUS(AuthManager.getMerchantUUID()));
    if (doc_status === MERCHANT_DOC_STATUS.DOCUMENT_APPROVED || doc_status === MERCHANT_DOC_STATUS.DOCUMENT_RECEIVED)
      yield put(uiActions.loadUI({ document_status: doc_status }));
    yield put(
      toastActions.show({
        variant: "info",
        title: "Info",
        content: message,
      })
    );
  }
  catch (err) {
    yield put(
      toastActions.show({
        variant: "error",
        title: "Error",
        content: "We encountered an error. Refresh the page and try again.",
      })
    );
  }
}
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchDocumentDetails);
  yield takeEvery(actionTypes.uploadFile, uploadFile);
  yield takeEvery(actionTypes.removeFile, removeFile);
  yield takeEvery(actionTypes.appendAttachment, appendAttachment);
  yield takeEvery(actionTypes.removeAttachment, removeAttachment);
  yield takeLatest(actionTypes.mergeAttachments, mergeAttachments);
  yield takeLatest(actionTypes.mergeMultipleFileAttachments, mergeMultipleFileAttachments);
  yield takeLatest(actionTypes.removeAllAttachments, removeAllAttachments);
  yield takeLatest(actionTypes.verifyDocumentStatus, verifyDocumentStatusWorker);
}
