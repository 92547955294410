import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import uuid from 'uuid/v4';

import GenericModal from '../BaseComponents/GenericModal';
import DropUploader from '../BaseComponents/DropUploader';
import { classNames } from '../../services/utils';

const SortableList = SortableContainer(
  ({ items, onAddMore, onRemove, onValidate, onUpload }) => {
    return (
      <div
        className={`upload-multiple-docs__list mb-30 upload-multiple-docs--dragable
           ${items.length === 1 ? 'upload-multiple-docs__list--center' : ''}
          `}
      >
        {items.map(({ value, file }, index) => (
          <SortableItem
            fileToUpload={file}
            key={`item-${value}`}
            value={value}
            index={index}
            onValidate={onValidate}
            allowMultiple
            onUpload={onUpload(index)}
            onRemove={onRemove(index)}
            {...index === 0 && {
              disabled: true,
              onDrop: onAddMore,
              allowMultiple: true,
              ...(items.length > 1 && {
                buttonText: 'Add More',
                uploaderContent: <div className="plus-icon" />,
              }),
            }}
          />
        ))}
      </div>
    );
  },
);

const DropUploadItem = props => (
  <div className="upload-multiple-docs__list__item">
    <DropUploader {...props} />
  </div>
);

const SortableItem = SortableElement(DropUploadItem);

export default class UploadMultipleDocsModal extends React.PureComponent {
  static defaultProps = {
    description: '',
    modalTitle: '',
    onMount: () => [],
  };

  static propTypes = {
    /**
     * decription for the dialog
     */
    description: PropTypes.string,
    /**
     * dialog title
     */
    modalTitle: PropTypes.string,
    /**
     * Callback fired on component mount
     *
     * @param None
     */
    onMount: PropTypes.func,
    /**
     * Callback fired on component mount
     *
     * @param None
     */
    onUpload: PropTypes.func.isRequired,
  };

  // MAX file count that can be uploaded
  static MAX_FILE_COUNT = 5;

  static shouldCancelStart = (e) => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select`, `option` or `a`
    const disabledElements = [
      'input',
      'textarea',
      'select',
      'option',
      'button',
      'a',
    ];
    // Return true to cancel sorting
    return disabledElements.includes(e.target.tagName.toLowerCase());
  };

  static getFilePath = filePath => filePath;

  INITIAL_STATE = {
    isRemoveDocsPromptOpen: false,
    items: [{
        value: 'Page 1',
        file: null,
        id: null,
      },
    ],
    showLoader: false,
    isContentLoading: false,
    error: '',
    responseDelay: false,
  };

  state = { ...this.INITIAL_STATE };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({ isContentLoading: true });
      const {
        uploaded_documents: uploadedDocuments,
        uuid: kycDocumentUuId,
      } = await this.props.onMount();
      this.kycDocumentUuId = kycDocumentUuId;
      const uploadedItems = uploadedDocuments.map(doc => ({
        value: uuid(),
        id: doc.id,
        file: {
          preview: UploadMultipleDocsModal.getFilePath(doc.path),
          name: doc.filename,
          size: doc.byte_size,
        },
      }));
      this.setState({
        items: [...this.state.items, ...uploadedItems],
      });
      this.setState({ isContentLoading: false });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  onAddMore = (file) => {
    if (
      this.state.items.filter(doc => doc.id).length ===
      UploadMultipleDocsModal.MAX_FILE_COUNT
    ) {
      this.setState({
        error: `Error: Max ${
          UploadMultipleDocsModal.MAX_FILE_COUNT
        } Documents are allowed`,
      });
      return;
    }
    const item = {
      value: uuid('doc'),
      file,
    };
    this.setState(({ items }) => ({
      items: [...items, item],
    }));
  };

  onUpload = index => async (file) => {
    const response = await this.props.onUpload(file);
    this.kycDocumentUuId = response.attachment.kyc_document_uuid;
    this.setState(({ items }) => {
      items[index].id = response.attachment.id;
      return {
        items: [...items],
      };
    });
  };

  onRemove = index => (file) => {
    const { id } = this.state.items[index];
    if (id) {
      this.props.onRemove({
        file_ids: [id],
      });
    }
    this.setState({
      items: [
        ...this.state.items.slice(0, index),
        ...this.state.items.slice(index + 1),
      ],
    });
  };

  handleClose = () => this.setState({ isRemoveDocsPromptOpen: true });

  onRemoveDocs = () => {
    const docIds = this.state.items.filter(doc => doc.id).map(doc => doc.id);
    if (docIds.length !== 0) {
      this.props.onRemove({
        file_ids: docIds,
      });
    }
    this.setState({ ...this.INITIAL_STATE });
    this.props.onClose();
  };

  onCancelRemove = () => this.setState({ isRemoveDocsPromptOpen: false });

  onSubmit = async () => {
    const docIds = this.state.items.filter(doc => doc.id).map(doc => doc.id);
    this.setState({ showLoader: true, error: '' });
    const timeout = window.setTimeout(
      () =>
        this.setState({
          responseDelay: true,
        }),
      3000,
    );
    try {
      await this.props.mergeDocuments(this.kycDocumentUuId, {
        document_order: docIds,
      });
      this.setState({ ...this.INITIAL_STATE });
      this.props.onMergeDocumentSubmitSuccess();
    } catch (err) {
      this.setState({
        error: err.message,
      });
      this.props.onMergeDocumentSubmitFail(err.message);
    } finally {
      window.clearTimeout(timeout);
      this.setState({ showLoader: false, responseDelay: false });
    }
  };

  renderDocsList() {
    return (
      <SortableList
        axis="xy"
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        onAddMore={this.onAddMore}
        onRemove={this.onRemove}
        shouldCancelStart={UploadMultipleDocsModal.shouldCancelStart}
        helperClass="text-center"
        onUpload={this.onUpload}
        onValidate={this.props.onValidate}
      />
    );
  }

  render() {
    const {
      items,
      isRemoveDocsPromptOpen,
      showLoader,
      isContentLoading,
      error,
      responseDelay,
    } = this.state;
    const disableSubmit = items.every(doc => !doc.id);

    const btnClassName = classNames({
      'dflt-btn width-250': true,
      'is-loading': showLoader,
      'inactive fade': disableSubmit,
    });

    return (
      <React.Fragment>
        <GenericModal
          wrapClassName={isRemoveDocsPromptOpen ? 'd-none' : ''}
          isOpen={this.props.isOpen}
          modalTitle={this.props.modalTitle}
          contentClassName="upload-multiple-docs text-center"
          onClose={this.handleClose}
        >
          <p className="upload-multiple-docs__title">
            {this.props.description}
          </p>
          {isContentLoading && <div className="loader mt-15 mb-16" />}
          {!isContentLoading &&
            !showLoader &&
            React.Children.toArray(this.renderDocsList())}
          {showLoader && (
            <div className="pt-30">
              <div className="loader" />
              <div className="trbl pt-30 pb-30 upload-multiple-docs__title">
                <br />Uploading ...
              </div>
              {responseDelay && (
                <div className="upload-multiple-docs__title">
                  <br/>It’s taking longer than expected…<br />Please don’t close the
                  window or refresh
                </div>
              )}
            </div>
          )}
          <div className="upload-multiple-docs__error">{error}</div>
          {!showLoader && <button
            className={btnClassName}
            onClick={disableSubmit ? null : this.onSubmit}
          >
            DONE
          </button>}
        </GenericModal>
        <GenericModal
          isOpen={this.state.isRemoveDocsPromptOpen}
          contentClassName="upload-address-proof-modal text-center"
          isCloseDisabled
        >
          <div className="cancel-prompt mt-15">
            <div className="pl-r-30 cancel-prompt__heading">
              <p>If you cancel, this document will not be saved.</p>
              <p className="pt-30 cancel-prompt__subheading">
                Are you sure you want to cancel?
              </p>
            </div>
            <div className="cstmz-optn pt-30">
              <div className="form-group">
                <button
                  className="dflt-btn width-150"
                  onClick={this.onRemoveDocs}
                >
                  YES
                </button>
              </div>
              <button className="cmn-btn" onClick={this.onCancelRemove}>
                NO
              </button>
            </div>
          </div>
        </GenericModal>
      </React.Fragment>
    );
  }
}
