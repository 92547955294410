/*
 * team Account actions
 *
 */

import * as C from '../constants/merchantTypes';
import * as teamAccountActionsTypes from '../constants/teamAccountTypes';
import API_ENDPOINTS from '../services/apiConfig';
import RestClient from '../services/RestClient';
import { formatActiveModalErrors } from '../services/utils';
import { reset } from 'redux-form';

/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @param  {merchantUuid} params merchant primary key
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */

export const addAclRole = async ({ dispatch, callback, merchantUuid, formName }, params) => {
  try {
    dispatch({ type: C.ADD_ACL_ROLE_INITIATED });
    const apiUrl = ` ${API_ENDPOINTS.ACL.ADD_ROLES({ uuid: merchantUuid })}`,
      response = await RestClient.post(apiUrl, params);
    dispatch({ payload: response,
      type: C.ADD_ACL_ROLE_SUCCESS });
    // eslint-disable-next-line callback-return
    callback();
    dispatch(reset(formName));
    return response;
  } catch (e) {
    const message = await e.response;
    dispatch({ payload: formatActiveModalErrors(message),
      type: C.ADD_ACL_ROLE_FAILURE });
  }
},
/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @param  {merchantUuid} params merchant primary key
 * @param  {roleId} params acl role Uuid
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
deleteRole = async (dispatch, merchantUuid, roleId) => {
  try {
    dispatch({ type: C.DELETE_ROLE_INITIATED });
    await RestClient.delete(`${API_ENDPOINTS.ACL.DELETE_ROLE({
      aclRoleId: roleId,
      merchantUuid
    })}`);
    dispatch({ payload: { aclRoleId: roleId },
      type: C.DELETE_ROLE_SUCCESS });
    return { aclRoleId: roleId };
  } catch (e) {
    const message = await e.response;
    dispatch({ payload: formatActiveModalErrors(message),
      type: C.DELELTE_ROLE_FAILURE });
    return message;
  }
},

/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
fetchAclPermissions = async (dispatch) => {
  dispatch({ type: C.FETCH_ACL_PERMISSIONS_INITIATED });
  try {
    const apiUrl = `${API_ENDPOINTS.ONBOARDING.ACL_PERMISSIONS}`,
      response = await RestClient.get(apiUrl);
    dispatch({ payload: response.acl_permissions,
      type: C.FETCH_ACL_PERMISSIONS_SUCCESS });
  } catch (e) {
    dispatch({ payload: [],
      type: C.FETCH_ACL_PERMISSIONS_FAILURE });
  }
},

/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @param  {merchantUuid} params merchant primary key
 * @param  {roleId} params acl role Uuid
 * @param  {newRoleId} params new acl role Uuid
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
moveInvitesToNewRole = async (dispatch,
  merchantUuid, { newRoleId, roleId }) => {
  try {
    dispatch({ type: C.MOVE_INVITES_TO_NEW_ROLE_INITIATED });
    const apiUrl = API_ENDPOINTS.ACL.MOVE_ASSOC_TO_NEW_ROLE({ merchantUuid,
      newRoleId,
      roleId }),
      response = await RestClient.put(apiUrl);
    dispatch({ payload: { newRoleId,
      oldRoleId: roleId },
      type: C.MOVE_INVITES_TO_NEW_ROLE_SUCCESS });
    fetchEmployeeList(dispatch, merchantUuid);
    return response;
  } catch (e) {
    const message = await e.response;
    dispatch({ payload: formatActiveModalErrors(message),
      type: C.MOVE_INVITES_TO_NEW_ROLE_FAILURE });
    return message;
  }
},
/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @param  {merchantUuid} params merchant primary key
 * @param  {aclRoleId} params acl role Uuid
 * @return {object} An action object with a type of FETCH_BUSINESS_ENTITIES
 */
updateAclRole = async ({ callback, dispatch, merchantUuid }, aclRoleId, params) => {
  try {
    dispatch({ type: C.UPDATE_ACL_ROLE_INITIATED });
    const apiUrl = `${API_ENDPOINTS.ACL.UPDATE_ROLES({ aclRoleId,
      uuid: merchantUuid })}`,
      response = await RestClient.put(apiUrl, params);
    dispatch({ payload: response,
      type: C.UPDATE_ACL_ROLE_SUCCESS });
    callback();
  } catch (e) {
    const message = await e.response;
    dispatch({ payload: formatActiveModalErrors(message),
      type: C.UPDATE_ACL_ROLE_FAILURE });
  }
},
/**
 * called on Settings page
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_RESELLER_DETAILS
 */

userOwnedAclRoles = async (dispatch, merchantUuid) => {
  dispatch({ type: C.FETCH_OWNED_ACL_ROLES });
  try {
    const apiUrl = `${API_ENDPOINTS.ACL.ROLES({ uuid: merchantUuid })}`,
      response = await RestClient.get(apiUrl);
    dispatch({ payload: response,
      type: C.FETCH_OWNED_ACL_ROLES_SUCCESS });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ payload: formatActiveModalErrors(body),
      type: C.FETCH_OWNED_ACL_ROLES_FAILURE });
  }
},

/**
 * called on settings page
 *
 * @param {function} dispatch
 * @param {string} merchantUuid
 */
fetchEmployeeList = async (dispatch, merchantUuid) => {
  dispatch({ type: teamAccountActionsTypes.FETCH_EMPLOYEE_LIST_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.TEAM_ACCOUNTS.STAFF({ uuid: merchantUuid }));
    dispatch({
      payload: response,
      type: teamAccountActionsTypes.FETCH_EMPLOYEE_LIST_SUCCESS
    });
  } catch (err) {
    const body = await err.response;
    dispatch({
      payload: body,
      type: teamAccountActionsTypes.FETCH_EMPLOYEE_LIST_FAILURE
    });
  }
},

/**
 * called on settings page
 *
 * @param {function} dispatch
 * @param {string} merchantUuid
 * @param {function} callback
 * @param {function} errorCallback
 * @param {object} params
 */
createEmployee = async ({ dispatch, merchantUuid, callback, errorCallback, params }) => {
  dispatch({ type: teamAccountActionsTypes.CREATE_EMPLOYEE_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.TEAM_ACCOUNTS.STAFF({ uuid: merchantUuid }), params);
    callback()
    dispatch({
      payload: response,
      type: teamAccountActionsTypes.CREATE_EMPLOYEE_SUCCESS
    });
    fetchEmployeeList(dispatch, merchantUuid);
    userOwnedAclRoles(dispatch, merchantUuid);
  } catch (err) {
    const body = await err.response;
    if (errorCallback) {
  errorCallback(body.errors)
}
    dispatch({
      payload: body,
      type: teamAccountActionsTypes.CREATE_EMPLOYEE_FAILURE
    });
    fetchEmployeeList(dispatch, merchantUuid);
  }
},

/**
 * called on settings page
 *
 * @param {function} dispatch
 * @param {string} merchantUuid
 * @param {number} staffInviteId
 * @param {function} callback
 */
deleteEmployee = async ({ dispatch, merchantUuid, staffInviteId, callback }) => {
  dispatch({ type: teamAccountActionsTypes.DELETE_EMPLOYEE_INITIATED });
  try {
    const response = await RestClient.delete(API_ENDPOINTS.TEAM_ACCOUNTS.STAFF_ACTION({
      staffInviteId,
      uuid: merchantUuid
    }));
    callback()
    dispatch({
      payload: response,
      type: teamAccountActionsTypes.DELETE_EMPLOYEE_SUCCESS
    });
    fetchEmployeeList(dispatch, merchantUuid);
    userOwnedAclRoles(dispatch, merchantUuid);
  } catch (err) {
    const body = await err.response;
    dispatch({
      payload: body,
      type: teamAccountActionsTypes.DELETE_EMPLOYEE_FAILURE
    });
    fetchEmployeeList(dispatch, merchantUuid);
  }
},

/**
 * called on settings page
 *
 * @param {function} dispatch
 * @param {string} merchantUuid
 * @param {number} staffInviteId
 * @param {function} callback
 * @param {function} errorCallback
 * @param {object} params
 */
updateEmployee = async ({ dispatch, merchantUuid, staffInviteId, callback, errorCallback, params }) => {
  dispatch({ type: teamAccountActionsTypes.UPDATE_EMPLOYEE_INITIATED });
  try {
    const response = await RestClient.put(API_ENDPOINTS.TEAM_ACCOUNTS.STAFF_ACTION({
      staffInviteId,
      uuid: merchantUuid
    }), params);
    dispatch({
      payload: response,
      type: teamAccountActionsTypes.UPDATE_EMPLOYEE_SUCCESS
    });
    fetchEmployeeList(dispatch, merchantUuid);
    userOwnedAclRoles(dispatch, merchantUuid);
    if (callback) {
  callback();
}
  } catch (err) {
    const body = await err.response;
    if (errorCallback) {
  errorCallback(body.errors)
}
    dispatch({
      payload: body,
      type: teamAccountActionsTypes.UPDATE_EMPLOYEE_FAILURE
    });
    fetchEmployeeList(dispatch, merchantUuid);
  }
}
