
import React from 'react';
import PropTypes from 'prop-types';
import { pickBy, identity, keys, cloneDeep } from 'lodash';
import { Field, reset } from 'redux-form';
import { updateAclRole, addAclRole } from '../../actions/team_account';
import Button from 'deltoid/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from 'deltoid/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AddButton from '@assets/images/icons/add-button.svg';
import ErrorImage from '@assets/images/error.svg';
import GenericFormFields
  from '../../components/BaseComponents/GenericFormFields';
import roleFormCreator from '../../services/RoleFormCreator';
import PermissionCheckBox from '../../components/PermissionCheckBox';
import Delete from '@material-ui/icons/DeleteOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import DownArrow from '@assets/images/icons/down-arrow.svg';
import { required, maxLength, nameRegexNoSpace } from '../../services/validations';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteRole from './DeleteRole';
import GenericModal from '../../components/BaseComponents/GenericModal';
import cleverTap from '../../services/cleverTap';
import { formatDate, classNames } from '../../services/utils';
import IconButton from '@material-ui/core/IconButton';

import {
  ADD_ACL_ROLE_FAILURE,
  UPDATE_ACL_ROLE_FAILURE
}
from '../../constants/merchantTypes';

export default class RoleCard extends React.Component {

  constructor(props) {
    super(props);
    this.newFormName = 'new_role_form';
    this.state = {
      confirmation: false,
      editable: false,
      expanded: false,
      openDeleteModal: false,
      type: props.type
    };
  }

  groupBy = (xs, key) => xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {})

  permissionsObjects = () => {
    const { permissionsMasterData } = this.props;
    return this.groupBy(permissionsMasterData || [], 'permission_group');
  }

  handleExpandClick = () => {
    const { dispatch, type } = this.props,
      { expanded: oldExpandedState } = this.state;
    this.setState((state) => ({
      editable: state.editable,
      expanded: !state.expanded
    }));
    if (type === 'new' && oldExpandedState) {
      dispatch(reset(this.newFormName));
    }
  }
  handleEditSave = () => {
    this.setState(() => ({
      editable: false,
      expanded: false,
      type: 'roleView'
    }));
  }
  handleCancelClick = () => {
    this.setState({
      confirmation: true
    })
  }

  handleConfirmationCancel = () => {
    this.setState({
      confirmation: false
    })
  }
  handleConfirmationClose = () => {
    this.props.dispatch({ type: 'ROLE_FORM_CLOSED' });
    this.setState({
      confirmation: false
    })
    this.handleExpandClick()
  }

  handleEditableClick = () => {
    this.props.dispatch({ type: 'ROLE_FORM_CLOSED' });
    this.setState((state) => ({
      editable: !state.editable,
      expanded: true,
      type: 'edit'
    }));
  }

  editCancel = () => {
    this.props.dispatch({ type: 'ROLE_FORM_CLOSED' });
    this.setState({
      editable: false,
      type: 'roleView',
      expanded: false
    });
  }
  handleDeleteClick = () => {
    this.handleExpandClick();
    this.setState({ openDeleteModal: true });
  }

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  }

  rolePermissions = () => {
    const { aclRole: { acl_permissions: aclPermissions } } = this.props;
    return aclPermissions || [];
  }

  errorStrip = () => <div className="error-strip">
                       <span className="error_img"><ErrorImage></ErrorImage></span>
                       <span className="error_text"> { this.props.error } </span>
                     </div>

  sortedPermissionGroups = () => {
    const permissionGroups = [
      'Transaction/Settlements',
      'Settings',
      'Request Payments',
      'Account Activation',
      'Integration',
      'Manage Staff',
      'Approval Flow'
    ];
    return permissionGroups;
  }

  aclRoleContent = (type = 'new') => <React.Fragment>
    {
      this.sortedPermissionGroups().map((permissionGroup) => <Grid key={`${type}_${permissionGroup}`}
        className="permission_group" item xs={4}>
          <div className="permission-group-name" >{permissionGroup}</div>
          {
            this.permissionsObjects()[permissionGroup].map((permission) => <li
              key={`${permissionGroup}-${permission.uuid}`}>
                {
                  type !== 'new' && type !== 'edit' &&
                    <Checkbox
                      disabled={true}
                      checked={this.rolePermissions().some((rolePermission) => rolePermission.uuid === permission.uuid)}
                label={permission.title} /> }
                { (type === 'new' || type === 'edit') && <Field
                      name={`acl_role[acl_permission_uuids][${permission.uuid}]`}
                      component={(props) => <PermissionCheckBox
                        key={`aclPermission-${permission.uuid}`}
                        label={permission.title}
                        {...props}
                      />
                      }
                    />
                }
          </li>)}
      </Grid>)
    }
  </React.Fragment>

  formValues = () => {
    const { permissionsMasterData } = this.props,
      { aclRole } = this.props,
      enabledPermissions = permissionsMasterData.reduce((permissionMap, currentPermission) => {
        permissionMap[currentPermission.uuid] =
          this.rolePermissions().some((rolePermission) => rolePermission.uuid === currentPermission.uuid)
        return permissionMap;
      }, {}),
      initialFormValue = { acl_role: { name: aclRole.name } };
    initialFormValue.acl_role.acl_permission_uuids = enabledPermissions;
    initialFormValue.acl_role.acl_role_id = aclRole.uuid;
    return initialFormValue;
  }

  createFormValue = (values) => {
    values.acl_role && values.acl_role.name && (values.acl_role.name = values.acl_role.name.trim());
    const { acl_role: { acl_permission_uuids: aclPermissionUuids } } = values,
      aclRoleValue = cloneDeep(values),
      selectedPermissionUuids = keys(pickBy(aclPermissionUuids, identity));
    aclRoleValue.acl_role.acl_permission_uuids = selectedPermissionUuids;
    return aclRoleValue;
  }

  addRole = (values) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'New role added',
        'CTA Page': 'Manage roles',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const { dispatch, merchantUuid } = this.props,
      aclValues = this.createFormValue(values);
    addAclRole({ callback: this.handleExpandClick,
      dispatch,
      formName: this.newFormName,
merchantUuid }, aclValues);
    return Promise.resolve(values);
  }

  saveRole = (values) => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Role edited',
        'CTA Page': 'Manage roles',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const { dispatch, merchantUuid } = this.props,
      aclValues = this.createFormValue(values);
      updateAclRole({ callback: this.handleEditSave,
        dispatch,
        merchantUuid }, values.acl_role.acl_role_id, aclValues);
    return Promise.resolve(values);
  }

  cardActionItems = () => <React.Fragment>
    <IconButton>
      <Edit
        className="edit-icon"
        onClick={this.handleEditableClick} />
    </IconButton>
    <IconButton>
      <Delete
        className="delete-icon"
        onClick={this.handleDeleteClick}
      />
    </IconButton>
  </React.Fragment>

  actionClassName = () => {
    const { isFetching } = this.props;
    return classNames({ 'isLoading': isFetching });
  }

  editableRoleForm = () => {
    const { aclRole: { id: formId }, action } = this.props,
      EditRoleForm = roleFormCreator(`edit_role_form_${formId}`),
      lengthLimit = 50;
    return (
      <EditRoleForm
        onSubmit={this.saveRole}
        formInitValues={this.formValues()}
      >
        { action && action === UPDATE_ACL_ROLE_FAILURE && this.errorStrip()}
        <Field
          name={'acl_role[name]'}
          component={GenericFormFields}
          className="role-name"
          validate={[
required,
maxLength(lengthLimit),
nameRegexNoSpace
]}
          type="text"
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={24}>
              {this.aclRoleContent('edit')}
            </Grid>
          </CardContent>
        </Collapse>
        <CardActions disableActionSpacing>
          <Button className={this.actionClassName()} buttonType="submit"> Submit </Button>
          <Button onClick={this.editCancel} type="OUTLINED"> Cancel </Button>
        </CardActions>
      </EditRoleForm>
    );
  }

  renderDeleteModal = () => {
    const { openDeleteModal } = this.state,
      { aclRole, dispatch, isFetching, merchantUuid } = this.props;
    if (openDeleteModal) {
      return <DeleteRole
              closeDeleteModal={this.closeDeleteModal}
              isFetching={isFetching}
              isModalOpen={true}
              dispatch={dispatch}
              merchantUuid={merchantUuid} aclRole={aclRole}
            />;
    }
    return <div />;
  }

  cardHideOrShow = () => {
    const { expanded } = this.state;
    if (expanded) {
      return ['HIDE PERMISSIONS'];
    }
    return [
 'VIEW PERMISSIONS',
        <div key="arrow2" className="arrow"><DownArrow /></div>
      ]
  }

  renderRoleName = () => {
    const { expanded } = this.state;
    if (!expanded) {
      return <div className="padding-4 add-button">
                <IconButton>
                  <AddButton onClick={this.handleExpandClick} />
                </IconButton>
                <div className="text" onClick={this.handleExpandClick}>
                  Add New Role
                </div>
              </div>;
      }
    return <div />
  }

  roleView() {
    const { aclRole } = this.props,
      subheaderTypographyProps = { className: 'subHeading' },
      titleTypographyProps = { className: 'cardHeading' };
    return (
      <React.Fragment>
        {
          this.renderDeleteModal()
        }
        <CardHeader
          title={aclRole.name}
          subheader={`Added on ${formatDate(aclRole.created_at)}`}
          disableTypography={false}
          titleTypographyProps={titleTypographyProps}
          subheaderTypographyProps={subheaderTypographyProps}
          action={this.cardActionItems()}
        />
        <Divider />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={24}>
              {this.aclRoleContent('show')}
            </Grid>
          </CardContent>
        </Collapse>
        <CardActions disableActionSpacing>
          <div className="toggle" onClick={this.handleExpandClick} >
            <b>{this.cardHideOrShow()}</b>
          </div>
        </CardActions>
      </React.Fragment>
    );
  }

  handleFloatingButtonClick = () => {
    this.handleExpandClick();
    window.scrollTo(0, document.body.scrollHeight);
  }

  newRoleForm() {
    const NewRoleForm = roleFormCreator(this.newFormName),
      lengthLimit = 50,
      { action } = this.props;
    return (
      <React.Fragment>
        <Fab color="primary" aria-label="Add" className="floating-add-button">
          <AddIcon onClick={this.handleFloatingButtonClick}/>
        </Fab>
        { action && action === ADD_ACL_ROLE_FAILURE && this.errorStrip()}
        {
          this.renderRoleName()
        }
        <NewRoleForm onSubmit={this.addRole} >
          {
            this.state.expanded &&
              <Field
                  name={'acl_role[name]'}
                  component={GenericFormFields}
                  type="input"
                  validate={
                    [
                        required,
                        maxLength(lengthLimit),
                        nameRegexNoSpace
                    ]
                  }
                  className={'role-name'}
                  placeholder={'Add New Role Name'}
                />
          }
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container spacing={24}>
                {this.aclRoleContent('new')}
              </Grid>
            </CardContent>
            <CardActions disableActionSpacing>
              <Button buttonType="submit" className={this.actionClassName()}> Save </Button>
              <Button
                onClick={this.handleCancelClick}
                type="OUTLINED"
              >
                Cancel
                </Button>
            </CardActions>
          </Collapse>
        </NewRoleForm>
        <div>
          <GenericModal
              isOpen={this.state.confirmation}
              contentClassName="upload-address-proof-modal text-center p-0"
              onClose={this.handleConfirmationCancel}
          >
            <div className="main-content">
              <p className="confirmation-txt">Are you sure you want to close the window?</p>
              <div className="cstmz-optn">
                <div className="form-group">
                  <button className="dflt-btn" onClick={this.handleConfirmationClose}>Close</button>
                </div>
                <button className="cmn-btn" onClick={this.handleConfirmationCancel}>CANCEL</button>
              </div>
            </div>
          </GenericModal>
        </div>
      </React.Fragment>
    );
  }

  renderComponents() {
    const { type } = this.state;
    switch (type) {
      case 'edit':
        return this.editableRoleForm();
      case 'new':
        return this.newRoleForm();
      default:
        return this.roleView();
    }
  }

  render() {
    return (
      <div className="roles">
        <Card className="role_card">
          {this.renderComponents()}
        </Card>
      </div>
    );
  }
}
RoleCard.propTypes = {
  aclRole: PropTypes.object,
  action: PropTypes.string,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  isFetching: PropTypes.bool,
  merchantUuid: PropTypes.string,
  permissionsMasterData: PropTypes.array,
  type: PropTypes.string
};
