import { encodeToUrlParams } from "@utils/common";
/*
 *
 * Config for API
 *
 */

const BASE_URL = "/";
const HUB_HOST = `${process.env.HUB_HOST}/`;
const PARTNER_BASE_URL = `${process.env.PARTNER_HOST}`

const API_ENDPOINTS = {
  CREATE_PRODUCT_ACCOUNT: `${BASE_URL}api/v3/product_accounts`,
  AUTH: {
    PRODUCTACCOUNTS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}`,
    MERCHANTS: (merchantUUID) => `${BASE_URL}api/v1/merchants/${merchantUUID}`,
    LOGOUT: `${BASE_URL}api/v1/user/logout`,
    KAM_TOKEN_INFO: ({ mid }) => `${BASE_URL}api/v3/oauth/token/info?mid=${mid}`,
    TOKEN_INFO: `${BASE_URL}api/v3/oauth/token/info`
  },
  HUB: {
    VERIFY_PASSWORD: `${HUB_HOST}oauth/token`,
    FIND_OR_CREATE: `${HUB_HOST}api/v1/users/find_or_create`,
    TOKEN: `${HUB_HOST}oauth/token.json`,
  },
  MASTER_DATA: {
    BUSINESS_ENTITIES: `${BASE_URL}api/v1/business_entities`,
    DOC_CATEGORY_TYPE: `${BASE_URL}api/v1/document_categories_and_types`,
    AREA_CODES: `${BASE_URL}api/v1/area_codes`,
  },
  GET_DISPLAY_NAMES: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/fetch_display_name`,
  UPDATE_PASSWORD: `${BASE_URL}api/v1/user/update_password`,
  PAN_GSTIN: productAccountUUID => `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/pan_gst_details`,
  PAN_STATUS: productAccountUUID=> `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/verify_pan_details`,
  PAN_LINKED_GST: productAccountUUID => `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/gst_details`,
  GST_DETAILS: productAccountUUID => `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/gstin_details`,
  SUBMIT_ONBOARDING_DETAILS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}`,
  SHOP_DETAILS: productAccountUUID => `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/shop_details`,
  ONBOARDING: {
    BUSINESS_CATEGORY: `${BASE_URL}api/v1/business_category`,
    LIVELINESS_CHECK: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/website_details`,
    UPDATE_DOMAIN_STATUS: (productAccountUUID, websiteUUID) =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/website_details/${websiteUUID}`,
    CREATE_USER: userUuid => `${BASE_URL}api/v3/product_enrollments/find_or_create/${userUuid}`,
    // UPDATE_BUSINESS_DETAILS: (merchantUUID) => `${BASE_URL}api/v1/merchants/${merchantUUID}/update_business_details`,
    DELETE_ULTIMATE_BENEFICIARY_OWNER: (merchantUUID, uboId) => `${BASE_URL}api/v1/merchants/${merchantUUID}/ultimate_beneficiaries/${uboId}`
  },
  DOCUMENTS: {
    DOCUMENT_DETAILS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/merchant_docs_req`,
    UPLOAD_DOCUMENT: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents`,
    UPLOAD_DOC_ATTACHMENT: productAccountUUID =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/append_attachment`,
    REMOVE_DOCUMENT: (productAccountUUID, docUUID) => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/${docUUID}`,
    REMOVE_DOCUMENT_ATTACHMENT: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/attachment`,
    MERGE_DOCUMENTS: (productAccountUUID, docId) =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/${docId}/merge_documents`,
    DOWNLOAD_SIGNING_AUTHORITY_LETTER: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/generate_authority_letter`,
    DOWNLOAD_SCHEDULE_C_SAMPLE: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/generate_schedule_c_document`,
    DOWNLOAD_BENEFICIARY_SAMPLE:  (productAccountUUID) => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/generate_beneficiary_list`,
    VERIFY_DOCUMENT_STATUS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/receive_all_docs`,
  },
  UPDATE_BANK_DETAILS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/bank_details`,
  UPLOAD_BANK_PROOF: productAccountUUID =>
    `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/bank_details/bank_verification_by_bank_proof`,
  UPDATE_GSTN: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/update_gst_details`,
  IFSC: {
    BANK_NAMES: `${BASE_URL}api/v1/ifsc_details/bank_names`,
    STATES: (params) => `${BASE_URL}api/v1/ifsc_details/states?${encodeToUrlParams(params)}`,
    CITIES: (params) => `${BASE_URL}api/v1/ifsc_details/cities?${encodeToUrlParams(params)}`,
    BRANCHES: (params) => `${BASE_URL}api/v1/ifsc_details/branches?${encodeToUrlParams(params)}`,
    DETAILS: (ifscCode) => `${BASE_URL}api/v1/ifsc_details/${ifscCode}`,
  },
  OTP: {
    SEND: `${HUB_HOST}api/v1/otps`,
    VERIFY: `${HUB_HOST}api/v1/otps/verify`,
  },
  CIN: {
    SIGNATORIES_DETAILS: productAccountUUID => `${BASE_URL}api/v3/dvs_wrapper/${productAccountUUID}/signing_authority_details`,
  },
  SUBMIT_SIGNING_AUTHORITY: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/contact_details`,
  UPDATE_SIGNING_AUTHORITY: (accountUUID) => `${BASE_URL}api/v1/accounts/${accountUUID}/update_signatory_detail`,
  KYC: {
    CENTRAL: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/ckyc_data`,
    AADHAR: {
      CONSENT: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/aadhaar_xml_consent`,
      SEND_OTP: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/aadhaar_xml_otp_generation`,
      VERIFY_OTP: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/aadhaar_xml_file_data`,
      XML_SHARE_CODE: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/aadhaar_file_details`,
    },
    STATUS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc/kyc_status`,
  },
  SERVICE_AGREEMENT: {
    GENERATED: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/generated_agreements`,
    HTML: kycDocumentUuid => `${BASE_URL}api/v1/kyc_document/${kycDocumentUuid}/generated_agreement_html`,
    GENERATE_MERGED_DOCUMENT_ESIGN: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/generate_merged_document_for_esign`,
    SEND_SINGNATORY_OTP: ({
      kycDocumentUuid,
      productAccountUUID,
    }) => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/${kycDocumentUuid}/send_signatory_otp`,
    ESIGN_MERGED_DOCUMENT: ({ productAccountUUID, kycDocumentUuid }) =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents/${kycDocumentUuid}/esign_merged_document`,
    ESIGNED_DOCUMENT: (productAccountUUID, params) => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/kyc_documents?${encodeToUrlParams(params)}`,
  },
  TIMELINE: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/status_timeline`,
  PINCODE_DETAILS: pinCode => `${BASE_URL}api/v1/pincode_details/${pinCode}`,
  COMMENTS: {
    WEBSITE: ({ productAccountUUID, websiteUUID }) =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/website_details/${websiteUUID}/comments`,
    CREATE_WEBSITE_DOCUMENT: ({ productAccountUUID, websiteUUID }) =>
      `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/website_details/${websiteUUID}/create_website_documents`,
  },
  PARTNER: {
    DETAILS: `${PARTNER_BASE_URL}/api/v1/partners/fetch_platform_details`,
    AUTHORIZE_PARTNER: `${PARTNER_BASE_URL}/api/v1/merchants/auth_code`,
    LINK_PARTNER: `${PARTNER_BASE_URL}/api/v1/merchants/link_partner`,
  },
  CROSS_SELL: {
    PRIORITY_STLMNT: {
      FETCH_PLANS: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/fetch_settlement_plan`,
      ACTIVATE_PLAN: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/activate_priority_settlement`,
    },
    INTNL_PYMNT: {
      CREATE_MCP_TICKET: productAccountUUID => `${BASE_URL}api/v3/product_accounts/${productAccountUUID}/create_mcp_ticket`,
    }
  },
  SUBSCRIPTION_ACTIVE_PLAN: (productAccountUUID) => `/api/v3/subscription_wrapper/${productAccountUUID}/get_user_plan`,
};

export default API_ENDPOINTS;
