import { connect } from 'react-redux';

import BankAccountVerification from './BankAccountVerification';
import {
  checkIsBankDetailsFilled,
  selectPennyStatus,
  selectPennyAttemptCount,
  selectPennyDepositMethod,
  checkIsWebsiteUrlFilled
} from '../../reducers/selectors';
import { verifyPenny } from '../../actions/profile';


function mapStateToProps(state) {
  return {
    isWebsiteFilled: checkIsWebsiteUrlFilled(state),
    product: state.product,
    merchant: state.session.user || {},
    isBankDetailsFilled: checkIsBankDetailsFilled(state),
    pennyStatus: selectPennyStatus(state),
    pennyDepositMethod: selectPennyDepositMethod(state),
    attemptsLeft: selectPennyAttemptCount(state),
    verifyPenny,
  };
}

export default connect(mapStateToProps)(BankAccountVerification);
