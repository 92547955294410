import React from 'react';

import Developer from '@assets/images/developer-graphic.svg';
import Business from '@assets/images/business-graphic.svg';
import Customer from '@assets/images/customer-graphic.svg';

import AuthManager from '../../services/AuthManager';

const USER_GRAPHICS = {
  Business: <Business className="info-graphic" />,
  Developer: <Developer className="info-graphic" />,
  Customer: <Customer className="info-graphic" />,
}

const Step1 = ({
  next,
  currentStep,
  questions,
  answers,
  handleSelect
}) => {

  const validate = answers.length;

  const renderCard = ({
    selected,
    title,
    description,
    id,
    category,
  }) => {
    return (
      <ul className="need-assessment" onClick={() => handleSelect(id, category)}>
        <li>
          <div
            className={`${
              validate
              ? `assessment-card ${answers[0].selected_options[0] == id ? '' : 'not-'}selected`
              : 'assessment-card'}`}
          >
            {
              USER_GRAPHICS[category] ? USER_GRAPHICS[category] : USER_GRAPHICS['Business']
            }
            <label className="custom-radio mt-4">
              <input type="radio" name="radio" value="male" checked={selected} />
              <span className="checkmark"></span>
              <span className="title">{title}</span>
            </label>
              <p>“{description}”</p>
          </div>
        </li>
      </ul>
    )
  }

  if (currentStep !== 1) {
    return null;
  }
  const name = AuthManager.getMerchantName();
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 m-auto text-center assessment-step-1">
          <h5 className="co-text-grey40 mt-5">Hi {name}! Help us know you better</h5>
          <h3 className="sub-title mb-5">Please select what describes you best</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="row">
            {
              questions[0].assessment_options.map((option, i) => (
                <div className="col-md-4 mx-auto" key={`step-one-${i}`}>
                  {renderCard({
                    title: option.statement,
                    description: option.label,
                    selected: validate && answers[0].selected_options[0] == option.id,
                    id: option.id,
                    category: option.category,
                  })}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100">
        <button
          onClick={validate ? next : () => {}}
          className={`need-assessment-btn-confirm${validate ? '' : ' disable'}`}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default Step1;