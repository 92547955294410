export const BANK_STATUS = {
  VERIFIED: 'Success',
  PENDING: 'Pending',
  FAILED: 'Failed',
  VERIFICATION_ATTEMPTS_EXHAUSTED: 'Verification Attempts Exhausted',
  NEED_BANK_PROOF_DOC: 'NEED_BANK_PROOF_DOC',
};

export const OCR_VALIDATIONS_PLACEHOLDER = {
  CC: [
    'Must have your name printed',
    'Must show your Account Number',
    'Must show the IFSC',
  ],
  BS: [
    'Must have your name printed',
    'Must have your account number & IFSC printed',
    'Mobile banking screenshot & SMS will not be considered as valid',
  ],
  PB: [
    'Must have your name printed',
    'Must have your account number & IFSC printed',
    'Must have your photograph & bank stamp',
  ],
  BC: [
    'Must be on bank/ your letter head',
    'Must have sign & stamp of bank manager',
    'Must have your name, account number & IFSC',
  ],
};

export const DOES_NOT_HAVE_OCR = ['BS', 'PB', 'BC', 'CC'];

export const BANK_PROOF_DOC_TYPES = [
  {
    label: 'Cancelled Cheque',
    value: 'CC',
    placeholderImg: '/assets/images/bank-proof/cheque-illustration.svg',
    staticValidations: OCR_VALIDATIONS_PLACEHOLDER.CC.map((content) => ({ type: 'valid', content })),
  },
  {
    label: 'Bank Statement',
    value: 'BS',
    placeholderImg: '/assets/images/bank-proof/bank-letter-illustration.svg',
    staticValidations: OCR_VALIDATIONS_PLACEHOLDER.BS.map((content) => ({ type: 'valid', content })),
  },
  {
    label: 'Passbook',
    value: 'PB',
    placeholderImg: '/assets/images/bank-proof/passbook-illustration.svg',
    staticValidations: OCR_VALIDATIONS_PLACEHOLDER.PB.map((content) => ({ type: 'valid', content })),
  },
  {
    label: 'Bank Verification Letter',
    value: 'BC',
    placeholderImg: '/assets/images/bank-proof/bank-letter-illustration.svg',
    staticValidations: OCR_VALIDATIONS_PLACEHOLDER.BC.map((content) => ({ type: 'valid', content })),
  },
];