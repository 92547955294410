import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';


export default class FileUploaderButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.domId = this.props.id || uuid(); // unique dom id for repeated elements
  }

  render() {
    return (
      <div className={`${this.props.className}`}>
        <input ref={el => this.inputFile = el }
            type="file" id={this.domId} className="upload" onChange={this.props.onInputChange} />
        <label htmlFor={this.domId}
          className={`${this.props.buttonClassName} ${this.props.disabled ? 'disabled': ''}`}
          onClick={this.props.onButtonClick}>
            {this.props.buttonLabel}
        </label>
      </div>
    );
  }
}

FileUploaderButton.propTypes = {
  buttonLabel: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onInputChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

FileUploaderButton.defaultProps = {
  buttonLabel: '',
  className: 'file-upload',
  buttonClassName: 'cmn-btn',
  onInputChange: () => {},
  onButtonClick: () => {},
  disabled: false,
};
