import React from 'react';
import PropTypes from 'prop-types';
import GenericModal from '../../BaseComponents/GenericModal';

class NoAccessModal extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    buttonText: 'Ok, got it!',
    isOpen: true,
    modalText: 'Oops! You don\'t have access to this feature. Please contact $merchantAdminEmail for more details'
  }

  render () {
    const { isOpen, onClose } = this.props;
    return (
      <GenericModal
        isOpen={ isOpen }
        isCloseDisabled={ true }
        contentClassName="no-access-modal-content"
        modalClassName="no-access-modal-container">
        <div className="no-access-modal-wrapper">
          <div className="no-access-text-wrapper">
            <p>
            Oops!
            </p>
            <p>
              You don&#39;t have access to this feature.
            </p>
          </div>
          <div>
            <button className="cmn-btn" onClick={ onClose }> { this.state.buttonText } </button>
          </div>
        </div>
      </GenericModal>
    );
  }
}
NoAccessModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
}
NoAccessModal.defaultProps = {
  isOpen: false
}

export default NoAccessModal;
