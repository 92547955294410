import React from 'react';
import RetryIcon from "@assets/images/status/retry.svg";

import GenericModal from '../BaseComponents/GenericModal';

export default function AgreementRetry(props) {
  return (
    <GenericModal
      isOpen={props.isOpen}
      modalTitle="Something went wrong!"
      contentClassName="service-agreement-retry-popup"
      isCloseDisabled={props.isCloseDisabled}
    >
      <div className="text-center">
        <RetryIcon width="50" height="50" />
      </div>
      <div className="text-center">
        <input
          type="button"
          className="dflt-btn"
          value="RETRY"
          onClick={props.action}
        />
      </div>
    </GenericModal>
  );
};
