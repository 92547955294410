import React from 'react';
import PropTypes from 'prop-types';
import GenericModal from '../../../components/BaseComponents/GenericModal';
import Button from 'deltoid/core/Button';
import { classNames } from '../../../services/utils';

class UserConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDeleted: false,
      isOpen: props.isOpen }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isDeleted: false,
      isOpen: nextProps.isOpen });
  }

  handleDeleteRole = async () => {
    const { onDeleteRole } = this.props;
    if (onDeleteRole) {
      const response = await onDeleteRole();
      if (response.aclRoleId) {
        this.setState({ isDeleted: true });
      }
    }
  }

  handleClose = () => {
    this.props.onClose();
  }

  buttonClassNames = () => {
    const { isFetching } = this.props;
    classNames({ isLoading: isFetching });
  }

  renderContent = () => {
    const { aclRoleName, isFetching } = this.props,
      { isDeleted } = this.state,
        notDeleted = !isDeleted;
    if (notDeleted) {
      return <React.Fragment>
        <div className="heading text-center">
          <h4 className="role-name">
            Are you sure you want to delete role <span>&quot;</span><span className="role-name">{ aclRoleName }</span><span>&quot; </span> ?
          </h4>
          <h6>
            Click <span>&apos;</span>Delete<span>&apos;</span> to Proceed.
          </h6>
        </div>
        <div className="actions">
          <Button onClick={this.handleDeleteRole} isLoading={isFetching} > DELETE </Button>
          <Button
            onClick={this.handleClose}
            type="OUTLINED"
          >
            CANCEL
          </Button>
        </div>
      </React.Fragment>
    }
    return <React.Fragment>
            <div className="heading text-center">
              <h4>
                Role Deleted Successfully.
              </h4>
            </div>
          </React.Fragment>
  }

  render() {
    return <GenericModal
        isOpen={this.state.isOpen}
        onClose={this.props.onClose}
        backdrop={false}
        modalContentClassName="move-invite-role"
        footerClassName="align-self-center"
      >
        <div className="justify-content-center">
          {this.renderContent()}
        </div>
      </GenericModal>;
  }
}

UserConfirmation.propTypes = {
  aclRoleName: PropTypes.string,
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteRole: PropTypes.func
}
export default UserConfirmation;
