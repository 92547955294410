import * as C from '../constants/merchantTypes';

const timelineState = {
  fetched: false,
  fetching: false,
  error: null,
  data: {
    timelineStatus: {},
    conditionalStatus: {},
  },
};

export default function timelineReducer(state = timelineState, action) {
  switch (action.type) {
    case C.FETCH_TIMELINE_STATUS_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.FETCH_TIMELINE_STATUS_SUCCESS:
      return { ...state, data: action.payload, fetching: false, fetched: true };
    // case C.PROFILE_COMPLETED_FOR_TIMELINE: {
    //   const stateClone = { ...state };
    //   const timelineStatusInvertMap = invert(TIMELINE_STATUS);
    //   stateClone.data.timelineStatus.profileStatus.status =
    //     timelineStatusInvertMap[COMPLETED];
    //   stateClone.data.timelineStatus.bankStatus.status =
    //     timelineStatusInvertMap[IN_PROGRESS];
    //   stateClone.data.conditionalStatus.status = invert(
    //     CONDITIONAL_STATUS
    //   ).PENNY_PENDING;
    //   return stateClone;
    // }
    default:
      return state;
  }
}
