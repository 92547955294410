import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import * as panDetails from '@store/ducks/pan-details.duck';
import * as bankDetails from '@store/ducks/bank-details.duck';
import * as merchant from '@store/ducks/merchant.duck';
import * as dialog from '@store/ducks/dialog.duck';
import * as masterData from '@store/ducks/master-data.duck';
import * as IFSC from '@store/ducks/ifsc.duck';
import * as businessDetails from '@store/ducks/businessDetails.duck';
import * as ui from '@store/ducks/ui.duck';
import * as otp from '@store/ducks/otp.duck';
import * as hub from '@store/ducks/hub.duck';
import * as toast from '@store/ducks/toast.duck';
import * as signingAuthority from '@store/ducks/signing-authority.duck';
import * as kyc from '@store/ducks/kyc.duck';
import * as websiteDetails from '@store/ducks/websiteDetails.duck';
import * as manualUpload from '@store/ducks/manualUpload.duck';
import * as timeline from '@store/ducks/timeline.duck';
import * as serviceAgreement from '@store/ducks/service-agreement.duck';
import * as acl from '@store/ducks/acl.duck';
import * as comments from '@store/ducks/comments.duck';
import * as partner from '@store/ducks/partner.duck';
import * as websocket from '@store/ducks/web-socket.duck';
import * as edit from '@store/ducks/edit.duck';
import * as cache from '@store/ducks/cache.duck';
import * as productAccount from '@store/ducks/product-account.duck';
import * as priorityStlmnt from '@store/ducks/priority-stlmnt.duck';
import * as mcpTicket from '@store/ducks/mcp-ticket.duck';

export const rootReducer = combineReducers({
  panDetails: panDetails.reducer,
  bankDetails: bankDetails.reducer,
  merchant: merchant.reducer,
  dialog: dialog.reducer,
  masterData: masterData.reducer,
  businessDetails: businessDetails.reducer,
  IFSC: IFSC.reducer,
  UI: ui.reducer,
  otp: otp.reducer,
  hub: hub.reducer,
  toast: toast.reducer,
  signingAuthority: signingAuthority.reducer,
  kyc: kyc.reducer,
  websiteDetails: websiteDetails.reducer,
  manualUpload: manualUpload.reducer,
  timeline: timeline.reducer,
  serviceAgreement: serviceAgreement.reducer,
  acl: acl.reducer,
  comments: comments.reducer,
  partner: partner.reducer,
  websocket: websocket.reducer,
  edit: edit.reducer,
  cache: cache.reducer,
  productAccount: productAccount.reducer,
  priorityStlmnt: priorityStlmnt.reducer,
  mcpTicket: mcpTicket.reducer,
});

export function* rootSaga() {
  yield all([
    merchant.saga(),
    panDetails.saga(),
    bankDetails.saga(),
    masterData.saga(),
    businessDetails.saga(),
    IFSC.saga(),
    ui.saga(),
    otp.saga(),
    hub.saga(),
    signingAuthority.saga(),
    kyc.saga(),
    dialog.saga(),
    websiteDetails.saga(),
    manualUpload.saga(),
    timeline.saga(),
    serviceAgreement.saga(),
    acl.saga(),
    comments.saga(),
    partner.saga(),
    edit.saga(),
    productAccount.saga(),
    priorityStlmnt.saga(),
    mcpTicket.saga(),
  ]);
}
