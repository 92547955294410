/**
 * Wrapper for making network requests using es6 native `fetch`
 * @type class
 * @name RestClient
 */

import 'whatwg-fetch';
import 'babel-polyfill';
import AuthManager from './AuthManager';

const getToken = () => {
  try {
    return JSON.parse(window.localStorage.getItem('tokenData')).access_token;
  } catch (e) {
    return null;
  }
};

// getting csrfToken from header meta tag
const getCsrfToken = () => {
  const $el = document.getElementsByName('csrf-token')[0];
  return $el ? $el.content : null;
};

export default class RestClient {
  static options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // x_csrf_token: getCsrfToken(),
    },
    // credentials: 'include', // include cookies with every request
  };

  /**
   * Parses the Object to query string
   * @param {object} obj JS object
   * @return {object} The parsed query string
   */
  static encodeToUrlParams(obj) {
    if (obj instanceof String) {
      return obj;
    }
    const queryString = Object.keys(obj)
      .reduce((a, k) => {
        a.push(`${k}=${encodeURIComponent(obj[k])}`);
        return a;
      }, []).join('&');
    return `${queryString}`;
  }


  /**
   * Parses the JSON returned by a network request
   * @param {object} response A response from a network request
   * @return {object} The parsed JSON from the request
   */
  static async responseJson(response) {
    try {
      return await response.json();
    } catch (e) {
      // console.warn(e);
      return null;
    }
  }

  /**
   * Parses the JSON returned by a network request
   * @param {object} response A response from a network request
   * @return {object} The raw response from the request
   */
  static async rawResponse(response) {
    try {
      return await response.text();
    } catch (e) {
      return null;
    }
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   * @param {object} response   A response from a network request
   * @return {object|undefined} Returns either the response, or throws an error
   */
  /* eslint-disable consistent-return */
  static checkStatus(response) {
    if (response.ok) {
      return response;
    }
    if (response.status === 401 && !response.url.includes('/user/login')) {
      AuthManager.removeLocalStorageData();
      const { origin, search, pathname } = window.location;
      if (pathname === '/account/service_login') {
        window.location.assign(`${origin}/app/account${search}`);
      } else {
        const urlSearchParams = new URLSearchParams(decodeURIComponent(search));
        const redirectTo = urlSearchParams.get('redirect_to') || urlSearchParams.get('redirectTo');
        if (redirectTo
          && !urlSearchParams.get('client_id')) {
          window.location = `/app/account?redirectTo=${redirectTo}`;
          return;
        }
        const redirectURL = `${origin}${pathname}${encodeURIComponent(decodeURIComponent(search))}`;
        window.location = `/app/account?redirectTo=${redirectURL}`;
      }
    }
    const error = new Error(response.statusText);
    error.responseStatus = response.status;
    if (response.status === 500) { // json error handler for internal server error
      error.response = { base: ['Some error occured. Please try again later'] };
    } else {
      error.response = this.responseJson(response);
    }
    throw error;
  }

  /**
   * Send HTTP requests
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @param {object} options, one can pass {token: 'access_token'} to override header authorization token
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static async request(url, options, extraArgs = null) {
    const token = extraArgs && extraArgs.token || getToken();
    const allOptions = { ...this.options, ...options };
    const combinedOptions = {
      ...allOptions,
      headers: { ...allOptions.headers, Authorization: `Bearer ${token}` },
    };
    const response = await fetch(url, combinedOptions);
    await this.checkStatus(response);
    if (extraArgs && extraArgs.raw) {
      return this.rawResponse(response);
    }
    return this.responseJson(response);
  }

  /**
   * Send HTTP GET request
   * @param {String} url  API endpoints
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static get(url, payload, extraArgs = null) {
    const options = { method: 'GET' };
    let endpoint = url;
    if (payload) {
      endpoint += `?${this.encodeToUrlParams(payload)}`;
    }
    return this.request(endpoint, options, extraArgs);
  }

  /**
   * Send HTTP POST request
   * @param {String} url  API endpoint
   * @param payload
   * @param extraArgs to set token in authorisaton header explicitly
   *        example: extraArgs =  { token: 'access_token' }
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static post(url, payload, extraArgs = {}) {
    const options = { method: 'POST', body: JSON.stringify(payload), ...extraArgs };
    return this.request(url, options, extraArgs);
  }

  /**
   * Send HTTP PUT request
   * @param {String} url  API endpoint
   * @param payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static put(url, payload) {
    const options = { method: 'PUT', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  /**
   * Send HTTP DELETE request
   * @param {String} url  API endpoint
   * @param payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static delete(url, payload) {
    const options = { method: 'DELETE', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  /**
   * Send HTTP POST request for form data
   * @param {String} url API endpoint
   * @param payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static postWithFile(url, payload) {
    const options = {
      method: 'POST',
      body: payload,
      headers: {
        Accept: 'application/json',
        x_csrf_token: getCsrfToken(),
      },
    };
    delete options.headers['Content-Type'];
    return this.request(url, options);
  }

  /**
   * Send HTTP PUT request for form data
   * @param {String} url API endpoint
   * @param payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static putWithFile(url, payload) {
    const options = {
      body: payload,
      headers: {
        Accept: 'application/json',
        x_csrf_token: getCsrfToken(),
      },
      method: 'PUT',
    };
    delete options.headers['Content-Type'];
    return this.request(url, options);
  }
}
window.RestClient = RestClient;
