import { config } from 'hub-redux-auth';
import AuthManager from '../services/AuthManager';
import { onRedirectionCLick } from '../services/utils';
import careHost from '../utils/careHost'

const setIntegrationDropdownMenuList = () => {
  const user = AuthManager.getUser();
  if (user && user.product === 'PayUmoney'){
    const moneyProductHost = config.get('moneyProductHost');
    config.set('integrationDropdownMenuList', [
      {
        label: 'Integration Details',
        linkTo: `${moneyProductHost}/merchant-dashboard/#/integration`
      },
      {
        label: 'Developer Guide',
        linkTo: 'https://developer.payumoney.com/'
      }
    ]);
    config.set('productHelpUrl', `${moneyProductHost}/support.html`);
  }
},
  setSessionDropDownList = () => {
    const user = AuthManager.getUser();
    if (user && user.product === 'PayUmoney'){
      const moneyProductHost = config.get('moneyProductHost');
      config.set('sessionDropdownMenuList', [
        {
          label: 'Profile',
          linkTo: '/app/onboarding#'
        },
        {
          label: 'Settings',
          linkTo: `${moneyProductHost}/merchant-dashboard/#/notification-settings`
        },
        {
          label: 'Business Dashboard',
          linkTo: `${moneyProductHost}/merchant-dashboard/#/transactions`
        },
        {
          label: 'PayUmoney Wallet',
          linkTo: `${moneyProductHost}/customer/dashboard/`
        },
        {
          label: 'Team Accounts',
          linkTo: '/app/account/team-accounts'
        }
      ]);
    } else if (user && user.product === 'PayUbiz'){
      config.set('sessionDropdownMenuList', [
        {
          label: 'Business Dashboard',
          onItemClick: onRedirectionCLick
        }
      ]);
      config.set('productHelpUrl', careHost());
    }
  };

export {
  setIntegrationDropdownMenuList,
  setSessionDropDownList
};
