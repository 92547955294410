import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

function RadioButton(props) {
  const domId = props.id || uuid();

  function handleOptionChange(e) {
    if (props.onRadioClick) {
      props.onRadioClick(e);
    }
  }
  return (
    <div className={`${props.wrapperClassName} base-radio-btn`}>
      <input
        type="radio"
        id={domId}
        value={props.value}
        checked={props.selectedOption === props.label}
        onChange={handleOptionChange}
      />
      <label htmlFor={domId}>
        {props.label}
        {React.Children.toArray(props.children)}
      </label>
      <div className="check" />
    </div>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  onRadioClick: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

RadioButton.defaultProps = {
  wrapperClassName: ''
};

export default RadioButton;
