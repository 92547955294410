import React from 'react';
import PropTypes from 'prop-types';
import Form from 'deltoid/core/Form';
import { Container } from 'deltoid/core/Blocks/BootstrapContainers';

import clsx from 'clsx';

const Topbar = ({
  logo,
  menuList,
}) => {

  return (
    <div className='co-main-header'>
      <Container className='d-flex justify-content-between'>
        <img src={logo} alt='payu-logo' height='40' className='w-auto'/>
        <div className='d-inline'>
          {
            menuList.map((menuItem, i) => {
              const { label, variant, onClick, startIcon, endIcon } = menuItem;
              let extraProps = {};
              if (startIcon && startIcon.type) extraProps.startIcon = startIcon;
              if (endIcon && endIcon.type) extraProps.endIcon = endIcon;

              const className = clsx(
                'ml-3',
                {
                  'p-0': variant === 'underlined',
                }
              );

              return (
                <Form.Button
                  variant={variant}
                  onClick={onClick}
                  // style={{ marginTop: 'auto', marginBottom: 'auto' }}
                  className={className}
                  key={`h-btn-${i}`}
                  {...extraProps}
                >
                  {label}
                </Form.Button>
              )
            })
          }
        </div>
      </Container>
    </div>
  )
};

Topbar.defaultProps = {
  menuList: [],
};

Topbar.propTypes = {
  logo: PropTypes.string.isRequired,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.oneOf([ 'underlined', 'outlined', 'default', null]),
      startIcon: PropTypes.shape({
        type: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.string,
      })
    })
  )
}

export default Topbar;
