import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Card from 'deltoid/core/Card';
import Button from 'deltoid/core/Button';
import ReduxFormField from 'deltoid/core/ReduxFormField';
import { emailRegex, required, maxLength50, nameRegexNew } from '../../../services/validations';
import ErrorImage from '@assets/images/error.svg';
import { TEAM_ACCOUNTS_LITERALS } from '../../../constants/appConstants';
import GenericModal from '../../BaseComponents/GenericModal';

/**
 *  add new employee form
 *
 *  @param  {object}    props      reacts props
 */

class AddEmployeeDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.resetEdit();
  }


  render() {
  const { roleData, onCancel, onSave, handleEmailChange,
    handleRoleChange, handleNameChange, invalid, staffCount, formError, formErrorText } = this.props,
    roleOptions = roleData.data &&
    roleData.data.acl_roles &&
    roleData.data.acl_roles.map((ele) => ({ label: ele.name,
    value: ele.id }));

    return (
    <div>
      <div className="page-heading">
        <span>Manage Employees</span>&nbsp;
        <span>({ staffCount }) </span>
      </div>
      <span className="page-subheading">
      { TEAM_ACCOUNTS_LITERALS.MANAGE_EMPLOYEES_SCREEN_SUBHEADER }
      </span>
    <Card className="add-employee-details-widget">
      <div>
        {
          formError && <div className="error-strip">
            <span className="error_img"><ErrorImage></ErrorImage></span>
            <span class="error_content">
              { formErrorText.split('#').map((ele) => {
                return <span><span className="error_text"> { ele } </span><br></br></span>
              })}
            </span>
          </div>
        }
        <form>
          <div>
            <Field
              className="add-emp-name"
              label="Add employee name"
              component={ ReduxFormField }
              name="name"
              onChange={ handleNameChange }
              validate={[
                required,
                nameRegexNew,
                maxLength50
              ]}
            >
            </Field>
          </div>
          <div className="assign-role-container">
            {
              roleData.data && roleData.data.acl_roles &&
              <Field
                type="select"
                className="text-field assign-role"
                name="role"
                maxLength="18"
                component={ ReduxFormField }
                label="Assign role"
                options={roleOptions}
                onChange={ handleRoleChange }
                validate={ required }
              >
              </Field>
            }
          </div>
          <div className="add-email-container">
            <Field
                name="email"
                className="add-contact-number"
                component={ ReduxFormField }
                label="Email ID"
                onChange= { handleEmailChange }
                validate={ [
                  required,
                  emailRegex
                ] }
            >
            </Field>
          </div>
          <div>
            <span>
              <Button
                className={ `save-emp-details-btn dflt-btn ${invalid
                  ? 'inactive'
                  : ''}` }
                onClick={ onSave }
              >
                Send Invite
              </Button>
            </span>
            <span>
              <Button
                className="cancel-save-emp-details dflt-btn-brdr"
                type="OUTLINED"
                onClick={ onCancel }
              >
                Cancel
              </Button>
            </span>
          </div>
        </form>
        <div>
          <GenericModal
              isOpen={this.props.confirmation}
              contentClassName="upload-address-proof-modal text-center p-0"
              onClose={this.props.handleConfirmationCancel}
          >
            <div className="main-content">
              <p className="confirmation-txt">Are you sure you want to close the window?</p>
              <div className="cstmz-optn">
                <div className="form-group">
                  <button className="dflt-btn" onClick={this.props.handleConfirmationClose}>Close</button>
                </div>
                <button className="cmn-btn" onClick={this.props.handleConfirmationCancel}>CANCEL</button>
              </div>
            </div>
          </GenericModal>
        </div>

      </div>
    </Card>
    </div>
    );
  }
}
/**
 * function to map redux state data to props
 *
 * @param {object} state  redux state
 */
const mapStateToProps = (state) => {
  const stateValue = {
    formData: state.form.AddEmployeeDetailsForm && state.form.AddEmployeeDetailsForm.values,
    initialValues: {
      email: '',
      role: ''
    },
    roleData: state.app.ownedAclRoles,
    values: state.form.AddEmployeeDetailsForm && state.form.AddEmployeeDetailsForm.values
  };
  return stateValue;
},

AddEmployeeDetailsForm = reduxForm({
  form: 'AddEmployeeDetailsForm'
})(AddEmployeeDetails)
AddEmployeeDetails.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  formError: PropTypes.bool.isRequired,
  formErrorText: PropTypes.string,
  handleConfirmationCancel: PropTypes.func.isRequired,
  handleConfirmationClose: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func,
  handleRoleChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  roleData: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  staffCount: PropTypes.number.isRequired
};

AddEmployeeDetails.defaultProps = {
  roleData: {
    label: '',
    value: ''
  }
};

export default connect(mapStateToProps)(AddEmployeeDetailsForm)
