import React from 'react';

const AppLoader = () => (
  <div className="app-loader-wrapper">
    <div className="app-loader">
      <span />
      <span />
      <span />
    </div>
  </div>
);
export default AppLoader;
