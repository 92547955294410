import { takeEvery, put, call, select } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';
import AuthManager from '@services/AuthManager';
import { dialogActions, masterDataSelectors, uiActions, productAccountActions, productAccountSelectors, toastActions } from '.';
import { pushGTMEvent, stepCompletionGTMEvent } from '@services/gtm';
import { CLEVERTAP_EVENTS } from "../../../src/constants/cleverTapEvents";
import ClevertapHandler from "../../../src/services/cleverTap";
//  Action types
export const actionTypes = {
  verifyPan: 'verifyPan/INITIATE',
  verifyPanSuccess: 'verifyPan/SUCCESS',
  verifyPanFailed: 'verifyPan/FAILED',
  verifyPanDetails: 'verifyPanDetails/INITIATE',
  verifyPanDetailsSuccess: 'verifyPanDetails/SUCCESS',
  verifyPanDetailsFailed: 'verifyPanDetails/FAILED',
  submitPan: 'submitPan/INITIATE',
  submitPanSuccess: 'submitPan/SUCCESS',
  submitPanFailed: 'submitPan/FAILED',
  fetchPanLinkedGST: "panLinkedGST/INITIATE",
  storePanLinkedGST: "panLinkedGST/SAVE_TO_STORE",
  updateBusinessEntity: 'updateBusinessEntity/INITIATE',
  updateBusinessEntitySuccess: 'updateBusinessEntity/SUCCESS',
  updateBusinessEntityFailed: 'updateBusinessEntity/FAILED',
};

const initialState = {
  verify: {
    processing: false,
    valid: false,
    errMsg: null,
    panCardNumber: null,
    panName: null,
  },
  verifyPanDetails: {
    processing: false,
    valid: false,
    errMsg: null,
    panCardNumber: null,
    panName: null,
  },
  submit: {
    processing: false,
    response: null
  },
  updateBusinessEntity: {
    processing: false,
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.verifyPan:
      return {
        ...state,
        verify: {
          ...initialState.verify,
          processing: true,
        },
      };
    case actionTypes.verifyPanSuccess:
    case actionTypes.storePanLinkedGST:
      return {
        ...state,
        verify: {
          ...initialState.verify,
          processing: false,
          valid: true,
          ...action.payload
        },
      };
    case actionTypes.verifyPanFailed:
      return {
        ...state,
        verify: {
          ...initialState.verify,
          processing: false,
          valid: false,
          errMsg: action.payload
        },
      };
    case actionTypes.verifyPanDetails:
      return {
        ...state,
        verifyPanDetails: {
          ...initialState.verifyPanDetails,
          processing: true,
        },
      };
    case actionTypes.verifyPanDetailsSuccess:  
    case actionTypes.verifyPanDetailsFailed:
      return {
        ...state,
        verifyPanDetails: {
          ...initialState.verifyPanDetails,
          processing: false,
          valid: false,
          errMsg: action.payload
        },
      };
    case actionTypes.submitPan:
      return { ...state, submit: { processing: true, response: null } };
    case actionTypes.submitPanSuccess:
    case actionTypes.submitPanFailed:
      return { ...state, submit: { processing: false, response: action.payload } };
    case actionTypes.updateBusinessEntity:
      return { ...state, updateBusinessEntity: { processing: true } };
    case actionTypes.updateBusinessEntitySuccess:
    case actionTypes.updateBusinessEntityFailed:
      return { ...state, updateBusinessEntity: { processing: false } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  verifying: state => state.onboardingApp.panDetails.verify.processing,
  verifiedPanDetails: state => {
    const { panCardNumber, panName, gstDetails, dob } = state.onboardingApp.panDetails.verify;
    return { panCardNumber, panName, gstDetails, dob };
  },
  errMsg: state => state.onboardingApp.panDetails.verify.errMsg,
  panVerficationErrMsg: state => state.onboardingApp.panDetails.verifyPanDetails.errMsg,
  submitProcessing: state => state.onboardingApp.panDetails.submit.processing,
  updateBusinessEntityProcessing: state => state.onboardingApp.panDetails.updateBusinessEntity.processing,
};


//  Actions
export const actions = {
  verifyPan: (panCardNumber, dob) => ({ type: actionTypes.verifyPan, payload: { panCardNumber, dob } }),
  verifyPanDetails: (panCardNumber, dob, name) => ({ type: actionTypes.verifyPanDetails, payload: { panCardNumber, dob, name } }),
  submitPan: buinessEntity => ({ type: actionTypes.submitPan, payload: buinessEntity }),
  fetchPanLinkedGST: () => ({ type: actionTypes.fetchPanLinkedGST }),
  updateBusinessEntity: buinessEntity => ({ type: actionTypes.updateBusinessEntity, payload: buinessEntity }),
};

//  Workers
function* verifyPanWorker(action) {
  const { payload: { panCardNumber: pan_number, dob } } = action;

  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.PAN_GSTIN(AuthManager.getMerchantUUID()),
      { pan_number }
    ) || { data: { pan_detail: {} } };
    const { pan_detail: { name: panName , gst_details, dob} } = response.data;
    ClevertapHandler.sendEvent({["CTA Name"] : CLEVERTAP_EVENTS.PAN_VERIFIED})
    if (dob) {
      ClevertapHandler.sendEvent({ ["CTA Name"]: CLEVERTAP_EVENTS.DOB_PREFILLED })
    }
    if (panName) {
      ClevertapHandler.sendEvent({ ["CTA Name"]: CLEVERTAP_EVENTS.NAME_PREFILLED })
    }
    yield put({
      type: actionTypes.verifyPanSuccess,
      payload: {
        panName,
        panCardNumber: pan_number,
        gstDetails: gst_details,
        dob,
      }
    });
  } catch(err) {
    ClevertapHandler.sendEvent({["CTA Name"] : CLEVERTAP_EVENTS.PAN_FAILED})
    const errResponse = yield err.response;
    yield put({
      type: actionTypes.verifyPanFailed,
      payload: errResponse?.data?.message || "Something went wrong. Please try again later."
    })
  }
}

function* verifyPanDetailsWorker(action) {
  const { payload: { panCardNumber: pan_number, dob, name: pan_name } } = action;
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.PAN_STATUS(AuthManager.getMerchantUUID()),
      { pan_number, dob, pan_name }
    ) || { data: { pan_detail: {} } };
    if(response?.data?.status === "Active" && response?.data?.dobMatch === "Y" && response?.data?.nameMatch === "Y"){
      yield put({
        type: actionTypes.verifyPanSuccess,
        payload: {
          panName: pan_name,
          panCardNumber: pan_number,
          dob,
        }
      });
      yield put(dialogActions.open('pan-confirmation', { panName: pan_name, panCardNumber: pan_number, isPanConfirm: true }));
    } else if (response?.data?.status === "Active" && response?.data?.dobMatch === "Y" && response?.data?.nameMatch === "N") {
      yield put({
        type: actionTypes.verifyPanDetailsFailed,
        payload: 'Please enter a valid pan name'
      })
    }
    else if (response?.data?.status === "Active" && response?.data?.dobMatch === "N" && response?.data?.nameMatch === "Y") {
      yield put({
        type: actionTypes.verifyPanDetailsFailed,
        payload: 'Please enter a valid date of birth'
      })
    }     else if (response?.data?.status === "Active" && response?.data?.dobMatch === "N" && response?.data?.nameMatch === "N") {
      yield put({
        type: actionTypes.verifyPanDetailsFailed,
        payload: 'Please enter a valid name and date of birth'
      })
    }
  } catch {
    yield put({
      type: actionTypes.verifyPanDetailsFailed,
      payload: 'Something went wrong. Please try again after sometime.'
    })
  }
}

function* submitPanWorker(action) {
  const { payload: business_entity } = action;
  const {
    panCardNumber: pancard_number, panName: pancard_name, dob: dob
  } = yield select(selectors.verifiedPanDetails);
  const businessEntities = yield select(masterDataSelectors.businessEntities);
  const business_entity_id = businessEntities.list.find(entity => entity.name === business_entity)?.id;

  try {
    const { data: response } = yield call(
      [axios, 'put'],
      API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
      {
        product_account: {
          pancard_number: pancard_number.toUpperCase(),
          business_entity_id,
          dob,
          pancard_name
        }
      },
    );
    ClevertapHandler.sendEvent({["CTA Name"] : CLEVERTAP_EVENTS.PAN_STEP_COMPLETED})
    yield put(dialogActions.close());
    yield put(productAccountActions.updateProductAccountDetails(response.product_account));
    yield put(uiActions.loadUI(response.product_account));
    yield put({ type: actionTypes.submitPanSuccess, payload: response.product_account });
    stepCompletionGTMEvent({ stepNo: '1', stepName: 'pan_submitted'});
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    const errMsg = data?.errors?.dob?.[0] || data?.errors?.pancard_number?.[0] || data?.errors?.base?.[0] || 'Pan not valid';
    yield put({ type: actionTypes.verifyPanFailed, payload: errMsg });
    yield put({ type: actionTypes.verifyPanDetailsFailed, payload: errMsg });
    yield put({ type: actionTypes.submitPanFailed });
    yield put(dialogActions.close());
  }
}


function* fetchPanLinkedGSTWorker() {
  const { pancard_number = ""} = (yield select(productAccountSelectors.getProductAccountDetails)) || { };
  const { gstDetails, panCardNumber } = yield select(selectors.verifiedPanDetails);

  try {
    if (pancard_number && !gstDetails && pancard_number != panCardNumber) {
      const response = yield call(
        [axios, 'post'],
        API_ENDPOINTS.PAN_LINKED_GST(AuthManager.getMerchantUUID()),
        { pan_number: pancard_number },
      ) || {};
      yield put({
        type: actionTypes.storePanLinkedGST,
        payload: { gstDetails: response?.data?.gst_details, panCardNumber: pancard_number,  }
      });
    }
  } catch {
    yield put({
      type: actionTypes.storePanLinkedGST,
      payload: { gstDetails: [] }
    });
  }
}

function* updateBusinessEntityWorker(action) {
  const { payload: business_entity } = action;
  const buinessEntities = yield select(masterDataSelectors.businessEntities);
  const business_entity_id = buinessEntities.list.find(entity => entity.name === business_entity).id;

  try {
    const { data: response } = yield call(
      [axios, 'put'],
      API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
      { business_entity_id },
    );
    yield put(dialogActions.close());
    yield put(uiActions.loadUI(response.product_account));
    yield put({ type: actionTypes.updateBusinessEntitySuccess, payload: response.product_account });
    yield put(toastActions.show({
      variant: 'success',
      title: 'Success',
      content: 'Business Entity updated.',
    }));
  } catch {
    yield put({ type: actionTypes.updateBusinessEntityFailed });
    yield put(dialogActions.close());
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: 'Business Entity update failed.',
    }));
  }
}

//  Sagas
export function* saga() {
  yield takeEvery(actionTypes.verifyPan, verifyPanWorker);
  yield takeEvery(actionTypes.verifyPanDetails, verifyPanDetailsWorker);
  yield takeEvery(actionTypes.submitPan, submitPanWorker);
  yield takeEvery(actionTypes.fetchPanLinkedGST, fetchPanLinkedGSTWorker);
  yield takeEvery(actionTypes.updateBusinessEntity, updateBusinessEntityWorker);
}
