import {
  INDIVIDUAL,
  PROPRIETORSHIP,
  PATNERSHIP,
  TRUST,
  SOCIETIES,
  PRIVATE_LIMITED,
  PUBLIC_LIMITED,
  LLP,
} from './profileConstants';

export const SHOW_SELECTED_FIELDS = {
  [INDIVIDUAL] : {nameOnPanCard: 'Name on PAN', panCardNo: 'PAN number', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [PROPRIETORSHIP] : {businessName: 'Business name',nameOnPanCard: 'Name on PAN', panCardNo: 'PAN number', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [PRIVATE_LIMITED] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.',holderName: 'Name of Bank Account holder ' , bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [PATNERSHIP] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [PUBLIC_LIMITED] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.', holderName: 'Name of Bank Account holder', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [SOCIETIES] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.', holderName: 'Name of Bank Account holder', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [TRUST] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.', holderName: 'Name of Bank Account holder', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  [LLP] : {nameOnPanCard: 'Name on Company PAN Card', panCardNo: 'Company PAN Card No.', bankAccountNumber: 'Bank Account No.', ifscCode: 'Bank IFSC'},
  '': {nameOnPanCard: "Name on PAN", panCardNo: "PAN number", bankAccountNumber: "Bank account no.", ifscCode: "Bank IFSC"}
};

export const ACCOUNT_NUMBER_HINTS = {
  [INDIVIDUAL] : 'Name of account holder should be same as name on PAN',
  [PROPRIETORSHIP] : 'Name of account holder should be same as Business name',
  [PATNERSHIP] : 'Name of account holder should be same as name on PAN',
  [LLP] : 'Name of account holder should be same as name on PAN',
  [PRIVATE_LIMITED] : '',
  [PUBLIC_LIMITED] : '',
  [SOCIETIES] : '',
  [TRUST] : '',
  '': '',
};

export const DEFAULT_ENABLED_FIELDS = {
  nameOnPanCard: "Name on PAN",
  panCardNo: "PAN number",
  bankAccountNumber: "Bank account no.",
  ifscCode: "Bank IFSC"
}