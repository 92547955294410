export const ACTION_CHECK_SESSION = 'CHECK_SESSION';
export const ACTION_CHECK_FAILURE = 'ACTION_CHECK_FAILURE';
export const ACTION_LOGIN = 'LOGIN';
export const ACTION_LOGOUT = 'LOGOUT';

export const FETCH_ACL_SCOPES_INITIATED = 'FETCH_ACL_SCOPES_INITIATED';
export const FETCH_ACL_SCOPES_SUCCESS = 'FETCH_ACL_SCOPES_SUCCESS';
export const FETCH_ACL_SCOPES_FAILURE = 'FETCH_ACL_SCOPES_FAILURE';

export const FETCH_USER_INITIATED = 'FETCH_USER_INITIATED';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const CREATE_USER_INITIATED = 'CREATE_USER_INITIATED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';


export const SEND_OTP_INITIATED = 'SEND_OTP_INITIATED';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET';
export const VERIFY_OTP_INITIATED = 'VERIFY_OTP_INITIATED';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const SET_PASSWORD_INITIATED = 'SET_PASSWORD_INITIATED';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_INITIATED = 'UPDATE_PASSWORD_INITIATED';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const LOGIN_WITH_PASSWORD_INITIATED = 'LOGIN_WITH_PASSWORD_INITIATED';
export const LOGIN_WITH_PASSWORD_SUCCESS = 'LOGIN_WITH_PASSWORD_SUCCESS';
export const LOGIN_WITH_PASSWORD_FAILURE = 'LOGIN_WITH_PASSWORD_FAILURE';

export const AUTHORIZE_USER_INTIATED = 'AUTHORIZE_USER_INTIATED';
export const AUTHORIZE_USER_SUCCESS = 'AUTHORIZE_USER_SUCCESS';
export const AUTHORIZE_USER_FAILURE = 'AUTHORIZE_USER_failure';