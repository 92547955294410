import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../BaseComponents/GenericModal';
import SuccessTick from '@assets/images/success-tick.svg';
import { TEAM_ACCOUNTS_CONSTANTS } from '../../../constants/appConstants';

/**
 *  layover to affirm successful addition of new employee
 *
 *  @param  {object}    props      reacts props
 */
export default function RoleAddedSuccessfully(props) {
  const {
    successText, helpTextEnabled, isOpen, onClose, autoClose, employee
  } = props;
  setTimeout(() => {
    onClose();
  }, autoClose * TEAM_ACCOUNTS_CONSTANTS.MODAL_AUTO_CLOSE_TIMEOUT);
  return (
    <Modal
      modalContentClassName="role-added-successfully"
      isCloseDisabled={true}
      isOpen={ isOpen }
      onClose={ onClose }
      width={ 450 }
    >
      <div>
        <div className="success-tick">
          <SuccessTick width="60" height="60"/>
        </div>
      </div>
      <div>
        <p className="success-text">{ successText }</p>
      </div>
      {
          helpTextEnabled &&
          <div>
            <p className="success-directions">This role will be assigned to { employee.name } once they accept the invitation</p><br></br>
          </div>
      }
    </Modal>
  );
}

RoleAddedSuccessfully.propTypes = {
  autoClose: PropTypes.number,
  helpTextEnabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  successText: PropTypes.string
};

RoleAddedSuccessfully.defaultProps = {
  autoClose: null,
  helpTextDisabled: true,
  isOpen: true,
  successText: 'Successfully Added!'
};
