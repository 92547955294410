/*
 *
 * Merchant actions
 *
 */

import * as C from '../constants/merchantTypes';
import API_ENDPOINTS from '../services/apiConfig';
import { formatErrors } from '../services/utils';
import RestClient from '../services/RestClient';
import { toCamelCase } from '../services/utils';

/**
 * Called on Onboarding (profile page)
 * @param  {dispatch} redux action dispatcher func
 * @param  {param} params in case of api params
 * @return {object} An action object with a type of FETCH_RESELLER_DETAILS
 */
export async function updateMerchantDetails(dispatch, uuid, params) {
  dispatch({ type: C.UPDATE_MERCHANT_DETAILS_INITIATED });
  try {
    const response = await RestClient.put(
      `${API_ENDPOINTS.AUTH.MERCHANTS}/${uuid}`,
      params,
    );
    dispatch({
      type: C.UPDATE_MERCHANT_DETAILS_SUCCESS,
      payload: response.merchant,
    });
    return response;
  } catch (err) {
    const body = await err.response;
    let errorMsgs = formatErrors(body.errors, 'base');
    dispatch({ type: C.UPDATE_MERCHANT_DETAILS_FAILURE, payload: errorMsgs });
  }
}

/**
 * Called on Dashboard (After referring merchant)
 * @param  {dispatch} redux action dispatcher func
 * @param  {merchantUuid} merchant uuid for which activation link is to be sent on SMS.
 * @return {object} An action object with a type of SEND_SMS_LINK
 */
export async function sendSmsLink(dispatch, merchantUuid) {
  dispatch({ type: C.SEND_SMS_LINK_REQUEST_INITIATED });
  try {
    const response = await RestClient.post(
      API_ENDPOINTS.DASHBOARD.SEND_SMS_LINK(merchantUuid),
    );
    dispatch({ type: C.SEND_SMS_LINK_REQUEST_SUCCESS, payload: response });
  } catch (e) {
    const response = await e.response;
    dispatch({ type: C.SEND_SMS_LINK_REQUEST_FAILURE, payload: response });
  }
}

/**
 * Called on transaction tab and onboarding on load
 * @param  {param} params for GET request
 * @return {object} An action object with a type of FETCH_TIMELINE_STATUS
 */
export async function fetchTimelineStatus(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_TIMELINE_STATUS_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.ONBOARDING.STATUS_TIMELINE({ uuid: merchantUuid }));
    dispatch({ type: C.FETCH_TIMELINE_STATUS_SUCCESS, payload: toCamelCase(response) });
  } catch (err) {
    const response = await err.response;
    dispatch({ type: C.FETCH_TIMELINE_STATUS_FAILED, payload: response });
  }
}

/**
 * Called on profile page to fetch associated pricing plan with merchant.
 * @param dispatch
 * @param merchantUuid merchant's uuid to make GET request
 * @returns {object} An action object with a type of FETCH_MERCHANT_PRICING_PLAN
 */
export async function fetchMerchantPricingPlan(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_MERCHANT_PRICING_PLAN_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.ONBOARDING.MERCHANT_PRICING_PLAN({ uuid: merchantUuid }));
    dispatch({ type: C.FETCH_MERCHANT_PRICING_PLAN_SUCCESS, payload: toCamelCase(response) });
  } catch (err) {
    const response = await err.response;
    dispatch({ type: C.FETCH_MERCHANT_PRICING_PLAN_FAILED, payload: response });
  }
}

/**
 * Called on profile page to set merchant's pricing plan.
 * @param dispatch
 * @param merchantUuid merchant's uuid to make PUT request
 * @param pricingPlanUuid pricing plan's uuid to make PUT request
 * @returns {object} An action object with a type of UPDATE_MERCHANT_PRICING_PLAN
 */
export async function updateMerchantPricingPlan(dispatch, merchantUuid, pricingPlanUuid) {
  dispatch({ type: C.UPDATE_MERCHANT_PRICING_PLAN_INITIATED });
  try {
    const response = await RestClient.put(API_ENDPOINTS.ONBOARDING.UPDATE_MERCHANT_PRICING_PLAN({ merchantUuid: merchantUuid,
      pricingPlanUuid: pricingPlanUuid }));
    dispatch({ type: C.UPDATE_MERCHANT_PRICING_PLAN_SUCCESS, payload: toCamelCase(response) });
  } catch (err) {
    const response = await err.response;
    dispatch({ type: C.UPDATE_MERCHANT_PRICING_PLAN_FAILED, payload: response });
  }
}

/**
 * Called on merchant documents page.
 * @param dispatch
 * @param merchantUuid merchant's SALESFORCE_ACCOUNT uuid to make GET request
 * @returns {object} An action object with a type of FETCH_MERCHANT_ACCOUNT_{*}
 */
export async function fetchMerchantAccount(dispatch, accountUuid) {
  dispatch({ type: C.FETCH_MERCHANT_ACCOUNT_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.ONBOARDING.ACCOUNT({ uuid: accountUuid }));
    dispatch({
      payload: response.account,
      type: C.FETCH_MERCHANT_ACCOUNT_SUCCESS
    });
  } catch (err) {
    const response = await err.response;
    dispatch({ type: C.FETCH_MERCHANT_ACCOUNT_FAILURE, payload: response });
  }
}

export function setProfileAsCompleteForTimeline() {
  return {
    type: C.PROFILE_COMPLETED_FOR_TIMELINE,
  };
}

/**
 * called on Business Details Handle Update Address
 * @param {*} dispatch
 * @param {*} merchantUuid merchant UUID
 * @param {*} newAddress New address Details
 */
export async function updateAddressDetails(dispatch, merchantUuid, newAddress) {
  dispatch({ type: C.UPDATE_ADDRESS_DETAILS_INITIATED });
  try {
    const addressDetails = {
      'Registered': {
        address_line: newAddress.new_registration_address_line,
        city: newAddress.new_registration_city,
        pincode: newAddress.new_registration_address_pincode,
        state: newAddress.new_registration_state
      },
      'Operating': {
        address_line: newAddress.new_operating_address_line,
        city: newAddress.new_operating_city,
        pincode: newAddress.new_operating_address_pincode,
        state: newAddress.new_operating_state
      }
    },
    response = await RestClient.put(`${API_ENDPOINTS.ONBOARDING.UPDATE_ADDRESS_DETAILS({ uuid: merchantUuid })}`,
                addressDetails);
    dispatch({
      payload: response,
      type: C.UPDATE_ADDRESS_DETAILS_SUCCESS
    });
    return true;
  } catch (e) {
    const body = await e.response
    let errorMsgs = formatErrors(body);
    dispatch({ payload: errorMsgs, type: C.UPDATE_ADDRESS_DETAILS_FAILURE });
    return new Error(errorMsgs);
  }
}
