import RestClient from '../src/services/RestClient';
import API_ENDPOINTS from '../src/services/apiConfig'
import AuthManager from '../src/services/AuthManager';
import { onRedirectionCLick as redirectToBizDashboard } from '../src/services/utils';
import { config } from 'hub-redux-auth';

import { extractDomainFromString } from '@utils/common';

export function fetchParams(url){
    let queryString = url.split('?')[1];
    let obj = {};
    if (queryString) {
        queryString = queryString.split('#')[0];
        // split our query string into its component parts
        let arr = queryString.split('&');
        for (let i=0; i<arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=');
            // set parameter value (use 'true' if empty)
            let paramValue = typeof(a[1])==='undefined' ? true : a[1];
            obj[a[0]] = paramValue;
        }
    }

    return obj;
}

function RedirectBackUrl(user_token,params_obj,profile_status){
    let url = decodeURIComponent(params_obj["redirect_to"]);
    url = url.concat("?");
    for(let prop in params_obj){
        if(prop!=="redirect_to"){
            url=url.concat(`${prop}=${params_obj[prop]}&`);
        }
    }
    url = url.concat(`profileCompleted=${profile_status}&authToken=${user_token}&merchantId=${JSON.parse(window.localStorage.getItem('user')).mid}`);
    return url;
}
function checkProfileCompletion(merchants) {
    let lob_status = false, doc_status = false;
    merchants["merchants"][0].merchant_statuses.forEach((element)=>{
        if(element.status_type==='LOB' && element.status_value==='Approved' )
            lob_status=true;
        if(element.status_type==='KYC_DOCUMENTS' && ['Docs Approved', 'Exceptionally Approved'].indexOf(element.status_value)>-1)
            doc_status=true;
    });
    return !!(lob_status & doc_status);
}


  export const checkSession = async (callback) => {
    try {
        const paramsObj = fetchParams(window.location.search);
        const redirectTo = paramsObj["redirect_to"] || paramsObj["redirectTo"]
        const redirectDomain = redirectTo && extractDomainFromString(redirectTo)
        if (redirectTo && redirectDomain) {
          const whitelistedDomain = process.env.URL_WHITELIST;
          if (!whitelistedDomain.split(' ').includes(redirectDomain)) {
            callback('redirect_to-not-allowed')
            return;
          }
        } else {
          callback('redirect_to-not-found')
          return;
        }
        if (AuthManager.getFullToken()){
            const bizRedirectionUrl = process.env.BIZ_LOGIN_URL,
              oneDashboardUrl = process.env.ONE_DASHBOARD_HOST,
              reqBody = { credentials: 'omit',
                headers: { Accept: 'application/json',
                  'Content-Type': 'application/json' },
                method: 'GET' },
              merchants = await RestClient.request(`${API_ENDPOINTS.AUTH.MERCHANTS}`, reqBody),
              user = AuthManager.getUser(),
              userToken = AuthManager.getFullToken();

              // if now user does not has access to this merchant then logout it.
              if (!merchants || !merchants['product_accounts'].map(function(m){return m.mid}).includes(user.mid)) {
                await AuthManager.logout('/app/account');
              }
            /*
             * need to set bizRedirectionUrl in config again because different pack in rendered here
             * if bizRedirectionUrl is not set here then redirectToBizDashboard func dont get bizRedirectionUrl
             * which dont redirect the merchant to bizDashboard
             */
            config.set('bizRedirectionUrl', bizRedirectionUrl);
            config.set('oneDashboardUrl', oneDashboardUrl);
            if (user && user.product.toUpperCase() === 'PAYUBIZ'){
              redirectToBizDashboard(redirectTo);
            } else {
              const profileComplete = checkProfileCompletion(merchants);
              window.location.href = RedirectBackUrl(userToken, paramsObj, profileComplete);
            }
        } else {
          window.location.href = `/app/account/signin?redirect_to=${redirectTo}`;
        }
    } catch (err) {
        console.log(err);
        callback('code-break')
        return;
    }

};
/*
 * need to check if session is already present on CO side or not
 * if session is present pass authToken in queryParams along with url present in redirectTo queryParams
 * else redirectTo to signUp or loginIn flow
 */
// checkSession();



