import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-web';

export default class ReactLottie extends React.Component {

  componentDidMount() {
    const options = { ...this.props.options };
    options.container = this.container;
    options.renderer = 'svg';
    this.animation = Lottie.loadAnimation(options);
    this.registerEvents(this.props.eventListeners);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.animation.destroy();
  }

  registerEvents(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.animation.addEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  render() {
    const storeContainer = el => this.container = el;
    return <div className="react-lottie-container" ref={storeContainer} />;
  }
}

ReactLottie.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object.isRequired,
};

ReactLottie.defaultProps = {
  eventListeners: [],
};
