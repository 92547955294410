import axios from "axios"
import API_ENDPOINTS from "../../apiConfig"
import { useFetch } from "./useFetch"
import AuthManager from "../../AuthManager"

export const useMerchantParams = (props = {}) => {
  return useFetch({
    fetchFn: () => axios.get(
      API_ENDPOINTS.MERCHANT_PARAMS,
      {
        headers: {
          mid: AuthManager.getMID(),
        }
      }
    ),
    ...props,
  })
}
