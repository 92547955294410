import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { config } from 'hub-redux-auth';
import NowBankDetailsForm from '../../components/NowBankDetails';
import PaymentLogo from '@assets/images/payments.png';
import BrandLogo from '@assets/images/payunow-brand-logo-red.svg';
import LoadingPage from '../../components/BaseComponents/LoadingPage';
import NeftFallbackScreen from '../../components/NeftFallbackScreen';
import AuthManager from '../../services/AuthManager';
import { sessionCheck } from '../../actions/auth';
import { updateMerchantDetails } from '../../actions/merchant';
import { PENNY_STATUS } from '../../constants/profileConstants';
import _ from 'lodash';
import { getContactUsLink, getBusinessAndAccountHolderNameForNow } from '../../services/utils';
import {
  fetchBusinessEntities,
  fetchCityStateFromPincode,
} from '../../actions/profile';
import {
  checkIsNowBankDetailsFilled,
  checkIsPanDetailsFilled,
  selectPennyStatus,
  selectPennyDepositMethod,
} from '../../reducers/selectors';
import {
  INDIVIDUAL,
} from '../../constants/profileConstants';

const Loader = ({ mid }) => {
  return <LoadingPage loadingText={"PayU is doing a test transaction\nto verify  your bank account..."} authParams={`?authToken=${AuthManager.getFullToken()}&merchantId=${mid}`}/>
};

function mapStateToProps(state) {
  const user = state.session.user || {};
  return {
    user,
    product: state.product,
    session: state.session,
    businessEntities: state.masterData.businessEntities,
    isNowBankDetailsFilled: checkIsNowBankDetailsFilled(state),
    isPanFilled: checkIsPanDetailsFilled(state),
    mid: user.mid,
    pennyStatus: selectPennyStatus(state),
    pennyDepositMethod: selectPennyDepositMethod(state),
  };
}
@connect(mapStateToProps)
export default class BankDetailsFlow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNeftScreen: false,
      showLoadingScreen: false,
      showPennyDetails: false,
      errors: [],
      messageIndex: 1,
    };

    const { dispatch } = this.props;
    sessionCheck(dispatch);

    this.onNowBankDetailsSubmit = this.onNowBankDetailsSubmit.bind(this);
    this.revertLoader = this.revertLoader.bind(this);
  }

  componentWillMount() {
    if(this.props.product != 'NOW') {
      this.props.history.push('/onboarding')
    }
    fetchBusinessEntities(this.props.dispatch);
  }

  componentWillReceiveProps(nextProps) {
    const { pennyDepositMethod, pennyStatus } = this.props;
    if (pennyDepositMethod !== nextProps.pennyDepositMethod || pennyStatus !== nextProps.pennyStatus) {
      clearTimeout(window.neftTimeout);
      this.setState({ messageIndex: 1 });
      if (nextProps.pennyStatus === PENNY_STATUS.SUCCESS || nextProps.pennyStatus === PENNY_STATUS.FAILED) {
        this.setState({ showLoadingScreen: false });
      }
    }
    if (nextProps.pennyDepositMethod === 'IMPS' && nextProps.pennyStatus === PENNY_STATUS.NOT_INITIATED) {
      this.setState({ showLoadingScreen: true });
      this.resetErrors();
    } else if (nextProps.pennyDepositMethod === 'IMPS' && nextProps.pennyStatus === PENNY_STATUS.SUCCESS) {
      this.setState({ showPennyDetails: true });
    } else if (nextProps.pennyDepositMethod === 'NEFT' && nextProps.pennyStatus === PENNY_STATUS.NOT_INITIATED) {
      this.setState({ showNeftScreen: true, showLoadingScreen: false });
    } else if (nextProps.pennyStatus === PENNY_STATUS.FAILED) {
      this.setState({ errors: ['Bank Details are incorrect'] });
    }
  }

  onNowBankDetailsSubmit = values => () => {
    this.resetErrors();
    window.neftTimeout = setTimeout(() => {
      this.setState({
        showLoadingScreen: false,
        showNeftScreen: true,
      });
    }, 25000);
    const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == values.business_entity_id);
    const individualBusinessEntity = this.props.businessEntities.find(o => o.name == INDIVIDUAL);
    const filingStatus = selectedBusinessEntity ? selectedBusinessEntity.name : INDIVIDUAL;
    const { businessName, holderName } = getBusinessAndAccountHolderNameForNow(values, filingStatus);
    const params = {
      merchant: {
        bank_detail: {
          ifsc_code: values.ifsc_code,
          bank_account_number: values.bank_account_number,
          holder_name: holderName,
        },
        pancard_name: values.pancard_name,
        pancard_number: values.pancard_number,
        business_name: businessName,
        business_entity_id: (selectedBusinessEntity && selectedBusinessEntity.id) || (individualBusinessEntity && individualBusinessEntity.id),
      },
    };
    return updateMerchantDetails(this.props.dispatch, this.props.user.uuid, params);
  }

  impsWait = () => {
    const message = 'Its taking longer than expected...';
    setTimeout(() => { this.setState({ messageIndex: 2 }) }, 30000);
    return (<div class="now-message margin-20">
      <div className="loader loader-1 margin-20" />
      {
        this.state.messageIndex == 1 ? <div className="text-center text"> We are depositing small amount (&#8377;1-5) in your bank account </div>:
        <div className="text-center text"> { message } </div>
      }
    </div>);
  }

  fetchMerchant = () => {
    const { dispatch } = this.props;
    sessionCheck(dispatch);
  }

  revertLoader(err) {
    this.setState({
      showLoadingScreen: false,
      errors: err,
    })
  }

  resetErrors = () => this.setState({ errors: [] })

  render() {
    if(this.state.showPennyDetails && !this.state.showNeftScreen) return (<Redirect to='penny-details' />)

    const { user } = this.props.session;
    const { mid } = this.props;
    const { isNowBankDetailsFilled } = this.props;
    const merchant = user || {};
    const bankDetailProps = {
      isCompleted: false,
      initialValues: {
        ...merchant.bank_detail,
        business_entity_id: merchant.business_entity_id,
        pancard_name: merchant.pancard_name,
        pancard_number: merchant.pancard_number,
        business_name: merchant.business_name,
        bank_update_attempt_left: merchant.bank_update_attempt_left,
      },
      user,
      businessFilingStatus: this.props.businessFilingStatus,
      businessEntities: this.props.businessEntities,
      businessName: this.props.businessName,
      onSubmit: this.onNowBankDetailsSubmit,
      revertLoader: this.revertLoader,
      errors: this.state.errors,
      disableForm: isNowBankDetailsFilled && !this.state.errors,
      isPanFilled: this.props.isPanFilled,
      bankFlow: true,
      resetErrors: this.resetErrors,
      fetchLocation: params =>
        fetchCityStateFromPincode(this.props.dispatch, params),
    }

    const content = (<div className="bank-details-flow">
        <div className="wave-content" />
        <div className="page-container first-section">
          <div className="page-container-inner">
            <header className="main-topheader colorize">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 clearfix">
                    <a title="PayUmoney" className="main-logo" href={`${config.get('productHost')}?authToken=${AuthManager.getFullToken()}&merchantId=${mid}`}>
                      <BrandLogo width="95" height="27" />
                    </a>
                    <a className='clickable-txt noselect logout-text' href='/account/service_logout' >LOGOUT</a>
                  </div>
                </div>
              </div>
            </header>
            <div className="container">
              <h1 className="text-center">
                <p>Provide bank details</p>
                <p>to setup your payment gateway</p>
              </h1>
              <div className="otp-sub-header"></div>
              <div className="now-profile landing-form-box">
                <NowBankDetailsForm {...bankDetailProps} />
                <div>
                  {this.state.showLoadingScreen ? this.impsWait() : ''}
                </div>
                <small className="trbl">
                  Facing any trouble?{' '}
                  <a href={getContactUsLink(`issue=Account Related&authToken=${AuthManager.getFullToken()}&merchantId=${mid}\``)} target="_blank">
                    {' '}
                    contact us
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center m-0 mt-4 pl-2 pr-2 pb-3">
          <div className="col-md-5 col-sm-5">
            <img src={PaymentLogo} alt="payment-logo" className="img-fluid" />
          </div>
        </div>
      </div>
    );


		let formContent = this.state.showNeftScreen ? <NeftFallbackScreen user={user} /> : content;
		let renderContent = this.state.showLoadingScreen ? <Loader mid={mid} /> : formContent ;
    return renderContent;
  };
};

