import { takeLatest, call, put, select } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import RestClient from '@services/RestClient';
import axios from 'axios';
import ClevertapHandler from "../../../src/services/cleverTap";
import { CLEVERTAP_EVENTS } from "../../../src/constants/cleverTapEvents";
//  Action types
export const actionTypes = {
  fetchBankNames: 'fetchBankNames/INITIATE',
  fetchBankNamesSuccess: 'fetchBankNames/SUCCESS',
  fetchBankNamesFailed: 'fetchBankNames/Failed',
  fetchBankStates: 'fetchBankStates/INITIATE',
  fetchBankStatesSuccess: 'fetchBankStates/SUCCESS',
  fetchBankCities: 'fetchBankCities/INITIATE',
  fetchBankCitiesSuccess: 'fetchBankCities/SUCCESS',
  fetchBranches: 'fetchBranches/INITIATE',
  fetchBranchesSuccess: 'fetchBranches/SUCCESS',
  submitSearchIFSC: 'submitSearchIFSC/CAPTURE',
  resetSearchForm: 'searchForm/RESET',
  fetchBranchDetails: 'fetchBranchDetails/INITIATE',
  resetBranchDetails: 'fetchBranchDetails/RESET',
};

const initialState = {
  bank_names: [],
  states: [],
  cities: [],
  branches: [],
  details: null,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchBankNamesSuccess: {
      return { ...initialState, bank_names: action.payload, details: state.details }
    }
    case actionTypes.fetchBankStatesSuccess: {
      return {
        ...initialState,
        bank_names: state.bank_names,
        states: action.payload,
        details: state.details,
      }
    }
    case actionTypes.fetchBankCitiesSuccess: {
      return {
        ...state,
        cities: action.payload,
        branches: [],
      }
    }
    case actionTypes.fetchBranchesSuccess: {
      return { ...state, branches: action.payload, }
    }
    case actionTypes.submitSearchIFSC: {
      return { ...state, details: action.payload }
    }
    case actionTypes.resetBranchDetails: {
      return { ...state,  details: null }
    }
    case actionTypes.resetSearchForm: {
      return { ...initialState, details: state.details, }
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getBankNames: state => state.onboardingApp.IFSC.bank_names,
  getStates: state => state.onboardingApp.IFSC.states,
  getCities: state => state.onboardingApp.IFSC.cities,
  getBranches: state => state.onboardingApp.IFSC.branches,
  getIFSCDetails: state => state.onboardingApp.IFSC.details,
};


//  Actions
export const actions = {
  fetchBankNames: () => ({ type: actionTypes.fetchBankNames }),
  fetchBankStates: bankName => ({ type: actionTypes.fetchBankStates, payload: bankName }),
  fetchBankCities: (bankName, state) => ({
    type: actionTypes.fetchBankCities,
    payload: { bank_name: bankName, state }
  }),
  fetchBranches: (bankName, state, city) => ({
    type: actionTypes.fetchBranches,
    payload: { bank_name: bankName, state, city }
  }),
  submitSearchIFSC: details => ({ type: actionTypes.submitSearchIFSC, payload: details }),
  resetSearchForm: () => ({ type: actionTypes.resetSearchForm }),
  fetchBranchDetails: ifsc_code => ({ type: actionTypes.fetchBranchDetails, payload: ifsc_code }),
};

function* fetchBankNamesWorker() {
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.IFSC.BANK_NAMES
    ) || { data: { bank_names: [] } };
    yield put({ type: actionTypes.fetchBankNamesSuccess, payload: response.data.bank_names })
  } catch (err) {
    const errResponse = yield err.response;
  }
}

function* fetchBankStatesWorker(action) {
  const { payload: bank_name } = action;
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.IFSC.STATES({ bank_name }),
    ) || { data: { states: [] } };
    yield put({ type: actionTypes.fetchBankStatesSuccess, payload: response.data.states })
  } catch (err) {
    const errResponse = yield err.response;
  }
}

function* fetchBankCitiesWorker(action) {
  const { payload: { bank_name, state } } = action;
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.IFSC.CITIES({ bank_name, state }),
    ) || { data: { cities: [] } };
    yield put({ type: actionTypes.fetchBankCitiesSuccess, payload: response.data.cities })
  } catch (err) {
    const errResponse = yield err.response;
  }
}

function* fetchBranchesWorker(action) {
  const { payload: { bank_name, state, city } } = action;
  try {
    const response = yield call(
      [axios, 'get'],
      API_ENDPOINTS.IFSC.BRANCHES({ bank_name, state, city }),
    ) || { data: { ifsc_details: [] } };
    yield put({ type: actionTypes.fetchBranchesSuccess, payload: response.data.ifsc_details })
  } catch (err) {
    const errResponse = yield err.response;
  }
}

function* fetchBranchDetailsWorker(action) {
  const { payload: ifsc_code } = action;
  try {
    const { data } = yield call(
      [axios, 'get'],
      API_ENDPOINTS.IFSC.DETAILS(ifsc_code),
    ) || { data: { ifsc_detail: [] } };
    yield put({ type: actionTypes.submitSearchIFSC, payload: data.ifsc_detail });
    ClevertapHandler.sendEvent({["CTA Name"] : CLEVERTAP_EVENTS.IFSC_CODE_VERIFIED})
  } catch (err) {
    const errResponse = yield err.response;
    yield put({ type: actionTypes.resetBranchDetails });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchBankNames, fetchBankNamesWorker);
  yield takeLatest(actionTypes.fetchBankStates, fetchBankStatesWorker);
  yield takeLatest(actionTypes.fetchBankCities, fetchBankCitiesWorker);
  yield takeLatest(actionTypes.fetchBranches, fetchBranchesWorker);
  yield takeLatest(actionTypes.fetchBranchDetails, fetchBranchDetailsWorker);
}