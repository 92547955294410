import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import GenericModal from '../BaseComponents/GenericModal';
import GenericForm from '../BaseComponents/GenericForm';
import GenericFormFields from '../BaseComponents/GenericFormFields';
import cleverTap from '../../services/cleverTap';
import {
  required,
  amountRegx,
  minValue,
  maxLength,
  onlyNumber,
  maxValue,
  nonZeroAmountRegex,
} from '../../services/validations';
import { fetchBusinessCategories} from '../../actions/profile';
import { updateMerchantDetails } from '../../actions/merchant';
import { EXPECTED_SALES_OPTIONS } from '../../constants/profileConstants';

@connect(mapStateToProps)
export default class AgreementForm extends React.Component {
  state = {
    isBusinessCategoryOpen: false,
    availableBusinessSubCategories: [],
    loading: false,
  };
  
  async componentWillMount() {
    const { business_category_id } = this.props.initialValues;
    await fetchBusinessCategories(this.props.dispatch)
    const { businessCategories } = this.props;
    if (business_category_id && businessCategories.length !== 0) {
      this.fetchBusinessSubCategories(businessCategories,business_category_id )
    }
  }

  handleBusinessCategoryChange = ({ value: selectedOption, key: id }) => {
    const { businessCategories } = this.props;
    cleverTap.event('Business type selected', { type: selectedOption, mid: this.props.mid });
    this.fetchBusinessSubCategories(businessCategories,id )
  };

  fetchBusinessSubCategories = (businessCategories, business_category_id) => {
    const selectedBusinessCategory = businessCategories.find(item => item.id == business_category_id);
    const { business_sub_categories: availableBusinessSubCategories } = selectedBusinessCategory || {};
    if (Array.isArray(availableBusinessSubCategories)) {
      this.setState({
        availableBusinessSubCategories,
      });
    }
  }

  handleSubmit = async (values) => {
    const response = await updateMerchantDetails(this.props.dispatch, this.props.merchantUuid, values);
    if(response.merchant) {
      this.props.onSubmit();
    }
  }
  
  getModalContent() {
    let businessType;
    let businessSubType;
    let expectedMonthySales;

    const {
      businessCategories,
      initialValues,
    } = this.props;

    const {
      business_category_id,
      business_sub_category_id,
      monthly_expected_volume,
    } = initialValues;

    const { availableBusinessSubCategories } = this.state;
    businessType = (
      <Field
        type="custom-select"
        name="business_category_id"
        component={GenericFormFields}
        label="Business Type"
        validate={business_category_id ? null : [required]}
        cssClass={`select_box business-status ${business_category_id ? 'disabled' : ''}`}
        selectedOption={business_category_id} 
        dropDownItems={_.chain(businessCategories)
          .keyBy('id')
          .mapValues('name')
          .value()}
        disable={!!business_category_id}
        onSelect={this.handleBusinessCategoryChange}
      />
    );

    businessSubType = (
      <Field
        type="custom-select"
        name="business_sub_category_id"
        component={GenericFormFields}
        label="Business Sub Type" 
        validate={business_sub_category_id ? null :[required]}
        cssClass={`select_box business-status ${business_sub_category_id ? 'disabled' : ''}`}
        selectedOption={business_sub_category_id}
        dropDownItems={_.chain(availableBusinessSubCategories)
          .keyBy('id')
          .mapValues('name')
          .value()}
        disable={!!business_sub_category_id}
      />
    );

    expectedMonthySales = (
      <Field
        type="custom-select"
        name="monthly_expected_volume"
        component={GenericFormFields}
        label="Expected Monthly Sales"
        validate={[required]}
        selectedOption={monthly_expected_volume}
        dropDownItems={EXPECTED_SALES_OPTIONS}
      />
    );

    return (
      <GenericForm
        form="AgreementForm"
        showLoader={this.state.loading}
        onSubmit={this.handleSubmit}
        btnText="SUBMIT"
        btnClassName="cmn-btn bg agreement-submit"
        wrapperClassName="agreement-form"
        initialValues={this.props.initialValues}
      >
        {businessType}
        {businessSubType}
        {expectedMonthySales}
      </GenericForm>
    );
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <GenericModal
        isOpen={isOpen}
        onClose={onClose}
        contentClassName="agreement-popup"
        modalTitle="Fill the details below to prepare your service agreement"
      >
        {this.getModalContent()}
      </GenericModal>
    );
  }
}
function mapStateToProps(state) {
  return {
    businessCategories: state.masterData.businessCategories,
  };
}