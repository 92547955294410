import React from "react";
import { PayuIcon } from "payu-ui-v2";

export const Icon = ({ type, size, variant, color, className, onClick = () => {} }) => {

  return (
    <PayuIcon
      type={type}
      variant={variant}
      size={size}
      color={color}
      className={className}
      onClick={onClick}
    />
  );
}