import React from 'react';
import PropTypes from 'prop-types';
import GenericModal from '../GenericModal';
import RadioButton from '../RadioButton';
import FileUploaderButton from '../FileUploaderButton';
import _ from 'lodash';

export default class GenericRadioListModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const { coachMark, label, value } = this.getOptions()[0];
    this.state = {
      selectedOption: { coachMark, label, value }
    };
    this.onRadioClick = this.onRadioClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.radioList, this.props.radioList)) {
      const { coachMark, label, value } = nextProps.radioList[0];
      this.setState({ selectedOption: { coachMark, label, value } });
    }
  }

  getOptions() {
    return this.props.radioList;
  }

  onRadioClick(e, index) {
    const { coachMark, label } = this.getOptions()[index];
    this.setState({
      selectedOption: {
        coachMark: coachMark,
        value: e.target.value,
        label: label
      }
    });
    this.props.onOptionSelect(label);
  }

  createRadioButton(option, index) {
    return (
      <RadioButton
        label={option.label}
        key={index}
        selectedOption={this.state.selectedOption.label}
        onRadioClick={e => this.onRadioClick(e, index)}
        value={option.value}
      >
        <div className="radio-sublabel">{option.subLabel}</div>
      </RadioButton>
    );
  }

  renderOptions() {
    const options = this.getOptions();
    return options.map((option, index) =>
      this.createRadioButton(option, index)
    );
  }

  handleInputChange(e) {
    const { value, label } = this.state.selectedOption;
    this.props.onInputChange(e, value, label);
  }

  handleClick = e => {
    const { value, label } = this.state.selectedOption;
    this.props.onClick(e, value, label);
  };

  onClose() {
    const { coachMark, label, value } = this.props.radioList[0];
    this.setState({ selectedOption: { coachMark, label, value } });
    this.props.onClose();
  }

  render() {
    return (
      <GenericModal
        isOpen={this.props.isOpen}
        modalTitle={this.props.modalTitle}
        contentClassName="upload-address-proof-modal text-center pb-0"
        onClose={this.onClose}
      >
        <div className="options-wrapper">{this.renderOptions()}</div>
        { this.state.selectedOption.coachMark &&
          <span className="document-coachmark">{this.state.selectedOption.coachMark}</span>
        }
        <div className="bottom-section">
          <FileUploaderButton
            buttonLabel="BROWSE"
            buttonClassName="dflt-btn width-250"
            onInputChange={this.handleInputChange}
            onButtonClick={this.handleClick}
          />
          <p>(Only jpeg, jpg, pdf and png allowed)</p>
        </div>
      </GenericModal>
    );
  }
}

GenericRadioListModal.propTypes = {
  onOptionSelect: PropTypes.func,
  modalTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isCloseDisabled: PropTypes.bool
};

GenericRadioListModal.defaultProps = {
  onOptionSelect: () => { },
  onClick: () => { },
  modalTitle: '',
  isOpen: true,
  isCloseDisabled: true
};
