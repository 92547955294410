import React from 'react';

import GenericModal from '../BaseComponents/GenericModal';

export default function AgreementLoader(props) {
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={!props.onOpen}
      modalTitle="Generating Service Agreement"
      contentClassName="service-agreement-loader-popup"
      isCloseDisabled={props.isCloseDisabled}
    >
      <div className="loader" />
    </GenericModal>
  );
};
