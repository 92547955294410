import React from 'react';
import PropTypes from 'prop-types';
import { deleteRole as deleteAclRole,
  moveInvitesToNewRole } from '../../../actions/team_account';
import UserConfirmation from './UserConfirmation';
import MoveUsersToNewRole from './MoveUsersToNewRole';
import cleverTap from '../../../services/cleverTap';

export default class DeleteRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = { areUsersMoved: false,
      isRoleDeleted: false,
      openConfirmationModal: true };
  }

  deleteRole = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    cleverTap.event('Dashboard CTA clicked', {
        'CTA Name': 'Role deleted',
        'CTA Page': 'Manage roles',
        'CTA Type': 'Action',
        'Email ID': user.email,
        MID: user.mid,
    });
    const { aclRole, dispatch, merchantUuid } = this.props,
      response = await deleteAclRole(dispatch, merchantUuid, aclRole.uuid);
    return response;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ openConfirmationModal: nextProps.isModalOpen })
  }

  onClose = () => {
    this.setState({ openConfirmationModal: false });
    this.props.closeDeleteModal();
  }

  handleUserMove = async (selectedRoleId) => {
    const { aclRole, dispatch, merchantUuid } = this.props;
    await moveInvitesToNewRole(
      dispatch,
      merchantUuid, { newRoleId: selectedRoleId,
        roleId: aclRole.uuid }
        );
    // check if status is 204
    this.setState({ areUsersMoved: true });
  }

  render() {
    const { aclRole, dispatch,
      aclRole: { staff_invite_count: staffInviteCount }, isFetching } = this.props,
     { areUsersMoved, openConfirmationModal } = this.state;
    if (areUsersMoved || !staffInviteCount) {
      return <UserConfirmation
        onClose={this.onClose}
        aclRoleName={aclRole.name}
        isFetching={isFetching}
        isOpen={openConfirmationModal }
        onDeleteRole={this.deleteRole}
        dispatch={dispatch}
        staffInviteCount={staffInviteCount}
      />;
    }
      return <MoveUsersToNewRole
        onUserMove={this.handleUserMove}
        isFetching={isFetching}
        isOpen={true}
        aclRole={aclRole}
        onClose={this.onClose}
        dispatch={dispatch}
      />;

  }
}

DeleteRole.propTypes = {
  aclRole: PropTypes.object,
  closeDeleteModal: PropTypes.func,
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.string,
  merchantUuid: PropTypes.string
}
