/*
 *
 * Profile actions
 *
 */

import * as C from '../constants/documentTypes';
import API_ENDPOINTS from '../services/apiConfig';
import RestClient from '../services/RestClient';
import { CLEVERTAP_EVENTS_MAP } from '../constants/cleverTapEvents';
import cleverTap from '../services/cleverTap';
import { INDIVIDUAL_DOC_UPLOAD_STATUS } from "../constants/statusCodeConstants";
import { formatErrors } from '../services/utils';


/**
 * Called on DocumentContainer init, this fetches all the documents submitted including agreement
 * @param  {merchantUuid} merchant uuid
 * @return {object} An action object with a type of FETCH_SUBMITTED_DOC_LIST
 */
export async function fetchSubmittedDocs(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_SUBMITTED_DOC_LIST_INITIATED });
  const url = `${API_ENDPOINTS.DOCUMENTS.FETCH_DOCUMENTS({ uuid: merchantUuid })
    }?status[]=${INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_SUBMITTED}&status[]=${
    INDIVIDUAL_DOC_UPLOAD_STATUS.ACCEPTED}&status[]=${
    INDIVIDUAL_DOC_UPLOAD_STATUS.COUNTER_SIGNED}&status[]=${INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_REJECTED}&status[]=${
    INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_VERIFIED}&status[]=${INDIVIDUAL_DOC_UPLOAD_STATUS.DOCUMENT_REUPLOADED}`;
  try {
    const response = await RestClient.get(url);
    dispatch({ type: C.FETCH_SUBMITTED_DOC_LIST_SUCCESS, payload: response });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.FETCH_SUBMITTED_DOC_LIST_FAILURE, payload: body });
    throw { message: body };
  }
}

/**
 * Called on DocumentContainer on upload document btn
 * @param  {merchantUuid} merchant uuid
 * @param  {param} params for POST request
 * @return {object} An action object with a type of UPLOAD_DOCUMENT
 */
export async function uploadDocument(dispatch, merchantUuid, params) {
  dispatch({ type: C.UPLOAD_DOCUMENT_INITIATED });
  try {
    const response = await RestClient.postWithFile(
      API_ENDPOINTS.DOCUMENTS.UPLOAD_DOCUMENT({ uuid: merchantUuid }),
      params,
    );
    dispatch({ type: C.UPLOAD_DOCUMENT_SUCCESS, payload: response });
    return response;
  } catch (err) {
    console.log(err);
    const body = await err.response;
    dispatch({ type: C.UPLOAD_DOCUMENT_FAILED, payload: body });
    throw { message: body };
  }
}

/**
 * Called on DocumentContainer on remove document btn
 * @param  {merchantUuid} merchant uuid
 * @param  {param} params for POST request
 * @return {object} An action object with a type of UPLOAD_DOCUMENT
 */
export async function removeDocument(dispatch, merchantUuid, params) {
  dispatch({ type: C.REMOVE_DOCUMENT_INITIATED });
  try {
    const { uuid: docId } = params.fileObject;
    const response = await RestClient.delete(API_ENDPOINTS.DOCUMENTS.REMOVE_DOCUMENT({ uuid: merchantUuid, docId }));
    dispatch({
      type: C.REMOVE_DOCUMENT_SUCCESS,
      payload: { ...params.fileObject },
    });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.REMOVE_DOCUMENT_FAILED, payload: body });
    throw { message: body };
  }
}


/**
 * Called on DocumentContainer on upload document btn
 * @param  {merchantUuid} merchant uuid
 * @param  {param} params for POST request
 * @return {object} An action object with a type of UPLOAD_DOCUMENT
 */
export async function uploadDocumentAttachment(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.postWithFile(
      API_ENDPOINTS.DOCUMENTS.UPLOAD_DOC_ATTACHMENT({ uuid: merchantUuid }),
      params,
    );
    return response;
  } catch (err) {
    const body = await err.response;
    throw { message: body };
  }
}


/**
 * Called on DocumentContainer for fetching mutiple docs already uploaded
 * @param  {param} params for GET request
 * @return {object} Server response
 */
export async function fetchDocument(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.get(
      API_ENDPOINTS.DOCUMENTS.FETCH_DOCUMENTS({ uuid: merchantUuid }),
      params,
    );
    if (response.kyc_documents.length !== 0) {
      const payload = response.kyc_documents[0];
      return payload;
    }
    return { uploaded_documents: [] };
  } catch (err) {
    const body = await err.response;
    throw { message: body };
  }
}


/**
 * Called on DocumentContainer on remove document btn
 * @param  {merchantUuid} merchant uuid
 * @param  {param} params for POST request
 * @return {object} An action object with a type of UPLOAD_DOCUMENT
 */
export async function removeDocumentAttachment(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.delete(
      API_ENDPOINTS.DOCUMENTS.REMOVE_DOCUMENT_ATTACHMENT({ uuid: merchantUuid }),
      params,
    );
    return response;
  } catch (err) {
    const body = await err.response;
    throw { message: body };
  }
}

/**
 * Called on DocumentContainer on submitting multiple docs to merge
 * @param  {param} params for POST request
 * @return {object} Server Response
 */
export async function mergeDocuments(dispatch, merchantUuid, docId, params) {
  dispatch({ type: C.UPLOAD_DOCUMENT_INITIATED });
  try {
    const response = await RestClient.put(
      API_ENDPOINTS.DOCUMENTS.MERGE_DOCUMENTS({ uuid: merchantUuid, docId }),
      params,
    );
    dispatch({ type: C.UPLOAD_DOCUMENT_SUCCESS, payload: response });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.UPLOAD_DOCUMENT_FAILED, payload: body });
    throw { message: body };
  }
}

/**
 * Called on DocumentContainer to fetch generated service agreement.
 * @param dispatch
 * @param merchantUuid
 * @returns {Promise}
 */
export async function fetchGeneratedServiceAgreements(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_GENERATED_SERVICE_AGREEMENT_INITIATED });
  const url = API_ENDPOINTS.DOCUMENTS.FETCH_GENERATED_AGREEMENTS({ uuid: merchantUuid });
  try {
    const response = await RestClient.get(url);
    dispatch({ type: C.FETCH_GENERATED_SERVICE_AGREEMENT_SUCCESS, payload: response });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.FETCH_GENERATED_SERVICE_AGREEMENT_FAILURE, payload: body });
    throw { message: body };
  }
}

export async function generateServiceAgreement(dispatch, merchantUuid, params) {
  dispatch({ type: C.GENERATE_SERVICE_AGREEMENT_INITIATED });
  try {
    const response = await RestClient.post(
      API_ENDPOINTS.DOCUMENTS.GENERATE_SERVICE_AGREEMENT({ uuid: merchantUuid }),
      params,
    );
    // fetchGeneratedServiceAgreements(dispatch, merchantUuid);
    dispatch({ type: C.GENERATE_SERVICE_AGREEMENT_SUCCESS, payload: response });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.GENERATE_SERVICE_AGREEMENT_FAILED, payload: body });
    throw { message: body };
  }
}

export async function fetchDocumentCategoryAndTypeIds(dispatch) {
  dispatch({ type: C.FETCH_DOCUMENT_CATEGORY_AND_TYPES_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.DOCUMENTS.FETCH_DOCUMENT_CATEGORY_AND_TYPES);
    dispatch({ type: C.FETCH_DOCUMENT_CATEGORY_AND_TYPES_SUCCESS, payload: response });
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.FETCH_DOCUMENT_CATEGORY_AND_TYPES_FAILURE, payload: body });
    throw { message: body };
  }
}


/* Called on DocumentContainer on submit document
 * @param  {merchantUuid} merchant uuid
 * @return {object} An action object with a type of SUBMIT_DOCUMENTS
 */
export async function submitDocuments(dispatch, merchantUuid) {
  dispatch({ type: C.SUBMIT_DOCUMENTS_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.DOCUMENTS.SUBMIT_DOCUMENTS({ uuid: merchantUuid }));
    // intensional timeout for lottie animation
    setTimeout(() => dispatch({ type: C.SUBMIT_DOCUMENTS_SUCCESS, payload: response.merchant }), 2000);
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.SUBMIT_DOCUMENTS_FAILED });
    throw { message: body };
  }
}

/* Called on DocumentContainer on submit document
 * @param  {merchantUuid} merchant uuid
 * @return {object} An action object with a type of SUBMIT_DOCUMENTS
 */
export async function finalDocSubmit(dispatch, merchantUuid) {
  dispatch({ type: C.SUBMIT_DOCUMENTS_INITIATED });
  try {
    const response = await RestClient.post(API_ENDPOINTS.DOCUMENTS.FINAL_DOC_SUBMIT({ uuid: merchantUuid }));
    // intensional timeout for lottie animation
    setTimeout(() => dispatch({ type: C.SUBMIT_DOCUMENTS_SUCCESS, payload: response.merchant }), 2000);
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.SUBMIT_DOCUMENTS_FAILED });
    throw { message: body };
  }
}

// action to store  accept timestamp for generate agreement
export async function acceptServiceAgreement(dispatch, merchantUuid) {
  try {
    dispatch({ type: C.ACCEPT_SERVICE_AGREEMENT_INITIATED });
    const response = await RestClient.post(API_ENDPOINTS.DOCUMENTS.ACCEPT_SERVICE_AGREEMENT({ uuid: merchantUuid }));
    dispatch({ type: C.ACCEPT_SERVICE_AGREEMENT_SUCCESS, payload: response });
    dispatch({ type: C.UPLOAD_DOCUMENT_SUCCESS, payload: response });
    cleverTap.event(CLEVERTAP_EVENTS_MAP.SERVICE_AGREEMENT_ACCEPTED, {
      MID: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).mid
    })
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.ACCEPT_SERVICE_AGREEMENT_FAILED, payload: body });
    throw { message: body };
  }
}

export async function downloadSigningAuthorityLetter(dispatch, merchant_uuid) {
  dispatch({ type: C.DOWNLOAD_AUTHORITY_LETTER_INITIATED });
  try {
    const response = await RestClient.get(API_ENDPOINTS.DOCUMENTS.DOWNLOAD_SIGNING_AUTHORITY_LETTER({ uuid: merchant_uuid }));
    dispatch({ type: C.DOWNLOAD_AUTHORITY_LETTER_SUCCESS, payload: response.kyc_document });
  } catch (e) {
    const body = await e.response;
    const erroMsgs = 'Some error occured. Please try again later';
    dispatch({ type: C.DOWNLOAD_AUTHORITY_LETTER_FAILED, payload: erroMsgs });
  }
}

export async function uploadSigningAuthorityLetter(dispatch, merchantUuid, params) {
  try {
    const response = await RestClient.postWithFile(
      API_ENDPOINTS.DOCUMENTS.UPLOAD_DOCUMENT({ uuid: merchantUuid }),
      params,
    );
    dispatch({ type: C.UPLOAD_AUTHORITY_LETTER_SUCCESS, payload: response.kyc_document });
    return response;
  } catch (err) {
    console.log(err);
    const body = await err.response;
    dispatch({ type: C.UPLOAD_AUTHORITY_LETTER_FAILED, payload: body });
    throw { message: body };
  }
}

export async function updateSigningAuthorityDetails(dispatch, accountId, values) {
  dispatch({ type: C.UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_INITIATED });
  try {
    const params = {
      account: { signatory_contact_details_attributes: values },
    };
    const response = await RestClient.put(API_ENDPOINTS.DOCUMENTS.UPDATE_SIGNING_AUTHORITY_DETAILS({ uuid: accountId }), params);
    dispatch({
      payload: response.account,
      type: C.UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_SUCCESS
    });
    return response;
  } catch (err) {
    const body = await err.response;
    dispatch({ type: C.UPDATE_SIGNING_AUTHORITY_LETTER_DETAILS_FAILED, payload: body });
    throw { messae: body };
  }
}

export async function sendSignatoryOtp(dispatch, merchantUuid, docUuid, params) {
  try {
    dispatch({ type: C.SEND_SIGNATORY_OTP_INITIATED });
    const response = await RestClient.post(API_ENDPOINTS.DOCUMENTS.SEND_SIGNATORY_OTP({ merchantUuid, docUuid }), params);
    dispatch({ type: C.SEND_SIGNATORY_OTP_SUCCESS });

    return response;
  } catch (err) {
    const body = await err.response;
    if (body.error) {
      const erroMsgs = formatErrors(body);
      dispatch({ type: C.SEND_SIGNATORY_OTP_FAILURE, payload: erroMsgs });
    } else if (body.errors) {
      dispatch({ type: C.SEND_SIGNATORY_OTP_FAILURE, payload: { error: [body.errors.message], blocked: Boolean(body.errors.blocked) } })
    }
  }
}

export async function generateMergedDocumentForEsign(dispatch, merchantUuid) {
  try {
    dispatch({ type: C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_INITIATED });
    const response = await RestClient.get(API_ENDPOINTS.DOCUMENTS.GENERATE_MERGED_DOCUMENT_FOR_ESIGN({ uuid: merchantUuid }));
    dispatch({ type: C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_SUCCESS, payload: response });
    return response;
  } catch (err) {
    const body = await err.response;
    const erroMsgs = formatErrors(body);
    dispatch({ type: C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_FAILURE, payload: erroMsgs });
    throw { message: erroMsgs };
  }
}

export async function esignMergedDocument(dispatch, merchantUuid, docUuid, params) {
  try {
    dispatch({ type: C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_INITIATED });
    dispatch({ type: C.VERIFY_SIGNATORY_OTP_INITIATED });
    const response = await RestClient.post(API_ENDPOINTS.DOCUMENTS.ESIGN_MERGED_DOCUMENT({ merchantUuid, docUuid }), params);
    dispatch({ type: C.GENERATE_MERGED_DOCUMENT_FOR_ESIGN_SUCCESS, payload: { kyc_document: response.kyc_document, signed: true } });
    return response;
  } catch (err) {
    const body = await err.response;
    if (body && body.error) {
      dispatch({ type: C.VERIFY_SIGNATORY_OTP_FAILURE, payload: body.error });
    } else if (body && body.errors) {
      dispatch({
        payload: { blocked: Boolean(body.errors.blocked), error: [body.errors.message] },
        type: C.VERIFY_SIGNATORY_OTP_FAILURE
      });
    } else {
      dispatch({
        payload: { error: ['Some error occurred. Please try again later'] },
        type: C.VERIFY_SIGNATORY_OTP_FAILURE
      });
    }
  }
}

/**
 * called on AcceptAddendum modal
 * @param dispatch
 * @param merchantUuid merchant's uuid to get data
 * @returns {object} An action object with a type of FETCH_ADDENDUM_{*}
 */
export async function fetchBankAddendumData(dispatch, merchantUuid) {
  dispatch({ type: C.FETCH_ADDENDUM_DETAILS_INITIATED });
  try {
    const params = {
      source: 'bank'
   },
    response = await RestClient.put(API_ENDPOINTS.ONBOARDING.GENERATE_ADDENDUM_DATA({ uuid: merchantUuid }), params);
    dispatch({
      payload: response,
      type: C.FETCH_ADDENDUM_DETAILS_SUCCESS
    })
  } catch (err) {
    const response = await err.response;
    dispatch({
      payload: response,
      type: C.FETCH_ADDENDUM_DETAILS_FAILURE
    });
  }
}

/**
 * called on ServiceAgreementModal
 * @param kycDocumentUuid kycDocumentUuid
 * @returns {html} of corresponding kycDocument
 */
export async function generatedAgreementHtml(kycDocumentUuid) {
  try {
    const extraArgs = { raw: true };
    const response = await RestClient.get(API_ENDPOINTS.ONBOARDING.AGREEMENT_HTML({ kycDocumentUuid }), null, extraArgs);
    return response;
  } catch (err) {
    return null;
  }
}

