import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/BaseComponents/GenericModal';
import moneyTick from '@assets/lottie_animation/moneyTick.json';
import ReactLottie from '../BaseComponents/ReactLottie';
import SuccessTick from '@assets/images/success-tick.svg'

export default class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: props.openModal };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isOpen: nextProps.openModal });
  }

  toggleOpen = (value) => {
    const { onClose } = this.props;
    this.setState({ isOpen: value });
    if (onClose) {
      onClose();
    }
  }

  renderContent = () => {
    const { type, message, helpText, warningMessage } = this.props;
    if (type !== 'error') {
      setTimeout(() => {
        this.toggleOpen();
      }, 3000)
      return <React.Fragment>
        <div className="success-tick">
          <SuccessTick width="60" height="60"/>
        </div>
        <div>
          <p className="success-text">{ message }</p>
        </div>
        <div>
          <p className="success-directions">{ helpText }</p><br></br>
        </div>
        </React.Fragment>
    }
    return <div className="error-box">
      <div className="row text-center"><div className="error-sign">!</div></div>
      <h4 className="message-content">
        {message}
      </h4>
    </div>
  }

  render() {
    const { isOpen, type } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => this.toggleOpen(false)}
        modalContentClassName="role-added-successfully"
        width={ 450 }
      >
        {
          this.renderContent()
        }
      </Modal>
    );
  }
}

ErrorMessage.propTypes = {
  helpText: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  openModal: PropTypes.bool,
  type: PropTypes.string,
  warningMessage: PropTypes.string
}
