import React from 'react';
import GenericModal from '../BaseComponents/GenericModal';

import DownloadIcon from '@assets/images/icons/icon-download.svg';
import UploadIcon from '@assets/images/icons/icon-upload.svg';
import SignIcon from '@assets/images/icons/icon-sign.svg';
import SignIndividual from '@assets/images/icons/icon-sign-individual.svg';

export default function AgreementDownload(props) {
  const isFilingStatusIndividual = props.businessFilingStatus === 'Individual';
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      modalTitle="Your Service Agreement is ready!"
      contentClassName="service-agreement-download-popup"
      isCloseDisabled={props.isCloseDisabled}
    >
      <h4>Next Steps</h4>
      <div className="service-agreement-main-div">
        <div className="row">
          <div className="col-md-4">
            <p className="number-row">1</p>
          </div>
          <div className="col-md-4">
            <p className="number-row">2</p>
          </div>
          <div className="col-md-4">
            <p className="number-row">3</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center">
            <DownloadIcon />
          </div>
          <div className="col-md-4 text-center">
            {isFilingStatusIndividual && <SignIndividual />}
            {!isFilingStatusIndividual && <SignIcon />}
          </div>
          <div className="col-md-4 text-center">
            <UploadIcon />
          </div>
        </div>
        {isFilingStatusIndividual && (
          <div className="row">
            <div className="col-md-4">
              <p className="text-row">Download your</p>
              <p>Service Agreement</p>
            </div>
            <div className="col-md-4">
              <p className="text-row">Sign your </p>
              <p>Service Agreement</p>
            </div>
            <div className="col-md-4">
              <p className="text-row">Upload signed copy of</p>
              <p>your Service Agreement</p>
            </div>
          </div>
        )}
        {!isFilingStatusIndividual && (
          <div className="row">
            <div className="col-md-4">
              <p className="text-row">Download</p>
              <p>your</p>
              <p>Service Agreement</p>
            </div>
            <div className="col-md-4">
              <p className="text-row">Sign & Stamp </p>
              <p>your</p>
              <p>Service Agreement</p>
            </div>
            <div className="col-md-4">
              <p className="text-row">Upload signed &</p>
              <p>stamped copy of your</p>
              <p>Service Agreement</p>
            </div>
          </div>
        )}
      </div>
      <div className="download-agreement-btn text-center pb-3">
        <a
          className="dflt-btn"
          href={props.link}
          target="_blank"
          onClick={props.onDownload}
        >
          Download Your Service agreement.
        </a>
      </div>
    </GenericModal>
  );
}
