import { takeLatest, put, call, select } from 'redux-saga/effects';
import axios from 'axios';
import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import { productAccountActions, productAccountSelectors, uiActions, toastActions } from '.';
import { PEP_FLAG_ARRAY } from '@constants/signingAuthorityConstants';

//  Action types
export const actionTypes = {
  fetchAuthorityNames: 'fetchAuthorityNames/INITIATE',
  fetchAuthorityNamesSuccess: 'fetchAuthorityNames/SUCCESS',
  submitSigningAuthorityDetails: 'submitSigningAuthorityDetails/INITIATE',
  submitSigningAuthorityDetailsSuccess: 'submitSigningAuthorityDetails/SUCCESS',
  updateAuthorityEmail: 'updateAuthorityEmail/INITIATE',
  updateAuthorityEmailSuccess: 'updateAuthorityEmail/SUCCESS',
};

const initialState = {
  cin: {
    authorityNames: [],
  },
  submit: {
    processing: false,
    response: null,
  },
  updateEmail: {
    processing: false,
    response: null,
  }
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchAuthorityNamesSuccess:
      return { ...state, cin: { ...state.cin, authorityNames: action.payload } };
    case actionTypes.submitSigningAuthorityDetails:
      return { ...state, submit: { processing: true, response: null } };
    case actionTypes.updateAuthorityEmail:
      return { ...state, updateEmail: { processing: true, response: null } };
    case actionTypes.updateAuthorityEmailSuccess:
      return { ...state, updateEmail: { processing: false, response: action?.payload } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getAuthorityNames: (state) => state.onboardingApp.signingAuthority.cin.authorityNames,
};

//  Actions
export const actions = {
  fetchAuthorityNames: (cin) => ({ type: actionTypes.fetchAuthorityNames, payload: cin }),
  submitSigningAuthorityDetails: (formData) => ({
    type: actionTypes.submitSigningAuthorityDetails,
    payload: formData,
  }),
  updateAuthorityEmail: (formData) => ({ type: actionTypes.updateAuthorityEmail, payload: formData }),
};

// Workers
function* fetchAuthorityNamesWorker(action) {
  const { payload: cin } = action;
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.CIN.SIGNATORIES_DETAILS(AuthManager.getMerchantUUID()),
      { cin },
    ) || {};
    const { signatories_details } = (response && response.data) || { signatories_details: [] };
    yield put({ type: actionTypes.fetchAuthorityNamesSuccess, payload: signatories_details });
  } catch { }
}

function* submitSigningAuthorityDetailsWorker(action) {
  const {
    payload: {
      cin: cin_number,
      signatoryEmail: email,
      signingAuthorityName: name,
      signingPanNumber: pancard_number,
      contactId,
      pepFlag: pep,
    },
  } = action;

  const accountUuid = yield select(productAccountSelectors.getAccountUuid);
  try {
    const response = yield call(
      [axios, 'put'],
      API_ENDPOINTS.SUBMIT_SIGNING_AUTHORITY(accountUuid),
      {
        account: {
          signatory_contact_details_attributes: [
            {
              cin_number,
              email,
              pancard_number,
              name: name.value || name,
              authorised_signatory: true,
              contact_detail_type: 'Signing Authority',
              id: contactId,
              pep: parseInt(pep),
            }
          ],
          merchant_uuid: AuthManager.getMerchantUUID() || "",
        }
      },
    ) || {};
    if (response && response.data) {
      yield put(uiActions.loadUI({
        ...response.data,
        updatedSigningAuthorityDetails: true,
        product_account_detail: { pep: PEP_FLAG_ARRAY[pep] }
      }));
    }
  } catch (err) {
    const errResponse = yield err.response;
    const { data } = errResponse || {};
    yield put(toastActions.show({
      variant: 'warning',
      title: 'Warning',
      content: data?.error ? data.error : 'Something went wrong',
    }));
  }
}

function* updateSigningAuthorityEmailWorker(action) {
  const accountUuid = yield select(productAccountSelectors.getAccountUuid);
  const {
    payload: {
      signatoryEmail,
      contactId,
    },
  } = action;
  try {
    const response = yield call(
      [axios, 'put'],
      API_ENDPOINTS.UPDATE_SIGNING_AUTHORITY(accountUuid),
      {
        account: {
          signatory_contact_details_attributes: [{
            id: contactId ? contactId : null,
            email: signatoryEmail,
            authorised_signatory: true,
            contact_detail_type: "Signing Authority",
          }],
          merchant_uuid: AuthManager.getMerchantUUID() || "",
        }
      },
    )
    yield put(uiActions.loadUI({
      account: {
        signatory_contact_details: response?.data?.account?.signatory_contact_details || [],
      },
    }));
  } catch { }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchAuthorityNames, fetchAuthorityNamesWorker);
  yield takeLatest(actionTypes.submitSigningAuthorityDetails, submitSigningAuthorityDetailsWorker);
  yield takeLatest(actionTypes.updateAuthorityEmail, updateSigningAuthorityEmailWorker);
}
