import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { config } from 'hub-redux-auth';
import StatementImage from '@assets/images/statement.png';
import ClockCircle from '@assets/images/clock-circle.svg';
import { PENNY_STATUS, MERCHANT_TYPE } from '../../constants/profileConstants';
import ReactLottie from '../BaseComponents/ReactLottie';
import cleverTap from '../../services/cleverTap';
import { isPenny } from '../../services/validations';
import { getContactUsLink, skipToChooseWebsiteName, classNames } from '../../services/utils';
import * as C from '../../constants/merchantTypes';
import NoAccessModal from '../ManageEmployeesAndRoles/NoAccessModal';
import ActionCableConsumer from '../../components/ActionCableConsumer';
import { sessionCheck } from '../../actions/auth';

export default class BankAccountVerification extends React.Component {
  static propTypes = {
    canUpdate: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    isWebsiteFilled: PropTypes.bool.isRequired,
    merchant: PropTypes.shape({
      integration_type: PropTypes.string
    }).isRequired,
    verifyPenny: PropTypes.func.isRequired,
    pennyStatus: PropTypes.string,
    history: PropTypes.object
  };

  state = {
    amountError: false,
    attemptsLeft: null,
    isNoAccessModalOpen: false,
    messageIndex: 1,
    pennyAmount: '',
    response: '',
    showLoader: false,
    success: false
  };

  impsWait = () => {
    const message = 'Its taking longer than expected...';
    setTimeout(() => {
 this.setState({ messageIndex: 2 })
}, 30000);
    return <div className="message">
      <div className="loader loader-1" />
      {
        this.state.messageIndex == 1 ? <span> We are depositing small amount (&#8377;1-5) in your bank account </span>
        : message
      }
  </div>;
  }

  componentWillReceiveProps(nextProps) {
    const { pennyDepositMethod, pennyStatus } = this.props;
    if (pennyDepositMethod !== nextProps.pennyDepositMethod || pennyStatus !== nextProps.pennyStatus) {
      this.notified = false;
      this.setState({ messageIndex: 1 });
    }
  }

  componentWillMount() {
    this.notifyErrors();
    // using webpack dynamic import at the time of build generation
    if (this.props.product === 'BIZ') {
      this.animationData = require('@assets/lottie_animation/bizTick.json');
    } else if (this.props.product === 'NOW') {
      this.animationData = require('@assets/lottie_animation/nowTick.json');
    } else {
      this.animationData = require('@assets/lottie_animation/moneyTick.json');
    }
  }

  onPennyInputBlur = (e) => {
    const { value } = e.target;
    if (value) {
      cleverTap.event('Penny amount entered', { Amount: value });
    }
  };

  onPennyChange = (e) => {
    const { value: pennyAmount } = e.target;
    this.setState({ pennyAmount });
  };

  onLottieComplete = () => {
    const { integration_type: merchantType } = this.props.merchant;
    if (this.props.history.location.pathname === '/penny-details') {
      skipToChooseWebsiteName({ user: this.props.merchant });
    } else if (config.get('product') !== 'PAYUNOW' && this.props.isWebsiteFilled &&
      merchantType !== MERCHANT_TYPE.TOOLS) {
      setTimeout(() => this.props.history.push('/onboarding/documents'), 3000);
    } else if (config.get('product') !== 'PAYUNOW') {
      setTimeout(() => this.props.history.push('/onboarding/website'), 3000);
    }
  }

  validatePenny = (e) => {
    const pennyAmount = e.target.value + e.key;
    if (!isPenny(pennyAmount)) {
      e.preventDefault();
    }
  };

  submitPenny = async () => {
    const { pennyAmount } = this.state,
     { dispatch, merchant: { uuid } } = this.props;
    if (pennyAmount) {
      try {
        this.setState({ showLoader: true });
        const response = await this.props.verifyPenny(dispatch, uuid, {
          penny_amount: pennyAmount
        });
        cleverTap.event('Bank verification completed', { mid: this.props.merchant.mid });
        dataLayer.push({ 'event': 'final_step',
'description': 'Merchant has successfully verified his bank account' });// gtm event
        this.setState({ success: true,
response: response.merchant });
      } catch (err) {
        cleverTap.event('Penny amount invalid', { mid: this.props.merchant.mid,
Amount: pennyAmount,
            error: err.message && err.message.error });
        if (err.message) {
          const { attempts_left: attemptsLeft } = err.message;
          this.setState({ attemptsLeft });
        }
      }
    } else {
      this.setState({ amountError: true });
    }
    this.setState({ showLoader: false });
  };

  removeErrors = () => this.setState({ amountError: false });

  skipForNow() {
      if (!this.props.bankFlow) {
return null;
}
      return (
          <div className="text-center skip-for-now">
        <span className="clickable-txt" onClick={() => this.props.canUpdate
        ? skipToChooseWebsiteName({ user: this.props.user })
        : this.openNoAccessModal()}>
          SKIP FOR NOW
        </span>
          </div>
      )
  }

  renderAttemptsLeftContent() {
    const { attemptsLeft } = this.state;
    if (attemptsLeft > 1) {
      return (
        <h5 className="verified attempt-wrng">
          {' '}
          {attemptsLeft} attempts remaining!
        </h5>
      );
    } else if (attemptsLeft === 1) {
      return (
        <h5 className="verified attempt-wrng">
          Last attempt remaining!<br />
          <span>
            Your bank account would not be verified if you enter incorrect
            amount
          </span>
        </h5>
      );
    }
  }

  renderPennyPendingContent(pennyDepositMethod = null) {
    return (
      <React.Fragment>
        <h6>Bank account verification pending</h6>
        <div className="verf-cont d-flex">
          <span className="clock-circle svg-timer left mt-4 pt-1 pr-2">
            <ClockCircle width="50" height="50" />
          </span>
          <p>
            We will deposit a small amount (&#8377; 1 - 5) into your bank
            account within few {`${pennyDepositMethod === 'IMPS' ? 'minutes' : 'hours'}`} and notify
            you via email and SMS.
            <br />
            <br />
            <strong>
              Once notified enter the details below to verify your bank account.
            </strong>
          </p>
        </div>
        <div>
          <form>
            <div className="form-box disabled">
              <div className="d-flex justify-content-center">
                <div className="row d-flex flex-column">
                  <label className="label-name" htmlFor="penny_amount_disabled">
                    Deposited Amount
                  </label>
                  <input
                    type="text"
                    name=""
                    id="penny_amount_disabled"
                    className="text-field pin-code"
                    placeholder="Eg. &#8377; 1.70"
                    disabled
                  />
                </div>
                <div className="align-self-start mt-2">
                  <Button className="dflt-btn inactive pl-2 pr-2" id="bankAccountVerified">
                    VERIFICATION PENDING
                  </Button>
                </div>
              </div>
              {this.skipForNow()}
            </div>
          </form>
        </div>
        <div className="notification-box">{this.props.notificationActions}</div>
      </React.Fragment>
    );
  }
  renderPennyDepositedContent() {
    const { amountError, attemptsLeft, showLoader } = this.state,
     btnClass = classNames({
      'dflt-btn': true,
      'is-loading': showLoader
    });
    return (
      <React.Fragment>
        <h6>Deposited! Now verify your account</h6>
        <p>
          {' '}
          We have deposited a small amount (&#8377; 1 - 5) in your bank account.<br />Find
          the amount in your bank statement to enter below
        </p>
        <img src={StatementImage} alt="" />
        {attemptsLeft === 0 || this.props.attemptsLeft === 0
          ? <div>
            <h5 className="verified attempt-wrng">
              You have exhausted your attempts<br />
              <span> Your bank account is not verified</span>
            </h5>
            <br />
            <small className="trbl">
              To resolve the issue{' '}
              <a href={getContactUsLink('issue=Account Related', this.props.authParams)} target="_blank">
                {' '}
                contact us
              </a>
            </small>
          </div>
         : <div>
            {this.renderAttemptsLeftContent()}
            <form>
              <div className="form-box">
                <div className="d-flex justify-content-center">
                  <div className="row d-flex flex-column">
                    <label className="label-name">Deposited Amount</label>
                    <input
                      type="text"
                      name=""
                      id="penny_amount"
                      onFocus={this.removeErrors}
                      onBlur={this.onPennyInputBlur}
                      className={`text-field pin-code ${
                        amountError ? 'input-amount-error' : ''
                      }`}
                      onChange={this.onPennyChange}
                      onKeyPress={this.validatePenny}
                      placeholder="Eg. &#8377; 1.70"
                    />
                    {amountError &&
                      <label className="error-message text-left">
                        Incorrect amount
                      </label>
                    }
                  </div>
                  <div className="align-self-start mt-2">
                    <Button
                      id="pennyAmountFilled"
                      className={`${btnClass} px-2 mt-2`}
                      onClick={(e) => {
                                this.props.canUpdate
                                 ? this.submitPenny(e)
                                 : this.openNoAccessModal(e)
                                }}
                    >
                      VERIFY ACCOUNT
                    </Button>
                  </div>
                </div>
                {this.skipForNow()}
              </div>
            </form>

            <br />
            <small className="trbl">
              Facing any trouble?{' '}
              <a href={getContactUsLink('issue=Account Related', this.props.authParams)} target="_blank">
                {' '}
                contact us
              </a>
            </small>
          </div>
        }
      </React.Fragment>
    );
  }

  notifyErrors () {
    const { pennyStatus, pennyDepositMethod } = this.props;
    if (!this.notified && pennyStatus == PENNY_STATUS.FAILED &&
      [
'IMPS',
'NEFT'
].includes(pennyDepositMethod)) {
      this.notified = true;
      this.props.onError('Incorrect Bank details.Please enter correct bank details and retry.');
    }
  }

  componentDidUpdate() {
    this.notifyErrors();
  }
  componentWillUnmount() {
    if (this.state.response) {
      this.props.dispatch({ type: C.UPDATE_MERCHANT_DETAILS_SUCCESS,
payload: this.state.response });
    }
  }

  openNoAccessModal = (e) => {
    // e.preventDefault();
    this.setState({ isNoAccessModalOpen: true })
  }

  handleDataReceived = (message) => {
    const { bank_verified: bankVerified = false } = message;
    if (bankVerified){
      this.setState({ success: true });
      setTimeout(() => sessionCheck(this.props.dispatch), 5000);
    }
  };

  closeNoAccessModal = () => {
    this.setState({ isNoAccessModalOpen: false })
  }

  render() {
    let content = null;
    const { merchant: { uuid: merchantUuid = null } } = this.props;
    const {
      isWebsiteFilled,
      merchant: { integration_type: merchantType },
      pennyStatus,
      pennyDepositMethod
    } = this.props,
     eventListeners = [
      {
        eventName: 'complete',
        callback: this.onLottieComplete
      }
    ],
     lottieAnimation =
      <div className="d-flex justify-content-center">
        <ReactLottie
          options={{ loop: false,
animationData: this.animationData }}
          eventListeners={eventListeners}
        />
      </div>;
if (this.state.success) {
      content =
        <h5 className="verified">
          {lottieAnimation}
          <br />Bank account verified!<br />
          {this.props.product !== 'NOW' &&
            <div>
              { !isWebsiteFilled &&
                <span>You can now fill your website/App details..</span>
              }
              {
                isWebsiteFilled && merchantType !== MERCHANT_TYPE.TOOLS &&
                <span>You can now submit your documents..</span>
              }
              <br />
              <small className="trbl">Redirecting...</small>
            </div>
          }
          {this.props.product === 'NOW' && this.props.history.location.pathname !== '/penny-details' &&
            <div>
              <span>All successful payments will be transferred to your bank</span>
              <br />
              <span>account within 3 working days</span>
              <br />
              <button onClick={() => {
window.location = `${config.get('productHost')}?${this.props.authParams || ''}`
}} type="submit" className="dflt-btn go-to-home">GO TO HOME</button>
            </div>
          }
        </h5>;
} else if (pennyStatus === PENNY_STATUS.NOT_INITIATED && pennyDepositMethod === 'IMPS') {
      content = this.props.showImpsLoader ? this.impsWait() : '';
    } else if ((pennyDepositMethod === 'NEFT' &&
      [
        PENNY_STATUS.NOT_INITIATED,
        PENNY_STATUS.PENDING,
        PENNY_STATUS.SENT_TO_BANK
      ].includes(pennyStatus)) || (pennyDepositMethod === 'IMPS' && PENNY_STATUS.PENDING === pennyStatus)
    ) {
      content = this.renderPennyPendingContent(pennyDepositMethod);
    } else if (pennyStatus === PENNY_STATUS.SUCCESS) {
      content = this.renderPennyDepositedContent();
    } else if (pennyStatus === PENNY_STATUS.FAILED && pennyDepositMethod === 'IMPS') {
      content = <div />;
    }

    return (
      <div
        className={`accordian-container amnt-dpst ${
          pennyStatus === PENNY_STATUS.PENDING ? 'pending-verfy' : ''
        }`}
      >
        {merchantUuid && <ActionCableConsumer
          channelName='ProfileChannel'
          identifier={merchantUuid}
          action={this.handleDataReceived}
        />}
        {content}
        <NoAccessModal isOpen={this.state.isNoAccessModalOpen} onClose={this.closeNoAccessModal}></NoAccessModal>
      </div>
    );
  }
}
