import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from 'deltoid/core/Card';
import Button from 'deltoid/core/Button';
import ManageEmployeesImage from '@assets/images/manage-employees.svg';
import ManageRolesImage from '@assets/images/manage-roles.svg';
import { TEAM_ACCOUNTS_LITERALS } from '../../../constants/appConstants'


/**
 *  common component for initiating flow to manage employees and roles
 *
 *  @param  {object}    props      reacts props
 */
 class ManageRolesAndEmployeesCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    //reset the breadcrumb state
    this.props.resetBreadCrumb();
    this.props.resetEdit();
  }

  render() {
    const { forEmployees, onclick, count } = this.props,
      empHelpText = TEAM_ACCOUNTS_LITERALS.MANAGE_EMPLOYEE_HELP_TEXT,
      roleHelpText = TEAM_ACCOUNTS_LITERALS.MANAGE_ROLE_HELP_TEXT;
    return (
      <Card className={`manage-roles-and-emp-widget ${forEmployees
        ? 'for-employees'
        : 'for-roles'}`}>
        <div>
          {
            forEmployees
            ? <div>
                <ManageRolesImage></ManageRolesImage>
              </div>
            : <div>
                <ManageEmployeesImage></ManageEmployeesImage>
              </div>
          }
          <div>
            <p className="roles-and-emp-header">
              { forEmployees
              ? `Manage Employees (${count})`
              : `Manage Roles (${count})` }
            </p>
          </div>
          <div>
            <p className="roles-and-emp-content">
              {forEmployees
              ? empHelpText
              : roleHelpText}
            </p>
          </div>
          <div>
            <Button className="manage-emp-and-roles-btn" type="OUTLINED" onClick={ onclick }>MANAGE</Button>
          </div>
        </div>
      </Card>
    );
  }
}
ManageRolesAndEmployeesCard.propTypes = {
  count: PropTypes.number,
  forEmployees: PropTypes.bool,
  onclick: PropTypes.func.isRequired
};

ManageRolesAndEmployeesCard.defaultProps = { forEmployees: true };

const mapStateToProps = (state) => ({
    aclRolePermissions: state.masterData.aclPermissions
  })
export default connect(mapStateToProps)(ManageRolesAndEmployeesCard)
