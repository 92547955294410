export const DOC_UPLOAD_STATUS = {
  NOT_APPLICABLE: 0,
  TC_NOT_ACCEPTED: 1,
  TC_ACCEPTED: 2,
  TC_DOWNLOADED: 3,
  DOCUMENT_SUBMITTED: 'DOCUMENT_SUBMITTED',
  DOCUMENT_VERIFIED: 'Docs Approved',
  DOCUMENT_VERIFIED_EXC: 'Exceptionally Approved',
  DOCUMENT_REJECTED: 'Docs Error',
  DOCUMENT_REUPLOADED: 'DOCUMENT_REUPLOADED',
  DOCUMENT_ESIGNED: 'Counter Signed Received',
  DOCUMENT_PARTIALLY_REUPLOADED: 'Docs Partially Reuploaded',
  DOCUMENT_PARTIALLY_RECEIVED: 'Docs Partially Received',
  PENDING: 'Pending'
};

export const INDIVIDUAL_DOC_UPLOAD_STATUS = {
  DOCUMENT_SUBMITTED: 'DOCUMENT_SUBMITTED',
  DOCUMENT_REJECTED: 'Declined',
  DOCUMENT_VERIFIED: 'Approved',
  DOCUMENT_REUPLOADED: 'DOCUMENT_REUPLOADED',
  COUNTER_SIGNED: 'Counter Signed Received',
  ACCEPTED: 'accepted',
  PENDING: 'Pending'
};

export const LOB_STATUS = {
  DISAPPROVED: 'Rejected',
  INCOMPLETE: null,
  COMPLETE: 'Pending',
  APPROVED: 'Approved',
};

export const WEBSITE_STATUS = {
  COMPLETE: 'COMPLETE',
  DISAPPROVED: ['Website Error', 'Website Not live', 'Website Under Construction'],
  INCOMPLETE: 'Website Incomplete',
  APPROVED: 'Website OK',
};

export const AGREEMENT_STATUS = {
  PENDING: 'Pending',
  SENT: 'Sent',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ACCEPTED: 'accepted',
  COUNTER_SIGNED_RECIEVED: 'Counter Signed Received'
},
  SETTLEMENT_STATUS = { ACTIVE: 'Active' };

export const BANK_ADDENDUM_STATUS = {
  PENDING: 'pending',
};
export const WEBSITE_ADDENDUM_STATUS = {
  PENDING: 'pending',
};