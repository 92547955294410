import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { redirectTo, isAllowed, state } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        isAllowed(state) ? (
          <Component {...props} />
        ) : (
          <Redirect to={isFunction(redirectTo) ? redirectTo(state) : redirectTo} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
    .isRequired,
  isAllowed: PropTypes.func,
};

PrivateRoute.defaultProps = {
  isAllowed: () => true,
};

export default connect(state => ({ state }))(PrivateRoute);
