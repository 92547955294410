import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ArrowIcon from '@assets/images/icons/arrowicon.svg';
import GenericModal from '../BaseComponents/GenericModal';
import { acceptServiceAgreement } from '../../actions/documents';
import { classNames, checkPermissionOnElement } from '../../services/utils';
import { sessionCheck } from '../../actions/auth';
import { generatedAgreementHtml } from '../../actions/documents';
import { PERMISSIONS_LIST } from '../../constants/appConstants';
import NoAccessModal from '../ManageEmployeesAndRoles/NoAccessModal';


function mapStateToProps(state) {
  return {
    agreementAccepted: state.documents.acceptedServiceAgreement,
  };
}

@connect(mapStateToProps)
export default class ServiceAgreementModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementScrolled: false,
      errorInFetchingHtml: false,
      htmlDocument: null,
      isNoAccessModalOpen: false,
      showLoader: false
    };
    this.agreementHtmlRef = React.createRef();
  }

  componentWillMount = async () => {
    const kycDoc = this.props.agreementLink;
    if (kycDoc) {
      const response = await generatedAgreementHtml(kycDoc.uuid);
      if (response) {
        this.setState({ htmlDocument: response });
      }
      else {
        const errorMsg = this.errorResponseHtml();
        this.setState({ errorInFetchingHtml: true, htmlDocument: errorMsg });
      }
    }
  }

  errorResponseHtml = () => {
    return '<html><body><p>Oops!! Some error occurred, please try again after some time</p></body></html>';
  }
  get header() {
    return (
      <div>
        <h1 className="modal-heading">Service Agreement</h1>
        <p className="modal-sub-heading">Accept the Service Agreement below</p>
      </div>
    );
  }

  get footer() {
    const btnClass = classNames({
      'dflt-btn': true,
      'is-loading': this.state.showLoader
    });

    const acceptButton = (
      <div className="service-agreement-footer">
        <Button
          type="submit"
          className={btnClass}
          onClick={this.onAccept}
        >
          I Accept
        </Button>
      </div>
    ),
      arrowIcon = <ArrowIcon onClick={this.onArrowIconClick} />;
    if (this.state.errorInFetchingHtml) {
      return null;
    }
    return this.state.agreementScrolled ? acceptButton : arrowIcon;
  }

  openNoAccessModal = (e) => {
    e.preventDefault();
    this.setState({ isNoAccessModalOpen: true })
  }

  closeNoAccessModal = () => {
    this.setState({ isNoAccessModalOpen: false })
  }

  onAccept = async (e ) => {
    if(checkPermissionOnElement(PERMISSIONS_LIST.UPDATE_USER_PROFILE)){
      this.setState({ showLoader: true });
      const { dispatch } = this.props;
      await acceptServiceAgreement(dispatch, this.props.merchant.uuid);
      sessionCheck(dispatch);
      this.setState({ showLoader: false });
      this.props.onClose();
    } else{
      this.openNoAccessModal(e);
    }
  };

  onClose() {
    this.props.onClose();
    this.setState({ agreementScrolled: false });
  }

  trackScrolling = () => {
    const element = this.agreementHtmlRef.current,
      bodyContainer = element.contentDocument.body.children[1],
      diff = Math.floor(bodyContainer.scrollHeight) - Math.ceil(bodyContainer.scrollTop);
    if (diff <= bodyContainer.clientHeight) {
      this.setState({ agreementScrolled: true });
    }
  }

  onArrowIconClick = () => {
    const element = this.agreementHtmlRef.current,
      bodyContainer = element.contentDocument.body.children[1];
    if (bodyContainer) {
      bodyContainer.scrollBy(0, bodyContainer.clientHeight);
    }
  }

  onLoad = () => {
    const element = this.agreementHtmlRef.current;
    element.contentDocument.body.children[1].onscroll = this.trackScrolling;
  }

  onClose = () => {
    this.setState({ agreementScrolled: false });
    this.props.onClose();
  }

  render() {
    return (
        <React.Fragment>
          <GenericModal
              isOpen={this.props.isOpen}
              onClose={this.onClose}
              modalTitle={this.header}
              footer={this.footer}
              footerClassName="align-self-center"
              wrapClassName="service-agreement-modal"
          >
            <div className="service-agreement-body d-flex justify-content-center">
              <iframe className="pdf--width"
                      srcDoc={this.state.htmlDocument}
                      ref={this.agreementHtmlRef}
                      onLoad={this.onLoad}
              />
            </div>
          </GenericModal>
          <NoAccessModal isOpen={this.state.isNoAccessModalOpen} onClose={this.closeNoAccessModal}></NoAccessModal>
        </React.Fragment>
    );
  }
}
