
export const ALLOWED_QUERY_PARAMS = [
  'service_intent',
  'device',
  'source_type',
  'source_url',
  'campaign_name',
  'campaign_medium',
  'campaign_source',
  'campaign_term',
  'sub_source',
  'source_details',
  'device_type',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_adgroup',
  'utm_content',
  'channel',
  'ad_extension_id',
  'match_type',
  'keyword_type',
  'location',
  'network_type',
  'creative_id',
  'display_placement',
  'display_category',
  'ad_position',
  'cs_plan_name',
  'merchant_account_id',
  'reseller_id',
  'state',
  'partner_uuid',
  'redirect_uri',
  'gclid',
  'fbclid',
  'li_fat_id',
  'first_visit_url',
  'last_visit_url'
];

export const UTM_QUERY_PARAMS = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_adgroup',
  'utm_content',
]

export const AUTH_QUERY_PARAMS = {
  authToken: 'authToken',
  merchantUuid: 'merchantUuid',
  productAccountUuid: 'product_account_uuid',
  appSource: 'appSource',
};

export const ENUM_APP_SOURCE = {
  ANDROID: 'AndroidApp',
  WEB: 'Web',
  iOS: 'iOSApp',
};

export const PRISMIC_QUERY_PARAMS = {
  CAMPAIGN_CONTEXT: 'campaign_context',
}
