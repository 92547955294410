import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import GenericModal from '../../../components/BaseComponents/GenericModal';
import Button from 'deltoid/core/Button';
import FilterDropdown from '../../../components/BaseComponents/FilterDropdown';
import { reject as _reject } from 'lodash';
import { classNames } from '../../../services/utils';
/**
 *
 * @function
 * @param {state} object The desired state of redux store.
 */
const mapStatesToProps = (state) => ({
    ownedAclRoles: state.app.ownedAclRoles.data.acl_roles || []
  })
class MoveUsersToNewRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDropdownOpen: false,
      isOpen: props.isOpen };
  }

  handleDropDownClick = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  }

  handleRoleSelect = (roleId) => {
    this.selectedRole = roleId;
    this.handleDropDownClick();
  }

  onClose = () => {
    this.selectedRole = null;
    this.props.onClose();
  }

  handleClick = async () => {
    const { onUserMove } = this.props;
    if (onUserMove) {
      await onUserMove(this.selectedRole);
    }
  }

  getSelectableAclRoles = () => {
    const { ownedAclRoles, aclRole } = this.props;
    return _reject(ownedAclRoles, (role) => role.id === aclRole.id);
  }

  filterOptions = () => {
    const selectableAclRoles = this.getSelectableAclRoles();
    return selectableAclRoles.map((role) => ({ key: role.uuid,
      value: role.name }));
  }
  buttonClassNames = () => {
    const { isFetching } = this.props;
    classNames({ isLoading: isFetching });
  }
  getContent = () => {
    const inviteCount = 1,
      { aclRole } = this.props;
    if (aclRole.staff_invite_count > inviteCount) {
      return 'these employees';
    }
    return 'this employee';
  }
  deleteRoleContent = () => {
    const inviteCount = 1,
    { aclRole } = this.props;
    if (aclRole.staff_invite_count > inviteCount) {
      return 'employees ';
    }
     return 'employee ';
     }
  renderContent = () => {
    const { aclRole, aclRole: { staff_invite_count: staffInviteCount }, ownedAclRoles } = this.props;
    if (_reject(ownedAclRoles, (role) => role.id === aclRole.id).length) {
      return <div className="content">
        Role <span>&quot;</span>{ aclRole.name }<span>&quot;</span>&nbsp; is allotted to&nbsp;
        { staffInviteCount } {
          this.deleteRoleContent()
        }.
        Please select a new role for  {
          this.getContent()
        }
        &nbsp;from the following.
      </div>
    }
    return <div className="content">
      The Role <span>&quot;</span>{aclRole.name}<span>&quot;</span> &nbsp;is alloted to &nbsp;
      { staffInviteCount } {
        this.deleteRoleContent()
      }.
      Please create a new role for {
        this.getContent()
      }
      &nbsp;before deleting this role.
    </div>;
  }
  render() {
    const { isDropdownOpen } = this.state,
     { aclRole, ownedAclRoles } = this.props;
    return <GenericModal
        isOpen={this.state.isOpen}
        onClose={this.onClose}
        backdrop={false}
        modalContentClassName="move-invite-role"
        footerClassName="align-self-center"
      >
        <div className="justify-content-center">
          <div className="heading text-center">
            <h4>
              Oops!
            </h4>
          </div>
          {this.renderContent()}
          {
            _reject(ownedAclRoles, (role) => role.id === aclRole.id).length
            ? <React.Fragment>
              <div className="role-options">
                <label>
                  Choose new role
                </label>
                <FilterDropdown
                  onClick={this.handleDropDownClick}
                  isOpen={isDropdownOpen}
                  onItemClick={this.handleRoleSelect}
                  dropDownItems={this.filterOptions()}
                  label={'Choose new role'}
                />
              </div>
              <div className="actions">
                <Button onClick={this.handleClick} className={this.buttonClassNames}> APPLY </Button>
              </div>
            </React.Fragment>
            : <div className="actions">
              <Button onClick={this.onClose}> OK! GOT IT </Button>
            </div>
          }
        </div>
      </GenericModal>;
  }
}

MoveUsersToNewRole.propTypes = {
  aclRole: PropType.object,
  isFetching: PropType.bool,
  isOpen: PropType.bool,
  onClose: PropType.func,
  onUserMove: PropType.func,
  ownedAclRoles: PropType.array
}

export default connect(mapStatesToProps)(MoveUsersToNewRole);
