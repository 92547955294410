import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { NavLink } from 'react-router-dom';

export default function HeaderDropdown(props) {
  function renderDropdownMenu() {
    return props.menuList
      .map(({
        onItemClick,
        label,
        linkTo
      }) => {
        if (label === 'Business Dashboard' && isFunction(onItemClick) && !linkTo){
          return <li><a onClick={() => onItemClick()}>{label}</a></li>;
        }
        return <li><a href={linkTo || ''} onClick={onItemClick}>{label}</a></li>;
      });
  }

  return (
    <li>
      <a className={`icon ${props.linkClassName}`} onClick={props.onDropdownClick} href={props.linkTo} />
      <div className={`drp-dwn ${props.isOpen ? '' : 'd-none'}`}>
        <span className="icon-arrowtltip" />
        <ul className="action">
          {React.Children.toArray(renderDropdownMenu())}
        </ul>
      </div>
    </li>
  );
}

HeaderDropdown.propTypes = {
  linkClassName: PropTypes.string,
  onDropdownClick: PropTypes.func,
  linkTo: PropTypes.string,
  isOpen: PropTypes.bool,
  menuList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    linkTo: PropTypes.string,
  })),
};

HeaderDropdown.defaultProps = {
  linkClassName: '',
  linkTo: '',
  isOpen: false,
  menuList: [],
  onDropdownClick: () => {},
};
