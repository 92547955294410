import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import { Field, reduxForm, change, stopSubmit } from 'redux-form';
import regTypeHintImg from '@assets/images/reg_type_hint@2x.png';
import businessRegHintImg from '@assets/images/business_reg_hint@2x.png';
import ErrorBox from '../BaseComponents/ErrorBox';
import { classNames } from '../../services/utils';
import { skipToChooseWebsiteName } from '../../services/utils';
import GenericFormFields from '../BaseComponents/GenericFormFields';
import _ from 'lodash';
import IfscSearchModal from '../IfscSearchModal';
import { fetchBankDetailsFromIfsc } from '../../actions/profile';
import BankUpdateModal from '../BankUpdateModal';
import {
  PRIVATE_LIMITED,
  PUBLIC_LIMITED,
  TRUST,
  SOCIETIES,
  NOW_BUSINESS_ENTITIES_LIST,
  INDIVIDUAL,
  PROPRIETORSHIP,
} from '../../constants/profileConstants';
import {
  required,
  bankAccountRegex,
  ifscCodeRegex,
  minLength9,
  nameAlphaNumRegex,
  pancardNumberRegex,
  panNameRegex,
} from '../../services/validations';
import {
	SHOW_SELECTED_FIELDS,
	ACCOUNT_NUMBER_HINTS,
	DEFAULT_ENABLED_FIELDS
} from "../../constants/nowBankDetailsFields";

class NowBankDetailsForm extends React.Component {
  static propTypes = {
    disableForm: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
  };
  static defaultProps = {
    disableForm: false,
  };
  state = {
    errors: this.props.errors || [],
    isIfscDialogOpen: false,
    bankDetailsFromIfsc: null,
    isBusinessEntityOpen: false,
    businessNameLabel: 'Business Name',
    bankAccountNumberLabel: "Bank account No.",
    enabledBankDetailsFields: this.setInitialEnabledFields(),
    isBusinessRegistered: this.setIsBusinessRegistered(),
    isBusinessRegisteredClicked: false,
    isBankUpdateDialogOpen:false,
    enableBackOnIfsc: false,
  };

  setIsBusinessRegistered() {
    const initBusinessEntityId = this.props.initialValues.business_entity_id 
    if(!initBusinessEntityId) return false;

    const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == initBusinessEntityId);
    const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name;
    if(filingStatus == INDIVIDUAL) return false;
    return true;
  }

  setInitialEnabledFields() {
    if(!!this.props.initialValues.business_entity_id) {
      const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == this.props.initialValues.business_entity_id);
      const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name;
      return SHOW_SELECTED_FIELDS[filingStatus]; 
    } else {
      return DEFAULT_ENABLED_FIELDS;
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.errors && nextProps.errors.length !== 0) {
      this.setState({errors: nextProps.errors})
    }
    else if (nextProps.detailError) {
      this.setState({ errors: [nextProps.detailError] });
    }
    if(this.props.initialValues.business_entity_id !== nextProps.initialValues.business_entity_id) {
      const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == nextProps.initialValues.business_entity_id);
      const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name;
      if(filingStatus == INDIVIDUAL) {
        this.setState({ isBusinessRegistered: false });
        if(!this.props.disableForm) {
          document.querySelector('#noId').click();
        }
      } else if(filingStatus) {
        this.setState({ isBusinessRegistered: true });
        if(!this.props.disableForm) {
          document.querySelector('#yesId').click();
        }
      };
      this.setEnabledFieldsBasedOnFilingStatus(filingStatus);
    }
  }
  componentWillMount() {
    //document.body.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    //document.body.removeEventListener('click', this.handleClickOutside);
  }
  isFieldEnabled(fieldName) {
		return Object.keys(this.state.enabledBankDetailsFields).includes(fieldName);
  }
  isFieldReadOnly() {
    const { business_entity_id } = this.props.formData || {};
    const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == business_entity_id);
    const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name;
    return this.props.disableForm || (this.state.isBusinessRegistered && !filingStatus) || (!this.state.isBusinessRegisteredClicked && !(this.migratingFromMoney() && this.props.isPanFilled));
	}

  getselectedBusinessEntity = () => {
    const { business_entity_id } = this.props.formData || {};
    const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == business_entity_id);
    return ( selectedBusinessEntity ? selectedBusinessEntity.name : null ) ;
  };

  toggeleBankUpdateDialog = flag => () => this.setState({ isBankUpdateDialogOpen: flag, bankDetailsFromIfsc: null });

  onIfscClose = () => this.setState({ isIfscDialogOpen: false });

  onBackClick = () => {
    this.onIfscClose();
    this.setState({ isBankUpdateDialogOpen: true });
  };

  handleBankUpdateSubmit = () => {
    this.setState({ isBankUpdateDialogOpen: false, bankDetailsFromIfsc: null });
  };

  onSearchIfscClick = () =>  {
    this.ifscThroughBankUpdate = this.state.isBankUpdateDialogOpen;
    // if(this.isFieldReadOnly()) return;
    this.setState({
      isIfscDialogOpen: true,
      isBankUpdateDialogOpen: false,
      enableBackOnIfsc: this.ifscThroughBankUpdate,
    });
  };

  onIfscSubmit = (data) => {
    this.setState({
      bankDetailsFromIfsc: data,
      isBankUpdateDialogOpen: this.ifscThroughBankUpdate,
    });
    this.onIfscClose();
    if (this.ifscThroughBankUpdate) {
      this.props.dispatch(change('BankUpdateForm', 'ifsc_code', data.ifsc_code));
    } else {
      this.props.dispatch(change('NowBankDetailsForm', 'ifsc_code', data.ifsc_code));
    }
  };
  onIfscChange = async (e) => {
    const ifscCode = e.target.value;
    if (!ifscCodeRegex(ifscCode)) {
      // if ifsc is valid
      try {
        const response = await fetchBankDetailsFromIfsc(
          this.props.dispatch,
          ifscCode,
        );
        this.setState({
          bankDetailsFromIfsc: response.ifsc_detail,
        });
      } catch (err) {
        this.props.dispatch(
          stopSubmit('NowBankDetailsForm', { ifsc_code: err.message }),
        );
      }
    }
  };
  handleClickOutside = (event) => {
    const { classList } = event.target;
    if (
      classList.contains('filter-dropdown-box-item') ||
      classList.contains('form-control')
    ) {
      return;
    }
    this.setState({
      isBusinessEntityOpen: false,
    });
  };
  handleBusinessEntitySelect = (e) => {
    this.setState(({ isBusinessEntityOpen }) => ({
      isBusinessEntityOpen: !isBusinessEntityOpen,
    }));
  }
  setEnabledFieldsBasedOnFilingStatus = (filingStatus) => {
    this.setState({
      enabledBankDetailsFields: SHOW_SELECTED_FIELDS[filingStatus] || SHOW_SELECTED_FIELDS[''],
      accountNumberHint: ACCOUNT_NUMBER_HINTS[filingStatus] || ACCOUNT_NUMBER_HINTS[''],
    });
  }
  handleBusinessEntityChange = ({ value: filingStatus }) => {
    this.setEnabledFieldsBasedOnFilingStatus(filingStatus);
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.props.disableForm) {
      try {
        const onSubmit = this.props.handleSubmit();
        if (_.isFunction(onSubmit)) {
          this.setState({ showLoader: true, errors: [] });
          await onSubmit();
        }
      } catch (err) {
        clearTimeout(window.neftTimeout);
        const revertLoader = this.props.revertLoader;
        if(_.isFunction(revertLoader)) {
          revertLoader(err.message);
        }
        this.setState({
          errors: err.message,
        });
      } finally {
        this.setState({ showLoader: false });
      }
    }
  };

  resetErrors = () => {
    this.setState({ errors: [] });
    if(_.isFunction(this.props.resetErrors)) {
      this.props.resetErrors();
    }
  }

  updateLabelOfBankAccountNumber = () => {
		const label = "Bank account no.";
		let bankAccountNumberLabel;
    const { business_name, business_entity_id, pancard_name, holder_name } = this.props.formData || {};
    const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == business_entity_id);
    const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name;
    if (filingStatus === PROPRIETORSHIP) {
			bankAccountNumberLabel = business_name ? `${label} of ${business_name}` : label;
		} else if ([PRIVATE_LIMITED, PUBLIC_LIMITED, TRUST, SOCIETIES].includes(filingStatus)){
			bankAccountNumberLabel = holder_name ? `${label} of ${holder_name}` : label;
		} else {
			bankAccountNumberLabel = pancard_name ? `${label} of ${pancard_name}` : label
    }
		this.setState({ bankAccountNumberLabel });
  }
  toggleBankFields = (e) => {
		const businessValues = { true: true, false: false };
    const isBusinessRegistered = businessValues[e.target.value];
    this.setState({ 
      isBusinessRegistered,
      isBusinessRegisteredClicked: true,
     });
    if(e.target.value === 'false') {
      const individualBusinessEntity = this.props.businessEntities.find(o => o.name == INDIVIDUAL);
      this.props.dispatch(change('NowBankDetailsForm', 'business_entity_id', (individualBusinessEntity && individualBusinessEntity.id)));
      this.handleBusinessEntityChange({ value: INDIVIDUAL });
      this.setState({
        enabledBankDetailsFields: DEFAULT_ENABLED_FIELDS,
      })
    } else {
      const selectedBusinessEntity = this.props.businessEntities.find(o => o.id == this.props.formData.business_entity_id);
      const filingStatus = selectedBusinessEntity && selectedBusinessEntity.name; 
      this.setEnabledFieldsBasedOnFilingStatus(filingStatus);
    }
	}
  fetchLocation = stateKey => async (e) => {
    try {
      const {
        pincode_detail: { city, state },
      } = await this.props.fetchLocation(e.target.value);
      this.setState({
        [stateKey]: {
          city,
          state,
        },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  migratingFromMoney = () => (this.props.user && (this.props.user.business_origin === 'PayUmoney' || this.props.user.product === 'PayUmoney'))

  render() {
    const {
      businessEntities,
      disableForm,
      initialValues,
    } = this.props;
    const filteredBusinessEntities = businessEntities.filter((obj, index, arr) => {
      if(obj.name === INDIVIDUAL) return false
      return NOW_BUSINESS_ENTITIES_LIST.includes(obj.name)
    });
    const business_entity_id = (this.props.formData && this.props.formData.business_entity_id) || initialValues.business_entity_id;
    const { isBusinessRegistered, bankDetailsFromIfsc } = this.state;
    
    let businessRegName;
    let businessFilingStatus;
    let bankAccountNoField;
    let bankIFSC;
    let nameOnPanCard;
    let panCardNo;
    let getCustomRadioButtons;
    let holderName;

    const btnClass = classNames({
      'dflt-btn': true,
      'is-loading': this.state.showLoader,
      inactive: (!this.state.showLoader && disableForm) || !!this.props.submitErrors,
    });

    let yesRadioDefaultChecked = {}
    let noRadioDefaultChecked = {}
    if(disableForm || (this.migratingFromMoney() && this.props.isPanFilled)) {
      yesRadioDefaultChecked = { checked: isBusinessRegistered };
      noRadioDefaultChecked = { checked: !isBusinessRegistered };
    }
    getCustomRadioButtons = (
      <div className={`radio-buttons ${disableForm ? 'disabled' : ''} ${(this.migratingFromMoney() && this.props.isPanFilled && !disableForm) ? 'read-only' : ''}`}>
        <label className="label-name">
          <span>Is your business registered with Govt. of India?</span>
            <span className="hint-ques">?
              <span>
                <img src={businessRegHintImg}
                  className="business-reg-hint" />
              </span>
            </span>
        </label>
        <ul className="custom-radio">
          <li>
            <input
              type="radio"
              id="yesId"
              name="selector"
              value="true"
              disabled={disableForm || (this.migratingFromMoney() && this.props.isPanFilled)}
              {...yesRadioDefaultChecked}
              onChange={this.toggleBankFields.bind(this)}
            />
            <label htmlFor="yesId">Yes</label>
            <div className="check" />
          </li>
          <li>
            <input
              type="radio"
              id="noId"
              name="selector"
              value="false"
              disabled={disableForm || (this.migratingFromMoney() && this.props.isPanFilled)}
              {...noRadioDefaultChecked}
              onChange={this.toggleBankFields.bind(this)}
            />
            <label htmlFor="noId">No</label>
            <div className="check">
              <div className="inside" />
            </div>
          </li>
        </ul>
      </div>
    );

    businessFilingStatus = (
      <div className={`filing-status ${(this.migratingFromMoney() && this.props.isPanFilled && !disableForm) ? 'read-only' : ''}`}>
        <Field
          type="custom-select"
          name="business_entity_id"
          component={GenericFormFields}
          label="Select business registration type"
          validate={[required]}
          cssClass={`select_box business-status ${disableForm ? 'disabled' : ''}`}
          selectedOption={business_entity_id} // TODO: should be set by name
          dropDownItems={_.chain(filteredBusinessEntities)
            .keyBy('id')
            .mapValues('name')
            .value()}
          disable={disableForm || (this.migratingFromMoney() && this.props.isPanFilled)}
          onClick={this.handleBusinessEntitySelect}
          onSelect={this.handleBusinessEntityChange}
          isOpen={this.state.isBusinessEntityOpen}
        />
        <span className="hint-ques">?
          <span>
            <img src={regTypeHintImg}
              className="reg-type-hint"
            />
          </span>
        </span>
      </div>
    );

    if (disableForm) {
      businessRegName = (
        <div className="row">
          <span className="label-name d-block">
            {this.state.businessNameLabel}
          </span>
          <div className="text-field text-ellipsis">
            {initialValues.business_name}
          </div>
        </div>
      );
      nameOnPanCard = (
        <div className="row">
          <span className="label-name d-block">{this.state.enabledBankDetailsFields.nameOnPanCard}</span>
          <div className="text-field text-ellipsis">{this.props.initialValues.pancard_name}</div>
        </div>
      );
      panCardNo = (
        <div className="row">
          <span className="label-name d-block">Number on PAN Card</span>
          <div className="text-field text-ellipsis">{this.props.initialValues.pancard_number}</div>
        </div>
      );
      holderName = (
        <div className="row">
          <span className="label-name d-block">{this.state.enabledBankDetailsFields.holderName}</span>
          <div className="text-field text-ellipsis">{this.props.initialValues.holder_name}</div>
        </div>
      );
      bankAccountNoField = (
        <div className="row">
          <span className="label-name d-block">
            {this.state.bankAccountNumberLabel}
          </span>
          <div className="text-field text-ellipsis">
            {initialValues.bank_account_number}
          </div>
          { false && initialValues.bank_update_attempt_left > 0  && <label
            onClick={this.toggeleBankUpdateDialog(true)}
            className="label-name green-label bank-update"
            role="Update Bank"
          >
            Update
          </label>}
        </div>
      );
      bankIFSC = (
        <div className="row">
          <span className="label-name d-block">
            IFSC Code
          </span>
          <div className="text-field text-ellipsis">
            {initialValues.ifsc_code}
          </div>
        </div>
      );
    } else {
      businessRegName = (
        <Field
          type="text"
          name="business_name"
          className="text-field"
          component={GenericFormFields}
          maxLength="100"
          label={this.state.businessNameLabel}
          validate={[required, nameAlphaNumRegex]}
          disabled={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
          disableLabel={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
				  onBlur={this.updateLabelOfBankAccountNumber.bind(this)}
        />
      );
      nameOnPanCard = (
        <Field
          type="text"
          name="pancard_name"
          className="text-field"
          placeholder=""
          component={GenericFormFields}
          maxLength="100"
          label={this.state.enabledBankDetailsFields.nameOnPanCard}
          validate={[required, panNameRegex]}
          onBlur={this.updateLabelOfBankAccountNumber.bind(this)}
          disabled={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
          disableLabel={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
        />
      );
      panCardNo = (
        <Field
          type="text"
          name="pancard_number"
          className="text-field"
          placeholder=""
          component={GenericFormFields}
          maxLength="10"
          minLength="10"
          normalize={(text) => ((text || '').toUpperCase())}
          label={this.state.enabledBankDetailsFields.panCardNo}
          validate={[required, pancardNumberRegex]}
          disabled={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
          disableLabel={this.isFieldReadOnly() || (this.migratingFromMoney() && this.props.isPanFilled)}
        />
      );
      holderName = (
        <Field
          type="text"
          className="text-field form-control"
          name="holder_name"
          component={GenericFormFields}
          label={this.state.enabledBankDetailsFields.holderName}
          validate={[required]}
          onBlur={this.updateLabelOfBankAccountNumber.bind(this)}
          disabled={this.isFieldReadOnly()}
          disableLabel={this.isFieldReadOnly()}
        />
      );
      bankAccountNoField = (
        <Field
          type="text"
          name="bank_account_number"
          className="text-field"
          component={GenericFormFields}
          maxLength="18"
          hint={this.state.accountNumberHint}
          label={this.state.bankAccountNumberLabel}
          validate={[required, minLength9, bankAccountRegex]}
          disabled={this.isFieldReadOnly()}
          disableLabel={this.isFieldReadOnly()}
        />
      );
      bankIFSC = (
        <Field
          type="text"
          name="ifsc_code"
          className="text-field"
          component={GenericFormFields}
          maxLength="11"
          label={this.state.enabledBankDetailsFields.ifscCode}
          validate={[required, ifscCodeRegex]}
          onChange={this.onIfscChange}
          disabled={this.isFieldReadOnly()}
          disableLabel={this.isFieldReadOnly()}
        />
      );
    }

    const hasErrors = this.state.errors.length !== 0;
    return (
      <React.Fragment>
        {hasErrors && (
          <ErrorBox title="Oh no!" onClose={this.resetErrors}>
            {React.Children.toArray(Object.values(this.state.errors))}
          </ErrorBox>
        )}
        <Form onSubmit={this.handleSubmit}>
          <div className="common-bank-form">
            <div className="form-box">
              <div className="onboarding-bank-form">
                <div className="row">
                  {getCustomRadioButtons}
                </div>
                {isBusinessRegistered && businessFilingStatus}
                {this.isFieldEnabled("businessName") && businessRegName}
                {this.isFieldEnabled("nameOnPanCard") && nameOnPanCard}
                {this.isFieldEnabled("panCardNo") && panCardNo}
                {this.isFieldEnabled("holderName") && holderName}
                {this.isFieldEnabled("bankAccountNumber") && bankAccountNoField}
                {bankIFSC}
                { !disableForm && <div className="row block-container">
                  <label
                    id="searchIfscBtn"
                    onClick={this.onSearchIfscClick}
                    className={`label-name green-label bottom-label ${this.isFieldReadOnly() ? 'disabled' : ''}`}
                  >
                    Search
                  </label>
                </div>}
                {bankDetailsFromIfsc && (
                  <div className="bank-ifsc">
                    <p className="address-section m-0">
                      {bankDetailsFromIfsc.bank_name}
                    </p>
                    <p className="address-section m-0">
                      {bankDetailsFromIfsc.branch_name}
                    </p>
                    <p className="address-section m-0">
                      {bankDetailsFromIfsc.city}
                    </p>
                    <p className="address-section m-0">
                      {bankDetailsFromIfsc.state}
                    </p>
                    <p className="address-section m-0">
                      {bankDetailsFromIfsc.address}
                    </p>
                  </div>
                )}
                <div className="row button-row">
                  <Button
                    type="submit"
                    className={btnClass}
                  >{this.props.bankFlow ? 'Next' : 'Submit'}</Button>
                </div>
              </div>
              {this.props.bankFlow &&
                <div className="text-center skip-for-now">
                  <span className="clickable-txt" onClick={() => skipToChooseWebsiteName({user: this.props.user})}>
                    SKIP FOR NOW
                  </span>
                </div>
              }
            </div>
          </div>
        </Form>
        <IfscSearchModal
          cssClass="ifscSearchModal"
          isOpen={this.state.isIfscDialogOpen}
          onClose={this.onIfscClose}
          onSubmit={this.onIfscSubmit}
          enableBackBtn={this.state.enableBackOnIfsc}
          onBackClick={this.onBackClick}
        />
        { initialValues.bank_update_attempt_left > 0 && <BankUpdateModal
          bankDetailsFromIfsc={bankDetailsFromIfsc}
          merchantUuid={this.props.user.uuid}
          merchantEmail={this.props.user.email}
          updateAttemptsLeft={initialValues.bank_update_attempt_left}
          onSearchIfscClick={this.onSearchIfscClick}
          onIfscChange={this.onIfscChange}
          oldValues={initialValues}
          isOpen={this.state.isBankUpdateDialogOpen}
          onClose={this.toggeleBankUpdateDialog(false)}
          onSubmit={this.handleBankUpdateSubmit}
          businessFilingStatus={this.getselectedBusinessEntity()}
          tncPath={`termsandconditions`}
        /> }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
	return {
    formData: state.form.NowBankDetailsForm && state.form.NowBankDetailsForm.values,
    submitErrors: state.form.NowBankDetailsForm && state.form.NowBankDetailsForm.submitErrors,
	};
}

NowBankDetailsForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
  form: 'NowBankDetailsForm', // a unique identifier for this form
  onSubmitFail: errors => {
    console.log(errors);
  },
})(NowBankDetailsForm);

NowBankDetailsForm = connect(mapStateToProps)(NowBankDetailsForm);

export default NowBankDetailsForm;
