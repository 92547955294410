import * as C from '../constants/merchantTypes';

const merchantPricingPlan = {
  fetched: false,
  fetching: false,
  error: null,
  data: { },
};

export default function merchantPricingPlanReducer(state = merchantPricingPlan, action) {
  switch (action.type) {
    case C.FETCH_MERCHANT_PRICING_PLAN_INITIATED:
    case C.UPDATE_MERCHANT_PRICING_PLAN_INITIATED:
      return { ...state, fetching: true, fetched: false };
    case C.FETCH_MERCHANT_PRICING_PLAN_SUCCESS:
    case C.UPDATE_MERCHANT_PRICING_PLAN_SUCCESS:
      return { ...state, data: action.payload, fetching: false, fetched: true };
    case C.FETCH_MERCHANT_PRICING_PLAN_FAILED:
    case C.UPDATE_MERCHANT_PRICING_PLAN_FAILED:
      return { ...state, data: {}, fetching: false, fetched: false };
    default:
      return state;
  }
}
