import { combineReducers } from 'redux';
import mapValues from 'lodash/mapValues';

import * as C from '../constants/merchantTypes';
import { FETCH_DOCUMENT_CATEGORY_AND_TYPES_SUCCESS } from '../constants/documentTypes';

/**
 * business entity reducer responsible for fething business registration type
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export const aclPermissionReducer = (state = [], action) => {
  switch (action.type) {
    case C.FETCH_ACL_PERMISSIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

/**
 * business entity reducer responsible for fething business registration type
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export function businessEntityReducer(state = [], action) {
  switch (action.type) {
    case C.FETCH_BUSINESS_ENTITIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

/**
 * business category reducer responsible for fething business category type
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export function businessCategoryReducer(state = [], action) {
  switch (action.type) {
    case C.FETCH_BUSINESS_CATEGORIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

/**
 * ifsc reducer for fetching ifsc code from bank details
 * @param  {object} state  initial state
 * @param  {object} action redux action object
 * @return {object}        redux store state
 */
export function ifscDetailsReducer(
  state = {
    bankNames: [],
    bankStates: [],
    bankCities: [],
    bankBranchesIfscDetails: [],
  },
  action,
) {
  switch (action.type) {
    case C.FETCH_BANK_NAMES_FOR_IFSC_SUCCESS:
      return {
        ...state,
        bankNames: action.payload.bank_names,
      };
    case C.FETCH_BANK_CITIES_FOR_IFSC_SUCCESS:
      return {
        ...state,
        bankCities: action.payload.cities,
      };
    case C.FETCH_BANK_STATES_FOR_IFSC_SUCCESS:
      return {
        ...state,
        bankStates: action.payload.states,
      };
    case C.FETCH_BANK_BRANCHES_FOR_IFSC_SUCCESS:
      return {
        ...state,
        bankBranchesIfscDetails: action.payload.ifsc_details,
      };
    default:
      return state;
  }
}

export function pricingPlansReducer(state = [], action) {
  switch (action.type) {
    case C.FETCH_PRICING_PLANS_SUCCESS:
      return action.payload.pricingPlans;
    default:
      return state;
  }
}

export function documentCategoryAndTypesReducer(
  state = {
    documentCategories: {},
    documentTypes: {},
  },
  action,
) {
  switch (action.type) {
    case FETCH_DOCUMENT_CATEGORY_AND_TYPES_SUCCESS:
      return {
        ...state,
        documentCategories: mapValues(
          action.payload.document_categories,
          cat => cat.id,
        ),
        documentTypes: mapValues(action.payload.document_types, typ => typ.id),
      };
    default:
      return state;
  }
}

export default combineReducers({
  aclPermissions: aclPermissionReducer,
  businessEntities: businessEntityReducer,
  ifscDetails: ifscDetailsReducer,
  businessCategories: businessCategoryReducer,
  pricingPlans: pricingPlansReducer,
  documentCategoryAndTypes: documentCategoryAndTypesReducer,
});
