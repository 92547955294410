import { useState, useRef, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useDocumentActivityListener } from './useDocumentActivityListener';
export const sessionTimeoutCookieKey = 'session_timeout';
const NOOP = () => { };
const EMPTY_OBJECT = {};
const FALSE = false;
const ALLOWED_IDLE_TIME_IN_SECONDS = 60 * 60 * 3; // 3 hours
const ONE_MINUTE_IN_SECONDS = 60;

export const useAutoLogout = ({
  aboutToExpireCallback = NOOP, // would be trigger just before 1 minute of expiry
  allowedIdleTimeInSeconds = ALLOWED_IDLE_TIME_IN_SECONDS,
  disableCallbackOnActivity = FALSE,
  isLoggedIn = FALSE,
} = EMPTY_OBJECT) => {
  const cookies = new Cookies();
  const options = {
    domain: 'payu.in',
    // expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // expires in 24 hours
    path: '/',
    secure: true
  };
  const [timeLeftToTimeoutInSeconds, setTimeLeftToTimeoutInSeconds] = useState(0);
  const disableCallbackOnActivityRef = useRef();
  disableCallbackOnActivityRef.current = disableCallbackOnActivity;
  const clearCookie = () => {
    cookies.remove(sessionTimeoutCookieKey, options);
    setTimeLeftToTimeoutInSeconds(0)
  }
  const onLogout = () => {
    try {
      cookies.remove(sessionTimeoutCookieKey, options)
      window.location.href = `/account/service_logout?redirect_to=${window.location.origin}/app/session-logged-out`;
    } catch { }
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      const sessionTimeout = cookies.get(sessionTimeoutCookieKey);
      if (sessionTimeout) {
        try {
          const timeDifferenceInMilliSeconds = new Date(sessionTimeout) - new Date();
          setTimeLeftToTimeoutInSeconds(timeDifferenceInMilliSeconds / 1000)
          if (Math.ceil(timeDifferenceInMilliSeconds/1000) <= ONE_MINUTE_IN_SECONDS && timeDifferenceInMilliSeconds > 0) {
            if (!disableCallbackOnActivityRef.current)
              aboutToExpireCallback();

          } else if (timeDifferenceInMilliSeconds <= 0) {
            onLogout()
          }
        } catch {
        }
      }
    }, 1000);
    return () => clearInterval(intervalId)
  }, [])

  const isLoggedInRef = useRef();
  isLoggedInRef.current = isLoggedIn;
  const allowedIdleTimeInSecondsRef = useRef();
  allowedIdleTimeInSecondsRef.current = allowedIdleTimeInSeconds;
  const setAutoLogoutTimeout = () => {
    try {
      if (isLoggedInRef.current) {
        cookies.set(sessionTimeoutCookieKey, new Date(Date.now() + allowedIdleTimeInSecondsRef.current * 1000), options);
      } else {
        cookies.remove(sessionTimeoutCookieKey, options)
      }
    } catch { }
  }
  const activityCallback = () => {
    if (!disableCallbackOnActivityRef.current) {
      setAutoLogoutTimeout()
    }
  }

  useDocumentActivityListener({ activityCallback })
  return {
    setAutoLogoutTimeout,
    timeLeftToTimeoutInSeconds,
    onLogout,
    clearCookie,
  }
}