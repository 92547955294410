import React, { Children } from 'react';
import PropTypes from 'prop-types';


export default class FilterDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentLabel: props.label,
    };
  }

  onClickingItem(item) {
    this.setState({ currentLabel: item.value });
    this.props.onItemClick(item.key, item.value);
  }

  render() {
    return (
      <div className="filter-dropdown">
        <div className="filter-dropdown-txt" onClick={this.props.onClick}>
          {this.state.currentLabel}
        </div>
        <div className={`filter-dropdown-box ${this.props.isOpen ? '' : 'd-none'}`}>
          {Children.toArray(this.props.dropDownItems
            .map(item => (
              <div
                className="filter-dropdown-box-item"
                onClick={() => this.onClickingItem(item)}
              >
                {item.value}
              </div>
            )))}
        </div>
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onItemClick: PropTypes.func,
  dropDownItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FilterDropdown.defaultProps = {
  onClick: () => {},
  onItemClick: () => {},
  isOpen: true,
  label: '',
};
